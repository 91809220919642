/* Code Quality: Bad */
import React from 'react'
import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'
import queryString from 'qs'

import { withUser } from 'data/wrappers/WithUser'
import SignInFlow from 'features/auth/stacker-auth/SignInFlow'
import publicAsset from 'utils/publicAsset'

import SignupFrame from './ui/SignupFrame'

class SignupStartPage extends React.Component {
    state = {
        step: 1,
        queryString: queryString.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
        }),
    }

    render() {
        const image = this.state.queryString && this.state.queryString.image

        return (
            <SignupFrame
                noPadding
                rightContents={
                    <img
                        style={{
                            maxHeight: '100%',
                        }}
                        alt={
                            image
                                ? ''
                                : "I love Stacker, it's the missing piece in the Airtable jigsaw. Jonathan Gaunt. CEO, FDWorks Accounting"
                        }
                        src={
                            image || publicAsset('/static/media/airtable-sheets-stripe-splash.png')
                        }
                    />
                }
            >
                <SignInFlow isSignUp />
            </SignupFrame>
        )
    }
}

SignupStartPage.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
}

export default withUser(withRouter(SignupStartPage))
