import React, { memo, useState } from 'react'

import classnames from 'classnames'

import { FavoriteType } from 'data/hooks/favorites/types'
import UserMenu from 'features/AppBar/UserMenu'
import { StackIconBadge } from 'features/core/StackIconBadge'
import { useFavoritesEnabled } from 'features/favorites/useFavoritesEnabled'
import { useNavContext } from 'features/utils/NavContext'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import { FloatingAddNewButton } from 'features/workspace/FloatingAddNewButton/FloatingAddNewButton'
import AppNavTree from 'features/workspace/Sidebar/AppNavTree'
import { ThemeConfig } from 'features/workspace/types'
import { wsNavThemeToV3 } from 'features/workspace/WorkspaceSettingsModalUi'
import analytics from 'utils/analytics'

import { Avatar, Flex, ScrollBox, Text, Tooltip } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
// import { LinkButton } from 'ui/components/LinkButton'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { CollapseControls } from './CollapseControls'
import { FavoriteButton } from './FavoriteButton'
import { SidebarUserMenuButton } from './SidebarUserMenuButton'
import { WorkspaceSidebarTop } from './WorkspaceSidebarTop'

import { SidebarHoverClass } from './WorkspaceSidebarStyles.css'

type ApplicationSidebarProps = {
    navTheme: ThemeConfig
    stack: StackDto
    shouldShowWorkspaceControls?: boolean
}

export const ApplicationSidebar = memo<ApplicationSidebarProps>(function Sidebar({
    navTheme,
    stack,
    shouldShowWorkspaceControls,
}) {
    const { sidebarState, setSidebarState, isPoppedOut } = useNavContext()

    const { handleClickOutside: handleClickOutsideSidePane } = useSlidingPane()

    const favoritesEnabled = useFavoritesEnabled()

    return (
        <Box
            flex
            column
            flexGrow={1}
            position="relative"
            alignItems="stretch"
            onClick={handleClickOutsideSidePane}
            maxWidth="full"
            borderWidth={0}
            borderColor="border"
            background="surface"
            borderStyle="base"
            borderRightWidth={1}
            className={classnames(ONBOARDING_CLASSES.APP_NAV_MENU, SidebarHoverClass)}
            style={{
                minWidth: 0,
            }}
            data-testid="app-nav-menu"
        >
            {shouldShowWorkspaceControls && (
                <>
                    <WorkspaceSidebarTop />
                    <Divider my="m" />
                </>
            )}
            <Box
                flex
                px="l"
                pt="m"
                pb="xs"
                pr="xs"
                style={{ color: navTheme.textBright }}
                justifyContent="space-between"
                center
                overflow="hidden"
            >
                <Box flex center shrink>
                    <StackIconBadge stack={stack} size={28} mr="m" mb="xs" />
                    <Tooltip
                        label={stack.name}
                        placement="right"
                        style={{
                            flexShrink: 1,
                            minWidth: 0,
                        }}
                    >
                        <div
                            style={{
                                ...V4DesignSystem.h2,
                                color: navTheme.textBright,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {stack.name}
                        </div>
                    </Tooltip>
                    {favoritesEnabled && (
                        <FavoriteButton
                            targetType={FavoriteType.App}
                            stack_id={stack._sid}
                            mb="xs"
                            ml="m"
                        />
                    )}
                </Box>
            </Box>
            <ScrollBox
                marginTop="10px"
                overflowY="auto"
                flexGrow={1}
                flexShrink={1}
                scrollbarColor={navTheme.highlightColor}
            >
                <AppNavTree stack={stack} navTheme={wsNavThemeToV3(navTheme)} />
            </ScrollBox>
            <FloatingAddNewButton p={3} />

            {shouldShowWorkspaceControls && (
                <>
                    <Divider />
                    <Box noShrink>
                        <SidebarUserMenuButton navTheme={navTheme} />
                    </Box>
                </>
            )}
            {!shouldShowWorkspaceControls && (
                <CollapseControls
                    isCollapsed={sidebarState === 'fullyCollapsed'}
                    toggleCollapsed={() =>
                        setSidebarState(
                            sidebarState !== 'fullyCollapsed' ? 'fullyCollapsed' : 'open'
                        )
                    }
                    isPoppedOut={isPoppedOut}
                />
            )}
        </Box>
    )
})

type UserMenuButtonProps = React.ComponentPropsWithoutRef<typeof UserMenu> & {
    user: UserDto
    navTheme: ThemeConfig
    mobile?: boolean
    iconOnly?: boolean
}

export const UserMenuButton: React.FC<UserMenuButtonProps> = ({
    user,
    navTheme,
    mobile,
    iconOnly,
    ...otherProps
}) => {
    const [userProfileMenuOpen, setUserProfileMenuOpen] = useState(false)

    const userMenuStyle = {
        backgroundColor: navTheme?.bgScdNav || navTheme?.button?.background || '#F4F5F8',
        color:
            navTheme?.textColorBright ||
            navTheme?.button?.color ||
            navTheme?.textBright ||
            '#ffffff',
    }

    const icon = user?.avatar ? <Avatar src={user.avatar} size="xs" /> : <Icon name="User" />

    const menuProps = mobile
        ? {
              height: '24px',
              width: '24px',
              fontSize: 'sm',
          }
        : { width: !iconOnly ? '100%' : null, height: 10 }

    return (
        <UserMenu
            onClick={() => {
                analytics.track('user menu clicked', {
                    from: 'side nav',
                })
                setUserProfileMenuOpen(!userProfileMenuOpen)
            }}
            {...menuProps}
            {...otherProps}
            style={userMenuStyle}
        >
            {mobile || iconOnly ? (
                icon
            ) : (
                <Flex row width="100%" justify="space-between" align="center" wrap="nowrap">
                    <Flex row align="center" wrap="nowrap" flexShrink={1} minWidth={0}>
                        {icon}
                        <Text
                            color={navTheme?.textBright}
                            variant="profile"
                            ml="10px"
                            textOverflow="ellipsis"
                            overflow="hidden"
                        >
                            {user?.name || user?.email}
                        </Text>
                    </Flex>
                    <Icon name={userProfileMenuOpen ? 'ChevronUp' : 'ChevronDown'} ml="m" />
                </Flex>
            )}
        </UserMenu>
    )
}
