import React, { useCallback, useMemo, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useRoles } from 'data/hooks/roles'
import { slideInLeftAnimation } from 'features/AppSettings/AppSettingsModalSidebar'

import { Flex, SidebarItem, Text } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import AddRole from './AppSettingsModalAddRole'
import RolePage from './AppSettingsModalRolePage'

type UseRolesData = {
    data?: RoleDto[]
}

export default function AppSettingsModalRoles({
    defaultActiveRoleId,
}: {
    defaultActiveRoleId: string
}): JSX.Element {
    const { selectedStack } = useAppContext()

    const [addRoleModalOpen, setAddRoleModalOpen] = useState(false)
    const { data: allRoles }: UseRolesData = useRoles()

    const roles = useMemo(
        () => allRoles?.filter(({ stack_id }) => stack_id === selectedStack?._sid) || [],
        [allRoles, selectedStack]
    )

    const filteredRoles = useMemo(() => {
        return roles.filter((role) => role.api_name !== 'internal_admin')
    }, [roles])
    const [activeRoleId, setActiveRoleId] = useState<string | null>(
        defaultActiveRoleId || filteredRoles[0]?._sid
    )

    const resetActiveRole = useCallback(
        (): void => setActiveRoleId(defaultActiveRoleId || filteredRoles[0]?._sid),
        [defaultActiveRoleId, filteredRoles]
    )

    return (
        <>
            <Flex
                column
                height="100%"
                width="100%"
                align="stretch"
                wrap="nowrap"
                position="relative"
            >
                <Flex p={2} bg={V4DesignSystem.colors.gray[50]}>
                    <Text ml={2} display="inline">
                        Users permissions in the app are determined by their roles.
                    </Text>
                </Flex>
                <Flex style={{ alignItems: 'stretch', flexWrap: 'nowrap' }}>
                    <Flex
                        column
                        style={{
                            width: 161,
                            borderRight: `1px solid ${V4DesignSystem.colors.gray[100]}`,
                            overflowY: 'auto',
                            padding: '20px 10px',
                            flexShrink: 0,
                            zIndex: 1,
                            animation: `${slideInLeftAnimation.name} 0.2s ease-out`,
                        }}
                    >
                        {filteredRoles.map((role) => (
                            <SidebarItem
                                key={role._sid}
                                active={role._sid === activeRoleId}
                                onChange={() => {
                                    setActiveRoleId(role._sid)
                                }}
                                style={{ marginBottom: 1, width: 140 }}
                            >
                                {role.label}
                            </SidebarItem>
                        ))}
                        <SidebarItem
                            icon="add"
                            style={{ marginTop: 8, width: 140 }}
                            onChange={() => {
                                setActiveRoleId(null)
                                setAddRoleModalOpen(true)
                            }}
                        >
                            Add a role
                        </SidebarItem>
                    </Flex>
                    <div
                        style={{
                            flexGrow: 1,
                            padding: '20px 10px',
                            overflow: 'auto',
                        }}
                    >
                        {activeRoleId && (
                            <RolePage
                                key={activeRoleId}
                                role={roles.find(({ _sid }) => _sid === activeRoleId)}
                                resetActiveRole={resetActiveRole}
                            />
                        )}
                        {!activeRoleId && addRoleModalOpen && (
                            <AddRole
                                onSubmit={(data: any) => {
                                    setActiveRoleId(data._sid)
                                    setAddRoleModalOpen(false)
                                }}
                            />
                        )}
                    </div>
                </Flex>
            </Flex>
        </>
    )
}
