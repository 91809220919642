import React from 'react'

import { Box } from 'ui/components/Box'
import { Progress } from 'ui/components/Progress'
import { Skeleton } from 'ui/components/Skeleton'

import { usePercentageAttributeDisplayState } from './hooks/usePercentageAttributeDisplayState'
import { AttributeDisplayComponent, PercentageDisplay } from './types'

import { ProgressStyle } from './PercentageAttributeDisplay.css'

type PercentageAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Box> & {
    valueDisplay?: PercentageDisplay
    isLoading?: boolean
}

export const PercentageAttributeDisplay: AttributeDisplayComponent<
    number,
    PercentageAttributeDisplayProps
> = React.memo(function PercentageAttributeDisplay({ value, valueDisplay, isLoading, ...props }) {
    const { content, cappedValue } = usePercentageAttributeDisplayState({ value, isLoading })

    return (
        <Skeleton isLoading={isLoading} showChildren>
            <Box as={isLoading ? 'span' : undefined} {...props}>
                {valueDisplay === 'percentBar' ? (
                    <Progress
                        layout="horizontal"
                        value={cappedValue}
                        showHelperText
                        className={ProgressStyle}
                    />
                ) : (
                    content
                )}
            </Box>
        </Skeleton>
    )
})
