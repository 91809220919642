import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState, VFC } from 'react'

import { FlexProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { useStackRoles } from 'data/hooks/roleHelpers'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { usePreviewServiceContext } from 'features/PreviewService/PreviewServiceContext'
import { useGetRoleLabel } from 'features/utils/useGetRoleLabel'

import { Avatar, Box, Collapse, Divider, Flex, ScrollBox, SearchInput, Text } from 'v2/ui'
import VirtualizedList from 'v2/ui/components/VirtualizedList'
import { useScrollIntoView } from 'v2/ui/hooks/useScrollIntoView'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import stackerTheme from 'v2/ui/theme/styles/default'

const { colors } = stackerTheme()

const MenuHeader = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 16px;
`

const ListItem = styled.div<{ highlighted: boolean }>`
    cursor: pointer;

    display: flex;
    align-items: center;
    padding: 6px 16px;

    background: ${(props) => (props.highlighted ? colors.grey[100] : null)};
    &:hover {
        background: ${colors.grey[100]};
    }
`

type Props = FlexProps & {
    setIsLoading: (isLoading: boolean) => void
    onSelected?: () => void
}

const PreviewAsList: VFC<Props> = ({ setIsLoading, onSelected, ...props }) => {
    const [highlightedItem, setHighlightedItem] = useState<UserDto | RoleDto | undefined>()
    const [filter, setFilter] = useState<string | undefined>()

    const { user: currentUser } = useAuthContext()
    const { data: roles } = useStackRoles()
    const { data: users = [] } = useAppUsersForAdmin(true, undefined, {
        includeUsersWithNoAccess: false,
    })
    const { previewAsUser, previewAsRole } = usePreviewServiceContext()

    const filteredRoles = useMemo(
        () =>
            roles.filter(
                (role) =>
                    role.api_name !== 'internal_admin' &&
                    (!filter || role.label.toLowerCase().startsWith(filter))
            ),
        [filter, roles]
    )

    let filteredUsers = useMemo(() => {
        // If access is disabled for the stack, we should not include users in the preview list
        return (
            users.filter(
                (user: any) =>
                    user.role &&
                    user._sid !== currentUser?._sid &&
                    (!filter ||
                        user.name?.toLowerCase().includes(filter) ||
                        user.email?.toLowerCase().includes(filter))
            ) || []
        )
    }, [currentUser?._sid, filter, users])

    const visibleItems = filteredRoles.concat(filteredUsers)
    const visibleItemsCount = visibleItems.length

    useEffect(() => {
        // If the items list is filtered down to just one item, highlight it
        // by default so the user can just hit enter to select
        if (visibleItemsCount === 1) {
            setHighlightedItem(visibleItems[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleItemsCount])

    const previewUser = useCallback(
        async (user: UserDto) => {
            setIsLoading(true)
            await previewAsUser(user._sid).finally(() => setIsLoading(false))
            onSelected?.()
        },
        [onSelected, previewAsUser, setIsLoading]
    )

    const previewRole = useCallback(
        async (roleApiName: string) => {
            setIsLoading(true)
            await previewAsRole(roleApiName).finally(() => setIsLoading(false))
            onSelected?.()
        },
        [onSelected, previewAsRole, setIsLoading]
    )

    // keyboard nav while filtering the list
    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            let idx
            switch (e.key) {
                case 'Enter':
                    if (filteredUsers.includes(highlightedItem as UserDto)) {
                        previewUser(highlightedItem as UserDto)
                    } else if (filteredRoles.includes(highlightedItem)) {
                        previewRole((highlightedItem as RoleDto).api_name)
                    }
                    break
                case 'ArrowDown':
                    idx = visibleItems.indexOf(highlightedItem)
                    if (idx < visibleItems.length - 1) {
                        setHighlightedItem(visibleItems[idx + 1])
                    } else {
                        setHighlightedItem(visibleItems[0])
                    }
                    break
                case 'ArrowUp':
                    idx = visibleItems.indexOf(highlightedItem)
                    if (idx > 0) {
                        setHighlightedItem(visibleItems[idx - 1])
                    } else {
                        setHighlightedItem(visibleItems[visibleItems.length - 1])
                    }
                    break
                default:
                    break
            }
        },
        [filteredRoles, filteredUsers, highlightedItem, previewRole, previewUser, visibleItems]
    )

    return (
        <Flex
            column
            wrap="nowrap"
            align="stretch"
            fontSize="sm"
            onKeyDown={handleKeyDown}
            {...props}
        >
            <SearchInput
                value={filter}
                variant="noBorder"
                borderColor="gray.100"
                placeholder="Search for role or user"
                // @ts-ignore
                onChange={(value: string | undefined) => setFilter(value?.toLowerCase())}
                autoFocus
                mx={2}
            />
            <Divider />
            <ScrollBox overflowY="auto">
                <Collapse isOpen={!!filteredRoles.length}>
                    <Box my={2}>
                        <MenuHeader>
                            <Text color="gray.400" fontWeight="bold">
                                Roles
                            </Text>
                        </MenuHeader>

                        {filteredRoles.map((x) => (
                            <RoleListItem
                                role={x}
                                key={x._sid}
                                onClick={() => previewRole(x.api_name)}
                                highlighted={x === highlightedItem}
                            />
                        ))}
                    </Box>
                </Collapse>
                {filteredUsers.length > 0 && (
                    <Box my={2}>
                        <MenuHeader>
                            <Text color="gray.400" fontWeight="bold">
                                Users
                            </Text>
                        </MenuHeader>
                        <VirtualizedList
                            estimatedItemSize={45}
                            items={filteredUsers}
                            renderItem={({ item: user }) => (
                                <UserListItem
                                    key={user._sid}
                                    user={user}
                                    onClick={() => previewUser(user)}
                                    highlighted={user === highlightedItem}
                                />
                            )}
                        />
                    </Box>
                )}
                <Collapse isOpen={!!(!filteredUsers.length && !filteredRoles.length)}>
                    <Text m={4} color="gray.400">
                        No matching roles or users.
                    </Text>
                </Collapse>
            </ScrollBox>
        </Flex>
    )
}

const RoleListItem: VFC<{ role: RoleDto; highlighted: boolean; onClick: () => void }> = ({
    role,
    onClick,
    highlighted,
}) => {
    const ref = useScrollIntoView(highlighted, false)
    return (
        <ListItem ref={ref} role="button" onClick={onClick} highlighted={highlighted}>
            <Text fontWeight="bold">{role.label}</Text>
        </ListItem>
    )
}
const UserListItem: VFC<{ user: UserDto; highlighted: boolean; onClick: () => void }> = ({
    user,
    onClick,
    highlighted,
}) => {
    const ref = useScrollIntoView(highlighted, false)
    const roleLabel = useGetRoleLabel(user.role)

    return (
        <ListItem
            ref={ref}
            role="button"
            onClick={onClick}
            highlighted={highlighted}
            className={STYLE_CLASSES.DATA_BLOCK}
        >
            <Avatar src={user.avatar} name={user.name} size="sm" mr={2} />
            <Box flexShrink={1} minWidth={0}>
                <Box display="flex" alignItems="center" mb={1}>
                    <Text
                        fontWeight="bold"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                    >
                        {user.name}
                    </Text>
                    <Text
                        ml={1}
                        fontWeight="bold"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        color={colors.neutral[800]}
                    >
                        ({roleLabel})
                    </Text>
                </Box>

                <Text
                    color="neutral.800"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                >
                    {user.email}
                </Text>
            </Box>
        </ListItem>
    )
}

export default PreviewAsList
