import { getCurrentStackId } from 'app/GlobalStaticState'
import settings from 'app/settings'
import { Token } from 'data/utils/getUserToken'

type getOAuthBounceUrlProps = {
    dataConnection: DataConnectionDto
    apiToken: Token
    returnUrl: string
    shouldOverwriteToken: boolean
}

export const getOAuthBounceUrl = ({
    dataConnection,
    apiToken,
    returnUrl,
    shouldOverwriteToken,
}: getOAuthBounceUrlProps): string => {
    return `${settings.OAUTH_REDIRECT_URL}authenticate/oauth-connection?provider=${
        dataConnection.type
    }&api_token=${apiToken}&stack_id=${getCurrentStackId()}&connection=${
        dataConnection._sid
    }&is_redirect=1&return_url=${returnUrl}${shouldOverwriteToken ? '&overwrite_token=1' : ''}`
}
