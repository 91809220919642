// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Switch } from '@chakra-ui/react'
import { isEqual } from 'lodash'

import InputGroup from 'features/admin/data-connector/InputGroup'
import { testConnection } from 'features/admin/data-connector/testConnectionLogic'

import { Collapse, Flex, Text } from 'v2/ui'
import LoadingOverlay from 'v2/ui/components/LoadingOverlay'

import { getPostgresConnectionDetails } from './getPostgresConnectionDetails'
import { Placeholders } from './postgresConnectorConstants'
import { PostgresConnectionDetailsFormData } from './postgresConnectorTypes'

type PostgresConnectionDetailsFormProps =
    | {
          isCreate: true
          onSubmit: (data: PostgresConnectionDetailsFormData, paramsChanged: boolean) => void
          setNextHandler: (cb: () => void) => void
          setNextButtonText: (text: string) => void
          isTesting: boolean
          setIsTesting: (isTesting: boolean) => void
      }
    | {
          dataConnection: DataConnectionDto
          isCreate: false
          onSubmit: (data: PostgresConnectionDetailsFormData, paramsChanged: boolean) => void
          setNextHandler: (cb: () => void) => void
          setNextButtonText: (text: string) => void
          isTesting: boolean
          setIsTesting: (isTesting: boolean) => void
      }

export const PostgresConnectionDetailsForm = (
    props: PostgresConnectionDetailsFormProps
): React.ReactElement => {
    const { isCreate, onSubmit, setNextHandler, setNextButtonText, isTesting, setIsTesting } = props
    const [connectionDetails, setConnectionDetails] = useState<PostgresConnectionDetails | null>(
        null
    )
    const { register, formState, getValues, handleSubmit, setValue, watch, trigger } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        criteriaMode: 'firstError',
        defaultValues: {
            is_read_only: props.isCreate ? false : props.dataConnection.is_read_only,
            is_changing_password: isCreate,
            sql_hostname: '',
            sql_port: Placeholders.PORT,
            sql_database: '',
            sql_schema: '',
            sql_username: '',
            sql_password: '',
        },
    })
    const [isLoading, setIsLoading] = useState(!isCreate)

    const [testSuccessful, setTestSuccessful] = useState(false)
    const [connectionError, setConnectionError] = useState<null | string>(null)

    // value of tested db config (order matters and is preserved)
    const [testedValues, setTestedValues] = useState<Array<string | number | undefined>>([])
    const [hasUntestedChanges, setHasUntestedChanges] = useState(true)

    const DB_CONFIG_FIELDS = [
        'sql_hostname',
        'sql_port',
        'sql_database',
        'sql_schema',
        'sql_username',
        'sql_password',
    ] as const
    const watchedDBConfigValues = watch(DB_CONFIG_FIELDS)

    useEffect(
        () => {
            if (!props.isCreate) {
                getPostgresConnectionDetails(props.dataConnection._sid).then((details) => {
                    setConnectionDetails(details)
                    setValue('sql_hostname', details.sql_hostname)
                    setValue('sql_port', parseInt(details.sql_port as string, 10))
                    setValue('sql_database', details.sql_database)
                    setValue('sql_schema', details.sql_schema)
                    setValue('sql_username', details.sql_username)
                    setIsLoading(false)
                })
            }
        },
        // The hook is not liking the typing. :(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        props.isCreate
            ? [props.isCreate, setValue]
            : [props.dataConnection, props.isCreate, setValue]
    )

    const dcIsReadOnlyDep = props.isCreate ? undefined : props.dataConnection.is_read_only
    const saveHandler = useCallback(
        () => {
            handleSubmit(() => {
                let paramsChanged = true
                if (!props.isCreate) {
                    const oldValues = {
                        is_read_only: props.dataConnection.is_read_only,
                        ...(connectionDetails ? connectionDetails : {}),
                    }
                    const formValues = getValues()
                    const newValues = {
                        is_read_only: formValues.is_read_only,
                        sql_hostname: formValues.sql_hostname,
                        sql_port: formValues.sql_port,
                        sql_database: formValues.sql_database,
                        sql_schema: formValues.sql_schema,
                        sql_username: formValues.sql_username,
                    }

                    // if only the name/label has changed, we don't want to trigger a schema sync
                    paramsChanged =
                        !isEqual(oldValues, newValues) || formValues.is_changing_password
                }
                onSubmit(getValues(), paramsChanged)
            })()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleSubmit, onSubmit, props.isCreate, connectionDetails, getValues, dcIsReadOnlyDep]
    )

    const testHandler = useCallback(() => {
        const onTestSuccess = () => {
            setTestSuccessful(true)
            setConnectionError(null)

            setNextButtonText(isCreate ? 'Create connection' : 'Save connection')
            setNextHandler(saveHandler)
        }
        const onTestFailure = (errorMessage: string) => {
            setTestSuccessful(false)
            setConnectionError(errorMessage)
        }

        trigger().then((isFormValid: boolean) => {
            if (isFormValid) {
                const {
                    is_changing_password: _is_changing_password,
                    is_read_only: _is_read_only,
                    ...secret_options
                } = getValues()

                const data = {
                    type: 'postgresV2' as const,
                    updated_secret_options: secret_options,
                }
                if (!props.isCreate) {
                    data['data_connection_sid'] = props.dataConnection._sid
                }

                setIsTesting(true)

                testConnection(data).then((result) => {
                    if (result.success) {
                        onTestSuccess()
                    } else {
                        onTestFailure(result.error)
                    }
                    setIsTesting(false)
                })
                setTestedValues(DB_CONFIG_FIELDS.map((fieldName) => secret_options[fieldName]))
                setHasUntestedChanges(false)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getValues,
        trigger,
        setNextButtonText,
        setNextHandler,
        saveHandler,
        props.isCreate,
        setIsLoading,
    ])

    useEffect(() => {
        if (!isEqual(watchedDBConfigValues, testedValues)) {
            setNextButtonText('Test connection')
            setNextHandler(testHandler)

            setHasUntestedChanges(true)
        }
    }, [
        watchedDBConfigValues,
        testedValues,
        setNextButtonText,
        setNextHandler,
        testHandler,
        setHasUntestedChanges,
        saveHandler,
    ])

    const isPasswordFieldDisabled = !watch('is_changing_password') && !isCreate
    return (
        <>
            <LoadingOverlay isOpen={isLoading} />
            <Row>
                <Column size={3}>
                    <InputGroup
                        errors={formState.errors}
                        title="Hostname"
                        register={register('sql_hostname', {
                            required: 'Hostname is required',
                            minLength: 1,
                        })}
                        placeholder=""
                        mr={2}
                    />
                </Column>
                <Column>
                    <InputGroup
                        errors={formState.errors}
                        title="Port"
                        register={register('sql_port', {
                            required: 'Port is required',
                            minLength: 1,
                        })}
                        placeholder={Placeholders.PORT.toString()}
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <InputGroup
                        errors={formState.errors}
                        title="Database"
                        register={register('sql_database', {
                            required: 'Database is required',
                            minLength: 1,
                        })}
                        placeholder=""
                        mr={2}
                    />
                </Column>
                <Column>
                    <InputGroup
                        errors={formState.errors}
                        title="Schema"
                        register={register('sql_schema', {
                            required: 'Schema is required',
                            minLength: 1,
                        })}
                        placeholder=""
                    />
                </Column>
            </Row>
            <InputGroup
                errors={formState.errors}
                title="Username"
                register={register('sql_username', {
                    required: 'Username is required',
                    minLength: 1,
                })}
                placeholder=""
            />
            {!isCreate && (
                <SwitchInputGroup
                    title="Update password"
                    register={register('is_changing_password')}
                />
            )}
            <Collapse isOpen={isCreate || !isPasswordFieldDisabled}>
                <InputGroup
                    errors={formState.errors}
                    title="Password"
                    hideLabel={!isCreate}
                    register={register('sql_password', {
                        validate: {
                            required: (value) =>
                                !value && !isPasswordFieldDisabled ? 'Password is required' : true,
                        },
                        minLength: 1,
                    })}
                    disabled={isPasswordFieldDisabled}
                    placeholder=""
                />
            </Collapse>

            <SwitchInputGroup
                errors={formState.errors}
                title="Make connection read-only"
                register={register('is_read_only')}
            />

            <Collapse isOpen={!!connectionError && !hasUntestedChanges && !isTesting}>
                <Text variant="error" size="sm" py={2}>
                    The connection was not successful:{' '}
                    <Text variant="error" size="sm" fontWeight="bold" display="inline">
                        {connectionError}.
                    </Text>{' '}
                    Update parameters and try again.
                </Text>
            </Collapse>
            <Collapse isOpen={testSuccessful && !hasUntestedChanges && !isTesting}>
                <Text variant="success" size="sm" py={2}>
                    The connection was successful, you can click{' '}
                    <Text variant="success" size="sm" fontWeight="bold" display="inline">
                        {isCreate ? 'Create connection' : 'Save connection'}
                    </Text>{' '}
                    to activate it.
                </Text>
            </Collapse>
        </>
    )
}
const Row = ({ children }) => <div style={{ display: 'flex' }}>{children}</div>
const Column = ({ size = 1, children }) => <div style={{ flex: size }}>{children}</div>
const SwitchInputGroup = ({ title, errors = {}, register, ...rest }) => {
    const errorMessage = errors[register.name]?.message
    return (
        <>
            <Flex justifyContent="space-between">
                <Text size="sm" fontWeight="bold">
                    {title}
                </Text>
                <Switch {...rest} {...register} mt={2} mb={2} />
            </Flex>

            <Collapse isOpen={!!errorMessage}>
                <Text variant="error" size="sm" pb={2}>
                    {errorMessage}
                </Text>
            </Collapse>
        </>
    )
}
