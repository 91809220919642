export const getDetailRequiredFieldApiNames = ({
    actions,
    fields,
    obj,
    view,
}: Partial<{ actions: ActionDto[]; fields: FieldDto[]; obj: ObjectDto; view: ViewDto }>):
    | string[]
    | undefined => {
    if (!actions || !fields || !obj || !view || !fields.length) {
        return undefined
    }

    // There are LOTS of places which can cause us to need to require fields.
    // See https://stacker-app.slack.com/archives/C04JN84JLJF/p1714456307715699
    // for a (mostly complete) list

    const fieldsFromTraversal = (view.options?.blocks ?? [])
        .map((block) => traverseBlock(block, fields))
        .reduce((a, b) => new Set([...a, ...b]), new Set())
    for (const f of fields) {
        if (f.is_primary) {
            fieldsFromTraversal.add(f.api_name)
        }
    }

    ;[
        view?.options?.documentField?.id, // document layout
        view?.options?.recordHeader?.heading,
        view?.options?.recordHeader?.subheading,
        view?.options?.profileImage?.id,
    ]
        .filter((fieldApiName) => !!fieldApiName)
        .forEach((f) => fieldsFromTraversal.add(f as string))

    for (const f of view?.options?.profileFields ?? []) {
        // sidebar and profile fields
        if (f?.fieldName) {
            fieldsFromTraversal.add(f.fieldName)
        }
    }

    for (const button of (view?.options?.pageButtons ?? []).concat(view?.options?.tabs ?? [])) {
        for (const condition of button?.conditions ?? []) {
            const fieldApiName = condition?.field?.api_name
            if (fieldApiName) {
                fieldsFromTraversal.add(fieldApiName)
            }
        }
        const actionSid = button.id
        if (actionSid) {
            const action = actions.find((action) => action._sid === actionSid)
            if (action) {
                for (const step of action.options.steps.filter(
                    (step) => step.type === 'updateRecord'
                )) {
                    for (const stepField of step.fields) {
                        fieldsFromTraversal.add(stepField.fieldName)
                    }
                }
            }
        }
    }

    return [...fieldsFromTraversal]
}

function traverseBlock(block: Record<string, any>, fields: FieldDto[]): Set<string> {
    const result = new Set<string>()

    if (block.childBlocks) {
        for (const child of block.childBlocks) {
            for (const field of traverseBlock(child, fields)) {
                result.add(field)
            }
        }
    }

    if (block.type === 'field_container' || block.type === 'field_highlights') {
        const attributes = block.config?.attributes ?? {}
        if (attributes.showAllFields) {
            for (const f of fields) {
                result.add(f.api_name)
            }
        } else {
            for (const f of attributes.contents ?? []) {
                if (f?.fieldName) {
                    result.add(f.fieldName)
                }
            }
            if (attributes.fieldConditionalVisibilityFilters) {
                for (const filter of Object.values(attributes.fieldConditionalVisibilityFilters)) {
                    for (const entry of (filter as Record<string, any>[]) ?? []) {
                        const fieldApiName = (entry as Record<string, any>)?.field?.api_name
                        if (fieldApiName) {
                            result.add(fieldApiName)
                        }
                    }
                }
            }
        }
    } else if (block.type === 'detail_view_related_list') {
        const targetFieldSid = block.config?.attributes?.field?.fieldId
        if (targetFieldSid) {
            for (const f of fields) {
                if (f._sid === targetFieldSid) {
                    result.add(f.api_name)
                }
            }
        }
    } else if (
        block.type === 'related_record' ||
        block.type === 'pipeline' ||
        block.type === 'iframe'
    ) {
        const targetFieldSid = block.config?.attributes?.fieldId
        if (targetFieldSid) {
            for (const f of fields) {
                if (f._sid === targetFieldSid) {
                    result.add(f.api_name)
                }
            }
        }
    } else if (block.type === 'quicklinks') {
        const items = block.config?.attributes?.items ?? []
        for (const item of items) {
            const targetFieldSid = item?.link?.fieldId
            if (targetFieldSid) {
                for (const f of fields) {
                    if (f._sid === targetFieldSid) {
                        result.add(f.api_name)
                    }
                }
            }
        }
    } else if (block.config?.attributes?.filters) {
        // Static blocks, e.g., text or callout
        const filters = block.config?.attributes?.filters
        for (const item of filters) {
            for (const f of fields) {
                if (f._sid === item?.field_sid) {
                    result.add(f.api_name)
                }
            }
        }
    }

    return result
}
