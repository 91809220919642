import { REACT_QUERY } from 'data/utils/constants'

import { invalidatePermissions } from './permissions/permissionOperations'
import {
    ACCOUNT_QUERY_RETRY_OPTIONS,
    useCanRunWorkspaceScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useUpdateItem,
} from './_helpers'
import { invalidateFields } from './fields'
import { invalidatePages } from './pages'

/**
 *
 * @param {import('react-query').UseQueryOptions } options
 */
export function useRoles(options = {}) {
    const enabled = useCanRunWorkspaceScopedQueries()
    return useQuery(
        REACT_QUERY.roles.listName,
        REACT_QUERY.roles.endpoint,
        {
            ...options,
            ...ACCOUNT_QUERY_RETRY_OPTIONS,
            enabled: enabled && options.enabled !== false,
        },
        {
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
            bypassMatchingStackCheck: true,
        }
    )
}

export function useCreateRole() {
    return useCreateItem(REACT_QUERY.roles.listName, REACT_QUERY.roles.endpoint, {
        onSuccess: () => {
            invalidatePages()
            invalidatePermissions()
            invalidateFields()
        },
    })
}
export function useUpdateRole() {
    return useUpdateItem(REACT_QUERY.roles.listName, REACT_QUERY.roles.endpoint, {
        onSuccess: () => {
            invalidatePages()
            invalidatePermissions()
            invalidateFields()
        },
    })
}

export function useDeleteRole() {
    return useDeleteItem(REACT_QUERY.roles.listName, REACT_QUERY.roles.endpoint)
}
