import React, { FC } from 'react'

import { getCompatibleFields } from 'features/records/components/RecordFilters/utils/getCompatibleFields'
import MultiUserProfilesDropdown from 'features/studio/ui/MultiUserProfilesDropdown'
import UserProfilesDropdown from 'features/studio/ui/UserProfilesDropdown'

import type { AdminFilterProps } from './types'

export const FilterUserFieldSelect: FC<AdminFilterProps> = ({
    value,
    field,
    userObject,
    userProfiles,
    onChange,
    usePortal,
    variant,
    editMode = true,
}) => {
    const allFields = userProfiles
        ? [...(userObject as ObjectDto).fields, ...userProfiles?.flatMap((object) => object.fields)]
        : [...(userObject as ObjectDto).fields]
    // if we're not in edit mode, then just display the selected values
    // not the input control
    if (!editMode) {
        return <>{allFields?.find((x) => x.api_name === value)?.label}</>
    }

    if (userProfiles && userProfiles.length > 1) {
        const options = userProfiles.map((object) => ({
            label: object._sid,
            profile: object,
            options: getCompatibleFields(object, field).map((x) => ({
                value: x.api_name,
                label: x.label,
            })),
        }))

        return (
            <MultiUserProfilesDropdown
                id="user-profiles"
                groupedOptions={options}
                value={value as string | undefined}
                style={{ flex: 1 }}
                onChange={onChange}
                variant={variant}
                usePortal={usePortal}
            />
        )
    }

    let options: { label: string; withIcon: boolean; value: string }[] = []

    if (userProfiles?.length === 1) {
        options = getCompatibleFields(userProfiles[0], field).map((x) => ({
            withIcon: false,
            value: x.api_name,
            label: x.label,
        }))
    } else {
        options = getCompatibleFields(userObject as ObjectDto, field).map((x) => ({
            withIcon: false,
            value: x.api_name,
            label: x.label,
        }))
    }

    return (
        <UserProfilesDropdown
            style={{ flex: 1 }}
            options={options}
            value={value as string | undefined}
            onChange={onChange}
            variant={variant}
            usePortal={usePortal}
        />
    )
}
