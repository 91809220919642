import { useCallback, useMemo, useRef, useState } from 'react'

import { useDuplicateViewModal } from 'features/views/DuplicateViewModal'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { useConfirmDeleteViewModal } from 'features/views/useConfirmDeleteViewModal'

export function useListViewControlsState() {
    const {
        view,
        columnConfigs,
        isDeleteEnabled,
        commitChanges,
        discardChanges,
        hasPendingChanges,
        updateView,
        updateViewOptions,
        object,
        pageRoles,
        updatePageRoles,
        allFields,
        visibleFields,
    } = useListViewContext()

    const showConfirmDeleteModal = useConfirmDeleteViewModal()
    const [ignorePendingChanges, setIgnorePendingChanges] = useState(false)

    const actions = useMemo(() => {
        if (!isDeleteEnabled) return []

        return [
            {
                action: () => {
                    showConfirmDeleteModal({
                        view,
                        beforeRedirect: () => setIgnorePendingChanges(true),
                    })
                },
                label: 'Delete Layout',
            },
        ]
    }, [isDeleteEnabled, showConfirmDeleteModal, view])

    const showDuplicateViewModal = useDuplicateViewModal()
    const openDuplicateModal = useCallback(() => {
        showDuplicateViewModal({ view })
    }, [showDuplicateViewModal, view])

    const effectiveHasPendingChanges = hasPendingChanges && !ignorePendingChanges

    const prevDisplay = view.options.display
    const prevDisplayRef = useRef(prevDisplay)
    prevDisplayRef.current = prevDisplay

    const onDisplayChange = useCallback(
        (newDisplay: ListViewDisplay) => {
            const prevDisplay = prevDisplayRef.current

            const newViewOptions = translateDisplayChange(
                view.options,
                prevDisplay,
                newDisplay,
                visibleFields,
                allFields
            )

            updateViewOptions(newViewOptions)
        },
        [allFields, updateViewOptions, view.options, visibleFields]
    )

    return {
        view,
        commitChanges,
        discardChanges,
        hasPendingChanges: effectiveHasPendingChanges,
        updateView,
        actions,
        openDuplicateModal,
        object,
        columnConfigs,
        updateViewOptions,
        pageRoles,
        updatePageRoles,
        allFields,
        getPageSizeOptions,
        getDefaultPageSize,
        onDisplayChange,
    }
}

function getPageSizeOptions(display?: string): number[] {
    return PAGE_SIZES[display!] || PAGE_SIZES.default
}

function getDefaultPageSize(display?: string): number {
    return DEFAULT_PAGE_SIZE[display!] || DEFAULT_PAGE_SIZE.default
}

const PAGE_SIZES: Record<string, number[]> = {
    default: [10, 20, 30, 40, 50, 100, 200],
    card: [12, 24, 36, 48],
}

const DEFAULT_PAGE_SIZE: Record<string, number> = {
    default: 30,
    card: 24,
}

function translateDisplayChange(
    viewOptions: ListViewOptions,
    prevDisplay: ListViewDisplay,
    newDisplay: ListViewDisplay,
    visibleFields: FieldDto[],
    allFields: FieldDto[]
): ListViewOptions {
    const newViewOptions: ListViewOptions = {
        ...viewOptions,
        display: newDisplay,
    }

    // Card -> Board
    if (prevDisplay === 'cardV2' && newDisplay === 'boardV2') {
        newViewOptions.boardCardTitle = newViewOptions.cardCardTitle
        newViewOptions.boardCardSubtitle = newViewOptions.cardCardSubtitle
        newViewOptions.boardCardEyebrow = newViewOptions.cardCardEyebrow
        newViewOptions.boardTitleSize = newViewOptions.cardTitleSize
        newViewOptions.boardLabelStyle = newViewOptions.cardLabelStyle
    }

    // Board -> Card
    if (prevDisplay === 'boardV2' && newDisplay === 'cardV2') {
        newViewOptions.cardCardTitle = newViewOptions.boardCardTitle
        newViewOptions.cardCardSubtitle = newViewOptions.boardCardSubtitle
        newViewOptions.cardCardEyebrow = newViewOptions.boardCardEyebrow
        newViewOptions.cardTitleSize = newViewOptions.boardTitleSize
        newViewOptions.cardLabelStyle = newViewOptions.boardLabelStyle
    }

    // Table -> Board or Table -> Card
    if (prevDisplay === 'tableV2') {
        const titleField = visibleFields[0]
        if (titleField) {
            if (newDisplay === 'boardV2') {
                newViewOptions.boardCardTitle = {
                    fieldSid: titleField._sid,
                }
            } else if (newDisplay === 'cardV2') {
                newViewOptions.cardCardTitle = {
                    fieldSid: titleField._sid,
                }
            }

            if (
                (newDisplay === 'boardV2' || newDisplay === 'cardV2') &&
                !newViewOptions.showAllFields
            ) {
                // Remove the title field from the columns list.
                newViewOptions.columns = newViewOptions.columns.filter(
                    (c) => c.fieldId !== titleField._sid
                )
            }
        }
    }

    // Board -> Table or Card -> Table
    if ((prevDisplay === 'boardV2' || prevDisplay === 'cardV2') && newDisplay === 'tableV2') {
        // Add card fields to the columns list in the right order.
        if (!newViewOptions.showAllFields) {
            // Add header fields to the beginning.
            const fieldsToPrepend: string[] = []

            const eyebrowFieldSid =
                prevDisplay === 'boardV2'
                    ? newViewOptions.boardCardEyebrow?.fieldSid
                    : newViewOptions.cardCardEyebrow?.fieldSid
            if (eyebrowFieldSid) {
                fieldsToPrepend.push(eyebrowFieldSid)
            }

            const subtitleFieldSid =
                prevDisplay === 'boardV2'
                    ? newViewOptions.boardCardSubtitle?.fieldSid
                    : newViewOptions.cardCardSubtitle?.fieldSid
            if (subtitleFieldSid) {
                fieldsToPrepend.push(subtitleFieldSid)
            }

            const titleFieldSid =
                prevDisplay === 'boardV2'
                    ? newViewOptions.boardCardTitle?.fieldSid
                    : newViewOptions.cardCardTitle?.fieldSid
            if (titleFieldSid) {
                fieldsToPrepend.push(titleFieldSid)
            }

            for (const fieldSid of fieldsToPrepend) {
                newViewOptions.columns = addOrUpdateCardFieldInColumns(
                    fieldSid,
                    newViewOptions.columns,
                    allFields
                )
            }

            // Add footer fields to the end.
            const fieldsToAppend: string[] = []

            const footerFieldRightSid =
                prevDisplay === 'boardV2'
                    ? newViewOptions.boardCardFooter?.rightFieldSid
                    : newViewOptions.cardFooter?.rightFieldSid
            if (footerFieldRightSid && !footerFieldRightSid.startsWith('_')) {
                fieldsToAppend.push(footerFieldRightSid)
            }

            const footerFieldLeftSid =
                prevDisplay === 'boardV2'
                    ? newViewOptions.boardCardFooter?.leftFieldSid
                    : newViewOptions.cardFooter?.leftFieldSid
            if (footerFieldLeftSid && !footerFieldLeftSid.startsWith('_')) {
                fieldsToAppend.push(footerFieldLeftSid)
            }

            for (const fieldSid of fieldsToAppend) {
                newViewOptions.columns = addOrUpdateCardFieldInColumns(
                    fieldSid,
                    newViewOptions.columns,
                    allFields,
                    true
                )
            }
        }
    }

    return newViewOptions
}

function addOrUpdateCardFieldInColumns(
    fieldSid: string,
    columns: ListViewColumnConfig[],
    allFields: FieldDto[],
    append = false
): ListViewColumnConfig[] {
    let newColumns = [...columns]

    let column = newColumns.find((c) => c.fieldId === fieldSid)
    if (column) {
        newColumns = newColumns.filter((c) => c.fieldId !== fieldSid)
    } else {
        const titleField = allFields.find((f) => f._sid === fieldSid)
        if (titleField) {
            column = {
                fieldId: titleField._sid,
                fieldApiName: titleField.api_name,
                objectId: titleField.object_id,
                type: 'field',
                selected: true,
            }
        }
    }

    // Add the column at the desired index.
    if (column) {
        if (append) {
            newColumns = [...newColumns, column]
        } else {
            newColumns = [column, ...newColumns]
        }
    }

    return newColumns
}
