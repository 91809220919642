import React from 'react'

import { PreviewRecordContextProvider } from 'v2/views/List/PreviewRecord/PreviewRecordContext'
import { PreviewRecordModal } from 'v2/views/List/PreviewRecord/PreviewRecordModal'
import { useGoBackUsingBreadcrumbs } from 'v2/views/utils/useGoBackUsingBreadcrumbs'

import { useDocument } from 'data/hooks/documents'
import Frame from 'features/core/Frame'
import useWindowTitle from 'utils/useWindowTitle'

import { LoadingSplash } from 'v2/ui'

import { DocumentEditor } from './DocumentEditor'
import { DocumentBreadcrumbs } from './DocumentPageBreadCrumbs'
import { DocumentPageFrame } from './DocumentPageFrame'

export function DocumentPage(props: { match: { params: { documentId: string } } }) {
    const goBackUsingBreadcrumbs = useGoBackUsingBreadcrumbs()

    const { documentId } = props.match.params
    // Get the document and prepare some immutable structures to prevent the Editor (which is sensitive to rerenders)
    // from rerendering unnecessarily
    const { data, isLoading } = useDocument(Number(documentId), undefined, {
        onError: (err: Response) => {
            if (err.status === 403 || err.status === 404) {
                goBackUsingBreadcrumbs()
            }
        },
    })
    useWindowTitle(data?.document?.title ?? 'Untitled Document', false)
    return (
        <Frame width="100%" paddingOverride="0px">
            <PreviewRecordContextProvider>
                <DocumentPageFrame>
                    {isLoading && <LoadingSplash />}
                    {!isLoading && data && (
                        <DocumentBreadcrumbs
                            ml="4xl"
                            py="m"
                            mr="4xl"
                            document={data?.document}
                            documents={data?.documents ?? []}
                            records={data?.records ?? []}
                        />
                    )}
                    {!isLoading && data && <DocumentEditor key={data.document.auto_id} {...data} />}
                    <PreviewRecordModal />
                </DocumentPageFrame>
            </PreviewRecordContextProvider>
        </Frame>
    )
}
