// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'

import { Button, Flex, Icon, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { PlanPickerGridProps } from './types'

// Use more semantic names to avoid a "Flex soup"
const PlanCard = Flex
const PlanFeatures = Flex

const { colors } = stackerTheme()

const INCLUDES_FEAURES_PHRASE = {
    Starter: (
        <Text>
            <strong>Starter</strong> includes:
        </Text>
    ),
    Plus: (
        <Text>
            Everything in <strong>Starter,</strong> and:
        </Text>
    ),
    Pro: (
        <Text>
            Everything in <strong>Plus,</strong> and:
        </Text>
    ),
    Enterprise: (
        <Text>
            Everything in <strong>Pro,</strong> and:
        </Text>
    ),
}

const Container = styled('div')`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    column-gap: 20px;
`

export const PlanPickerGrid: React.FC<PlanPickerGridProps> = ({ plans, handleSelectedPlan }) => {
    return (
        <Container>
            {plans.map((plan, index) => {
                const {
                    isDisabled,
                    isEnterprisePlan,
                    buttonText,
                    buttonLabel,
                    buttonTestId,
                    basePrice,
                    features,
                    tier,
                    billingPeriod,
                } = plan

                return (
                    <PlanCard
                        data-testid={`plan-picker-card-${index}`}
                        key={index}
                        border="1px solid #e5e7ea"
                        height="630px"
                        textAlign="center"
                        borderRadius="6px"
                        column
                        paddingTop="20px"
                        position="relative"
                    >
                        {isDisabled && (
                            <div
                                className="overlay"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    position: 'absolute',
                                    top: '0',
                                    bottom: '0',
                                    right: '0',
                                    left: '0',
                                    opacity: '0.6',
                                    backgroundColor: 'white',
                                    zIndex: 9,
                                }}
                            ></div>
                        )}
                        <Text
                            fontSize="14px"
                            fontWeight="bold"
                            color={colors.userInterface.accent[1000]}
                        >
                            <span style={{ textTransform: 'uppercase' }}>{tier}</span>
                        </Text>
                        {!isEnterprisePlan ? (
                            <>
                                <Text fontSize="14px" paddingTop="8px">
                                    Starts at
                                </Text>
                                <Text fontSize="40px" fontWeight="600">
                                    {basePrice}
                                </Text>
                            </>
                        ) : (
                            <Text fontSize="14px" paddingTop="30px"></Text>
                        )}

                        {!isEnterprisePlan ? (
                            <>
                                <Text fontWeight="600">/month</Text>
                                <Text>
                                    Billed{' '}
                                    <span style={{ textTransform: 'capitalize' }}>
                                        {billingPeriod}
                                    </span>
                                </Text>
                            </>
                        ) : (
                            <>
                                <Text fontSize="32px" fontWeight="600">
                                    Custom
                                </Text>
                                <Text fontWeight="600">Individual contract</Text>
                                <Text>Custom Billing</Text>
                            </>
                        )}

                        <Flex width="100%" padding="12px 0 12px 20px">
                            {INCLUDES_FEAURES_PHRASE[tier]}
                        </Flex>

                        <PlanFeatures column align="left" paddingTop="10px">
                            {features.map((planFeature) => {
                                return (
                                    <Flex key={planFeature} padding="0 0 12px 3px">
                                        <Icon
                                            icon="faCheck"
                                            color={colors.userInterface.accent[1000]}
                                        />
                                        <p
                                            style={{
                                                margin: '0',
                                                textAlign: 'left',
                                                paddingLeft: '4px',
                                                fontSize: '14px',
                                            }}
                                            key={planFeature}
                                        >
                                            {planFeature}
                                        </p>
                                    </Flex>
                                )
                            })}
                        </PlanFeatures>

                        <Button
                            data-testid={buttonTestId}
                            variant="Primary"
                            buttonSize="large"
                            mb={2}
                            isDisabled={isDisabled}
                            aria-label={buttonLabel}
                            style={{
                                marginTop: 'auto',
                                marginBottom: '20px',
                                width: '150px',
                                opacity: 1,
                            }}
                            onClick={() => handleSelectedPlan(plan)}
                        >
                            {buttonText}
                        </Button>
                    </PlanCard>
                )
            })}
        </Container>
    )
}
