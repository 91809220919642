import React from 'react'
import { Link } from 'react-router-dom'

import { Avatar } from 'ui/components/Avatar'
import { AvatarGroup } from 'ui/components/AvatarGroup'
import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { UserItem, useUsersState } from './hooks/useUsersState'

const sizeMapping = {
    avatar: {
        s: 'xs',
        m: 's',
    },
} as const

type UsersProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: UserItem[]
    size?: ResponsiveValue<'s' | 'm'>
    avatarSize?: ResponsiveValue<'2xs' | 'xs' | 's'>
    isLoading?: boolean
    maxLines?: number
    maxLength?: number
    showTooltips?: boolean
    tooltipProps?: React.ComponentPropsWithoutRef<typeof Avatar>['tooltipProps']
    showSingleUserName?: boolean
}

export const Users: React.FC<UsersProps> = ({
    value,
    isLoading,
    size = 'm',
    avatarSize: providedAvatarSize,
    style,
    maxLength,
    maxLines,
    onClick,
    showTooltips = true,
    tooltipProps,
    showSingleUserName = true,
    ...props
}) => {
    const effectiveSize = useResponsiveValue(size)
    const effectiveProvidedAvatarSize = useResponsiveValue(providedAvatarSize)
    const avatarSize = effectiveProvidedAvatarSize ?? sizeMapping.avatar[effectiveSize]

    const { isEmpty, isSingle, valueWithDefaults } = useUsersState({ value })

    if (isEmpty) return null

    if (isSingle) {
        const { firstName, lastName, imageUrl, type, hideAvatar, to, fullName } =
            valueWithDefaults[0]

        return (
            <Box
                as={to ? Link : undefined}
                to={to!}
                flex
                center
                gap="m"
                color={
                    to
                        ? {
                              default: 'text',
                              hover: 'textTheme',
                          }
                        : undefined
                }
                style={{ transition: theme.transition.colors, ...style }}
                onClick={onClick}
                {...props}
            >
                {isSingle && (
                    <>
                        {!hideAvatar && (
                            <Skeleton isLoading={isLoading}>
                                <Avatar
                                    size={avatarSize}
                                    type={
                                        type as React.ComponentPropsWithoutRef<
                                            typeof Avatar
                                        >['type']
                                    }
                                    firstName={firstName}
                                    lastName={lastName}
                                    imageUrl={imageUrl}
                                />
                            </Skeleton>
                        )}
                        {showSingleUserName && (
                            <Skeleton isLoading={isLoading}>
                                <Body
                                    style={{
                                        fontWeight: 'inherit',
                                    }}
                                    size={effectiveSize}
                                    maxLength={maxLength}
                                    maxLines={maxLines}
                                >
                                    {fullName}
                                </Body>
                            </Skeleton>
                        )}
                    </>
                )}
            </Box>
        )
    }

    return (
        <Skeleton isLoading={isLoading}>
            <AvatarGroup
                size={avatarSize}
                overflow
                showTooltips={showTooltips}
                tooltipProps={tooltipProps}
                {...props}
            >
                {valueWithDefaults.map((user, index) => {
                    const { firstName, lastName, imageUrl, type, hideAvatar, to } = user

                    if (hideAvatar) return null

                    return (
                        <Avatar
                            key={index}
                            as={to ? Link : undefined}
                            to={to!}
                            type={type as React.ComponentPropsWithoutRef<typeof Avatar>['type']}
                            firstName={firstName}
                            lastName={lastName}
                            imageUrl={imageUrl}
                            onClick={onClick}
                        />
                    )
                })}
            </AvatarGroup>
        </Skeleton>
    )
}
