// @ts-strict-ignore
import React, { useCallback, useState } from 'react'

import styled from '@emotion/styled'

import { useObject } from 'data/hooks/objects'
import { withDataConnections } from 'data/wrappers/WithDataConnections'
import { withStack } from 'data/wrappers/WithStacks'
import DATA_PROVIDERS from 'features/admin/data-connector/dataProviderConfig'
import FieldEditorPopoverButton from 'features/admin/fields/FieldEditorPopoverButton'
import { NewFieldConfigurationEditor } from 'features/admin/settings/object/NewFieldConfigurationEditor'
import { showSyncDataSourceSchemaModal } from 'features/AppSettings/DataSources/SyncDataSourceSchemaModal'
import { Heading, Section } from 'legacy/v1/ui'

import { Button, Checkbox, Flex, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import stackerTheme from 'v2/ui/theme/styles/default'

const colors = stackerTheme().colors

type AppModalDataFieldSettingsProps = {
    stackOptions: StackDto['options']
    dataConnections: DataConnectionDto[]
    objectId: string
    noPadding: boolean
    setDataSyncRequired?: () => void
}

const AppModalDataFieldSettings: React.FC<AppModalDataFieldSettingsProps> = ({
    dataConnections,
    objectId,
    noPadding,
    setDataSyncRequired,
}: AppModalDataFieldSettingsProps) => {
    const { object } = useObject(objectId)
    const getDataConnection = useCallback((object, dataConnections) => {
        return dataConnections.find((dc) => dc._sid === object.data_connection)
    }, [])
    const [showDeveloperDetails, setShowDeveloperDetails] = useState(false)

    const objectDataConnection = getDataConnection(object, dataConnections)

    const { allowSchemaSync: schemaSync } = DATA_PROVIDERS[objectDataConnection?.type] || {}

    return (
        <Section
            style={{ width: '100%', marginBottom: 0, marginTop: 0 }}
            noMargin={noPadding}
            noPadding={noPadding}
        >
            <Section
                style={{
                    width: '100%',
                    marginBottom: 0,
                    marginTop: 0,
                    overflow: 'hidden',
                }}
                noMargin={noPadding}
                noPadding={noPadding}
            >
                <Flex mb={4} justifyContent="space-between">
                    <Heading helpAlign="right" margin="none" padding="none" size="small">
                        Fields
                    </Heading>

                    <Flex>
                        <Checkbox
                            id="field-developer-name-checkbox"
                            small
                            onChange={() => setShowDeveloperDetails(!showDeveloperDetails)}
                            value={showDeveloperDetails ? 'true' : undefined}
                        />
                        <CheckboxText
                            as="label"
                            htmlFor="field-developer-name-checkbox"
                            size="fontS"
                        >
                            Show developer name
                        </CheckboxText>
                        {schemaSync && (
                            <Button
                                flexShrink={0}
                                variant="adminSecondaryV4"
                                buttonSize="sm"
                                mx={2}
                                icon="refresh"
                                onClick={() => {
                                    showSyncDataSourceSchemaModal(objectDataConnection._sid)
                                }}
                            >
                                Schema
                            </Button>
                        )}
                        <FieldEditorPopoverButton
                            objectId={object?._sid}
                            className={ONBOARDING_CLASSES.APP_SETTINGS_TABLE_NEW_FIELD}
                        >
                            Add field
                        </FieldEditorPopoverButton>
                    </Flex>
                </Flex>
                {object && (
                    <NewFieldConfigurationEditor
                        object={object}
                        objectDataConnection={objectDataConnection}
                        showDeveloperDetails={showDeveloperDetails}
                        setDataSyncRequired={setDataSyncRequired}
                    />
                )}
            </Section>
        </Section>
    )
}

export default withStack(withDataConnections(AppModalDataFieldSettings))

const CheckboxText = styled(Text)`
    margin-right: 20px;
    margin-left: 10px;
    color: ${colors.neutral[900]};
    font-weight: 900;
    font-size: 15px;
`
