export function supportsItemsPerPage(options: ListViewOptions): boolean {
    return (
        options.display !== 'kanban' &&
        options.display !== 'calendar' &&
        options.display !== 'single_record' &&
        options.display !== 'blankpage' &&
        options.display !== 'boardV2' &&
        options.display !== 'cardV2'
    )
}
