import React from 'react'

import { isEmpty } from 'lodash'

import { Box } from 'v2/ui'

import ManageTablesDropdown from './ManageTablesDropdown'

type ManageTableDataSourcesDropdownProps = {
    allDataSourceTables: ObjectDto[]
    selectedDataConnection?: DataConnectionDto
    selectedObjectId?: string
    setSelectedObjectId: (value?: string) => void
}

export const ManageTableDataSourcesDropdown: React.VFC<ManageTableDataSourcesDropdownProps> = ({
    allDataSourceTables,
    selectedDataConnection,
    setSelectedObjectId,
    selectedObjectId,
}) => {
    return (
        <>
            {!isEmpty(allDataSourceTables) && (
                <Box ml={2} mr={3} flexShrink={0}>
                    <ManageTablesDropdown
                        tables={allDataSourceTables}
                        selectedTableId={selectedObjectId}
                        onSelectTable={(table) => setSelectedObjectId(table._sid)}
                        dataConnection={selectedDataConnection}
                    />
                </Box>
            )}
        </>
    )
}
