export type HelpLinks = {
    [key: string]: string
}

const HELP_LINKS: HelpLinks = {
    USER_ROLES: 'https://support.stackerhq.com/hc/en-us/articles/4409591860243-Set-up-User-Roles',
    PERMISSIONS: 'https://www.stackerhq.com/help-article/introduction-to-permissions',
    FORMULA_FIELDS: 'https://support.stackerhq.com/hc/en-us/articles/4415076803731',
    INLINE_FILTERS:
        ' https://support.stackerhq.com/hc/en-us/articles/4415788506643-List-Inline-Filters',
    UPDATE_AIRTABLE_API_KEY_AND_PASSWORD:
        'https://support.stackerhq.com/hc/en-us/articles/11720130321939-Update-Airtable-API-Key-and-password',
    AIRTABLE_OAUTH_LEARN_MORE:
        'https://support.stackerhq.com/hc/en-us/articles/14372703347219#airtable-data-connection-upgrade-explained-0-0',
    POSTGRES_CONNECTION_LEARN_MORE:
        'https://support.stackerhq.com/hc/en-us/articles/4410127291283-Postgres',
}

export default HELP_LINKS
