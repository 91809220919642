/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* Code Quality: OK */

import React from 'react'

import styled from '@emotion/styled'

import PoweredBy from 'features/core/PoweredBy'
import Card from 'legacy/v1/ui/components/Card'
import publicAsset from 'utils/publicAsset'

export const Hamburger = (props) => {
    const Wrapper = styled('span')`
        height: 40px;
        display: ${(props) => (props.open ? 'none' : 'inline')};
        width: 40px;
        margin: 10px;
        padding: 10px;
        z-index: 5;
    `

    const hamburgerInWrapper = (
        <Wrapper {...props}>
            <HamburgerLogo
                style={{ height: '35px', width: '35px' }}
                src={publicAsset('/static/media/stacker-square.png')}
            />
        </Wrapper>
    )
    return hamburgerInWrapper
}

export const HamburgerLogo = styled('img')`
    opacity: 0.3;
    filter: grayscale(50%);
    transition: all 0.1s;

    &:hover {
        opacity: 1;
        filter: grayscale(0%);
    }
`
export const Logo = styled('img')`
    height: 65px;
    width: 184px;
    padding: 17px;
`

export const Page = styled('div')`
    display: flex;
    height: auto;
    min-height: 100vh;
    width: 100vw;
    flex-direction: column; /*places the left and right headers above the bottom content*/
`
export const Main = styled('div')`
    height: 100%; /*allows both columns to span the full height of the browser window*/
    display: flex;
    flex-direction: row; /*places the left and right headers above the bottom content*/
    width: 100vw;
    background-color: ${(props) => props.theme.backgroundColor};
`

export const Header = styled('div')`
    flex-shrink: 0;
    flex-basis: 65px;
    display: inline-flex;
    // background: white;
    // box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    // z-index: 1; // Bring the shadow over the sidebar
    background: ${(props) => props.theme.backgroundColor};
    height: 65px;
    width: 100%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    background: #866c6c;
    background-image: ${(props) =>
        props.color === 'yellow'
            ? '-webkit-linear-gradient(150deg, rgb(232, 181, 39) 45%, rgb(247, 195, 50) 45%)'
            : props.color === 'red'
            ? '-webkit-linear-gradient(150deg, rgb(255, 78, 26) 45%, rgb(228, 67, 20) 45%)'
            : props.color === 'green'
            ? '-webkit-linear-gradient(150deg,rgb(57, 187, 19) 45%,rgb(72, 173, 43) 45%)'
            : '-webkit-linear-gradient(150deg,#2980b9 45%,#216ba5 45%)'};
`

export const Content = styled('div')`
    padding-top: ${(props) => props.theme.large};
    padding-left: calc((100% - 1000px) / 2);
    padding-right: calc((100% - 1000px) / 2);
    padding-bottom: 32px;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
`

export const CenterPanelWrap = styled('div')`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
`

export const CenterPanel = styled(Card)`
    width: 400px;
`

export const CenterPanelWrapper = ({ children, hidePoweredBy, className }) => (
    <CenterPanelWrap className={className} style={{ minHeight: '100%', height: 'auto' }}>
        {children}
        {!hidePoweredBy && <PoweredBy />}
    </CenterPanelWrap>
)
