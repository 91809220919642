import React from 'react'

import { Button, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'
import { AirtableUpgradeSuccess } from 'v2/ui/svgs'

import { StyledFlex } from './StyledComponents'

type UpgradeSuccessfulModalProps = {
    isOpen: boolean
    onClose: () => void
}

export const UpgradeSuccessfulModal = ({ isOpen, onClose }: UpgradeSuccessfulModalProps) => {
    return (
        <SimpleModalCompat
            size="400px"
            height="405px"
            isSecondLayer
            noDividers
            padding="0 32px 40px 32px"
            zIndex={1501}
            actionsBorder={false}
            isOpen={isOpen}
            onClose={onClose}
            hideTitleBottomBorder
            data-testid="airtable-upgrade.successful-modal"
        >
            <StyledFlex>
                <AirtableUpgradeSuccess />
                <Text variant="heading" marginTop={8} marginBottom={4}>
                    Your Airtable upgrade was successful!
                </Text>
                <Text fontSize="14px">
                    Your Airtable connection has been successfully upgraded to the latest version.
                    <br />
                    <br />
                </Text>
                <Button
                    flexShrink={0}
                    variant="Primary"
                    ml={2}
                    mt={8}
                    width="50%"
                    buttonSize="large"
                    onClick={onClose}
                >
                    OK
                </Button>
            </StyledFlex>
        </SimpleModalCompat>
    )
}
