import React from 'react'

import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'
import { Select, SelectOption, SelectSeparator } from 'ui/components/Select'
import { Toggle } from 'ui/components/Toggle'

import { useCardViewFieldInputState } from './hooks/useCardViewFieldInputState'

type CardViewCardFooterControlsProps = {
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
}

export const CardViewCardFooterControls: React.FC<CardViewCardFooterControlsProps> = ({
    fields,
    config,
    setConfig,
}) => {
    return (
        <ListViewControlItem label="Card footer" icon="PanelBottom">
            <Box maxWidth="full" minWidth="300px" flex flexDirection="column" gap="xl">
                <FieldInput
                    label="Left area"
                    fields={fields}
                    value={config.cardFooter?.leftFieldSid}
                    displayOptions={config.cardFooter?.leftFieldDisplayOptions}
                    onChange={(value) => {
                        setConfig({
                            cardFooter: {
                                ...config.cardFooter,
                                leftFieldSid: value,
                            },
                        })
                    }}
                    onDisplayOptionsChange={(value) => {
                        setConfig({
                            cardFooter: {
                                ...config.cardFooter,
                                leftFieldDisplayOptions: value,
                            },
                        })
                    }}
                />
                <FieldInput
                    label="Right area"
                    fields={fields}
                    value={config.cardFooter?.rightFieldSid}
                    displayOptions={config.cardFooter?.rightFieldDisplayOptions}
                    onChange={(value) => {
                        setConfig({
                            cardFooter: {
                                ...config.cardFooter,
                                rightFieldSid: value,
                            },
                        })
                    }}
                    onDisplayOptionsChange={(value) => {
                        setConfig({
                            cardFooter: {
                                ...config.cardFooter,
                                rightFieldDisplayOptions: value,
                            },
                        })
                    }}
                />
            </Box>
        </ListViewControlItem>
    )
}

type FieldInputProps = React.ComponentPropsWithoutRef<typeof Input> & {
    value?: CardFooterField
    displayOptions?: CardFooterDisplayOptions
    onChange: (value?: CardFooterField) => void
    onDisplayOptionsChange: (value: CardFooterDisplayOptions) => void
    fields: FieldDto[]
}

const FieldInput: React.FC<FieldInputProps> = ({
    fields,
    value,
    displayOptions,
    onDisplayOptionsChange,
    ...props
}) => {
    const { selectedField, isUsersObjectLink } = useCardViewFieldInputState({ fields, value })

    return (
        <Box>
            <Select
                size="m"
                isSearchable
                isClearable
                placeholder="Select field..."
                value={value}
                {...props}
            >
                <SelectOption value="_record_stats" label="Record stats" variant="action" />
                <SelectSeparator />
                {fields.map((field) => (
                    <SelectOption key={field._sid} value={field._sid} label={field.label} />
                ))}
            </Select>
            {selectedField?.type === 'multi_file' && (
                <Box mt="m">
                    <Toggle
                        checked={displayOptions?.displayAsImage}
                        onCheckedChange={(isChecked) => {
                            onDisplayOptionsChange({ displayAsImage: isChecked })
                        }}
                    >
                        Display as image
                    </Toggle>
                </Box>
            )}
            {(isUsersObjectLink || selectedField?.type === 'user_ref') && (
                <Box mt="m">
                    <Toggle
                        checked={!displayOptions?.hideUserName}
                        onCheckedChange={(isChecked) => {
                            onDisplayOptionsChange({ hideUserName: !isChecked })
                        }}
                    >
                        Show user name
                    </Toggle>
                </Box>
            )}
        </Box>
    )
}
