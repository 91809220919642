import React, { useState } from 'react'

import { Switch } from '@chakra-ui/react'
import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'
import ProfileCardEditor from 'v2/views/Detail/ProfileCardEditor'
import RightHandSideAttributes from 'v2/views/Detail/RightHandSideAttributes'
import { getMaxVisibleTabCount } from 'v2/views/Detail/utils'
import TabsSelector from 'v2/views/DetailsTabsSelector'
import MenuToggle from 'v2/views/MenuToggle'
import { useDetailViewConfig } from 'v2/views/useDetailViewConfig'

import { canDeleteRecords } from 'data/utils/getObjectRecordRestrictions'
import { getAreObjectRecordsEditable } from 'features/admin/fields/logic/availableFieldOperationUtils'
import CoverImage from 'features/pages/blocks/settings/attributes/items/form/CoverImage'
import DocumentFieldConfig from 'features/pages/blocks/settings/attributes/items/form/DocumentFieldConfig'

import { Box, Dropdown, Flex, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

import { RecordHeaderControls } from './RecordHeader'

const DetailViewControls = ({
    stack,
    config,
    setConfig,
    object,
    showCommentsOption,
    showActionButtonsOption,
    showTabsOption,
}) => {
    const { systemTabs, filterTabs, systemButtons, filterButtons } = useDetailViewConfig(object)
    const filteredSystemButtons = canDeleteRecords(object)
        ? systemButtons
        : systemButtons.filter((b) => b._sid !== 'delete')

    const selectedButtons = config?.pageButtons
        ? filterButtons(config.pageButtons)
        : filteredSystemButtons.filter((x) => x._sid !== 'delete').map((x) => ({ id: x._sid }))

    const [initialConfig] = useState({ ...config })
    const tabsEnabled = !isFeatureLocked(FEATURES.tabs, stack)

    if (!config) return ''

    const displayOptions = [
        {
            value: 'default',
            label: 'Default',
            onClick: () => {
                setConfig({
                    display: 'default',
                })
            },
        },
        {
            value: 'document',
            label: 'Document',
            onClick: () => {
                {
                    if (!config.profileImage) {
                        // If we select profile and no image is set, we will use the first attachement by default
                        const defaultDocumentField = object.fields.find(
                            (field) => field.type === 'document'
                        ) // could be null
                        setConfig({
                            display: 'document',
                            documentField: defaultDocumentField
                                ? { id: defaultDocumentField.api_name }
                                : undefined,
                        })
                    } else {
                        setConfig({
                            display: 'document',
                        })
                    }
                }
            },
        },
        {
            value: 'profile',
            label: 'Profile',
            onClick: () => {
                {
                    if (!config.profileImage) {
                        // If we select profile and no image is set, we will use the first attachement by default
                        const defaultImageField = object.fields.find(
                            (field) => field.type === 'multi_file'
                        ) // could be null
                        setConfig({
                            display: 'profile',
                            profileImage: defaultImageField
                                ? { id: defaultImageField.api_name }
                                : undefined,
                        })
                    } else {
                        setConfig({
                            display: 'profile',
                        })
                    }
                }
            },
        },
    ]

    const maxVisibleTabCount = getMaxVisibleTabCount(config)

    return (
        <Box pb={2}>
            <Text variant="paletteSectionLabel">Template</Text>
            <Dropdown
                adminTheme
                value={config.display || 'default'}
                options={displayOptions}
                returnObject
                isClearable={false}
                onChange={(value) => {
                    if (value) return value.onClick()
                    setConfig({
                        display: 'default',
                    })
                }}
                className={ONBOARDING_CLASSES.EDIT_LAYOUT_TEMPLATE}
            />
            <Box my={2}>
                <MenuToggle icon="profile" label="Record header" title="Record Header">
                    <RecordHeaderControls
                        objectId={object._sid}
                        headerValue={config.recordHeader}
                        onChangeHeader={(obj) => setConfig({ recordHeader: obj })}
                        profileValue={config.profileImage}
                        onChangeProfile={(val) => setConfig({ profileImage: val })}
                    />
                </MenuToggle>
                {config.display === 'list' && (
                    <MenuToggle icon="image" label="Listing Image" title="Listing Image">
                        <div id="coverImage">
                            <CoverImage
                                objectId={object._sid}
                                onChange={(listImage) =>
                                    setConfig({
                                        listImage,
                                    })
                                }
                                onChangeFit={(fitListImage) =>
                                    setConfig({
                                        fitListImage,
                                    })
                                }
                                value={config.listImage}
                                fitImage={config.fitListImage}
                                showImageDisplayOptions
                            />
                        </div>
                    </MenuToggle>
                )}
                {config.display === 'document' && (
                    <>
                        <MenuToggle icon="note" label="Document Field" title="Document Field">
                            <div id="coverImage">
                                <DocumentFieldConfig
                                    objectId={object._sid}
                                    onChange={(documentField) =>
                                        setConfig({
                                            documentField,
                                        })
                                    }
                                    value={config.documentField}
                                />
                            </div>
                        </MenuToggle>
                        <MenuToggle
                            icon="list"
                            label="Sidebar fields"
                            title="Sidebar fields"
                            tooltipStartOpen={initialConfig.display !== 'document'}
                            tooltipText="Click here to choose the fields for the sidebar"
                            buttonStyle={{ marginTop: '-1px' }}
                        >
                            <RightHandSideAttributes.Editor
                                object={object}
                                config={config}
                                setConfig={setConfig}
                            />
                        </MenuToggle>
                    </>
                )}
                {config.display === 'profile' && (
                    <MenuToggle
                        icon="list"
                        label="Profile fields"
                        title="Profile fields"
                        tooltipStartOpen={initialConfig.display !== 'profile'}
                        tooltipText="Click here to choose the fields for the profile card"
                        buttonStyle={{ marginTop: '-1px' }}
                    >
                        <ProfileCardEditor object={object} config={config} setConfig={setConfig} />
                    </MenuToggle>
                )}
                {showActionButtonsOption && getAreObjectRecordsEditable(object) && (
                    <MenuToggle
                        icon="cogs"
                        label="Page buttons"
                        title="Page buttons"
                        data-testid="detail-view.action-button-selector"
                        tooltipText="Choose the action buttons that are displayed on the page"
                        buttonStyle={{ marginTop: '-1px' }}
                    >
                        <ActionButtonsSelector
                            object={object}
                            selectedButtons={selectedButtons}
                            setConfig={setConfig}
                            additionalActions={systemButtons}
                        />
                    </MenuToggle>
                )}
                {showTabsOption && (
                    <MenuToggle
                        icon="tabs"
                        label="Tabs"
                        title="Tabs"
                        data-testid="detail-view.tabs-selector"
                        buttonStyle={{ marginTop: '-1px' }}
                        activityLabel={stack.options?.activity_label}
                        enableComments={true}
                    >
                        <TabsSelector
                            object={object}
                            blocks={config.blocks}
                            enableComments={true}
                            allowComments={showCommentsOption}
                            tabs={config.tabs ? filterTabs(config.tabs) : systemTabs}
                            setConfig={setConfig}
                            maxVisibleTabCount={maxVisibleTabCount}
                        />
                    </MenuToggle>
                )}
            </Box>
            {showCommentsOption && !tabsEnabled && (
                <>
                    <Text variant="paletteSectionLabel">Options</Text>
                    <Flex wrap="noWrap">
                        <Text variant="paletteOptionLabel" flexGrow={1}>
                            Enable comments
                        </Text>
                        <Switch
                            mr={2}
                            isChecked={config.enableComments}
                            onChange={(e) => {
                                setConfig({
                                    enableComments: e.target.checked,
                                })
                            }}
                        />
                    </Flex>
                </>
            )}
        </Box>
    )
}

export default DetailViewControls
