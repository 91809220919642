import * as ViewIcons from 'v2/ui/svgs/view-icons'
import { Feature, FEATURES } from 'v2/ui/utils/ProtectedFeature'

export type displayType = {
    title: string
    icon: React.ComponentType
    isReleased: boolean
    protectedFeature?: Feature
}

export const DISPLAY_TYPES: Record<Exclude<ListViewDisplay, 'blankpage'>, displayType> = {
    table: {
        title: 'Table',
        icon: ViewIcons.Table,
        isReleased: false,
    },
    tableV2: {
        title: 'Table',
        icon: ViewIcons.Table,
        isReleased: true,
    },
    card: {
        title: 'Cards',
        icon: ViewIcons.Cards,
        isReleased: true,
    },
    cardV2: {
        title: 'Cards',
        icon: ViewIcons.Cards,
        isReleased: false,
    },
    kanban: {
        protectedFeature: FEATURES.kanban,
        title: 'Kanban Board',
        icon: ViewIcons.Kanban,
        isReleased: false,
    },
    boardV2: {
        title: 'Board',
        icon: ViewIcons.Kanban,
        isReleased: true,
    },
    rows: {
        title: 'Rows',
        icon: ViewIcons.Rows,
        isReleased: true,
    },
    inbox: {
        protectedFeature: FEATURES.inboxView,
        title: 'Inbox',
        icon: ViewIcons.Inbox,
        isReleased: true,
    },
    calendar: {
        title: 'Calendar',
        icon: ViewIcons.Calendar,
        isReleased: false,
    },
    single_record: {
        title: 'One record only',
        icon: ViewIcons.OneRecordOnly,
        isReleased: true,
    },
}
