import React, { useCallback } from 'react'

import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Button, Flex, Modal, Text } from 'v2/ui'
import { Success } from 'v2/ui/svgs'

type SyncConfirmationModalProps = {
    isOpen: boolean
    onClose: () => void
    serviceName: string
    dataConnectionSid: string
}

const SyncConfirmationModal = ({
    isOpen,
    onClose,
    serviceName,
    dataConnectionSid,
}: SyncConfirmationModalProps) => {
    const { showManageData } = useSlidingPane()

    const onOpenData = useCallback(() => {
        showManageData({ dataConnectionId: dataConnectionSid })
    }, [dataConnectionSid, showManageData])

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noDividers
            noPadding
            size="304px"
            body={
                <Flex mb="15px" width="100%" column>
                    <Success />
                    <Text variant="modalTitle" mt="20px">
                        Connected Successfully
                    </Text>
                    <Text mt="20px" fontSize="14px" color="neutral.850">
                        Syncing from {serviceName || 'service'} is in progress. This can take up to
                        several minutes depending on your data.
                    </Text>
                    <Button
                        variant="Secondary"
                        buttonSize="large"
                        mt={4}
                        onClick={onOpenData}
                        width="100%"
                    >
                        Open data
                    </Button>
                    <Button
                        variant="Primary"
                        buttonSize="large"
                        mt={4}
                        onClick={onClose}
                        width="100%"
                    >
                        Done
                    </Button>
                </Flex>
            }
        />
    )
}

export default SyncConfirmationModal
