import React from 'react'

import {
    DropdownEndIndicatorIcon,
    DropdownHeadSearch,
    DropdownItem,
    DropdownSub,
    DropdownSubContent,
    DropdownSubTrigger,
} from 'ui/components/Dropdown'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import { useActionMenuEditRecordLinksState } from './hooks/useActionMenuEditRecordLinksState'
import { ActionMenuEditComponent } from './types'

type ActionMenuEditRecordLinksProps = {}

export const ActionMenuEditRecordLinks: ActionMenuEditComponent<
    string | string[],
    ActionMenuEditRecordLinksProps
> = React.memo(function ActionMenuEditRecordLinks(props) {
    const {
        value,
        fieldLabel,
        editOptions,
        onEditValue,
        onClear,
        isSingle,
        isFetchingSlow,
        isError,
        searchQuery,
        setSearchQuery,
    } = useActionMenuEditRecordLinksState(props)

    return (
        <DropdownSub>
            <DropdownSubTrigger startIcon={{ name: 'PencilLine' }} label={fieldLabel} />
            <DropdownSubContent
                head={!isFetchingSlow && <DropdownHeadSearch autoFocus={false} />}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onClick={stopPropagation}
            >
                {!isError && !isFetchingSlow && value.size > 0 && (
                    <DropdownItem
                        startIcon={{ name: 'XCircle' }}
                        label="Clear value"
                        onClick={onClear}
                    />
                )}
                {isError && (
                    <DropdownItem
                        label={
                            <Body size="m" color="textError">
                                There was a problem displaying options
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError && editOptions.length < 1 && (
                    <DropdownItem
                        label={
                            <Body size="m" color="textHelper">
                                No items found
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError &&
                    editOptions.map((option) => {
                        const isChecked = value.has(option.value)

                        return (
                            <DropdownItem
                                key={option.value}
                                multiSelect={!isFetchingSlow}
                                label={
                                    isFetchingSlow ? (
                                        <Skeleton isLoading={isFetchingSlow}>
                                            {option.label}
                                        </Skeleton>
                                    ) : (
                                        option.label
                                    )
                                }
                                checked={value.has(option.value)}
                                onCheckedChange={(checked) => {
                                    onEditValue(option.value, checked)
                                }}
                                disabled={isFetchingSlow}
                                startAvatar={option.avatar}
                                closeOnSelect={isSingle}
                                hideCheckbox={option.isRecent && !isChecked}
                                rightSlotContent={(props) => {
                                    if (option.isRecent && !isChecked) {
                                        return <DropdownEndIndicatorIcon name="Clock" {...props} />
                                    }

                                    return null
                                }}
                            />
                        )
                    })}
            </DropdownSubContent>
        </DropdownSub>
    )
})
