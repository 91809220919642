import React, { FC } from 'react'

import { ThemeProvider } from 'emotion-theming'

import { CenterSection, Frame, PageContentArea } from 'features/pages/editor/ui/components/Frame'
import AddBlockPaneLight from 'features/pages/editor/ui/panes/AddBlockPaneLight'
import stackerDarkTheme from 'legacy/v1/ui/styleHelpers/stackerDarkTheme'

type Props = {
    blocks: Block[]
    frameless: boolean
    hideTitle: boolean
    isEditing: boolean
    isFormatPainting: boolean
    menuTheme: typeof stackerDarkTheme
    showBlockSelector: boolean
    targetId: string
    targetPosition: number
    onClick: (args: { payload: any; targetId: string; targetPosition: number }) => void
    onDrop: (args: { dragPayload: any; dropPayload: any; position: boolean }) => void
}

const EditorFrame: FC<Props> = ({
    blocks,
    frameless,
    hideTitle,
    isEditing,
    isFormatPainting,
    menuTheme,
    showBlockSelector,
    targetId,
    targetPosition,
    children,
    onClick,
    onDrop,
}) => (
    <Frame editing={isEditing}>
        <CenterSection>
            {showBlockSelector && blocks && (
                <ThemeProvider theme={menuTheme || stackerDarkTheme}>
                    <div>
                        <AddBlockPaneLight
                            hideTitle={hideTitle}
                            blocks={blocks}
                            onDrop={onDrop}
                            onClick={onClick}
                            targetId={targetId}
                            targetPosition={targetPosition}
                        />
                    </div>
                </ThemeProvider>
            )}
            <PageContentArea
                className={isEditing ? 'isEditing' : ''}
                isFormatPainting={isFormatPainting}
                framed={isEditing && !frameless}
            >
                {children}
            </PageContentArea>
        </CenterSection>
    </Frame>
)

export default EditorFrame
