import React from 'react'

import { RecordLinksAttributeDisplay } from 'features/views/attributes/RecordLinksAttributeDisplay'
import { RecordLinksAttributeTag } from 'features/views/attributes/RecordLinksAttributeTag'

import { useRecordLinksAttributeState } from './hooks/useRecordLinksAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { CardViewAttributeComponent } from './types'

const MAX_RECORD_LINKS = 2
const MAX_RECORD_LINKS_TITLE = 1
const MAX_ITEM_LENGTH = 10
const MAX_ITEM_LENGTH_SINGLE = 30
const MAX_LINES = 1

type RecordLinksAttributeProps = {}

export const RecordLinksAttribute: CardViewAttributeComponent<
    string | string[],
    RecordLinksAttributeProps
> = ({ value, attribute, isLoading, displayStyle, ...props }) => {
    const { field, type, rawOptions } = attribute

    const isTitle = type === 'title'

    const { dereferencedRecords, displayAsTag, isSingle, isEmpty, size } =
        useRecordLinksAttributeState({
            value,
            isLoading,
            displayStyle,
            attribute,
        })

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {displayAsTag ? (
                <RecordLinksAttributeTag
                    size={size}
                    value={value}
                    field={field}
                    dereferencedRecords={dereferencedRecords}
                    maxItemLength={isSingle ? MAX_ITEM_LENGTH_SINGLE : MAX_ITEM_LENGTH}
                    isLoading={isLoading}
                />
            ) : (
                <RecordLinksAttributeDisplay
                    size={size}
                    value={value}
                    field={field}
                    maxLinks={isTitle ? MAX_RECORD_LINKS_TITLE : MAX_RECORD_LINKS}
                    dereferencedRecords={dereferencedRecords}
                    maxLines={MAX_LINES}
                    maxItemLength={isSingle && !isTitle ? MAX_ITEM_LENGTH_SINGLE : MAX_ITEM_LENGTH}
                    isLoading={isLoading}
                    showSingleUserName={!rawOptions.hideUserName}
                />
            )}
        </BaseAttribute>
    )
}
