import React, { useState } from 'react'

import { useDataConnectionActions } from 'data/hooks/dataConnections'
import DataConnectionEditor from 'features/admin/data-connector/DataConnectionEditor'
import { ProviderTypes } from 'features/admin/data-connector/dataConnectorTypes'

import { Flex, Modal } from 'v2/ui'

import { ConnectionModalMode, ProviderNameWithIconHeader } from './ConnectionModalHeader'
import { useDeleteDataSource } from './utils'

export type EditConnectionModalProps = {
    isOpen: boolean
    provider: ProviderTypes
    onClose: () => void
    dataConnection: any
    titleText?: string
    withDelete?: boolean
    onDeleteDataSource?: () => void
}

export const EditConnectionModal: React.FC<EditConnectionModalProps> = ({
    isOpen,
    provider,
    onClose,
    dataConnection,
    withDelete,
    onDeleteDataSource,
}) => {
    const [modalHidden, setModalHidden] = useState(false)
    const {
        create: createDataConnection,
        update: updateDataConnection,
        remove: removeDataConnection,
    }: { create: any; update: any; remove: any } = useDataConnectionActions()

    const onConnectionDeleted = () => {
        onClose()
        if (onDeleteDataSource) onDeleteDataSource()
    }

    const { showDeleteModal } = useDeleteDataSource({
        dataConnection,
        removeDataConnection,
        onConnectionDeleted,
    })

    return (
        <Modal
            isOpen={isOpen}
            isHidden={modalHidden}
            isSecondLayer
            noDividers
            onClose={onClose}
            closeButtonStyle={{ position: 'absolute', right: 16, top: 8 }}
        >
            <ProviderNameWithIconHeader
                customTitle={dataConnection?.label}
                serviceName={provider}
                type={provider}
                mode={ConnectionModalMode.Edit}
            />
            <Flex column align="stretch" width="100%">
                <DataConnectionEditor
                    onChangeHintImage={() => undefined}
                    provider={provider}
                    createDataConnection={createDataConnection}
                    dataConnection={dataConnection}
                    updateDataConnection={updateDataConnection}
                    setModalHidden={setModalHidden}
                    onClose={onClose}
                    isNew={false}
                    isAppCreateFlow={false}
                    // @ts-expect-error
                    onDelete={withDelete ? showDeleteModal : null}
                />
            </Flex>
        </Modal>
    )
}

export default EditConnectionModal
