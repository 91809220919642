import React, { useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { Rights } from 'app/AppUserContext'
import HELP_LINKS from 'app/helpLinks'
import { useDataConnections } from 'data/hooks/dataConnections'
import { getConnectedObjectsFromIDs, useObjects } from 'data/hooks/objects'
import { useRoles } from 'data/hooks/roles'
import { ProviderIcon } from 'features/admin/data-connector/ProviderIcon'
import { FormHeader } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box, Button, Collapse, Divider, Dropdown, Icon, SearchInput, Text } from 'v2/ui'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { PermissionTable, PermissionTableRows } from './PermissionTable'

function AppSettingsModalPermissionsSettings({ children }) {
    const { data: allDataConnections } = useDataConnections()
    const { data: roles } = useRoles()

    const { selectedStack } = useAppContext()

    const [tableFilter, setTableFilter] = useState('')
    const [roleFilter, setRoleFilter] = useState(null)

    const roleDropdownOptions = [
        { value: null, label: 'All roles' },
        ...roles
            .filter((r) => {
                const rightStack = r.stack_id === selectedStack?._sid
                const allAccess = r.options?.rights?.find((x) => x === Rights.AllDataAccess)
                return rightStack && !allAccess
            })
            .map((r) => ({ value: r._sid, label: r.label })),
    ]

    const learnMore = (
        <Button variant="adminSecondary" buttonSize="sm" href={HELP_LINKS.PERMISSIONS}>
            Learn more
        </Button>
    )

    return (
        <Box padding="20px" overflow="scroll" width="100%">
            <FormHeader
                title="Permissions"
                subtitle="Use permissions to set what your users can see and do in your app."
                rightSideContent={learnMore}
            />
            <Divider my={4} />
            <div style={{ display: 'inline-block', width: '250px', marginRight: '8px' }}>
                <SearchInput
                    variant="admin_white"
                    value={tableFilter}
                    onChange={setTableFilter}
                    placeholder="Search tables..."
                />
            </div>
            <div style={{ display: 'inline-block' }}>
                <Dropdown
                    variant="admin"
                    options={roleDropdownOptions}
                    value={roleFilter}
                    onChange={setRoleFilter}
                    isSearchable={false}
                    isClearable={true}
                    placeholder="All roles"
                    padding="5px" // Makes the height match the search input
                    optionHeight={26}
                />
            </div>
            {allDataConnections.map((connection) => (
                <RenderDataConnection
                    key={connection._sid}
                    dataConnection={connection}
                    tableFilter={tableFilter}
                    roleFilter={roleFilter}
                />
            ))}
            {children}
        </Box>
    )
}

function RenderDataConnection({ dataConnection, tableFilter, roleFilter }) {
    const [collapsed, setCollapsed] = useState(false)

    const { data: objects } = useObjects()

    const visibleObjects = getConnectedObjectsFromIDs(dataConnection.connected_objects, objects)

    const type = dataConnection.type

    return visibleObjects.length > 0 ? (
        <>
            <Text fontWeight={600} margin="24px 0 0" size="sm">
                <div style={{ width: 24, display: 'inline-block', verticalAlign: 'middle' }}>
                    <ProviderIcon
                        type={type}
                        serviceName={dataConnection.service_name}
                        color={V4DesignSystem.colors.gray[400]}
                        size={'16px'}
                    />
                </div>{' '}
                {dataConnection.label}
                <Icon
                    icon={collapsed ? 'caretDown' : 'caretUp'}
                    display="inline-block"
                    style={{ marginLeft: '6px' }}
                    button
                    color={V4DesignSystem.colors.gray[400]}
                    textAlign="center"
                    onClick={() => {
                        setCollapsed(!collapsed)
                    }}
                    padding={1}
                />
            </Text>
            <Collapse isOpen={!collapsed}>
                {/* Pass in object to decide whether to show the delete column. If delete is
                    available on one object, it is available on all so just use the first one */}
                <PermissionTable includeTableName={true}>
                    {visibleObjects.map((object) => (
                        <RenderObject
                            key={object._sid}
                            object={object}
                            tableFilter={tableFilter}
                            roleFilter={roleFilter}
                        />
                    ))}
                </PermissionTable>
            </Collapse>
        </>
    ) : null
}

function RenderObject({ object, tableFilter, roleFilter }) {
    const { selectedStack } = useAppContext()

    if (!object) {
        return <></>
    }

    if (!tableFilter || object.name.toLowerCase().includes(tableFilter.toLowerCase())) {
        return (
            <PermissionTableRows
                stack={selectedStack}
                stackId={selectedStack?._sid}
                objectId={object._sid}
                showTableName={true}
                roleFilter={roleFilter}
            />
        )
    }

    return <></>
}

export default AppSettingsModalPermissionsSettings
