import { LINK_TYPES } from 'data/utils/fieldDefinitions'
import { doesFieldTypeMatch } from 'data/utils/ts_utils'
import { formatNumber } from 'data/utils/utils'
import {
    FOREIGN_LINK_FILTER_OPTIONS,
    InlineFilterOperation,
    inlineFilterOpInputMap,
    inlineFilterOpsForType,
} from 'features/records/components/RecordFilters/constants'

const MAX_MULTI_VALUE_LABEL_LENGTH = 30

export function formatMultiValueLabel(values: string[]) {
    const formattedValue = values.join(', ').trim()
    if (formattedValue.length <= MAX_MULTI_VALUE_LABEL_LENGTH) {
        return formattedValue
    }

    let cappedValue = formattedValue.slice(0, MAX_MULTI_VALUE_LABEL_LENGTH).trim()

    if (cappedValue.endsWith(',')) {
        cappedValue = cappedValue.slice(0, -1)
    }

    return `${cappedValue}…`
}

export function formatPercentageValue(value: string | string[]) {
    try {
        const newValueAsNumber = parseFloat(Array.isArray(value) ? value[0] : value)
        if (Number.isNaN(newValueAsNumber)) {
            return ''
        }

        return formatNumber(newValueAsNumber / 100, 0, 3)
    } catch {
        return value
    }
}

export function changeFilterOperation(filter: Filter, newOp: InlineFilterOperation): Filter {
    const prevOp = filter.options.option
    if (prevOp === newOp) return filter

    let newValue: Filter['options']['value'] = undefined

    switch (true) {
        case prevOp === 'is' && newOp === 'oneOf':
        case prevOp === 'is' && newOp === 'noneOf':
        case prevOp === 'isnt' && newOp === 'oneOf':
        case prevOp === 'isnt' && newOp === 'noneOf':
            {
                const value = filter.options.value
                if (!value) break

                newValue = Array.isArray(value) ? value : [value]
            }
            break

        case prevOp === 'oneOf' && newOp === 'noneOf':
        case prevOp === 'noneOf' && newOp === 'oneOf':
        case prevOp === 'listIs' && newOp === 'listIsnt':
        case prevOp === 'listIs' && newOp === 'containsAny':
        case prevOp === 'listIs' && newOp === 'containsNone':
        case prevOp === 'listIsnt' && newOp === 'containsAny':
        case prevOp === 'listIsnt' && newOp === 'containsNone':
        case prevOp === 'listIsnt' && newOp === 'listIs':
        case prevOp === 'containsAny' && newOp === 'listIs':
        case prevOp === 'containsAny' && newOp === 'listIsnt':
        case prevOp === 'containsAny' && newOp === 'containsNone':
        case prevOp === 'containsNone' && newOp === 'listIs':
        case prevOp === 'containsNone' && newOp === 'listIsnt':
        case prevOp === 'containsNone' && newOp === 'containsAny':
        case prevOp === 'withinLast' && newOp === 'withinNext':
        case prevOp === 'withinNext' && newOp === 'withinLast':
        case prevOp === 'beforeDate' && newOp === 'afterDate':
        case prevOp === 'beforeDate' && newOp === 'sameDay':
        case prevOp === 'afterDate' && newOp === 'beforeDate':
        case prevOp === 'afterDate' && newOp === 'sameDay':
        case prevOp === 'sameDay' && newOp === 'beforeDate':
        case prevOp === 'sameDay' && newOp === 'afterDate':
        case prevOp === 'isCurrentUserField' && newOp === 'isNotCurrentUserField':
        case prevOp === 'isNotCurrentUserField' && newOp === 'isCurrentUserField':
            newValue = filter.options.value
            break
    }

    // keep value the same if it's change between scalar operations
    const scalarOperations = [
        'is',
        'isnt',
        'contains',
        "doesn't contain",
        'startsWith',
        'endsWith',
        'lessThan',
        'equalsOrLessThan',
        'greaterThan',
        'equalsOrGreaterThan',
        'equals',
        'notEquals',
    ]
    if (prevOp && scalarOperations.includes(prevOp) && scalarOperations.includes(newOp)) {
        newValue = filter.options.value
    }

    return {
        ...filter,
        options: {
            ...filter.options,
            option: newOp,
            value: newValue,
        },
    }
}

export function getInlineFilterOpsForField(field: FieldDto): InlineFilterOperation[] {
    // If this is a foreign link field, the only operations we support are isEmpty and isNotEmpty
    if (LINK_TYPES.includes(field.type) && field.is_foreign) {
        return FOREIGN_LINK_FILTER_OPTIONS
    }
    return inlineFilterOpsForType[field.type] ?? []
}

export function getInlineFilterOp(
    filter?: Filter,
    field?: FieldDto
): InlineFilterOperation | undefined {
    if (field?.type === 'checkbox') return 'is'

    //need a cast to InlineFilterOperation here as InlineFilterOperation has less options that admin filter
    if (filter?.options.option) return filter.options.option as InlineFilterOperation

    const supportedOps = getInlineFilterOpsForField(field!)
    if (!supportedOps.length) return 'is'

    const firstOp = supportedOps[0]

    if (inlineFilterOpInputMap[firstOp]) {
        return firstOp
    }

    return undefined
}

export function isInlineFilterActive(filter?: Filter): boolean {
    if (!filter) return false

    const needsValue = filter.options.option && filter.options.option in inlineFilterOpInputMap
    if (needsValue) return typeof filter.options.value !== 'undefined'

    return Boolean(filter.options.option)
}

export function processInlineFilterValue(
    newValue: Filter['options']['value'],
    fieldType: FieldType
): Filter['options']['value'] {
    if (fieldType === 'checkbox' && Array.isArray(newValue)) {
        return newValue[0]
    }

    if (fieldType === 'percentage' && !!newValue) {
        return formatPercentageValue(newValue)
    }

    return newValue
}

export const getUserFieldOptions = (
    field: FieldDto,
    userObject?: ObjectDto,
    userProfileObjects: ObjectDto[] = []
) => {
    const allFields =
        userProfileObjects.length > 0
            ? [...(userProfileObjects.flatMap((object) => object.fields) ?? [])]
            : [...(userObject?.fields ?? [])]

    const options = allFields
        .filter(
            (userField) =>
                !userField.connection_options?.is_disabled && doesFieldTypeMatch(field, userField)
        )
        .map((x) => ({
            value: x.api_name,
            label: x.label,
        }))

    return options
}
