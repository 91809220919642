// @ts-strict-ignore
import React from 'react'

import { BoxProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAppContext } from 'app/AppContext'
import { isProviderHidden } from 'features/admin/data-connector/DataConnectors'
import { DataConnector } from 'features/admin/data-connector/dataConnectorTypes'
import DATA_PROVIDERS from 'features/admin/data-connector/dataProviderConfig'
import { ProviderIcon } from 'features/admin/data-connector/ProviderIcon'

import { Box, Flex } from 'v2/ui'

import DataSourceCard from './DataSourceCard'

type DataSourcesBannerProps = {
    onSelect: (key: DataConnectionType) => void
} & BoxProps
const DataSourcesBanner = ({ onSelect, ...props }: DataSourcesBannerProps) => {
    const { selectedStack } = useAppContext()
    const dataProviders = Object.entries(DATA_PROVIDERS)
        .filter(([_, value]) => value.isProviderShownInAddNewMenu)
        .reduce((acc: JSX.Element[], [key, value]: [DataConnectionType, DataConnector]) => {
            if (!isProviderHidden(selectedStack, key)) {
                acc.push(
                    <DataSourceCard
                        label={value.name}
                        backgroundImage={value.backgroundImage}
                        cta={value.cta}
                        description={value.description}
                        onClick={(_) => onSelect(key)}
                        key={key}
                        icon={<ProviderIcon type={key} size="40px" />}
                        type={key}
                    />
                )
            }
            return acc
        }, [])

    return (
        <Box width="100%" pb="16px" borderRadius="3px" {...props}>
            <BannerContainer>{dataProviders}</BannerContainer>
        </Box>
    )
}

export default DataSourcesBanner

const BannerContainer = styled(Flex)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    overflow: hidden;
    margin-top: 2px;
    gap: 18px;
    @media (max-width: 980px) {
        grid-template-columns: 1fr 1fr;
    }
`
