import React from 'react'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
} from 'ui/components/Dropdown'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

import { useBoardViewAddNewCardFieldRecordLinksState } from './hooks/useBoardViewAddNewCardFieldRecordLinksState'

import { BoardViewAddNewCardPopover } from './BoardView.css'

type BoardViewAddNewCardFieldRecordLinksProps = {
    field: FieldDto
    value: string | string[]
    onChange: (value?: string | string[]) => void
}

export const BoardViewAddNewCardFieldRecordLinks: React.FC<
    BoardViewAddNewCardFieldRecordLinksProps
> = (props) => {
    const {
        label,
        onClear,
        editOptions,
        isSingle,
        onEditValue,
        value,
        isEditable,
        isFetchingSlow,
        isError,
        searchQuery,
        setSearchQuery,
    } = useBoardViewAddNewCardFieldRecordLinksState(props)

    return (
        <Dropdown modal={false}>
            <DropdownButton
                size="2xs"
                variant={value.size > 0 ? 'activated' : 'dotted'}
                pointerEvents={isEditable ? undefined : 'none'}
            >
                {label}
            </DropdownButton>
            <DropdownContent
                head={!isFetchingSlow && <DropdownHeadSearch autoFocus={false} />}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                className={BoardViewAddNewCardPopover}
            >
                {!isError && !isFetchingSlow && value.size > 0 && (
                    <DropdownItem
                        startIcon={{ name: 'XCircle' }}
                        label="Clear value"
                        onClick={onClear}
                    />
                )}
                {isError && (
                    <DropdownItem
                        label={
                            <Body size="m" color="textError">
                                There was a problem displaying options
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError && editOptions.length < 1 && (
                    <DropdownItem
                        label={
                            <Body size="m" color="textHelper">
                                No options found
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError &&
                    editOptions.map((option) => {
                        const isChecked = value.has(option.value)

                        return (
                            <DropdownItem
                                key={option.value.toString()}
                                label={
                                    isFetchingSlow ? (
                                        <Skeleton isLoading={isFetchingSlow}>
                                            {option.label}
                                        </Skeleton>
                                    ) : (
                                        option.label
                                    )
                                }
                                multiSelect={!isFetchingSlow}
                                checked={isChecked}
                                onCheckedChange={(checked) => {
                                    onEditValue(option.value, checked)
                                }}
                                closeOnSelect={isSingle}
                                disabled={isFetchingSlow}
                                startAvatar={option.avatar}
                            />
                        )
                    })}
            </DropdownContent>
        </Dropdown>
    )
}
