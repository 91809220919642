import React from 'react'

import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'

import { Box } from 'ui/components/Box'
import { Headline } from 'ui/components/Text'
import { StackerLogo } from 'ui/graphics/StackerLogo'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

type Props = {
    title: string
    children: React.ReactNode
}
export function AuthFrame({ children, title }: Props) {
    return (
        <ThemeProvider theme="admin">
            <DefaultHeaderMeta title={title} />
            <Box fullWidth height="100vh" flex column center justifyContent="center">
                <Box
                    p={{ mobile: 'xl', tablet: '7xl' }}
                    borderStyle="base"
                    borderWidth={{ mobile: 0, tablet: 'base' }}
                    boxShadow={{ tablet: 'xl' }}
                    minWidth={{ mobile: 'full', tablet: '500px' }}
                    rounded="4xl"
                    flex
                    column
                    stretch
                >
                    <Box alignSelf="center">
                        <StackerLogo width="48" sOnly />
                    </Box>
                    <Headline
                        size="l"
                        textAlign="center"
                        mt="5xl"
                        mb="3xl"
                        data-testid="login-title-header"
                    >
                        {title}
                    </Headline>
                    {children}
                </Box>
            </Box>
        </ThemeProvider>
    )
}
