import { useCallback, useMemo, useRef, useState } from 'react'

import { getAvatarFromUserRecord } from 'features/views/attributes/hooks/useUserRecordLinksAvatars'
import { makeQuickFilterValues } from 'features/views/ListView/Filters/utils'
import { useLookupRecords } from 'features/views/ListView/hooks/useLookupRecords'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

import { useQuickFilter } from './useQuickFilter'

const MAX_ITEM_LENGTH = 25
const MAX_ITEMS = 2
const DEBOUNCE_RATE = 200

type FilterOption = {
    label: string
    value: string
    avatar?: {
        imageUrl: string
        firstName: string
        lastName: string
        type: 'initial' | 'image'
    }
}

type UseQuickFilterRecordLinksStateOptions = {
    field: FieldDto
}

export function useQuickFilterRecordLinksState(options: UseQuickFilterRecordLinksStateOptions) {
    const { field } = options

    const { value: filters, setFiltersValue, isActive, label: defaultLabel } = useQuickFilter(field)

    const filterValue = filters[0]
    const filterValueRef = useRef(filterValue)
    filterValueRef.current = filterValue

    const onFilterRemove = useCallback(() => {
        setFiltersValue([])
    }, [setFiltersValue])

    const onSetFilterValue = useCallback(
        (value: string, isEnabled: boolean) => {
            const filterValue = filterValueRef.current
            const existingValue = (filterValue?.options.value as string[]) ?? []

            let newValue: string[] = []
            if (isEnabled) {
                newValue = [...existingValue, value]
            } else {
                newValue = existingValue.filter((v) => v !== value)
            }

            // Clear the filter if no values are selected.
            if (newValue.length < 1) {
                setFiltersValue([])
                return
            }

            setFiltersValue(makeQuickFilterValues(field, newValue))
        },
        [field, setFiltersValue]
    )

    const [searchQuery, setSearchQuery] = useState('')
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
    const applyDebouncedSearchQuery = useDebounce(setDebouncedSearchQuery, DEBOUNCE_RATE)

    const handleChangeSearchQuery = useCallback(
        (query: string) => {
            setSearchQuery(query)
            applyDebouncedSearchQuery(query)
        },
        [applyDebouncedSearchQuery]
    )

    const { isFetchingSlow, isError, records } = useLookupRecords({
        field,
        searchQuery: debouncedSearchQuery,
    })

    const usersObject = useStackerUsersObject()
    const filterOptions = isFetchingSlow
        ? PLACEHOLDER_OPTIONS
        : makeFilterOptions(records, usersObject)

    const internalValue = useDeepEqualsMemoValue((filterValue?.options.value as string[]) ?? [])
    const value = useMemo(() => new Set(internalValue), [internalValue])

    const hasValue = value.size > 0
    const isButtonLoading = hasValue && !records
    const label = isButtonLoading ? 'Loading...' : formatLabel(value, filterOptions, defaultLabel)

    return useMemo(
        () => ({
            value,
            isActive,
            label,
            onFilterRemove,
            onSetFilterValue,
            filterOptions,
            isError,
            isFetchingSlow,
            searchQuery,
            setSearchQuery: handleChangeSearchQuery,
            isButtonLoading,
        }),
        [
            value,
            isActive,
            label,
            onFilterRemove,
            onSetFilterValue,
            filterOptions,
            isError,
            isFetchingSlow,
            searchQuery,
            handleChangeSearchQuery,
            isButtonLoading,
        ]
    )
}

function makeFilterOptions(records?: RecordDto[], usersObject?: ObjectDto): FilterOption[] {
    if (!records) {
        return []
    }

    const options = records.map((record) => {
        const avatar = getAvatarFromUserRecord(record, usersObject)

        const recordPrimary = record?._primary || ''
        const label = typeof recordPrimary === 'string' ? recordPrimary : recordPrimary.toString()

        return {
            label: truncateText(label, MAX_ITEM_LENGTH),
            value: record._sid,
            avatar,
        }
    })

    options.sort((a, b) => {
        // Sort by label alphabetically.
        if (a.label.localeCompare(b.label) < 0) return -1
        if (a.label.localeCompare(b.label) > 0) return 1

        return 0
    })

    return options
}

function formatLabel(value: Set<string>, filterOptions: FilterOption[], defaultLabel: string) {
    if (value.size < 1) {
        return defaultLabel
    }

    const activeOptions = filterOptions.filter((option) => value.has(option.value))

    const overflowingItemCount = activeOptions.length - MAX_ITEMS
    const truncatedOptions = activeOptions.slice(0, MAX_ITEMS)

    const formattedItems = truncatedOptions.map((option) => option.label).join(', ')

    if (overflowingItemCount > 0) {
        return `${formattedItems} +${overflowingItemCount}`
    }

    return formattedItems
}

const PLACEHOLDER_OPTIONS: FilterOption[] = [
    { label: 'Loading all filter options...', value: 'loading1' },
    { label: 'Loading all filter options...', value: 'loading2' },
    { label: 'Loading all filter options...', value: 'loading3' },
    { label: 'Loading all filter options...', value: 'loading4' },
    { label: 'Loading all filter options...', value: 'loading5' },
    { label: 'Loading all filter options...', value: 'loading6' },
]
