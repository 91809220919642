import React from 'react'

import { Button, Flex, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'
import { AirtableUpgradeInitial } from 'v2/ui/svgs'

import { AirtableOAuthLearnMoreLink } from './AirtableOAuthLearnMoreLink'
import { StyledFlex } from './StyledComponents'
type InitialAirtableUpgradeModalProps = {
    isOpen: boolean
    onClose: () => void
    onClickUpgrade: () => void
    disabled?: boolean
}

export const InitialAirtableUpgradeModal = ({
    isOpen,
    onClose,
    onClickUpgrade,
    disabled,
}: InitialAirtableUpgradeModalProps) => {
    return (
        <SimpleModalCompat
            size="400px"
            height="450px"
            isSecondLayer
            noDividers
            padding="0 32px 20px 32px"
            zIndex={1501}
            actionsBorder={false}
            isOpen={isOpen}
            onClose={onClose}
            hideTitleBottomBorder
            data-testid="airtable-upgrade.initial-modal"
        >
            <StyledFlex>
                <AirtableUpgradeInitial />
                <Text variant="heading" fontWeight="bold" marginTop={8} marginBottom={4}>
                    Upgrade Your Airtable Connection
                </Text>
                <Text fontSize="14px">
                    We&apos;ve made some updates to Airtable connections to improve security and
                    reliability.
                    <br />
                    <br />
                    Click &quot;Continue&quot; to update your existing connection to the new version
                    and enjoy these benefits.
                </Text>
                <Flex width="100%" marginTop={8} justifyContent="space-between">
                    <Button
                        flexShrink={0}
                        variant="Secondary"
                        ml={2}
                        buttonSize="large"
                        width="45%"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        flexShrink={0}
                        variant="Primary"
                        ml={2}
                        buttonSize="large"
                        width="45%"
                        onClick={onClickUpgrade}
                        data-testid="airtable-upgrade.start-upgrade"
                        disabled={disabled}
                    >
                        Continue
                    </Button>
                </Flex>
                <AirtableOAuthLearnMoreLink />
            </StyledFlex>
        </SimpleModalCompat>
    )
}
