import { useMemo } from 'react'

import { useCardViewContext } from 'features/views/ListView/CardView/CardViewContext'

export function useCardViewPaginationState() {
    const { currentPageIndex, setCurrentPageIndex, pageSize, recordCount } = useCardViewContext()

    return useMemo(
        () => ({
            pageIndex: currentPageIndex,
            onChangePageIndex: setCurrentPageIndex,
            pageSize,
            totalItemsCount: recordCount,
        }),
        [currentPageIndex, setCurrentPageIndex, pageSize, recordCount]
    )
}
