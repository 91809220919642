import React, { useRef } from 'react'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownContent, DropdownTrigger } from 'ui/components/Dropdown'
import { useDropdownContext } from 'ui/components/Dropdown/DropdownContext'
import { Icon } from 'ui/components/Icon'
import { Tag } from 'ui/components/Tag'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

type QuickFilterButtonRef = HTMLButtonElement

type QuickFilterButtonProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    isActive?: boolean
}

export const QuickFilterButton = React.forwardRef<QuickFilterButtonRef, QuickFilterButtonProps>(
    ({ children, isActive, ...props }, ref) => {
        const tagRef = useRef<HTMLDivElement>(null)

        const { isOpen } = useDropdownContext()!

        const tagSize = useResponsiveValue({
            mobile: '2xs' as const,
            tablet: 'm' as const,
        })

        return (
            <DropdownTrigger
                onPointerDown={(e) => {
                    const tag = tagRef.current
                    if (!tag) return

                    const target = e.target as HTMLElement
                    const closestInteractive = target.closest('[role="button"], button, a')
                    if (closestInteractive && closestInteractive !== tag) {
                        // Prevent the click from being passed to the parent dropdown if we're clicking on the delete button.
                        e.preventDefault()
                        e.stopPropagation()
                    }
                }}
                asChild
                ref={ref}
            >
                <Tag
                    ref={tagRef}
                    type={isActive ? 'solid' : 'outline'}
                    color={isActive ? 'Theme' : 'Neutral'}
                    size={tagSize}
                    shade="light"
                    shape="pill"
                    role="button"
                    showRemoveButton={isActive}
                    noShrink
                    {...props}
                >
                    <Box flex center gap="xs">
                        {children}
                        {!isActive && (
                            <Icon
                                name={isOpen ? 'ChevronUp' : 'ChevronDown'}
                                size="xs"
                                style={{
                                    color: theme.tag.color.iconOn,
                                }}
                            />
                        )}
                    </Box>
                </Tag>
            </DropdownTrigger>
        )
    }
)

export const QuickFilter = Dropdown
export const QuickFilterContent = DropdownContent
