import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import { useTasks } from 'data/hooks/tasks/tasks'
import Frame from 'features/core/Frame'
import { TaskListContextProvider } from 'features/tasks/TaskListContext'
import { useLocalStorageState } from 'utils/useLocalStorageState'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'ui/components/Tabs'

import { ActivityFeed } from './ActivityFeed'
import { FeedParameters } from './types'

type ViewDefinition = {
    id: string
    label: string
    params?: FeedParameters
}

export function FeedPage() {
    const { user } = useAppUserContext()
    const { selectedStack } = useAppContext()
    const history = useHistory()
    const location = useLocation()
    const [savedViewId, setSavedViewId] = useLocalStorageState(
        `FeedPage_SelectedViewId${selectedStack ? '_' + selectedStack._sid : ''}`,
        {
            defaultValue: 'following',
        }
    )
    const [selectedViewId, setSelectedViewId] = useState(location.hash?.slice(1) || savedViewId)

    useEffect(() => {
        const hash = `#${selectedViewId}`

        if (hash !== location.hash) {
            history.replace({
                pathname: location.pathname,
                search: location.search,
                hash,
                state: location.state,
            })
            setSavedViewId(selectedViewId)
        }
    }, [
        history,
        location.hash,
        location.pathname,
        location.search,
        location.state,
        selectedViewId,
        setSavedViewId,
    ])
    const Views: ViewDefinition[] = [
        {
            id: 'following',
            label: 'What I follow',
            params: {
                isFollowing: true,
                excludeActivityBy: user ? [user._sid] : undefined,
            },
        },
        { id: 'all', label: 'All Updates' },
    ]

    // const selectedView = Views.find((view) => view.id === selectedViewId)
    // const { id: _id, label: _label, ...selectedViewFilters } = selectedView ?? {}

    const { data: taskData = { tasks: [] }, actions } = useTasks()
    const taskListContext = {
        ...taskData,
        ...actions,
    }

    return (
        <Frame hideSideNav={!selectedStack} width="100%" paddingOverride="0px">
            <Box flex stretch column width="full" height="100vh" grow>
                <Tabs
                    value={selectedViewId}
                    onValueChange={setSelectedViewId}
                    orientation="vertical"
                    height="full"
                >
                    {/* <Container flex column width={56} variant="neutral" border p={4} noShrink> */}
                    <TabsList py="l" background="surface" style={{ minWidth: '150px' }}>
                        <>
                            <Box fontWeight="bodyBold" fontSize="bodyL" mb="l" mx="3xl">
                                Activity
                            </Box>
                            <Divider mb="l" />
                        </>
                        {/* <RadioGroup value={selectedViewId} onValueChange={setSelectedViewId}>
                                {Views.map((view) => (
                                    <RadioButton key={view.id} id={view.id} value={view.id}>
                                        {view.label}
                                    </RadioButton>
                                ))}
                            </RadioGroup> */}
                        <Box flex column overflowY="auto" pl="m">
                            {Views.map((view) => (
                                <TabsTrigger key={view.id} value={view.id} label={view.label} />
                            ))}
                        </Box>
                    </TabsList>
                    {/* </Container> */}
                    <Box flex column grow px="xl" maxHeight="100vh" overflowY="auto" py="m">
                        <Box style={{ width: '900px' }} maxWidth="full" flex column shrink>
                            <Box height="2xl" />

                            {Views.map((view) => (
                                <TabsContent key={view.id} value={view.id}>
                                    <TaskListContextProvider value={taskListContext}>
                                        <ActivityFeed
                                            contextKey={`${
                                                selectedStack ? selectedStack._sid + '_' : ''
                                            }${view.id}`}
                                            target={{ stackId: selectedStack?._sid }}
                                            params={{
                                                ...Views.find((v) => v.id === view.id)?.params,
                                                pageSize: 30,
                                            }}
                                            showLocationPreview={true}
                                        />
                                    </TaskListContextProvider>
                                </TabsContent>
                            ))}
                            {/* <ActivityFeed
                                viewId={selectedViewId}
                                params={{
                                    ...selectedViewFilters,
                                    pageSize: 30,
                                }}
                            /> */}
                        </Box>
                    </Box>
                </Tabs>
            </Box>
        </Frame>
    )
}
