import React, { useState } from 'react'

import { compact } from 'lodash'

import { useDataConnections } from 'data/hooks/dataConnections'
import { SettingsWrapper } from 'features/admin/settings/ui/SettingsFrame'
import SettingsHeading from 'features/admin/settings/ui/SettingsHeading'
import AdminFrame from 'features/core/AdminFrame'

import { Button, Text } from 'v2/ui'
import { Badge } from 'v2/ui/components/Badge'
import SimpleList from 'v2/ui/components/List/List'

import { ConnectionDetailsModal } from './ConnectionDetailsModal/ConnectionDetailsModal'

type ConnectionTableCell = { value: string }

const ConnectionInfoTable = ({
    connections,
}: {
    connections: DataConnectionDto[]
}): React.ReactElement => {
    const [showModal, setShowModal] = useState(false)
    const [selectedConnection, setSelectedConnection] = useState<DataConnectionDto | undefined>(
        undefined
    )

    const openModal = (connectionSid: string): void => {
        const selectedConnection = connections.find(
            (connection) => connection._sid == connectionSid
        )
        if (selectedConnection) {
            setShowModal(true)
            setSelectedConnection(selectedConnection)
        } else {
            closeModal()
        }
    }

    const closeModal = (): void => {
        setShowModal(false)
        setSelectedConnection(undefined)
    }

    const renderToText = ({ cell }: { cell: ConnectionTableCell }) => <Text>{cell.value}</Text>
    const renderVersion = ({ cell }: { cell: ConnectionTableCell }) => (
        <Badge color={cell.value == '1' ? 'gray' : 'blue'}>{cell.value}</Badge>
    )
    const renderStatus = ({ cell }: { cell: ConnectionTableCell }) => (
        <Badge color={cell.value == 'valid' ? 'green' : 'red'}>{cell.value}</Badge>
    )

    const renderDetailsButton = ({ cell }: { cell: ConnectionTableCell }) => (
        <Button variant="Secondary" buttonSize="small" onClick={() => openModal(cell.value)}>
            Connection Details
        </Button>
    )

    const columns = compact([
        { Header: 'Connection Name', accessor: 'label', Cell: renderToText },
        { Header: 'Type', accessor: 'type', Cell: renderToText },
        { Header: 'Version', accessor: 'version', Cell: renderVersion },
        { Header: 'Status', accessor: 'status', Cell: renderStatus },
        { Header: 'Actions', accessor: '_sid', Cell: renderDetailsButton },
    ])

    return (
        <>
            {/* @ts-expect-error */}
            <SimpleList
                columns={columns}
                data={connections}
                display="table"
                isRecordList={true}
                hideSearch={true}
            />
            {selectedConnection && (
                <ConnectionDetailsModal
                    connection={selectedConnection}
                    isOpen={showModal}
                    onClose={closeModal}
                />
            )}
        </>
    )
}
export const ConnectionDebuggerPage = (): React.ReactElement => {
    const { data: allDataConnections = [] } = useDataConnections()

    return (
        <AdminFrame header={false}>
            <SettingsHeading name="Connection Debugger" parents={[]} />
            <SettingsWrapper>
                <ConnectionInfoTable connections={allDataConnections} />
            </SettingsWrapper>
        </AdminFrame>
    )
}
