import store from 'app/store'
import {
    createField,
    deleteField,
    getCachedFieldsById,
    refetchFields,
    switchField,
    updateField,
} from 'data/hooks/fields'
import { getCachedObjectsById } from 'data/hooks/objects/objectOperations'
import { getLinksById } from 'data/selectors/linkSelectors'

import { buildLinkRequest } from './links/linkFactories'

const DefaultValues = {
    TYPE: 'lookup',
    INTERNAL_TYPE: 'relationship',
}

/**
 *
 * @param {LinkCreateData} data
 * @returns
 */
export async function create(data) {
    const objectsById = getCachedObjectsById()
    const isReversed = data.linkFieldObject === data.target
    // Create new link field
    const request = buildLinkRequest(objectsById, data)
    const createResp = await createField(request)
    // Get a reference to the automatically created symmetric field
    await renameSymmetric(
        createResp,
        isReversed ? data.sourceFieldLinkName : data.targetFieldLinkName
    )
    return Promise.resolve()
}

/**
 *
 * @param {string} linkId
 * @param {LinkUpdateData} data
 * @returns
 */
export async function update(linkId, data) {
    const state = store.getState()
    const fieldsById = getCachedFieldsById()
    const linksById = getLinksById(state)
    const link = linksById[linkId]
    const linkField = fieldsById[linkId]
    const symmetricField = link?.symmetric?.fieldId ? fieldsById?.[link.symmetric.fieldId] : null

    if (!linkField) {
        console.error(`Link '${linkId}' not found in redux state`, fieldsById)
        return Promise.reject()
    }

    const revertFields = linkField.object_id !== data.linkFieldObject

    // We have changed the direction of the link and need to
    // recreate each side in reverse
    if (revertFields) {
        const createResp = await switchField(linkId, {
            label: data.targetFieldLinkName,
            type: data.mode === 'many-to-many' ? 'multi_lookup' : 'lookup',
            object_id: data.target,
            link_target_object_id: data.source,
            synthetic_field_type: DefaultValues.INTERNAL_TYPE,
            connection_options: {
                relationship_local_lookup_field: data.targetField,
                relationship_local_lookup_field_delimiter: data.delimiter,
                relationship_target_lookup_field: data.sourceField,
                relationship_target_lookup_filters: data.filters,
            },
        })
        if (!symmetricField) {
            // Legacy links do not have symmetric field
            return
        }
        await renameSymmetric(createResp, data.sourceFieldLinkName)
    } else {
        await updateField(linkId, {
            label: data.sourceFieldLinkName,
            type: data.mode === 'many-to-many' ? 'multi_lookup' : 'lookup',
            link_target_object_id: data.target ?? linkField.link_target_object_id,
            connection_options: {
                ...linkField.connection_options,
                relationship_local_lookup_field:
                    data.sourceField ??
                    linkField.connection_options.relationship_local_lookup_field,
                relationship_target_lookup_field:
                    data.targetField ??
                    linkField.connection_options.relationship_target_lookup_field,
                relationship_target_lookup_filters:
                    data.filters ?? linkField.connection_options.relationship_target_lookup_filters,
                relationship_local_lookup_field_delimiter:
                    data.delimiter?.trim() ??
                    linkField.connection_options.relationship_local_lookup_field_delimiter,
            },
        })

        if (
            symmetricField &&
            symmetricField.label !== data.targetFieldLinkName &&
            !!data.targetFieldLinkName
        ) {
            await updateField(symmetricField._sid, {
                label: data.targetFieldLinkName,
            })
        }

        return Promise.resolve()
    }
}

/**
 *
 * @param {string} linkId
 * @returns
 */
export function remove(linkId) {
    return deleteField(linkId)
}

async function renameSymmetric(linkField, symmetricFieldName) {
    const fetchResp = await refetchFields()
    const symmetricField = fetchResp.find(
        (field) =>
            // We locate the newly created symmetric field by joining in with the new link field
            field.connection_options.relationship_synthetic_lookup_field === linkField._sid
    )
    return symmetricField && !!symmetricFieldName
        ? await updateField(symmetricField._sid, {
              label: symmetricFieldName,
          })
        : null
}

export const linkApi = {
    create,
    update,
    remove,
}
