/* Code Quality: Not audited */

import React from 'react'
import { Link } from 'react-router-dom'

import styled from '@emotion/styled'

import { getUrl, Urls } from 'app/UrlService'
import WithStacks from 'data/wrappers/WithStacks'
import { withUser } from 'data/wrappers/WithUser'

const PanelFloatingButton = () => {
    const FloatingButton = styled('img')`
        height: 35px;
        width: 35px;
        position: fixed;
        top: 20px;
        left: 20px;
        z-index: 99;
        opacity: 0.5;
    `

    const isStudio = window.location.pathname.startsWith(getUrl(Urls.Studio))
    return (
        <WithStacks>
            {({ selectedStack }) => {
                return !isStudio && selectedStack.options.icon ? (
                    <Link to={getUrl(Urls.StudioFeatures)}>
                        <FloatingButton alt="Home" src={selectedStack.options.icon} />
                    </Link>
                ) : (
                    ''
                )
            }}
        </WithStacks>
    )
}
export default withUser(PanelFloatingButton)
