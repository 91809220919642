// @ts-strict-ignore
import { UseQueryOptions, UseQueryResult } from 'react-query'

import { TIERS } from 'features/billing/types'

import { queryClient, useQuery } from './_helpers'

const LIST_NAME = ['usePlanPickerOptions']
const ENDPOINT = 'subscriptions/plan-picker'

export type StackerPlans = {
    product_id: string
    tier: keyof typeof TIERS
    order: number
    disabled: boolean
    prices: {
        month: {
            base_price: string
            included_users: number
            extra_user_price: string
            price_id: string
            current_plan: boolean
            disabled: boolean
        }
        year: {
            base_price: string
            included_users: number
            extra_user_price: string
            price_id: string
            current_plan: boolean
            disabled: boolean
        }
    }
    features: string[]
}

export type FeatureUsage = {
    name: string
    count?: number
    required_tier: string
    is_skip_tier_restriction?: boolean
}

export type PlanPickerOptions = {
    feature_usage: FeatureUsage[]
    min_tier: string
    plans: StackerPlans[]
    users_count: number
}

type PlanPickersParams = {
    options?: UseQueryOptions
    requiredTier?: string
}

export function usePlanPickerOptions({
    options = {},
    requiredTier,
}: PlanPickersParams): UseQueryResult<PlanPickerOptions, any> {
    let queryParams = {}
    if (requiredTier) queryParams['required_tier'] = requiredTier
    const queryOptions: UseQueryOptions<any, any, any> = { ...options, refetchOnMount: 'always' }
    return useQuery(LIST_NAME, ENDPOINT, queryOptions, {
        bypassMatchingStackCheck: true,
        // Submit this request using the studio user's token
        // and ignore any user or role previewing.
        bypassPreviewAs: true,
        queryParams,
    })
}

export const resetPlanPicker = () => {
    return queryClient.resetQueries(LIST_NAME)
}
