import React, { useCallback, useEffect, useState } from 'react'

import styled from '@emotion/styled'
import capitalize from 'lodash/capitalize'

import { ErrorDetails } from 'features/admin/data-connector/dataConnectorTypes'
import DATA_PROVIDERS from 'features/admin/data-connector/dataProviderConfig'
import SchemaUpdate from 'features/admin/data-connector/SchemaUpdate'
import useTrack from 'utils/useTrack'

import { SchemaSyncError } from './SchemaSyncError'

const SchemaContainer = styled('div')`
    overflow-y: auto;
    max-height: 55vh;

    @media (max-height: 640px) {
        max-height: 40vh;
    }
`

type FullSchemaSyncProps = {
    onComplete: () => void
    dataConnection: DataConnectionDto
    onError?: (err: string, details?: string) => void
    onStartSync?: () => void
    renderError?: (err: string, details?: ErrorDetails) => any
}

const FullSchemaSync: React.FC<FullSchemaSyncProps> = (props) => {
    const [error, setError] = useState<string | undefined>()
    const [errorDetails, setErrorDetails] = useState<ErrorDetails | undefined>()
    const { track } = useTrack()

    const handleSchemaImportComplete = useCallback(() => {
        track('WIP - Frontend - Data Connection - Schema Sync - Completed', {
            data_connection_type: props.dataConnection.type,
        })
        props.onComplete()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onComplete, props.dataConnection, track])

    const handleSchemaImportError = useCallback(
        (err, details) => {
            track('WIP - Frontend - Data Connection - Schema Sync - Error', {
                data_connection_type: props.dataConnection.type,
            })
            setError(err)
            setErrorDetails(details)
            if (props.onError) {
                props.onError(err, details)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [track, props.dataConnection]
    )

    useEffect(() => {
        track('WIP - Frontend - Data Connection - Schema Sync - Started', {
            data_connection_type: props.dataConnection.type,
        })
        if (props.onStartSync) {
            props.onStartSync()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (error) {
        const OverrideError = props.renderError?.(error, errorDetails)
        return (
            OverrideError || (
                <SchemaSyncError
                    provider={DATA_PROVIDERS[props.dataConnection.type]}
                    error={error}
                    details={errorDetails}
                />
            )
        )
    }

    return (
        <SchemaContainer>
            <SchemaUpdate
                onComplete={handleSchemaImportComplete}
                onError={handleSchemaImportError}
                portalType={props.dataConnection.type}
                portalName={capitalize(props.dataConnection.service_name)}
                dataConnectionSid={props.dataConnection._sid}
                dataConnectionType={props.dataConnection.type}
                autostart
            />
        </SchemaContainer>
    )
}

export default FullSchemaSync
