// @ts-strict-ignore
import React from 'react'

import HELP_LINKS from 'app/helpLinks'
import { useObject } from 'data/hooks/objects'
import {
    PermissionTable,
    PermissionTableRows,
} from 'features/AppSettings/Permissions/PermissionTable'
import { FormHeader } from 'features/workspace/WorkspaceSettingsModalUi'

import { Box, Button, Divider, Flex } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

const learnMore = (
    <Button variant="adminSecondary" buttonSize="sm" href={HELP_LINKS.PERMISSIONS}>
        Learn more
    </Button>
)

const AppSettingsModalDataPermissions = ({ stack, stackId, objectId, noPadding }) => {
    const { object } = useObject(objectId)

    if (!object) return null

    // Get list of roles WITH all data access. For now we are not showing the corresponding banner
    // because that plus the "You can now edit permissions for your whole app in one place" one
    // would be too much noise, but once people are used to that feature we can reinstate this

    // const { data: allRoles } = useRoles()
    // const rolesWithAllAccess = allRoles.filter((role) => {
    //     return (
    //         role.stack_id === stackId &&
    //         role.options?.rights?.find((x) => x === Rights.AllDataAccess)
    //     )
    // })

    return (
        <Box width="100%">
            <Flex
                p={!noPadding ? '20px' : undefined}
                flexDirection="column"
                alignItems="stretch"
                minHeight="100%"
                className={ONBOARDING_CLASSES.APP_SETTINGS_PERMISSIONS_LIST}
            >
                <FormHeader
                    title="Permissions"
                    subtitle="Choose what kind of data access you want to give your users"
                    rightSideContent={learnMore}
                />

                <Divider my={1} />

                {/* <Banner icon="info">
                    {rolesWithAllAccess.map((role, idx) => (
                        <>
                            {idx > 0 && idx < rolesWithAllAccess.length - 1 && ', '}
                            {idx > 0 && idx === rolesWithAllAccess.length - 1 && ' and '}
                            <strong>{role.label}</strong>
                        </>
                    ))}{' '}
                    users can view and modify all app data regardless of permission settings
                </Banner> */}

                <PermissionTable includeTableName={false}>
                    <PermissionTableRows stack={stack} stackId={stackId} objectId={objectId} />
                </PermissionTable>
            </Flex>
        </Box>
    )
}

export default AppSettingsModalDataPermissions
