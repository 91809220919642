import React from 'react'

import { Button } from 'v2/ui'
import { SolidDuplicate } from 'v2/ui/svgs'

type ListViewAdditionalActionsProps = {
    openDuplicateModal?: () => void
    setShowDuplicateListsLocked?: (isLocked: boolean) => void
    isDuplicateListsLocked?: boolean
}

const ListViewAdditionalActions: React.FC<ListViewAdditionalActionsProps> = ({
    openDuplicateModal,
    setShowDuplicateListsLocked,
    isDuplicateListsLocked,
}) => (
    <>
        <Button
            label="Duplicate layout"
            variant="clear"
            p={0}
            onClick={() => {
                if (!isDuplicateListsLocked) {
                    openDuplicateModal?.()
                } else {
                    setShowDuplicateListsLocked?.(true)
                }
            }}
            svgIcon={<SolidDuplicate />}
        />
    </>
)

export default ListViewAdditionalActions
