import { getLocale } from 'utils/dateUtils'

type UseCurrencyAttributeDisplayStateProps = {
    field: FieldDto
    value?: number
    isLoading?: boolean
}

export function useCurrencyAttributeDisplayState({
    field,
    value,
    isLoading,
}: UseCurrencyAttributeDisplayStateProps) {
    const content = formatCurrencyValue(field, isLoading ? PLACEHOLDER_VALUE : value)

    return { content }
}

const MAX_FRACTION_DIGITS = 2

function formatCurrencyValue(field: FieldDto, value?: number): string {
    const currencySign = field.options?.currency_symbol

    let formattedValue = '0'
    if (typeof value === 'number' && !Number.isNaN(value)) {
        const locale = getLocale()
        const formatter = new Intl.NumberFormat(locale, {
            useGrouping: true,
            minimumFractionDigits: MAX_FRACTION_DIGITS,
            maximumFractionDigits: MAX_FRACTION_DIGITS,
        })
        formattedValue = formatter.format(value)
    }

    if (currencySign) {
        return `${currencySign}\u200A${formattedValue}`
    }
    return formattedValue
}

const PLACEHOLDER_VALUE: number = 1234.56789
