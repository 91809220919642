import { useMemo } from 'react'

import { useRecordLinksAttributeDisplayState } from './useRecordLinksAttributeDisplayState'

type UseRecordLinksAttributeTagStateProps = {
    field: FieldDto
    value?: string | string[]
    maxItemLength?: number
    dereferencedRecords?: RecordDto[]
    isLoading?: boolean
}

export function useRecordLinksAttributeTagState({
    field,
    value,
    dereferencedRecords = [],
    maxItemLength,
    isLoading,
}: UseRecordLinksAttributeTagStateProps) {
    const {
        records,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelCloseAutoFocus,
    } = useRecordLinksAttributeDisplayState({
        field,
        value,
        dereferencedRecords,
        maxItemLength,
        isLoading,
    })

    const label = formatLabel(records)

    return useMemo(
        () => ({
            records,
            isOverflowPopupOpen,
            onOverflowPopupOpenChange,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelCloseAutoFocus,
            label,
        }),
        [
            isOverflowPopupOpen,
            onOverflowLabelCloseAutoFocus,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowPopupOpenChange,
            records,
            label,
        ]
    )
}

function formatLabel(records: ReturnType<typeof useRecordLinksAttributeDisplayState>['records']) {
    const recordCount = records.length
    if (recordCount === 1) {
        return records[0].name
    }

    return `${recordCount} linked records`
}
