import { useObjects } from 'data/hooks/objects'
import { assertIsDefined } from 'data/utils/ts_utils'

import { SubscriptionStatus } from './types'

/**
 * Checks if the object is the Stacker User object
 * @returns True if the object is the Stacker User object
 */
export const getIsStackerUserObject = (o: ObjectDto): boolean => {
    return o.system_object_type === 'users'
}

export function useStackerUsersObject(): ObjectDto {
    const { data: objects } = useObjects()
    const stackerUsersObject = objects?.find(getIsStackerUserObject)
    assertIsDefined(stackerUsersObject)
    return stackerUsersObject
}

export const getSubscriptionStatus = (workspaceAccount: Account): SubscriptionStatus => {
    return workspaceAccount?.subscription_status
}

export const isSubscriptionCancelled = (workspaceAccount: Account): boolean => {
    return getSubscriptionStatus(workspaceAccount) === 'cancelled'
}

export function isTrialUIEnabled(_workspaceAccount: Account): boolean {
    // return workspaceAccount?.trial_ui_enabled

    return false
}
