import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { AspectRatio, useThumbnailState } from './hooks/useThumbnailState'
import { EmptyThumbnailContent } from './EmptyThumbnailContent'

import { ThumbnailImageStyle, ThumbnailWrapperStyles } from './Thumbnail.css'

const sizeMapping = {
    radius: {
        s: 's',
        m: 'm',
    },
} as const

type ThumbnailProps = React.ComponentPropsWithoutRef<typeof Box> & {
    placeholder?: string
    size?: ResponsiveValue<'s' | 'm' | 'manual'>
    aspectRatio?: AspectRatio
}

export const Thumbnail: React.FC<ThumbnailProps> = ({
    src,
    placeholder,
    alt,
    className,
    size = 'm',
    aspectRatio: providedAspectRatio,
    ...props
}) => {
    const effectiveSize = useResponsiveValue(size)
    const radius = sizeMapping.radius[effectiveSize]

    const { isLoaded, onLoad, aspectRatio, isError, onError } = useThumbnailState({
        src,
        aspectRatio: providedAspectRatio,
    })

    return (
        <Box
            overflow="hidden"
            borderRadius={radius}
            background="gray100"
            noShrink
            flex
            center
            justifyContent="center"
            className={classNames(
                ThumbnailWrapperStyles.styleFunction({ aspectRatio, size: effectiveSize }),
                className
            )}
            {...props}
        >
            {(isError || !src) && (
                <EmptyThumbnailContent placeholder={placeholder} effectiveSize={effectiveSize} />
            )}
            {!isError && !!src && (
                <Box
                    as="img"
                    width="full"
                    height="full"
                    src={src}
                    opacity={isLoaded ? 1 : 0}
                    onLoad={onLoad}
                    onError={onError}
                    alt={alt}
                    pointerEvents="none"
                    className={ThumbnailImageStyle}
                />
            )}
        </Box>
    )
}
