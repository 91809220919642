import { useMemo } from 'react'

import { getUrl } from 'app/UrlService'
import { extractPartsFromUserName } from 'features/views/attributes/utils'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import { UserItem } from './useUsersState'

type UseUserAttributeDisplayStateProps = {
    value?: UserRefDto
    isLoading?: boolean
}

export function useUserAttributeDisplayState({
    value,
    isLoading,
}: UseUserAttributeDisplayStateProps) {
    const isByStackerSystem = !!value && value.source === 'stacker'

    const { firstName, lastName } = extractPartsFromUserName(value?.name)

    const avatarUrl = value?.avatar

    const userObject = useStackerUsersObject()
    const userUrl = getUserUrl(value, userObject)

    const effectiveValue: UserItem[] = useMemo(() => {
        if (isLoading) return getPlaceholderValue()

        return [
            {
                firstName,
                lastName,
                to: isByStackerSystem ? undefined : userUrl,
                hideAvatar: isByStackerSystem,
                imageUrl: avatarUrl,
                type: avatarUrl ? 'image' : 'initial',
            },
        ]
    }, [avatarUrl, firstName, isByStackerSystem, isLoading, lastName, userUrl])

    return { value: effectiveValue, isByStackerSystem }
}

function getUserUrl(user?: UserRefDto, userObject?: ObjectDto) {
    if (!user || !userObject) return ''

    const objectUrl = userObject.url
    const url = `${objectUrl}/view/${user.sid}`

    return getUrl(url)
}

function getPlaceholderValue(): UserItem[] {
    return [
        {
            firstName: 'Stacker',
            lastName: 'User',
            to: '',
            imageUrl: 'https://stackerhq.com',
        },
    ]
}
