import { useMemo } from 'react'

import { useBoardViewContext } from 'features/views/ListView/BoardView/BoardViewContext'

export function useBoardViewDataState() {
    const { isFetchingSlow, inlineFilterType, availableInlineFilterFields, header } =
        useBoardViewContext()

    const showTopPadding =
        (header.type === 'portal' && header.style === 'full') ||
        (header.type === 'default' &&
            (inlineFilterType !== 'quick' ||
                (inlineFilterType === 'quick' && availableInlineFilterFields.length < 1)))

    return useMemo(
        () => ({
            showTopPadding,
            isFetchingSlow,
        }),
        [showTopPadding, isFetchingSlow]
    )
}
