// @ts-strict-ignore
import React from 'react'
import { components } from 'react-select'

import { ProviderIcon } from 'features/admin/data-connector/ProviderIcon'

// @ts-ignore
import { Dropdown, Flex, Icon } from 'v2/ui'

type DropdownOptions = {
    label: string
    value: string
}

type MultiUserProfilesDropdownOptions = {
    label: string
    profile: ObjectDto
    options: DropdownOptions[]
}

type MultiUserProfilesDropdownProps = {
    id: string
    placeholder?: string
    value: string | undefined
    groupedOptions: MultiUserProfilesDropdownOptions[]
    style?: any
    onChange?: (field: any) => void
    isClearable?: boolean
    variant?: string
    usePortal?: boolean
}

const MultiUserProfilesDropdown: React.FC<MultiUserProfilesDropdownProps> = ({
    id,
    placeholder = 'Select attribute',
    value,
    groupedOptions,
    style,
    onChange,
    variant,
    usePortal,
}) => {
    const formatGroupLabel = ({ profile }) => {
        const getConnectionType = (connDetails) => {
            if (connDetails.includes('airtable')) {
                return 'airtable'
            } else {
                return 'stacker'
            }
        }

        return (
            <div
                style={{
                    borderBottom: '1px solid #eee',
                    marginLeft: '-6px',
                    paddingLeft: '6px',
                    paddingBottom: '3px',
                }}
            >
                <ProviderIcon
                    type={getConnectionType(profile?.data_connection)}
                    mr={2}
                    display="inline"
                    verticalAlign="middle"
                />
                <span
                    style={{
                        verticalAlign: 'baseline',
                        fontWeight: 'bold',
                        color: 'black',
                        textTransform: 'none',
                        fontSize: '0.875rem',
                    }}
                >
                    {profile?.name} Records
                </span>
            </div>
        )
    }

    return (
        <Dropdown
            id={id}
            placeholder={placeholder}
            clearable={false}
            options={groupedOptions}
            value={value}
            adminTheme
            style={style}
            singleValueComponent={FilterSelectValue}
            disableComponentOverrides={true}
            optionComponent={FilterOption}
            formatGroupLabel={formatGroupLabel}
            onChange={onChange}
            variant={variant}
            usePortal={usePortal}
        />
    )
}

const FilterSelectValue = (props) => (
    <div>
        <Flex align="center">
            <span>{props.data?.label}</span>
        </Flex>
    </div>
)

function FilterOption(props) {
    const { label } = props.data
    return (
        <components.Option {...props}>
            <div>
                {props.isSelected && (
                    <Icon
                        icon="checkmark"
                        size="sm"
                        color="neutral.600"
                        display="inline"
                        mr="6px"
                    />
                )}
                <span style={{ marginLeft: !props.isSelected ? '20px' : 0 }}>{label}</span>
            </div>
        </components.Option>
    )
}

export default MultiUserProfilesDropdown
