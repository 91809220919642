/* eslint-disable react/no-unknown-property */
import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import ReactQuill from 'react-quill'

import styled from '@emotion/styled'
import ImageResize from '@looop/quill-image-resize-module-react'
import isEqual from 'lodash/isEqual'
import Delta from 'quill-delta'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import MagicUrl from 'quill-magic-url'
import shortid from 'shortid'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDimension from 'v2/ui/utils/useDimension'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { customTurndownService } from './richTextEditorHelpers/customTurndownService'
import { MarkdownToQuill } from './richTextEditorHelpers/mdToDelta'
import {
    addNewLineForBlockTypes,
    convertAirtableToMd,
    convertMdToAirtable,
    processDeltaBeforeConverter,
    removeAddedNbsp,
} from './richTextEditorHelpers/quillRichTextUtils'

import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'

// Changes editor's inline code button icon.
const icons = ReactQuill.Quill.import('ui/icons')
icons['code'] = '<i class="fa fa-terminal" aria-hidden="true"></i>'

// Automatically detect and convert links.
ReactQuill.Quill.register('modules/magicUrl', MagicUrl)
ReactQuill.Quill.register('modules/imageResize', ImageResize)

const converter = new MarkdownToQuill()

function imageHandler() {
    const { tooltip } = this.quill.theme
    const originalSave = tooltip.save
    const originalHide = tooltip.hide

    tooltip.save = () => {
        const range = this.quill.getSelection(true)
        const { value } = tooltip.textbox
        this.quill.insertEmbed(range.index, 'image', value)
    }

    // Called on hide and save.
    tooltip.hide = () => {
        tooltip.save = originalSave
        tooltip.hide = originalHide
        tooltip.hide()
    }

    tooltip.edit('image')
    tooltip.textbox.placeholder = 'Image URL'
}

/**
 * multiLineHeader is needed to prevent the below
 * when 2 headers are back to back:
 * # Header
 * text
 */
var DELTA_TO_HTML_CONFIG = {
    multiLineHeader: false,
    // multiLineParagraph: false, // New line on each enter
}

function setTabindexes(rootElement, value) {
    //quill converts selects in custom toolbar into spans with tabindex="0", so
    //to avoid focusing on those elements, we need to remove tabindexes after the render.
    //related issues:
    // https://stackoverflow.com/questions/62035253/how-can-i-remove-tab-from-the-toolbar-of-primeng-p-editor-in-angular-8
    const spans = rootElement.getElementsByClassName('ql-picker-label')
    const buttons = rootElement.getElementsByTagName('button')
    const elements = [...spans, ...buttons]
    for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', value)
    }
}

function CustomToolbar({ toolbarId, isAirtable }) {
    const [showOptionalButton, setShowOptionalButton] = useState(false)

    const handleShowOptionalButton = () => {
        setShowOptionalButton(!showOptionalButton)
    }
    const ref = useRef()

    useLayoutEffect(() => {
        setTabindexes(ref.current, '-1')
    }, [])

    const { width } = useDimension(ref)
    const narrow = width < 470
    const veryNarrow = width < 290
    const showBoldEtc = !veryNarrow || showOptionalButton
    const showAll = !narrow || showOptionalButton

    return (
        <div id={toolbarId} ref={ref}>
            <span className="ql-formats">
                <button className="ql-header" value="1" tabIndex={-1} />
                <button className="ql-header" value="2" tabIndex={-1} />
                <button className="ql-blockquote" tabIndex={-1} />
            </span>

            <span className="ql-formats" style={{ display: showBoldEtc ? 'inline-block' : 'none' }}>
                <button className="ql-bold" tabIndex={-1} />
                <button className="ql-italic" tabIndex={-1} />
                {/*
                    For some reason the underline button is not working, but I don't have the
                    appetite to spend the time working out why. For now, no button is better than a
                    broken button   -- Martin

                    <button className="ql-underline" tabIndex={-1} />
                */}
                <button className="ql-strike" tabIndex={-1} />
            </span>

            {narrow && !showOptionalButton && (
                <span className="more-button">
                    <button
                        onClick={handleShowOptionalButton}
                        className="ql-others"
                        tabIndex={-1}
                        style={{ display: 'inline-block' }}
                    >
                        <strong>•••</strong>
                    </button>
                </span>
            )}

            <span className="ql-formats" style={{ display: showAll ? 'inline-block' : 'none' }}>
                <button className="ql-list" value="ordered" tabIndex={-1} />
                <button className="ql-list" value="bullet" tabIndex={-1} />
                <button className="ql-list" value="check" tabIndex={-1} />
            </span>

            <span className="ql-formats" style={{ display: showAll ? 'inline-block' : 'none' }}>
                {!isAirtable && <button className="ql-image" tabIndex={-1} />}
                <button className="ql-link" tabIndex={-1} />
                <button className="ql-code" tabIndex={-1}>
                    <i class="fa fa-terminal" aria-hidden="true"></i>
                </button>
                <button className="ql-code-block" tabIndex={-1}>
                    <i class="fa fa-code" aria-hidden="true"></i>
                </button>
            </span>
        </div>
    )
}

const getDelta = (value) => {
    // If we get an object, then this is already in quill delta format
    const val =
        typeof value === 'object'
            ? value
            : converter.convert(addNewLineForBlockTypes(convertAirtableToMd(value)))
    const delta = new Delta(val)
    delta.ops = removeAddedNbsp(delta.ops)
    return delta
}

const QuillRichText = React.forwardRef(
    (
        {
            value,
            onChange,
            placeholder,
            defaultTheme,
            readOnly,
            background,
            borderColor,
            convertToMarkdown = true,
            height,
            isAirtable = false,
            fontSize,
            autofocus,
            containerHeadingSize = true,
            ...props
        },
        ref
    ) => {
        const currentValue = useRef(value)
        const [quillRef, setQuillRef] = useState()
        const delta = getDelta(value)

        const [editorHtml, setEditorHtml] = useState(delta)

        const getNewValue = useCallback((val) => {
            if (!isEqual(val, currentValue.current)) {
                setEditorHtml(getDelta(val))
            }
        }, [])

        // This is a workaround to allow translation to work in read only mode
        useEffect(() => {
            const quill = quillRef?.editor
            if (quill) {
                const updateScroll = quill.scroll.update.bind(quill.scroll)
                quill.scroll.update = (mutations, context) => {
                    if (!quill.isEnabled()) {
                        return
                    }
                    updateScroll(mutations, context)
                }
                const scrollEnable = quill.scroll.enable.bind(quill.scroll)
                quill.scroll.enable = (enabled = true) => {
                    quill.container.classList.toggle('notranslate', enabled)
                    scrollEnable(enabled)
                }
                quill.container.classList.toggle('notranslate', quill.isEnabled())
            }
        }, [quillRef?.editor])

        useEffect(() => {
            // Autofocus to the last character in the editor if the autofocus prop is set
            if (autofocus && quillRef && !readOnly) {
                const editor = quillRef.getEditor()
                if (editor) {
                    const unprivilegedEditor = quillRef.makeUnprivilegedEditor(editor)
                    const len = unprivilegedEditor.getLength()
                    const selection = { index: len, length: len }
                    quillRef.setEditorSelection(editor, selection)
                }
            }
        }, [autofocus, quillRef, readOnly])

        const debouncedNewValue = useDebounce(getNewValue, readOnly ? 0 : 1000, [readOnly])

        // We use a ref here to store the current value in the editor
        // This ensures that we only refresh the editor if a new value comes back from the props
        // Also need to debounce this function so that it isn't constantly updaing if the onchange
        // event is delayed (it is debounced in attributedisplay too)
        useEffect(() => {
            debouncedNewValue(value)
        }, [debouncedNewValue, value])

        const [theme] = useState(defaultTheme ? defaultTheme : 'snow')

        // Generates a random toolbarId for custom toolbar.
        const toolbarId = useMemo(() => {
            // Quill require toolbar id to start with a letter.
            return generateRandomLetter() + shortid.generate()
        }, [])

        const modules = useMemo(() => {
            const allModules = {
                ...DEFAULT_MODULES,
                toolbar: {
                    container: `#${toolbarId}`,
                    handlers: {
                        image: imageHandler,
                    },
                },
            }
            if (!readOnly && !convertToMarkdown) {
                allModules.imageResize = {
                    parchment: ReactQuill.Quill.import('parchment'),
                    modules: ['Resize', 'DisplaySize'],
                }
            }
            return allModules
        }, [readOnly, toolbarId, convertToMarkdown])

        const quillContainerRef = useRef()
        useEffect(() => {
            setTabindexes(quillContainerRef.current, '-1')
        }, [])

        // We want to debounc this work, as it is quite expensive and is only needed to update
        // the parent. A small delay here makes for faster typing esp when there are images
        // pasted in the content.
        const processChange = useDebounce(
            useCallback(
                (newDelta) => {
                    // If we are not converting to markdown, then just save the Quill Delta json
                    if (!convertToMarkdown) {
                        const newValue = { ...newDelta }
                        currentValue.current = newValue
                        onChange(newValue)
                    } else {
                        // Otherwise convert from Delta -> HTML -> Markdown
                        // Delta -> HTML.
                        const deltaToHtmlConverter = new QuillDeltaToHtmlConverter(
                            processDeltaBeforeConverter(newDelta.ops),
                            DELTA_TO_HTML_CONFIG
                        )
                        // Remove break in empty list item because it induces an extra '\n'.
                        deltaToHtmlConverter.afterRender(function (groupType, htmlString) {
                            if (groupType === 'list') {
                                return htmlString
                                    .replaceAll('<li><br/></li>', '<li></li>')
                                    .replaceAll('<li><br/><ul>', '<li><ul>')
                                    .replaceAll('<li><br/><ol>', '<li><ol>')
                                    .replaceAll(
                                        '<li data-checked="false"><br/>',
                                        '<li data-checked="false">'
                                    )
                                    .replaceAll(
                                        '<li data-checked="true"><br/>',
                                        '<li data-checked="true">'
                                    )
                            }
                            return htmlString
                        })

                        let html = deltaToHtmlConverter.convert()

                        // HTML -> markdown.
                        let newMarkdown = convertMdToAirtable(customTurndownService.turndown(html))
                        currentValue.current = newMarkdown
                        onChange(newMarkdown)
                    }
                },
                [convertToMarkdown, onChange]
            ),
            300
        )

        const handleChange = useCallback(
            (newEditorHtml, delta, source, editor) => {
                if (!readOnly) {
                    let newDelta = editor.getContents()
                    // Check if the newDelta only consists of a single newline character
                    const isEmptyContent =
                        newDelta.ops.length === 1 && newDelta.ops[0].insert === '\n'
                    if (isEmptyContent) newDelta = new Delta()

                    setEditorHtml(newDelta)
                    processChange(newDelta)
                }
            },
            [processChange, readOnly]
        )

        useImperativeHandle(ref, () => quillRef, [quillRef])

        return (
            <StyledDiv
                ref={quillContainerRef}
                {...props}
                id="quillDiv"
                readOnly={readOnly}
                background={background}
                borderColor={borderColor}
                height={height}
                style={fontSize ? { fontSize: fontSize } : {}}
                containerHeadingSize={containerHeadingSize}
            >
                <CustomToolbar
                    toolbarId={toolbarId}
                    convertToMarkdown={convertToMarkdown}
                    isAirtable={isAirtable}
                />
                <ReactQuill
                    ref={setQuillRef}
                    theme={theme}
                    onChange={handleChange}
                    value={editorHtml}
                    modules={modules}
                    formats={convertToMarkdown ? FORMATS : undefined}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    bounds={`#quillDiv`}
                    tab="disabled"
                    {...props}
                />
            </StyledDiv>
        )
    }
)

export default QuillRichText

function generateRandomLetter() {
    return String.fromCharCode(97 + Math.floor(Math.random() * 26))
}

// Override Quill's font-family.
const StyledDiv = styled.div`
    background: ${(props) => props.background};
    flex-basis: 100%;
    max-width: 100%;
    .ql-container {
        font-family: inherit;
        font-size: inherit;
        text-align: inherit;
        border-radius: 0px 0px 6px 6px;
        overflow: auto;
        height: ${(props) => props.height || 'auto'};
        ${(props) =>
            props.borderColor
                ? `
            border-color: ${props.borderColor};
            border-top-color: ${V4DesignSystem.colors.gray[100]}
        `
                : ''};
    }

    .ql-toolbar {
        border-radius: 6px 6px 0px 0px;
        border-bottom: none;
        padding: 4px 8px;
        ${(props) => (props.borderColor ? `border-color: ${props.borderColor}` : '')};
    }

    .ql-toolbar.ql-snow .ql-formats,
    .ql-formats {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid lightgrey;
        height: 18px;
        margin: 5px 10px 5px 0;

        &:last-child {
            border-right: none;
            padding-right: 0;
            margin-right: 0;
        }
    }

    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        height: 24px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-top: -3px;
        margin-bottom: -3px;
        border-radius: 2px;

        color: ${V4DesignSystem.colors.gray[600]};
        .ql-fill {
            fill: ${V4DesignSystem.colors.gray[600]};
        }
        .ql-stroke {
            stroke: ${V4DesignSystem.colors.gray[600]};
        }

        &.ql-active,
        &:hover {
            background: ${V4DesignSystem.colors.gray[100]};
            color: ${V4DesignSystem.colors.gray[800]};
            .ql-fill {
                fill: ${V4DesignSystem.colors.gray[800]};
            }
            .ql-stroke {
                stroke: ${V4DesignSystem.colors.gray[800]};
            }
        }
    }

    .ql-editor {
        text-align: inherit;
        padding: ${(props) => (props.readOnly ? '0' : '')};
        cursor: ${(props) => (!props.readOnly ? 'text' : 'inherit')};
    }

    .ql-editor h1 {
        font-size: ${(props) => (props.containerHeadingSize ? '1.25rem' : '')};
    }

    .ql-editor h2 {
        font-size: ${(props) => (props.containerHeadingSize ? '1rem' : '')};
    }

    .ql-editor img {
        display: inline;
    }

    .more-button {
        display: inline-block;
        vertical-align: middle;
    }

    .ql-tooltip[data-mode='image']::before {
        content: 'Url';
    }

    .ql-tooltip {
        left: 0 !important;
        top: 30px !important;
    }
    .ql-blank {
        height: ${(props) => (props.readOnly ? '0' : '')};
    }
`

const QUILL_BINDINGS = {
    tab: false,
}

const DEFAULT_MODULES = {
    keyboard: {
        bindings: QUILL_BINDINGS,
    },
    magicUrl: true,
}

const FORMATS = [
    'header',
    'bold',
    'italic',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'indent',
    'code',
    'code-block',
    'image',
]
