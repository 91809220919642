import React, { useState } from 'react'

import classNames from 'classnames'

import { useAppContext } from 'app/AppContext'
import { useWorkspaceBarEnabled } from 'features/core/useWorkspaceBarEnabled'
import { WorkspaceSidebar } from 'features/NewAppBar/WorkspaceSidebar'
import { useNavContext } from 'features/utils/NavContext'

import { Flex } from 'v2/ui'
import useNavigationColor from 'v2/ui/hooks/useNavigationColor'
import useHover from 'v2/ui/utils/useHover'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Divider } from 'ui/components/Divider'
import { breakpoints } from 'ui/styling/helpers/breakpoints'

import { ApplicationSidebar } from './ApplicationSidebar'

import { SidebarHoverClass, SidebarsContainerStyle } from './WorkspaceSidebarStyles.css'

type SidebarsContainerProps = {}

const SidebarsContainer: React.FC<SidebarsContainerProps> = () => {
    const { sidebarState, isPoppedOut, setIsPoppedOut, hasMenuOpen } = useNavContext()
    const { selectedStack: stack } = useAppContext()
    const [showingWorkspaceBar, setShowingWorkspaceBar] = useState(false)
    const isMobile = useIsMobile(breakpoints.mobile.end)

    const [_isHovered, hoverHandlers] = useHover({
        onHoverStart: () => (sidebarState === 'fullyCollapsed' ? setIsPoppedOut(true) : undefined),
        onHoverEnd: () => (sidebarState === 'fullyCollapsed' ? setIsPoppedOut(false) : undefined),
        delay: 50,
    })
    const theme = stack?.options?.theme ?? {}
    const navTheme = useNavigationColor({ ...theme, navColor: 'light' })
    // Use default for appBar
    const appBarTheme = useNavigationColor({ navColor: 'light' })

    const isWorkspaceBarEnabled = useWorkspaceBarEnabled()

    const hideWorkspaceBar = (isMobile && !showingWorkspaceBar) || !isWorkspaceBarEnabled
    const isSidebarPoppedOut = isPoppedOut || hasMenuOpen
    let state: 'open' | 'workspaceBarCollapsed' | 'fullyCollapsed' | undefined =
        isMobile || isSidebarPoppedOut ? 'open' : sidebarState

    return (
        <>
            {isPoppedOut && (
                <Box
                    position="fixed"
                    style={{
                        zIndex: -1,
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                    onClick={() => {
                        setIsPoppedOut(false)
                    }}
                />
            )}
            <Flex
                className={classNames([
                    SidebarsContainerStyle({
                        state,
                        isPoppedOut,
                        hasAppNav: !!stack?._sid,
                        hasWorkspaceBar: true,
                    }),
                    sidebarState === 'fullyCollapsed' && SidebarHoverClass,
                ])}
                column
                wrap="nowrap"
                align="stretch"
                height="100%"
                maxHeight="100%"
                maxWidth="100%"
                pointerEvents="auto"
                {...hoverHandlers}
            >
                {isMobile && isWorkspaceBarEnabled && (
                    <>
                        <Box
                            flex
                            justifyContent={showingWorkspaceBar ? 'flex-end' : 'flex-start'}
                            borderColor="border"
                            background="surface"
                            borderStyle="base"
                            borderRightWidth={1}
                            maxHeight="full"
                        >
                            {!showingWorkspaceBar && (
                                <Button
                                    variant="ghost"
                                    onClick={() => setShowingWorkspaceBar(true)}
                                    startIcon={{ name: 'ChevronLeft' }}
                                >
                                    Workspace
                                </Button>
                            )}
                            {showingWorkspaceBar && (
                                <Button
                                    variant="ghost"
                                    onClick={() => setShowingWorkspaceBar(false)}
                                    endIcon={{ name: 'ChevronRight' }}
                                >
                                    {stack?.name}
                                </Button>
                            )}
                        </Box>
                        <Divider />
                    </>
                )}
                <Flex wrap="nowrap" align="stretch" flexGrow={1} shrink maxHeight="full">
                    {!hideWorkspaceBar && <WorkspaceSidebar navTheme={appBarTheme} />}
                    {stack?._sid && (
                        <ApplicationSidebar
                            navTheme={navTheme}
                            stack={stack}
                            shouldShowWorkspaceControls={!isWorkspaceBarEnabled}
                        />
                    )}
                </Flex>
            </Flex>
        </>
    )
}

export default SidebarsContainer
