import React from 'react'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
    DropdownItemTag,
} from 'ui/components/Dropdown'

import { useBoardViewAddNewCardFieldDropdownState } from './hooks/useBoardViewAddNewCardFieldDropdownState'

import { BoardViewAddNewCardPopover } from './BoardView.css'

type BoardViewAddNewCardFieldDropdownProps = {
    field: FieldDto
    value: string | string[]
    onChange: (value?: string | string[]) => void
}

export const BoardViewAddNewCardFieldDropdown: React.FC<BoardViewAddNewCardFieldDropdownProps> = (
    props
) => {
    const { label, onClear, editOptions, isSingle, onEditValue, value, isEditable } =
        useBoardViewAddNewCardFieldDropdownState(props)

    return (
        <Dropdown modal={false}>
            <DropdownButton
                size="2xs"
                variant={value.size > 0 ? 'activated' : 'dotted'}
                pointerEvents={isEditable ? undefined : 'none'}
            >
                {label}
            </DropdownButton>
            <DropdownContent
                head={<DropdownHeadSearch autoFocus={false} />}
                className={BoardViewAddNewCardPopover}
            >
                {value.size > 0 && (
                    <DropdownItem
                        startIcon={{ name: 'XCircle' }}
                        label="Clear value"
                        onClick={onClear}
                    />
                )}
                {editOptions.map((option) => {
                    const isChecked = value.has(option.value)

                    return (
                        <DropdownItemTag
                            key={option.value.toString()}
                            label={option.label}
                            size="2xs"
                            color={option.color}
                            multiSelect
                            checked={isChecked}
                            onCheckedChange={(checked) => {
                                onEditValue(option.value, checked)
                            }}
                            closeOnSelect={isSingle}
                        />
                    )
                })}
            </DropdownContent>
        </Dropdown>
    )
}
