import React, { ChangeEvent, useEffect, useRef } from 'react'

import styled from '@emotion/styled'
import { useMachine } from '@xstate/react'
import { isEmpty } from 'lodash'

import HELP_LINKS from 'app/helpLinks'
import { useDataConnection } from 'data/hooks/dataConnections'
import useTrack from 'utils/useTrack'

import { Box, Flex, Text, Textarea } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

import { FORMULA_EDITOR_PLACEHOLDER } from './constants/formulaEditorTextConstants'
import { formulaEditorMachine } from './machines/formulaEditorMachine'
import { FormulaEditorProps } from './formulaEditorTypes'

const colors = stackerTheme().colors

export const FormulaEditor: React.FC<FormulaEditorProps> = ({
    field,
    initialFormulaString,
    object,
    onChange,
}: FormulaEditorProps) => {
    const { track } = useTrack()
    const editorRef = useRef<HTMLTextAreaElement>(null)

    const [state, send] = useMachine(formulaEditorMachine)

    const { data: dataConnection } = useDataConnection(object?.data_connection)

    useEffect(() => {
        send({
            dataConnectionType: dataConnection?.type,
            editorRef,
            field,
            initialFormulaString,
            object,
            onChange,
            track,
            type: 'INITIALIZE',
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        send({ type: 'SET_DATA_CONNECTION_TYPE', dataConnectionType: dataConnection?.type })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataConnection])

    const {
        canAutocomplete,
        functionForHelpPanel,
        isBlank,
        shouldOnlyShowFields,
        suggestedFields,
        suggestedKeywords,
        validationError,
    } = state.context

    const canShowSuggestions = isBlank || !isEmpty(suggestedKeywords) || !isEmpty(suggestedFields)

    return (
        <Box>
            <Text
                textAlign="left"
                color={colors.userInterface.neutral[850]}
                size="xs"
                // icon="info"
                mb={1}
            >
                Start typing a formula or <b>Scroll</b> through available fields and functions
                below.
                <br />
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a target="_blank" href={HELP_LINKS.FORMULA_FIELDS}>
                    {' '}
                    Learn more
                </a>
            </Text>
            <Textarea
                style={{
                    borderWidth: 1,
                    borderColor: colors.grey[200],
                    maxHeight: '360px',
                    fontFamily: 'Consolas, Courier',
                    borderBottomLeftRadius: canShowSuggestions ? 0 : 6,
                    borderBottomRightRadius: canShowSuggestions ? 0 : 6,
                }}
                mb={0}
                placeholder={FORMULA_EDITOR_PLACEHOLDER}
                size="md"
                rows="5"
                width="100%"
                variant="normal"
                forwardRef={editorRef}
                onBlur={() => send({ type: 'BLUR' })}
                onFocus={() => send({ type: 'FOCUS' })}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    send({
                        formulaString: event.target.value,
                        type: 'TEXT_CHANGED',
                    })
                }
                // We want to update the selection if the user's selection changes. There is a selectionchange
                // event, but at the time of writing (August 2022), that only works on Firefox:
                // https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/selectionchange_event
                // A workaround for this is to listen for clicks, and keys which can change the selection.
                onClick={() => send({ type: 'SELECTION_MOVED' })}
                onKeyUp={(event: KeyboardEvent) => {
                    if (
                        [
                            'ArrowLeft',
                            'ArrowUp',
                            'ArrowRight',
                            'ArrowDown',
                            'Home',
                            'End',
                            'PageDown',
                            'PageUp',
                        ].includes(event.key)
                    ) {
                        send({ type: 'SELECTION_MOVED' })
                    } else if (['Backspace', 'Delete'].includes(event.key)) {
                        // This is to solve a bug where suggestions were not getting updates when we select & delete some text
                        // Make sure this always triggers a TEXT_CHANGED event so we check isBlank and update suggestions
                        send({
                            formulaString: (event.target as HTMLTextAreaElement).value,
                            type: 'TEXT_CHANGED',
                        })
                    }
                }}
                onKeyDown={(event: KeyboardEvent) => {
                    const isSomeSuggestionPresent =
                        !isEmpty(suggestedFields) || !isEmpty(suggestedKeywords)
                    if (event.key === 'Tab' && isSomeSuggestionPresent && canAutocomplete) {
                        event.preventDefault()
                        event.stopPropagation()
                        send({ type: 'AUTOCOMPLETE' })
                    }
                }}
            />
            {canShowSuggestions && validationError && (
                <SuggestionFlex
                    display={
                        isEmpty(suggestedFields) && isEmpty(suggestedKeywords) ? 'none' : 'flex'
                    }
                    flexWrap="nowrap"
                    flexDirection="column"
                    alignItems="stretch"
                    mb={1}
                    py={3}
                >
                    {!isEmpty(suggestedFields) && (
                        <>
                            <Text
                                textAlign="left"
                                pl={4}
                                size="xs"
                                color={colors.userInterface.neutral[800]}
                            >
                                Available fields
                            </Text>
                            <Flex flexDirection="column" alignItems="start">
                                {suggestedFields.map((field) => (
                                    <SelectOption
                                        key={field._sid}
                                        pl={4}
                                        onClick={() =>
                                            send({
                                                payloadType: 'field',
                                                field,
                                                type: 'AUTOCOMPLETE',
                                            })
                                        }
                                    >
                                        <FieldOption size="sm" as="span">
                                            {field.insertText}
                                        </FieldOption>
                                    </SelectOption>
                                ))}
                            </Flex>
                        </>
                    )}

                    {!isEmpty(suggestedKeywords) && !shouldOnlyShowFields && (
                        <>
                            <Text
                                textAlign="left"
                                pl={4}
                                mt={3}
                                size="xs"
                                color={colors.userInterface.neutral[800]}
                            >
                                Functions
                            </Text>
                            {suggestedKeywords.map((keyword) => (
                                <SelectOption
                                    key={keyword.insertText}
                                    pl={4}
                                    onClick={() =>
                                        send({
                                            payloadType: 'keyword',
                                            keyword,
                                            type: 'AUTOCOMPLETE',
                                        })
                                    }
                                >
                                    <FunctionOption size="sm" as="span">
                                        {keyword.insertText}
                                    </FunctionOption>
                                </SelectOption>
                            ))}
                        </>
                    )}
                </SuggestionFlex>
            )}

            <Box minHeight="24px" textAlign="left" mt={0}>
                {isBlank ? (
                    <Text size="xs">Type a formula</Text>
                ) : validationError ? (
                    <Text size="xs" color={colors.userInterface.error[600]}>
                        {validationError}
                    </Text>
                ) : (
                    <Text size="xs" color={colors.userInterface.success[500]}>
                        Your formula is valid
                    </Text>
                )}
            </Box>

            {functionForHelpPanel && (
                <HelpPopupBox>
                    <HelpFormulaText color={colors.userInterface.formulaFunction}>
                        {functionForHelpPanel.syntax}
                    </HelpFormulaText>
                    <HelpText>{functionForHelpPanel.helpText}</HelpText>
                    <HelpText>
                        {functionForHelpPanel.examples.length > 1 ? 'Examples:' : 'Example:'}
                    </HelpText>
                    {functionForHelpPanel.examples.map((example, index) => (
                        <HelpFormulaText color={colors.userInterface.accent[1200]} key={index}>
                            {example}
                        </HelpFormulaText>
                    ))}
                </HelpPopupBox>
            )}
        </Box>
    )
}

const SelectOption = styled(Flex)`
    cursor: pointer;
    width: 100%;
    :hover {
        background-color: ${colors.userInterface.neutral[400]};
    }
`

const FormulaText = styled(Text)`
    font-family: Consolas, Courier;
    padding: 4px;
    border-radius: 2px;
    line-height: 1;
    margin: 1px 0;
`

const FieldOption = styled(FormulaText)`
    border: solid 0.5px var(--user-interface-neutral-colours-neutral-400);
    background-color: ${colors.userInterface.neutral[400]};
    color: #007299;
`

const FunctionOption = styled(FormulaText)`
    border: solid 0.5px var(--user-interface-neutral-colours-neutral-400);
    color: ${colors.userInterface.formulaFunction};
`

const SuggestionFlex = styled(Flex)`
    margin-top: -5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: ${() => `1px solid ${colors.grey[200]}`};
    max-height: 130px;
    overflow-y: auto;
    border-top: none;
`

const HelpPopupBox = styled(Box)`
    position: absolute;
    top: 144px;
    right: 516px;
    width: 240px;
    padding: 16px;
    background-color: ${colors.neutral[100]};
    border-radius: 6px;
    border: 1px solid ${colors.grey[200]};
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
`

const HelpText = styled(Text)`
    padding: 6px 4px 8px 4px;
`

const HelpFormulaText = styled(FormulaText)`
    line-height: 1.25;
`
