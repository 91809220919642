import React from 'react'
import { useForm } from 'react-hook-form'

import { LinkFormFields, LinkFormFieldsMinimal, LinkModalForm } from 'features/links'

export const LinkNewModalView = ({ isOpen, onClose, onSubmit, source, minimal = false }) => {
    const formContext = useForm({
        mode: 'all',
        defaultValues: {
            source: source ?? ``,
            sourceField: ``,
            target: ``,
            targetField: ``,
            linkFieldObject: source ?? ``,
            sourceFieldLinkName: ``,
            targetFieldLinkName: ``,
            filters: [],
            // Newly created links always have a symmetric field
            // so they are not considered legacy
            legacy: false,
            mode: 'one-to-many',
            delimiter: ',',
        },
    })
    return (
        <LinkModalForm
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            form={formContext}
            isSecondLayer={true}
        >
            {minimal ? (
                <LinkFormFieldsMinimal form={formContext} />
            ) : (
                <LinkFormFields form={formContext} />
            )}
        </LinkModalForm>
    )
}
