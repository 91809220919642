import React, { useContext } from 'react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import AppContext from 'app/AppContext'
import { openPlanPickerModal } from 'features/utils/__hackyGlobalModalControls'
import analytics from 'utils/analytics'

import Button from 'v2/ui/components/Button'

const UpgradeButton = ({
    fromWorkspaceSettingsModal = false,
    plan,
    onClick,
    children,
    ...props
}: {
    fromWorkspaceSettingsModal?: boolean
    plan?: string
    onClick?: () => void
    children?: React.ReactNode
}): React.ReactElement | null => {
    const { workspaceAccount } = useContext(AppContext)
    const { hasRight } = useAccountUserContext()
    const isSubscribed = workspaceAccount?.subscription_status === 'subscribed'
    const customerId = workspaceAccount?.stripe_customer_id
    const onTopTier = workspaceAccount?.plan_tier === 'Enterprise'
    const isManualSubscription = !customerId && isSubscribed
    if (!hasRight(Rights.ManageBilling) || isManualSubscription || onTopTier) return null

    return (
        <Button
            w="100%"
            variant="adminPrimaryV4"
            onClick={() => {
                if (onClick) onClick()
                analytics.track('clicked upgrade', {})
                openPlanPickerModal({ fromWorkspaceSettingsModal, requiredTier: plan })
            }}
            {...props}
        >
            {children || 'Upgrade'}
        </Button>
    )
}

export default UpgradeButton
