import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

type UseBoardViewFieldInputStateOptions = {
    fields: FieldDto[]
    value?: BoardCardFooterField
}

export function useBoardViewFieldInputState(options: UseBoardViewFieldInputStateOptions) {
    const { fields, value } = options

    const selectedField = fields.find((field) => field._sid === value)

    const usersObject = useStackerUsersObject()
    const objectSid =
        ['lookup', 'multi_lookup'].includes(selectedField?.type ?? '') &&
        selectedField?.link_target_object_id
    const isUsersObjectLink = objectSid === usersObject?._sid

    return { selectedField, isUsersObjectLink }
}
