import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useCreateGrant, useGrants, useUpdateGrant } from 'data/hooks/accessGrants'
import { useObjects } from 'data/hooks/objects'
import { useRoleListOptions } from 'data/hooks/roleHelpers'
import {
    filtersToValue,
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'

import { Box, Collapse, ConditionalWrapper, Flex } from 'v2/ui'

import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Input } from 'ui/components/Input'
import { Body, Headline } from 'ui/components/Text'
import { useToast } from 'ui/components/Toast'
import { Tooltip } from 'ui/components/Tooltip'

import { EmailFieldPicker } from './inputs/EmailFieldPicker'
import { RolePicker } from './inputs/RolePicker'
import { TablePicker } from './inputs/TablePicker'

type FormData = Pick<
    AccessGrant,
    '_sid' | 'group_name' | 'role_sid' | 'object_sid' | 'email_field_api_name' | 'stack_sid'
> & { filters: FilterValue[] }

export const TableGrantForm = ({
    onClose,
    tableGrant,
}: {
    onClose: () => void
    tableGrant?: AccessGrant | null
}) => {
    const { data: objects = [] } = useObjects()
    const { mutateAsync: createGrant } = useCreateGrant()
    const { mutateAsync: updateGrant } = useUpdateGrant()
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
    const isEdit = !!tableGrant

    const { data: roles = [] } = useRoleListOptions('_sid', false)

    const {
        register,
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { errors, isDirty },
    } = useForm<FormData>({
        defaultValues: tableGrant
            ? {
                  _sid: tableGrant?._sid,
                  group_name: tableGrant?.group_name,
                  role_sid: tableGrant?.role_sid,
                  object_sid: tableGrant?.object_sid,
                  email_field_api_name: tableGrant?.email_field_api_name,
                  stack_sid: tableGrant?.stack_sid,
                  filters: tableGrant?.filters || [],
              }
            : { role_sid: roles.length === 1 ? roles[0].value : undefined },
    })

    const objectSid = watch('object_sid')
    const object = useMemo(() => {
        if (!objectSid || !objects) return undefined
        return objects?.find(({ _sid }) => _sid === objectSid)
    }, [objectSid, objects])

    const handleCreateOrUpdate = async (data: FormData) => {
        setIsLoading(true)
        try {
            if (isEdit) {
                await updateGrant({
                    id: tableGrant._sid,
                    patch: { ...data, stack_id: tableGrant.stack_sid },
                })
            } else {
                await createGrant(data)
            }
            onClose()
        } catch {
            if (isEdit) {
                toast({
                    helperText: 'Please try again later. If the issue persists, contact support.',
                    showDismissButton: true,
                    startIcon: {
                        name: 'AlertCircle',
                    },
                    title: 'Cannot update table group',
                    type: 'error',
                })
            } else {
                toast({
                    helperText: 'Please try again later. If the issue persists, contact support.',
                    showDismissButton: true,
                    startIcon: {
                        name: 'AlertCircle',
                    },
                    title: 'Cannot create table group',
                    type: 'error',
                })
            }
        }
        setIsLoading(false)
    }

    const { data: grants = [] } = useGrants()

    const validateUniqueGroupName = useCallback(
        (value: string) => {
            return (
                !grants.find(
                    (grant) =>
                        grant.group_name === value &&
                        (!tableGrant || grant._sid !== tableGrant._sid)
                ) || 'Group name must be unique.'
            )
        },
        [grants, tableGrant]
    )

    useEffect(() => {
        if (!isEdit) {
            setValue('email_field_api_name', undefined)
        }
    }, [objectSid, setValue, isEdit])

    return (
        <Flex alignItems="flex-start" mt={2}>
            <Flex width="20%">
                <Button
                    size="m"
                    startIcon={{
                        name: 'ArrowLeft',
                    }}
                    variant="ghost"
                    onClick={onClose}
                >
                    Back
                </Button>
            </Flex>
            <Flex column flexGrow={1} alignItems="left">
                <Headline size="s">
                    {tableGrant ? 'Table group settings' : 'New table group'}{' '}
                </Headline>
                <Box h={4} />
                <Input
                    label="Name"
                    size="m"
                    type="text"
                    isError={!!errors?.group_name}
                    helperText={errors?.group_name?.message}
                    {...register('group_name', {
                        required: 'Group name is required.',
                        validate: validateUniqueGroupName,
                    })}
                />
                <Box h={4} />
                <Controller
                    control={control}
                    name="role_sid"
                    rules={{ required: 'Role is required' }}
                    render={({ field, fieldState: { error } }) => (
                        <RolePicker roles={roles} error={error} modal={false} {...field} />
                    )}
                />
                <Container variant="neutralMuted" p="xl" my="xl">
                    <Controller
                        control={control}
                        name="object_sid"
                        rules={{ required: 'Table is required' }}
                        render={({ field, fieldState: { error } }) => (
                            <TablePicker
                                objects={objects}
                                error={error}
                                readOnly={isEdit}
                                modal={false}
                                {...field}
                            />
                        )}
                    />
                    <Collapse isOpen={!!objectSid}>
                        {objectSid && (
                            <>
                                <Box h={4} />
                                <Controller
                                    control={control}
                                    name="email_field_api_name"
                                    rules={{ required: 'Email field is required' }}
                                    render={({ field, fieldState: { error } }) => (
                                        <EmailFieldPicker
                                            objectId={objectSid}
                                            objects={objects}
                                            error={error}
                                            readOnly={isEdit}
                                            modal={false}
                                            {...field}
                                        />
                                    )}
                                />
                                <Box h={4} />
                                <Body weight="medium">Only include matching users</Body>
                                <Box mt={2}>
                                    <Controller
                                        control={control}
                                        name="filters"
                                        render={({ field }) => {
                                            const { value, onChange, ...restField } = field

                                            return (
                                                <Filters
                                                    {...restField}
                                                    object={object}
                                                    fields={object?.fields}
                                                    value={filtersToValue(value, object)}
                                                    onChange={(filters) =>
                                                        onChange(formatFilters(filters))
                                                    }
                                                    color="white"
                                                    customButtonRender={(props) => (
                                                        <Button
                                                            {...props}
                                                            type="button"
                                                            variant="link"
                                                            startIcon={{ name: 'Plus' }}
                                                        >
                                                            Add filter
                                                        </Button>
                                                    )}
                                                    hideTheRecordFilter
                                                />
                                            )
                                        }}
                                    />
                                </Box>
                            </>
                        )}
                    </Collapse>
                </Container>
                <Flex flexGrow={1} justifyContent="space-between">
                    <ConditionalWrapper
                        condition={!objectSid}
                        wrapper={(children) => (
                            <Tooltip
                                content="Select a table to create new table group"
                                side="bottom"
                                zIndex={1000}
                            >
                                {children}
                            </Tooltip>
                        )}
                    >
                        <Button
                            size="m"
                            onClick={() => {
                                handleSubmit(handleCreateOrUpdate)()
                            }}
                            isLoading={isLoading}
                            disabled={!objectSid || !isDirty}
                        >
                            {isEdit ? 'Save changes' : 'Create table group'}
                        </Button>
                    </ConditionalWrapper>
                </Flex>
            </Flex>
            <Box width="20%" />
        </Flex>
    )
}
