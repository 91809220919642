import * as React from 'react'
import { useEffect, useState } from 'react'

import {
    openWorkspaceSettingsModal,
    planModalState,
} from 'features/utils/__hackyGlobalModalControls'
import CustomPlanPicker from 'features/workspace/CustomPlanPicker'

import SimpleModal from 'v2/ui/components/SimpleModal'

// handle the open / closed state of the modal
export default function PlanPickerModal() {
    const [openWorkspaceSettingsAfterClose, setOpenWorkspaceSettingsAfterClose] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [requiredTier, setRequiredTier] = useState(false)
    const [disableFocusLock, setDisableFocusLock] = useState(false)

    useEffect(() => {
        planModalState.subscribers.push({
            setIsOpen,
            setOpenWorkspaceSettingsAfterClose,
            setDisableFocusLock,
            setRequiredTier,
        })

        return () => {
            planModalState.subscribers = []
        }
    }, [])

    return isOpen ? (
        <PlanPickerModalContent
            onRequestClose={() => {
                setIsOpen(false)
                if (openWorkspaceSettingsAfterClose) {
                    openWorkspaceSettingsModal({ page: 'general' })
                }
                setOpenWorkspaceSettingsAfterClose(false)
            }}
            defaultState={isOpen}
            disableFocusLock={disableFocusLock}
            requiredTier={requiredTier}
            openWorkspaceSettingsAfterClose={openWorkspaceSettingsAfterClose}
        />
    ) : null
}

/**
 *
 * @param {{onRequestClose: () => void , disableFocusLock: void, openWorkspaceSettingsAfterClose:void, requiredTier:void}} param0
 */
function PlanPickerModalContent({
    onRequestClose,
    disableFocusLock,
    openWorkspaceSettingsAfterClose,
    requiredTier,
}) {
    return (
        <SimpleModal
            title="Choose a plan"
            height="780px"
            zIndex={1500}
            showCloseButton={true}
            open={true}
            onClose={onRequestClose}
            disableFocusLock={disableFocusLock}
            padding="20px 20px 0 20px"
        >
            <CustomPlanPicker
                onClose={onRequestClose}
                openWorkspaceSettingsAfterClose={openWorkspaceSettingsAfterClose}
                requiredTier={requiredTier}
            />
        </SimpleModal>
    )
}
