// @ts-strict-ignore
import React from 'react'

import useConfirmModal from 'app/ConfirmModal'
import { refetchNavigation } from 'data/hooks/navigation'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { invalidatePages } from 'data/hooks/pages'
import { invalidateViews } from 'data/hooks/views'
import DATA_PROVIDERS from 'features/admin/data-connector/dataProviderConfig'

import { Checkbox, Text } from 'v2/ui'

export function useDeleteDataSource({ dataConnection, removeDataConnection, onConnectionDeleted }) {
    const { name: providerName, allowRevoke } = DATA_PROVIDERS[dataConnection?.type] ?? { name: '' }

    const modalData = {
        title: 'Remove this data source?',
        sid: dataConnection?._sid,
        message: ({ data, setData }) => (
            <>
                <Text>All tables and layouts for this data source will be removed.</Text>

                <Text mt={4} fontWeight="bold">
                    This action cannot be undone.
                </Text>

                {allowRevoke && (
                    <Checkbox
                        mt={4}
                        onChange={(e) => {
                            setData({ ...data, revoke: e.target.checked })
                        }}
                    >
                        <Text fontSize="sm" textAlign="left">
                            Revoke {providerName} access for all my Stacker apps
                        </Text>
                    </Checkbox>
                )}
            </>
        ),
        onConfirm: ({ data, setData, setIsOpen }) => {
            setData({ ...data, isSaving: true })

            removeDataConnection(dataConnection?._sid, data.revoke ? { revoke: true } : null).then(
                () => {
                    setData({ ...data, isSaving: false })
                    setIsOpen(false)
                    invalidateObjects()
                    refetchNavigation()
                    invalidateViews()
                    invalidatePages()
                    if (onConnectionDeleted) {
                        onConnectionDeleted()
                    }
                }
            )
        },
    }

    const { show } = useConfirmModal(modalData)

    return { showDeleteModal: show }
}
