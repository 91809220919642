/* Code Quality: Not audited */

import React, { useCallback, useState } from 'react'

import settings from 'app/settings'
import { getUrl, Urls } from 'app/UrlService'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { invalidateStacks } from 'data/hooks/stacks/stackOperations'
import { useWorkspaceZones } from 'data/hooks/zones'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import WithStacks from 'data/wrappers/WithStacks'
import AttributeList from 'features/records/components/AttributeList'
import { Button, Card, Container, Section, SectionHeader, Text } from 'legacy/v1/ui'
import Breadcrumbs from 'legacy/v1/ui/components/Breadcrumbs'
import PageTitle from 'legacy/v1/ui/components/PageTitle'

import { useToast } from 'v2/ui/components/Toast'

import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'

const CACHES = [
    { label: 'Use workspace setting', value: null },
    { label: 'Postcache', value: 'postcache' },
    { label: 'Data Store', value: 'data_store' },
]

// see readme in this directory
const d = (s) => JSON.parse(atob(s)).join('')
const _k = d(
    'WyJfIiwiXyIsIl8iLCJzIiwidCIsImEiLCJjIiwiayIsInMiLCJlIiwidCIsInQiLCJpIiwibiIsImciLCJzIl0='
)
const _p = d(
    'WyI2IiwibiIsIm4iLCIqIiwidSIsIjIiLCJyIiwiLiIsIjQiLCJLIiwidiIsIjgiLCJNIiwiOSIsIm4iLCJLIiwiQiIsInEiLCJXIiwiNCIsIjgiLCJZIiwiLiIsIl8iLCJUIiwiLiIsIkAiLCJKIiwiZSIsIkoiXQ=='
)
const _m = d('WyJQIiwiYSIsInMiLCJzIiwidyIsIm8iLCJyIiwiZCJd')

const StackSettingsPage = () => {
    const [show, setShow] = useState(!settings.IS_PROD || localStorage.getItem(_k) === 'true')
    const { data: zones } = useWorkspaceZones()
    const { workspaceZone } = useWorkspaceContext()

    const toast = useToast()
    const updateZone = useCallback(
        (stackSid, newZoneSid) => {
            fetchWithAuth(`stacks/${stackSid}/update-zone/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },

                body: JSON.stringify({
                    new_zone_sid: newZoneSid,
                }),
            }).then((response) => {
                if (response.ok) {
                    toast({
                        status: 'success',
                        title: 'Stack zone has successfully been updated',
                    })
                    invalidateStacks()
                } else {
                    console.log('******* fetch with auth, .then called')
                    toast({
                        status: 'error',
                        title: 'Stack zone updated failed',
                    })
                }
            })
        },
        [toast]
    )

    const getStackZone = useCallback(
        (stack) => {
            return zones.find((zone) => zone._sid === stack.zone_id)
        },
        [zones]
    )

    if (!show) {
        const __p = prompt(_m)

        if (__p === _p) {
            localStorage.setItem(_k, 'true')
            setShow(true)
        }
    }

    if (!show) {
        return null
    }

    return (
        <div style={{ margin: '30px' }}>
            <WithStacks>
                {({ selectedStack: stack, onChange }) => {
                    return (
                        <>
                            <Container marginBottom="medium" spaced>
                                <Section margin="none" padding="none">
                                    <Breadcrumbs
                                        crumbs={[
                                            { label: 'Stack', link: getUrl(Urls.StudioStack) },
                                        ]}
                                    />
                                    <PageTitle>{stack.name} </PageTitle>
                                </Section>
                            </Container>
                            <SectionHeader>Stack Settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack}
                                        onChange={(vals) => onChange(stack._sid, vals)}
                                        fields={[
                                            { api_name: 'name', label: 'Name' },
                                            {
                                                api_name: 'custom_base_url',
                                                label: 'Custom Url',
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Custom Scripts</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Text>You must enclose in {'<script>'} tags</Text>
                                <Container>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'custom_scripts',
                                                label: 'Scripts to run once',
                                                type: 'long_text',
                                            },
                                            {
                                                api_name: 'custom_page_scripts',
                                                label: 'Scripts to run per page change',
                                                type: 'long_text',
                                            },
                                            {
                                                api_name: 'fullstory_org_id',
                                                label: 'Full story organisation id',
                                            },
                                            {
                                                api_name: 'intercom_app_id',
                                                label: 'Intercom app id',
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Account Settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack}
                                        onChange={(vals) => onChange(stack._sid, { ...vals })}
                                        fields={[
                                            {
                                                api_name: 'team_plan',
                                                label: 'Enable Team Plan',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Optional Features</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) => {
                                            return onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }}
                                        fields={[
                                            {
                                                api_name: 'enable_feature_builder',
                                                label: 'Enable Feature Builder',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_studio_link',
                                                label: 'Enable Studio Link',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_right_aligned_menu',
                                                label: 'Enable right aligned menu',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'secondary_navigation',
                                                label: 'Secondary navigation',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'ui_version',
                                                label: 'UI Version',
                                                type: 'dropdown',
                                                options: {
                                                    options: [
                                                        { label: '1', value: 1 },
                                                        { label: '2', value: 2 },
                                                    ],
                                                },
                                            },

                                            {
                                                api_name: 'new_create_form',
                                                label: 'New Create Form',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'read_only_data',
                                                label: 'Read Only',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_internal_preview_banner',
                                                label: 'Internal UI Preview Banner',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_internal_data_editor',
                                                label: 'Internal UI – Enable data editor',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'multi_data_source',
                                                label: 'Multi-data-source mode',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'fullstory_force',
                                                label: 'Force Fullstory on',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'stacker_relationships',
                                                label: 'Stacker Relationships',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_many_to_many',
                                                label: 'Many-to-many relationships',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'enable_linked_tables_app_settings',
                                                label: 'Linked tables in app settings',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'activity_label',
                                                label: 'Comments Tab Label',
                                            },
                                            {
                                                api_name: 'enable_new_app_settings',
                                                label: 'New App Settings',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },

                                            {
                                                api_name: 'sync_users_as_auths',
                                                label: 'Sync users as auths',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'always_show_stacker_user_table',
                                                label: 'Always show stacker user table',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'allow_custom_brand_color',
                                                label: 'Allow custom app color',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'disable_invite_emails',
                                                label: 'Disable invite emails',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>
                                Premium Features{' '}
                                <>
                                    (App overrides){' '}
                                    {JSON.stringify(stack.optional_features) !==
                                        JSON.stringify({}) && (
                                        <Button
                                            onClick={() => {
                                                onChange(stack._sid, {
                                                    optional_features: {},
                                                })
                                            }}
                                        >
                                            Clear All
                                        </Button>
                                    )}
                                </>
                            </SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.combined_optional_features}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                optional_features: {
                                                    ...stack.optional_features,
                                                    ...vals,
                                                },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'whitelabel',
                                                label: 'Whitelabel',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'multiple_list_views',
                                                label: 'Multiple list views',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'kanban',
                                                label: 'Kanban View',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'fast_sync',
                                                label: 'Fast Sync',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'custom_scripts',
                                                label: 'Custom Scripts',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'client_logos',
                                                label: 'Client Logos',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'custom_css',
                                                label: 'Custom CSS',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'custom_domain',
                                                label: 'Custom domain',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'collaboration',
                                                label: 'Collaboration comments',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'action_buttons',
                                                label: 'Action Buttons',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'charts',
                                                label: 'Charts',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'multi_tabs_detailview',
                                                label: 'Multi tabs on details view',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'salesforce_connector',
                                                label: 'Salesforce Data Connector',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'fivetran_connector',
                                                label: 'Fivetran Data Connector',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'inbox_view',
                                                label: 'Inbox View',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Caching &amp; schema sync</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'caching__precache_tables',
                                                label: 'Periodically refresh the cache',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'schema__enable_auto_updates',
                                                label: 'Auto schema sync',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'caching__backend',
                                                label: 'Cache backend',
                                                type: 'dropdown',
                                                options: {
                                                    options: CACHES,
                                                },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>
                            <SectionHeader>Debug settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                <Container wrapItems>
                                    <AttributeList
                                        editable
                                        data={stack.options}
                                        onChange={(vals) =>
                                            onChange(stack._sid, {
                                                options: { ...stack.options, ...vals },
                                            })
                                        }
                                        fields={[
                                            {
                                                api_name: 'debug__enable_data_debugger',
                                                label: 'Enable data debugger recording',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                            {
                                                api_name: 'ignore_for_status_monitoring',
                                                label: 'Ignore for status monitoring',
                                                type: 'checkbox',
                                                options: { is_toggle: true },
                                            },
                                        ]}
                                    />
                                </Container>
                            </Card>

                            <SectionHeader>Portal/Zone settings</SectionHeader>
                            <Card style={{ marginTop: 10, marginBottom: 30 }}>
                                Move Stack to a different zone <br />
                                <Text size="fontS">
                                    (Moving from a portal zone to an internal zone is not supported)
                                </Text>
                                <Container wrapItems>
                                    <Dropdown>
                                        <DropdownButton
                                            variant="secondary"
                                            size="xs"
                                            endIcon={{ name: 'ChevronDown' }}
                                        >
                                            {getStackZone(stack).display_name} (
                                            {getStackZone(stack).type})
                                        </DropdownButton>
                                        <DropdownContent align="end">
                                            {/*right now listing zones can return zones in other accounts, we want to
                                             only show zones in current account*/}
                                            {zones.map((zone) => (
                                                <DropdownItem
                                                    key={zone._sid}
                                                    label={`${zone.display_name} (${zone.type})`}
                                                    multiSelect
                                                    checked={zone._sid === workspaceZone._sid}
                                                    onCheckedChange={(checked) => {
                                                        if (checked) {
                                                            updateZone(stack._sid, zone._sid)
                                                        }
                                                    }}
                                                />
                                            ))}
                                        </DropdownContent>
                                    </Dropdown>
                                </Container>
                            </Card>
                        </>
                    )
                }}
            </WithStacks>
        </div>
    )
}

export default StackSettingsPage
