import React, { memo, useEffect, useState } from 'react'

import WithDataConnections, { DISPLAY_NAMES } from 'data/wrappers/WithDataConnections'
import WithObjects from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'
import { Button, Heading, Icon8 } from 'legacy/v1/ui'
import Card from 'legacy/v1/ui/components/Card'
import Container from 'legacy/v1/ui/components/Container'
import Section from 'legacy/v1/ui/components/Section'

import { Box, Collapse, Flex, Link, Text } from 'v2/ui'

import {
    STATUS_COMPLETE,
    STATUS_ERROR,
    STATUS_STARTED,
    STEP_AIRTABLE_CONNECTION,
    STEP_COLUMN_CREATION,
    STEP_COMPLETE,
    STEP_STATUS_ERROR,
    STEP_TABLE_CREATION,
    updateMetadata,
} from './backend_import'
import { LoadingSpinner } from './ui'

const PROCESS_NOT_STARTED = 'PROCESS_NOT_STARTED'
const PROCESS_STARTED = 'PROCESS_STARTED'
const PROCESS_COMPLETE = 'PROCESS_COMPLETE'

class SchemaUpdate extends React.Component {
    constructor() {
        super()
        this.state = { process: PROCESS_NOT_STARTED, steps: {} }
    }

    // Milestone [{ stage: , status: }]

    updateStep = async (step) => {
        if (step.step === STEP_STATUS_ERROR) {
            // There was an error, cancel all other steps and display
            const finishSteps = (steps) => {
                return Object.keys(steps).map((stepKey) => {
                    let step = steps[stepKey]
                    if (step.status === STATUS_STARTED) step.status = STATUS_ERROR
                    return step
                })
            }
            this.setState((state) => ({
                steps: { ...finishSteps(state.steps), [step.step]: step },
            }))
        } else {
            this.setState((state) => ({
                steps: { ...state.steps, [step.step]: step },
            }))
        }
    }

    renderStartScreen = (onClick, autostart) => {
        if (this.props.renderStartScreen) {
            return this.props.renderStartScreen(onClick)
        } else if (autostart) {
            onClick()
            return <></>
        } else {
            return (
                <Card
                    style={{
                        marginTop: 20,
                        width: '100%',
                        padding: 0,
                    }}
                >
                    <Container margin="none" padding="none">
                        <Section margin="none">
                            <Text
                                style={{
                                    fontSize: 24,
                                    marginTop: 0,
                                }}
                            >
                                {this.props.title}
                            </Text>
                            {this.props.blurb}
                            <Button style={{ width: 200 }} onClick={onClick}>
                                {this.props.startButton}
                            </Button>
                        </Section>
                        {this.props.logo && (
                            <Section style={{ paddingTop: 50 }}>{this.props.logo}</Section>
                        )}
                    </Container>
                </Card>
            )
        }
    }

    render() {
        return (
            <WithDataConnections>
                {({ dataConnections }) => {
                    return (
                        <WithObjects>
                            {() => {
                                if (!dataConnections.length) return null
                                let dataConnection
                                if (this.props.dataConnectionSid) {
                                    dataConnection = dataConnections.find(
                                        (dc) => dc._sid === this.props.dataConnectionSid
                                    )
                                    if (!dataConnection) return null
                                } else {
                                    dataConnection = dataConnections[0]
                                }
                                const startImport = () => {
                                    this.setState({
                                        process: PROCESS_STARTED,
                                    })
                                    updateMetadata(
                                        dataConnection,
                                        this.updateStep,
                                        this.props.onError,
                                        this.props.isOnboarding,
                                        this.props.onComplete,
                                        this.props.stack?.options?.multi_data_source
                                    ).then(() => {
                                        this.setState({
                                            process: PROCESS_COMPLETE,
                                        })
                                    })
                                }
                                return (
                                    <>
                                        {this.state.process === PROCESS_NOT_STARTED &&
                                            this.renderStartScreen(
                                                startImport,
                                                this.props.autostart
                                            )}
                                        <StepPane
                                            steps={this.state.steps}
                                            renderEndButton={this.props.renderEndButton}
                                            portalType={this.props.portalType}
                                            portalName={this.props.portalName}
                                        />
                                    </>
                                )
                            }}
                        </WithObjects>
                    )
                }}
            </WithDataConnections>
        )
    }
}

const StepPane = (props) => (
    <Flex column align="stretch">
        {Object.keys(props.steps).map((key) => (
            <Step
                key={key}
                step={props.steps[key]}
                renderEndButton={props.renderEndButton}
                portalType={props.portalType}
                portalName={props.portalName}
            />
        ))}
    </Flex>
)

const Step = memo((props) => {
    const [loaded, setLoaded] = useState()
    const { step, portalType, portalName } = props
    const stepDetail = stepDetails(portalType, portalName)[props.step.step]
    const statusDetail = statusDetails[step.status]
    const endButton = props.renderEndButton && props.renderEndButton()

    useEffect(() => {
        setLoaded(true)
    }, [])
    return (
        <Collapse isOpen={!!loaded}>
            <Card
                style={{
                    marginBottom: 10,
                    borderTop: `5px solid ${statusDetail.highlightColor}`,
                    width: '100%',
                    padding: 8,
                }}
            >
                <Section padding="small" margin="none">
                    <Container margin="none" padding="none">
                        <div style={{ display: 'inline-flex', marginRight: 'auto' }}>
                            <Heading style={{ fontSize: 15, margin: '0px', padding: '0px' }}>
                                <Box as="span" display="flex" alignItems="center">
                                    <Icon8
                                        icon={stepDetail.icon}
                                        size="100"
                                        displaySize="20"
                                        iconStyle="ios-filled"
                                    />
                                    <span>{stepDetail.title}</span>
                                </Box>
                            </Heading>
                        </div>
                        <div>
                            {step.status === STATUS_STARTED && <LoadingSpinner />}
                            {step.status === STATUS_COMPLETE && (
                                <Icon8
                                    icon="checked"
                                    size="100"
                                    displaySize="20"
                                    iconStyle="color"
                                />
                            )}
                        </div>
                    </Container>
                    <Container>
                        <Text fontSize="sm" my={2} alignSelf="center">
                            {step.description || stepDetail.description}
                        </Text>
                        {step.step === STEP_STATUS_ERROR &&
                            step.errorDetails &&
                            step.errorDetails.sentry_event_id && (
                                <Text
                                    style={{
                                        fontSize: '13px',
                                        padding: '0px',
                                        marginLeft: '5px',
                                    }}
                                    alignSelf="center"
                                >
                                    [Error log: {step.errorDetails.sentry_event_id}]
                                </Text>
                            )}
                        {step.link && (
                            <Link to={step.link} ml={2} fontSize="sm" alignSelf="center">
                                {step.linkLabel}
                            </Link>
                        )}
                    </Container>
                </Section>
            </Card>
            {step.step === STEP_COMPLETE && step.status === STATUS_COMPLETE && endButton}
        </Collapse>
    )
})

const stepDetails = (portalType, portalName) => {
    const name = DISPLAY_NAMES[portalType] || 'Airtable'
    const details = {
        [STEP_AIRTABLE_CONNECTION]: {
            title: `Connecting to ${name}`,
            icon: 'remote-working',
            description: 'This can take up to a minute depending on your data.',
        },
        [STEP_TABLE_CREATION]: {
            title: 'Analyzing Tables',
            icon: 'table-1',
        },
        [STEP_COLUMN_CREATION]: {
            title: 'Analyzing Columns',
            icon: 'select-column',
        },
        [STEP_COMPLETE]: {
            title: 'Import Complete',
            icon: 'available-updates',
            description: `Your ${name} tables & columns have been imported.`,
        },
        [STEP_STATUS_ERROR]: {
            title: 'Error on import',
            icon: 'error',
        },
    }

    if (portalType === 'salesforce') {
        details[STEP_AIRTABLE_CONNECTION] = {
            title: 'Connecting to Salesforce',
            icon: 'cloud',
            description: '',
        }
        details[STEP_COMPLETE].description =
            'Stacker has all of the tables & columns from your Salesforce.'
    }

    if (portalType === 'fivetran') {
        details[STEP_AIRTABLE_CONNECTION] = {
            title: `Syncing from ${portalName}`,
            icon: 'remote-working',
            description: 'This can take up to several minutes depending on your data.',
        }
        details[STEP_COMPLETE] = {
            title: 'Import Complete',
            icon: 'available-updates',
            description: `Your ${portalName} tables & columns have been imported.`,
        }
    }

    return details
}

const statusDetails = {
    [STATUS_STARTED]: { highlightColor: '#FFA500', backgroundColor: '' },
    [STATUS_COMPLETE]: { highlightColor: '#2fd24a', backgroundColor: '' },
    [STATUS_ERROR]: { highlightColor: 'red', backgroundColor: '' },
}

export default withStack(SchemaUpdate)
