import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import queryString from 'qs'

import { useAppContext } from 'app/AppContext'
import { getUrl, Urls } from 'app/UrlService'
import { useNavigation } from 'data/hooks/navigation'
import { withDataConnections } from 'data/wrappers/WithDataConnections'
import { withStack } from 'data/wrappers/WithStacks'
import NewConnectionModal from 'features/AppSettings/DataSources/NewConnectionModal'
import AdminFrame from 'features/core/AdminFrame'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import { useCreateEmptyBlankPageModal } from 'features/workspace/FloatingAddNewButton/CreateEmptyBlankPageModal'

import { Box, Button, Flex } from 'v2/ui'
import { StackerWelcome } from 'v2/ui/svgs'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

function NewAppSplashScreen({ dataConnections, stack, setIsNewConnectionEditorOpen }) {
    const searchString = queryString.parse(window.location.search, {
        ignoreQueryPrefix: true,
    })

    const { showManageData } = useSlidingPane()
    const showCustomPage = useCreateEmptyBlankPageModal()
    const { selectedStack } = useAppContext()
    const { data: navigation } = useNavigation()
    const isEmptyStackerDataStack = useMemo(() => {
        if (!navigation || !selectedStack) {
            return false
        }

        return !navigation.find(
            ({ api_name, stack_id }) =>
                !api_name.startsWith('users') && stack_id === selectedStack._sid
        )
    }, [navigation, selectedStack])

    // if there is a predefined data source type in the query string,
    // a modal will automatically appear to add a data source of that type
    // don't show the choice of providers beneath
    const isDataSourceInQueryString = !!searchString.data
    useEffect(() => {
        if (isDataSourceInQueryString) {
            setIsNewConnectionEditorOpen(true) // a bit hacky, but required for the modal to function correctly once the import is complete
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // if there is a 'data' query string param, open up the appropriate data connector modal straight away
    const [dataSourceConnectorType, openDataSourceConnector] = useState(searchString.data)

    const history = useHistory()

    const [connectedId, setConnectedId] = useState(searchString.connected)
    const connectedDC = useMemo(
        () => dataConnections.find((dc) => dc._sid === connectedId),
        [dataConnections, connectedId]
    )

    useEffect(() => {
        if (connectedDC) {
            // if we have a connectedDC from a URL param, then we will be
            // showing the modal, so inform the parent that is happening
            setIsNewConnectionEditorOpen(true)
        }
    }, [connectedDC, setIsNewConnectionEditorOpen])

    let SplashImage = StackerWelcome

    // App has pages, but they are all disabled
    if (!isEmptyStackerDataStack)
        return (
            <AdminFrame ignoreLoadingState>
                <Flex
                    id="splashscreen_wrapper"
                    column
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    height="100%"
                >
                    <div style={{ marginBottom: 40 }}>
                        <SplashImage />
                    </div>
                    <Box fontSize={24} fontWeight={600} marginBottom="6px">
                        All of your pages have been hidden.
                    </Box>
                </Flex>
            </AdminFrame>
        )

    return (
        <Flex alignItems="center" justifyContent="center" column>
            <AdminFrame ignoreLoadingState>
                {!isDataSourceInQueryString && (
                    <Flex
                        id="splashscreen_wrapper"
                        column
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        height="100%"
                    >
                        <div style={{ marginBottom: 40 }}>
                            <SplashImage />
                        </div>
                        {
                            <>
                                <Box fontSize={18} fontWeight={600} marginBottom="6px">
                                    Your{' '}
                                    {selectedStack ? (
                                        <>
                                            <span
                                                style={{
                                                    color: V4DesignSystem.colors.stacker,
                                                }}
                                            >
                                                {selectedStack.name}
                                            </span>{' '}
                                            app
                                        </>
                                    ) : (
                                        'app'
                                    )}{' '}
                                    has been created!
                                </Box>
                                <Box
                                    mt={2}
                                    style={{
                                        color: V4DesignSystem.colors.text,
                                        maxWidth: 465,
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        lineHeight: 1.4,
                                    }}
                                >
                                    Start building your app by creating a new
                                    <br />
                                    table or a new custom page.
                                    {isEmptyStackerDataStack && (
                                        <Flex marginTop="20px" alignItems="center" direction="row">
                                            <Button
                                                marginRight="10px"
                                                width="158px"
                                                variant="Primary"
                                                fontSize="14px"
                                                onClick={() =>
                                                    showManageData({
                                                        shouldShowNewTableModal: true,
                                                    })
                                                }
                                                padding=" 8px 20px 8px 20px"
                                            >
                                                Create a new table
                                            </Button>
                                            <Button
                                                marginLeft="10px"
                                                width="158px"
                                                variant="Secondary"
                                                fontSize="14px"
                                                onClick={showCustomPage}
                                                padding=" 8px 20px 8px 20px"
                                            >
                                                Create a new page
                                            </Button>
                                        </Flex>
                                    )}
                                    {/* <Flex
                                            marginTop="8px"
                                            marginLeft="105px"
                                            alignItems="center"
                                        >
                                            <Button
                                                onClick={onClickAddDataSource}
                                                buttonSize="extraSmall"
                                                icon="forward"
                                                variant="linkNoUnderline"
                                                color={colors.userInterface.accent[1000]}
                                                background="transparent"
                                            >
                                                Add a data source
                                            </Button>
                                        </Flex> */}
                                </Box>
                            </>
                        }
                    </Flex>
                )}

                {(connectedDC || dataSourceConnectorType) && (
                    <NewConnectionModal
                        isOpen
                        stack={stack}
                        onClose={(data) => {
                            // open the data sources modal
                            if (data?._sid && stack?.options?.enable_new_app_settings) {
                                history.push(getUrl(Urls.Home))
                                openDataSourceConnector(null)
                                setIsNewConnectionEditorOpen(false)
                                setConnectedId(null)

                                return
                            }

                            // clear any query params when the modal closes.
                            // We will sometimes have query params when redirected
                            // back to this page during an onboarding flow. They are
                            // no longer needed after the dialog closes.
                            window.history.replaceState(
                                window.history.state,
                                null,
                                window.location.pathname
                            )
                            openDataSourceConnector(null)
                            setIsNewConnectionEditorOpen(false)
                            setConnectedId(null)
                        }}
                        provider={connectedDC?.type || dataSourceConnectorType}
                        dataConnection={connectedDC}
                        redirectUrl={Urls.AdminModal}
                        isAppCreateFlow
                    />
                )}
            </AdminFrame>
        </Flex>
    )
}

export default withStack(withDataConnections(NewAppSplashScreen))
