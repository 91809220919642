import React, { useCallback, useEffect, useRef, useState } from 'react'

import { FieldEditorPopover } from 'features/admin/fields/FieldEditorPopover'
import { useDataGridContext } from 'features/datagrid/components/DataGridContext'
import { GridInternalContext } from 'features/datagrid/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { theme } from 'ui/styling/Theme.css'

type AddNewFieldPanelProps = React.ComponentPropsWithoutRef<typeof Box> & {
    context: GridInternalContext
    onFieldCreated: (field: FieldDto) => void
}

export const AddNewFieldPanel: React.FC<AddNewFieldPanelProps> = ({
    context,
    onFieldCreated,
    ...props
}) => {
    const { gridApi } = useDataGridContext()

    const containerRef = useRef<HTMLDivElement>(null)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const [isFieldEditorOpen, setIsFieldEditorOpen] = useState(false)
    const onHeaderClick = useCallback(() => {
        setIsFieldEditorOpen((prev) => !prev)
    }, [])

    useEffect(() => {
        const hideFieldEditor = () => {
            setIsFieldEditorOpen(false)
        }

        gridApi?.addEventListener('bodyScroll', hideFieldEditor)
        gridApi?.addEventListener('gridSizeChanged', hideFieldEditor)

        return () => {
            if (gridApi?.isDestroyed()) return

            gridApi?.removeEventListener('bodyScroll', hideFieldEditor)
            gridApi?.removeEventListener('gridSizeChanged', hideFieldEditor)
        }
    })

    return (
        <Box background="surfaceStrong" height="full" flex center ref={containerRef} {...props}>
            <Button
                ref={anchorRef}
                variant="ghost"
                type="button"
                size="m"
                style={{
                    height: '100%',
                    width: '100%',
                    paddingLeft: theme.space.m,
                    paddingRight: theme.space.m,
                    borderRadius: 0,
                }}
                startIcon={{ name: 'Plus' }}
                color={{ default: 'textWeak', hover: 'text' }}
                onClick={onHeaderClick}
            />
            <FieldEditorPopover
                open={isFieldEditorOpen}
                placement="left-start"
                container={containerRef.current as HTMLElement}
                target={anchorRef.current as HTMLElement}
                objectId={context.object._sid}
                onSuccess={(newField) => {
                    setIsFieldEditorOpen(false)
                    onFieldCreated(newField)
                }}
                onCancel={() => {
                    setIsFieldEditorOpen(false)
                }}
                onClose={() => {
                    setIsFieldEditorOpen(false)
                }}
                adaptivePlacement={false}
            />
        </Box>
    )
}
