import { getCacheBackendType } from './getCacheBackendType'

function requiresBackendFiltering(
    stackOptions?: StackDto['options'],
    accountOptions?: Account['options']
) {
    const cacheBackend = getCacheBackendType(stackOptions, accountOptions)
    return ['postcache', 'data_store'].includes(cacheBackend)
}

export default requiresBackendFiltering
