import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { useAdvancedFiltersItemState } from './hooks/useAdvancedFiltersItemState'
import { useFilterLabelState } from './hooks/useFilterLabelState'
import { useFilterValueState } from './hooks/useFilterValueState'
import { OperationOption, useOperationDropdownState } from './hooks/useOperationDropdownState'
import { AdvancedFilterValueCurrency } from './AdvancedFilterValueCurrency'
import { AdvancedFilterValueDate } from './AdvancedFilterValueDate'
import { AdvancedFilterValueDropdown } from './AdvancedFilterValueDropdown'
import { AdvancedFilterValueNumber } from './AdvancedFilterValueNumber'
import { AdvancedFilterValuePercentage } from './AdvancedFilterValuePercentage'
import { AdvancedFilterValueRecordLinks } from './AdvancedFilterValueRecordLinks'
import { AdvancedFilterValueText } from './AdvancedFilterValueText'
import { AdvancedFilterValueUser } from './AdvancedFilterValueUser'
import { AdvancedFilter } from './types'

import {
    AdvancedFiltersOperationDropdownStyles,
    AdvancedFiltersValueStyles,
} from './AdvancedFilters.css'

const LABEL_MAX_LENGTH = 30

type AdvancedFiltersItemProps = {
    filter: AdvancedFilter
    onUpdateFilter: (filter: AdvancedFilter) => void
    onRemoveFilter: () => void
    onCommitDraft: () => void
}

export const AdvancedFiltersItem: React.FC<AdvancedFiltersItemProps> = ({
    filter,
    onUpdateFilter,
    onRemoveFilter,
    onCommitDraft,
}) => {
    const { label, operation, onChangeOperation, operationOptions, onChangeValue } =
        useAdvancedFiltersItemState({
            filter,
            onUpdateFilter,
            onCommitDraft,
            onRemoveFilter,
        })

    const field = filter.field as FieldDto

    return (
        <Box
            flex
            center
            rounded={{ mobile: 's', tablet: 'm' }}
            background="gray100"
            overflow="hidden"
            style={{ userSelect: 'none' }}
            position="relative"
            noShrink
        >
            <Label>{label}</Label>
            <OperationDropdown
                field={field}
                value={operation}
                options={operationOptions}
                onChange={onChangeOperation}
            />
            {operation && (
                <FilterValue
                    filter={filter}
                    onChange={onChangeValue}
                    onRemoveFilter={onRemoveFilter}
                />
            )}
            {!filter.isDraft && (
                <Box
                    pr="m"
                    position="absolute"
                    height="full"
                    flex
                    center
                    noShrink
                    style={{
                        top: 0,
                        right: 0,
                        zIndex: 1,
                    }}
                >
                    <Button
                        size="xs"
                        variant="ghost"
                        startIcon={{ name: 'X' }}
                        aria-label="Remove filter"
                        style={{ padding: 0, height: 'auto', width: 'auto' }}
                        onClick={onRemoveFilter}
                    />
                </Box>
            )}
        </Box>
    )
}

type LabelProps = {
    children: string
}

const Label: React.FC<LabelProps> = ({ children }) => {
    const { isOverflowing } = useFilterLabelState({ value: children, maxLength: LABEL_MAX_LENGTH })

    const content = (
        <Body
            size={{ mobile: 's', tablet: 'm' }}
            weight="medium"
            color="text"
            maxLength={LABEL_MAX_LENGTH}
        >
            {children}
        </Body>
    )

    const height = useResponsiveValue({
        mobile: theme.button.sizes.xs,
        tablet: theme.button.sizes.s,
    })

    return (
        <Box
            style={{
                height,
            }}
            flex
            center
            gap="xs"
            px="m"
            borderWidth={0}
            borderRightWidth="base"
            borderStyle="base"
            borderColor="dividerWeak"
            noShrink
        >
            {isOverflowing ? (
                <Tooltip zIndex={200} content={children} side="bottom" asChild>
                    {content}
                </Tooltip>
            ) : (
                content
            )}
        </Box>
    )
}

type OperationDropdownProps = {
    field: FieldDto
    value: AdvancedFilter['options']['option']
    options: OperationOption[]
    onChange: (value: string) => void
}

const OperationDropdown: React.FC<OperationDropdownProps> = ({
    options,
    onChange,
    field,
    value,
}) => {
    const { onCheckedChange, label, requiresValue } = useOperationDropdownState({
        field,
        value,
        options,
        onChange,
    })

    const size = useResponsiveValue<React.ComponentPropsWithoutRef<typeof DropdownButton>['size']>({
        mobile: '2xs',
        tablet: 's',
    })

    return (
        <Dropdown>
            <DropdownButton
                size={size}
                variant="ghost"
                aria-label="Select operation"
                className={AdvancedFiltersOperationDropdownStyles.styleFunction({ requiresValue })}
            >
                {label}
            </DropdownButton>
            <DropdownContent
                align="end"
                onClick={stopPropagation}
                onCloseAutoFocus={stopPropagation}
            >
                {options.map((option) => (
                    <DropdownItem
                        key={option.value}
                        label={option.label}
                        multiSelect
                        checked={option.value === value}
                        onCheckedChange={(checked) => onCheckedChange(checked, option)}
                        closeOnSelect
                    />
                ))}
            </DropdownContent>
        </Dropdown>
    )
}

type FilterValueProps = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
}

const FilterValue: React.FC<FilterValueProps> = React.memo(function FilterValue({
    filter,
    onChange,
    onRemoveFilter,
}) {
    const { requiresValue } = useFilterValueState({ filter })
    if (!requiresValue) {
        // No need to render anything if the operation does not require a value.
        return null
    }

    const className = AdvancedFiltersValueStyles.styleFunction({ isDraft: filter.isDraft })

    switch (filter.field?.type) {
        case 'dropdown':
        case 'multi_select':
            return (
                <AdvancedFilterValueDropdown
                    filter={filter}
                    onChange={onChange}
                    onRemoveFilter={onRemoveFilter}
                    className={className}
                />
            )
        case 'lookup':
        case 'multi_lookup':
            return (
                <AdvancedFilterValueRecordLinks
                    filter={filter}
                    onChange={onChange}
                    onRemoveFilter={onRemoveFilter}
                    className={className}
                />
            )
        case 'user_ref':
            return (
                <AdvancedFilterValueUser
                    filter={filter}
                    onChange={onChange}
                    onRemoveFilter={onRemoveFilter}
                    className={className}
                />
            )
        case 'string':
        case 'long_text':
        case 'document':
        case 'url':
            return (
                <AdvancedFilterValueText
                    filter={filter}
                    onChange={onChange}
                    onRemoveFilter={onRemoveFilter}
                    className={className}
                />
            )
        case 'number':
            return (
                <AdvancedFilterValueNumber
                    filter={filter}
                    onChange={onChange}
                    onRemoveFilter={onRemoveFilter}
                    className={className}
                />
            )
        case 'percentage':
            return (
                <AdvancedFilterValuePercentage
                    filter={filter}
                    onChange={onChange}
                    onRemoveFilter={onRemoveFilter}
                    className={className}
                />
            )
        case 'currency':
            return (
                <AdvancedFilterValueCurrency
                    filter={filter}
                    onChange={onChange}
                    onRemoveFilter={onRemoveFilter}
                    className={className}
                />
            )
        case 'date':
        case 'datetime':
            return (
                <AdvancedFilterValueDate
                    filter={filter}
                    onChange={onChange}
                    onRemoveFilter={onRemoveFilter}
                    className={className}
                />
            )
        default:
            return null
    }
})
