import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useWorkspaceUsers } from 'data/hooks/users/main'
import { extractPartsFromUserName } from 'features/views/attributes/utils'

import { truncateText } from 'ui/helpers/utilities'

import { useAppUsers } from './useAppUsers'

const MAX_LABEL_LENGTH = 30
const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

type UserOption = {
    label: string
    email: string
    value: string
    isInApp: boolean
    avatar?: {
        imageUrl: string
        firstName: string
        lastName: string
        type: 'initial' | 'image'
    }
}

type UseAppUsersAddUsersUserSelectStateOptions = {
    value: string
    onChange: (value: string) => void
    onClosePopup: () => void
    openInviteUsersModal: (prefillEmail?: string) => void
}

export function useAppUsersAddUsersUserSelectState(
    options: UseAppUsersAddUsersUserSelectStateOptions
) {
    const { value, onChange, onClosePopup, openInviteUsersModal } = options

    const {
        users,
        isError: isErrorAppUsers,
        isFetchingSlow: isFetchingAppUsersSlow,
    } = useAppUsers({
        paginate: false,
    })

    const {
        data: workspaceUsers,
        isFetching: isFetchingWorkspaceUsers,
        isError: isErrorWorkspaceUsers,
    } = useWorkspaceUsers()

    const isFetching = isFetchingWorkspaceUsers
    const isError = isErrorAppUsers || isErrorWorkspaceUsers

    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const existingEmails = useMemo(() => {
        return new Set(users?.map((user) => user.email))
    }, [users])

    const [searchQuery, setSearchQuery] = useState('')
    const searchQueryRef = useRef(searchQuery)
    searchQueryRef.current = searchQuery

    const userOptions: UserOption[] = useMemo(() => {
        if (isFetchingSlow) {
            return PLACEHOLDER_USER_OPTIONS
        }

        const normalizedSearchQuery = searchQuery.toLowerCase()

        const options = (workspaceUsers as UserDto[])?.reduce((acc, user) => {
            const name = user.name || user.email

            // Filter by search value.
            if (
                !user.name.toLowerCase().includes(normalizedSearchQuery) ||
                !user.email.toLowerCase().includes(normalizedSearchQuery)
            ) {
                return acc
            }

            const imageUrl = user.avatar
            const { firstName, lastName } = extractPartsFromUserName(name)

            const label = truncateText(name, MAX_LABEL_LENGTH)

            const isInApp = existingEmails.has(user.email)

            acc.push({
                label: label,
                value: user._sid,
                isInApp,
                email: user.email,
                avatar: {
                    imageUrl,
                    firstName,
                    lastName,
                    type: imageUrl ? ('image' as const) : ('initial' as const),
                },
            })

            return acc
        }, [] as UserOption[])

        return options?.sort((a, b) => a.label.localeCompare(b.label))
    }, [existingEmails, isFetchingSlow, searchQuery, workspaceUsers])

    const selectedUserOption = userOptions.find((option) => option.value === value)

    const { hasRight } = useAccountUserContext()
    const canInvite = hasRight(Rights.InviteUsers)

    const onValueChange = useCallback(
        (value: string) => {
            if (value === '__invite') {
                onClosePopup()

                queueMicrotask(() => {
                    const searchQuery = searchQueryRef.current
                    if (isEmail(searchQuery)) {
                        // If the user entered an email address, prefill the email field.
                        openInviteUsersModal(searchQuery)
                    } else {
                        openInviteUsersModal()
                    }
                })
            }

            onChange(value)
        },
        [onChange, onClosePopup, openInviteUsersModal]
    )

    return {
        selectedUserOption,
        userOptions,
        isFetchingSlow: isFetchingAppUsersSlow || isFetchingSlow,
        canInvite,
        searchQuery,
        setSearchQuery,
        isError,
        onValueChange,
    }
}

const PLACEHOLDER_USER_OPTIONS: UserOption[] = [
    {
        label: 'John Doe',
        email: 'john@stackerhq.com',
        value: '1',
        isInApp: false,
    },
    {
        label: 'Jane Doe',
        email: 'jane@stackerhq.com',
        value: '2',
        isInApp: false,
    },
    {
        label: 'Steve Smith',
        email: 'steve@stackerhq.com',
        value: '3',
        isInApp: false,
    },
    {
        label: 'Mary Smith',
        email: 'mary@stackerhq.com',
        value: '5',
        isInApp: false,
    },
]

function isEmail(query: string): boolean {
    return query.includes('@')
}
