import React from 'react'

import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'

import { useNumberAttributeDisplayState } from './hooks/useNumberAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type NumberAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    isLoading?: boolean
}

export const NumberAttributeTag: AttributeDisplayComponent<number, NumberAttributeTagProps> =
    React.memo(function NumberAttributeTag({ value, isLoading = false, style, ...props }) {
        const { content } = useNumberAttributeDisplayState({ value, isLoading })

        return (
            <Skeleton isLoading={isLoading}>
                <Tag
                    size="2xs"
                    type="outline"
                    weight="regular"
                    style={{ wordBreak: 'break-word', ...style }}
                    pointerEvents="none"
                    noShrink
                    {...props}
                >
                    {content}
                </Tag>
            </Skeleton>
        )
    })
