// @ts-strict-ignore
import React, { useState } from 'react'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'

import { Rights } from 'app/AppUserContext'
import { useAppErrors } from 'data/hooks/appErrors'
import { useObject, useObjects } from 'data/hooks/objects'
import { usePermissionRules } from 'data/hooks/permissions'
import { useRoles } from 'data/hooks/roles'
import PermissionRule, {
    AddPermission,
    NoPermissionRules,
} from 'features/AppSettings/Permissions/PermissionRule'

import useModalToggle from 'v2/ui/utils/useModalToggle'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import CreatePermissionModal from './CreatePermissionRuleModal'
import EditPermissionModal from './EditPermissionRuleModal'

const PermissionTableMarkup = styled.table`
    border: 1px solid ${V4DesignSystem.colors.gray[100]};
    border-radius: 5px;
    margin: 12px 0 0;
    width: 100%;
    border-spacing: 0;
    overflow: hidden;
    border-collapse: separate;

    thead {
        background-color: ${V4DesignSystem.colors.gray[10]};
        font-size: 14px;
        color: ${V4DesignSystem.colors.gray[600]};
        font-weight: 600;
        font-size: 0.75rem;
    }

    tr:hover {
        background-color: ${V4DesignSystem.colors.gray[10]};
    }

    td {
        padding: 16px 16px;
    }

    thead td {
        padding: 8px 16px;
        border-bottom: 1px solid ${V4DesignSystem.colors.gray[100]};
    }

    thead:last-child td {
        border-bottom: none;
    }

    .add-permission-row {
        padding: 10px 16px;
    }

    tr:last-child td {
        border-bottom: 1px solid ${V4DesignSystem.colors.gray[100]};
    }

    tbody:last-child tr:last-child td {
        border-bottom: none;
    }

    .error-header {
        width: 20px;
        text-align: left;
    }

    .checkbox-header {
        padding: 8px 0;
        text-align: center;
        width: 60px;
    }

    .action-header {
        width: 100px;
        text-align: center;
    }

    .access-header {
        width: 130px;
    }
`

export const PermissionTable = ({ includeTableName, children }) => {
    return (
        <PermissionTableMarkup className="admin-app-settings-permissions-list">
            <thead>
                {includeTableName && <td>Table</td>}
                <td>Permission</td>
                <td className="error-header"></td>
                <td className="access-header">Access</td>
                <td className="checkbox-header">Read</td>
                <td className="checkbox-header">Update</td>
                <td className="checkbox-header">Create</td>
                <td className="checkbox-header">Delete</td>
                <td className="action-header">Actions</td>
            </thead>
            {children}
        </PermissionTableMarkup>
    )
}

type PermissionTableRowsProps = {
    stack
    stackId
    objectId
    roleFilter?: any
    showTableName?: boolean
}

export const PermissionTableRows: React.FC<PermissionTableRowsProps> = ({
    stack,
    stackId,
    objectId,
    roleFilter,
    showTableName = false,
}) => {
    const { object } = useObject(objectId)

    const { data } = useAppErrors()
    const ruleErrors = data?.permission_rule_errors

    const checkRuleError = (rule) => !!ruleErrors?.find((err) => err.rule_id === rule._sid)

    // new modal state and functions
    const newModal = useModalToggle(`new-modal-${object?.api_name}`)
    const openNewModal = () => {
        newModal.setIsOpen(true)
        setEditingPermissionRule(undefined)
    }
    const closeNewModal = () => {
        newModal.setIsOpen(false)
    }

    // edit modal state and functions
    const [editingPermissionRule, setEditingPermissionRule] = useState()
    const openEditModal = (permissionRule) => {
        setEditingPermissionRule(permissionRule)
        newModal.setIsOpen(false)
    }
    const closeEditModal = () => {
        setEditingPermissionRule(undefined)
    }

    // load data
    const { data: allRoles } = useRoles()

    // Show role with no all data access. If role has all data access, only show it if it's associated to the rule.
    const showRole = (role, permissionRule) => {
        return (
            !role.options?.rights?.find((x) => x === Rights.AllDataAccess) ||
            (role.options?.rights?.find((x) => x === Rights.AllDataAccess) &&
                role.permission_rules.includes(permissionRule?._sid))
        )
    }

    const roles = allRoles.filter((role) => {
        return role.stack_id === stackId && showRole(role, editingPermissionRule)
    })

    const rolesMap = allRoles.reduce((rolesMap, role) => {
        if (role.stack_id === stackId) rolesMap[role._sid] = role

        return rolesMap
    }, {})
    const {
        data: permissionRules,
        status: permissionRulesLoadingStatus,
        permissionRuleActions,
    } = usePermissionRules({ objectId })
    const { data: allObjects } = useObjects()
    const objects = allObjects?.filter((object) => object.stack_id === stackId) || []
    const userObjectId = stack?.options?.data_mapping?.user_object
    const userObject = objects.find((object) => object._sid === userObjectId)

    const permissionRulesForRole = roleFilter
        ? permissionRules.filter((p) => p.granted_by_roles.includes(roleFilter) || p.all_roles)
        : permissionRules

    const showNoPermissions = !permissionRulesForRole?.length
    const showPermissions = !showNoPermissions

    return (
        <>
            {newModal.isOpen && (
                // ---------------------------- //
                // Create permission rule modal //
                // ---------------------------- //
                <CreatePermissionModal
                    objects={objects}
                    object={object}
                    userObject={userObject}
                    permissionRules={permissionRules}
                    roles={roles}
                    onClose={closeNewModal}
                    onSubmit={async (data) => {
                        try {
                            permissionRuleActions.create(data)
                            closeNewModal()
                        } catch (err) {
                            Sentry.captureMessage(
                                `Error creating new permission rule (new app settings). Error message: ${err.message}`
                            )
                        }
                    }}
                />
            )}

            {editingPermissionRule && (
                // -------------------------- //
                // Edit permission rule modal //
                // -------------------------- //
                <EditPermissionModal
                    editingPermissionRule={editingPermissionRule}
                    objects={objects}
                    object={object}
                    userObject={userObject}
                    permissionRules={permissionRules}
                    roles={roles}
                    onClose={closeEditModal}
                    onSubmit={async (permissionRuleId, data) => {
                        try {
                            await permissionRuleActions.update(permissionRuleId, data)

                            closeEditModal()
                        } catch (err) {
                            Sentry.captureMessage(
                                `Error editing new permission rule (new app settings). Error message: ${err.message}`
                            )
                        }
                    }}
                />
            )}

            {permissionRulesLoadingStatus !== 'loading' ? (
                <tbody>
                    {showPermissions && (
                        <>
                            {permissionRulesForRole.map((permissionRule, index) => (
                                <PermissionRule
                                    key={permissionRule._sid}
                                    permissionRule={permissionRule}
                                    rolesMap={rolesMap}
                                    openEditModal={openEditModal}
                                    permissionRuleActions={permissionRuleActions}
                                    showRole={showRole}
                                    tableName={index === 0 ? object?.name : ''}
                                    tableNameColumn={showTableName}
                                    addNewRule={openNewModal}
                                    checkRuleError={checkRuleError}
                                />
                            ))}
                        </>
                    )}
                    {showNoPermissions && (
                        <NoPermissionRules
                            tableName={object?.name}
                            tableNameColumn={showTableName}
                            addNewRule={openNewModal}
                        />
                    )}
                </tbody>
            ) : (
                <tbody style={{ height: '66px' }}></tbody>
            )}
            {!showTableName && (
                <tbody>
                    <tr>
                        <td colSpan={7} className="add-permission-row">
                            <AddPermission action={openNewModal} />
                        </td>
                    </tr>
                </tbody>
            )}
        </>
    )
}
