// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Switch } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'

import { InputField } from 'features/admin/data-connector/editors/UiComponents'
import publicAsset from 'utils/publicAsset'

import { Button, Collapse, Flex, Text } from 'v2/ui'
// @ts-ignore
import STYLE_CLASSES from 'v2/ui/styleClasses'

// @ts-ignore
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

export type AirtableConnectionEditorSettingsProps = {
    onChangeHintImage: (url: string) => void
    saveFunction: (data?: any) => Promise<DataConnectionDto>
    onSaveSuccess: (dc: DataConnectionDto, fields: any) => void
    connectionFields?: any
    showApiKey?: boolean
    showSharingLink?: boolean
    highlightedFields?: any
    dataBlock: boolean
    forceShowPassword?: boolean
    useOAuthToken?: boolean
}
type Phase = { id: number; hintUrl: string }
const phases: { [key in string]: Phase } = {
    initial: {
        id: 0,
        hintUrl: publicAsset('/static/media/newAirtableHint1.png'),
    },
    last: {
        id: 1,
        hintUrl: publicAsset('/static/media/newAirtableHint2.png'),
    },
}

export const AirtableConnectionEditorSettings: React.FC<AirtableConnectionEditorSettingsProps> = (
    props
) => {
    const [isSaving, setIsSaving] = useState(false)
    const [fields, setFields] = useState(props.connectionFields || {})
    const [fieldErrors, setFieldErrors] = useState({})
    const [showErrors, setShowErrors] = useState<boolean>(false)
    const [phase, setPhase] = useState(props.useOAuthToken ? phases.last : phases.initial)
    const [showPasswordInput, setShowPasswordInout] = useState<boolean>(false)
    const { onChangeHintImage } = props

    const validationRules = useMemo(
        () => ({
            apiKey: [
                {
                    test: (value) => !props.showApiKey || value?.startsWith('key'),
                    error: "Your API Key should start with 'key'",
                },
            ],
            sharingLink: [
                {
                    // Match either https://airtable.com/appNSAoZaZo5QIe3l/shrxLKfHQBbnibx8m
                    // or https://airtable.com/shrxLKfHQBbnibx8m
                    test: (value) => /^https:\/\/airtable\.com\/([A-Za-z0-9]+\/)?shr/.test(value),
                    error: 'Your link is not a valid Airtable sharing link',
                },
            ],
            //we check that the password value doesn't start with http because it's a common mistake
            // that users make when they copy the sharing link instead of the password
            password: [
                {
                    test: (value) => !value?.startsWith('http'),
                    error: 'The sharing link password should not be a link',
                },
            ],
        }),
        [props.showApiKey]
    )

    const togglePasswordInput = () => {
        setShowPasswordInout((showPasswordInput) => !showPasswordInput)
    }

    // create the new connection
    const saveConnection = useCallback(() => {
        if (!isEmpty(fieldErrors)) {
            setShowErrors(true)
            return
        }
        setShowErrors(false)
        setIsSaving(true)

        props
            .saveFunction({
                version: props.useOAuthToken ? 2 : 1,
                updated_secret_options: {
                    api_key: fields.apiKey,
                    base_url: fields.sharingLink,
                    password: fields.password || undefined,
                },
            })
            .then((dc) => {
                props.onSaveSuccess(dc, fields)
            })
            .catch(() => {
                setShowErrors(true)
            })
            .finally(() => {
                setIsSaving(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsSaving, fields, setShowErrors, fieldErrors, props.onSaveSuccess])

    const handleFieldChange = (e) => {
        const field = e.target.id
        const value = e.target.value
        setFields((state) => ({ ...state, [field]: value }))
    }

    const handlePhaseChange = (phase: Phase) => {
        setPhase(phase)
        setShowErrors(false)
    }

    useEffect(() => {
        onChangeHintImage(phase.hintUrl)
    }, [onChangeHintImage, phase])

    // On field change, run through the validation rules
    useEffect(() => {
        const errors = {}
        for (const field of Object.keys(fields)) {
            const value = fields[field]
            if (validationRules[field]) {
                for (const rule of validationRules[field]) {
                    if (!rule.test(value)) {
                        errors[field] = rule.error
                        // Only show the first error per field
                        break
                    }
                }
            }
        }
        setFieldErrors(errors)
    }, [fields, setFieldErrors, validationRules])
    const fieldProps = { fields, showErrors, fieldErrors, onChange: handleFieldChange }

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                saveConnection()
            }
        },
        [saveConnection]
    )

    return (
        <div style={{ color: V4DesignSystem.colors.text, lineHeight: '18px' }}>
            {props.showApiKey && (
                <>
                    <div style={{ height: 8 }} />
                    <div style={{ ...V4DesignSystem.label, color: V4DesignSystem.colors.text }}>
                        Airtable API key
                    </div>
                    <div style={{ height: 8 }} />
                    <div style={V4DesignSystem.pageSubtitle}>
                        Copy this from your{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://airtable.com/create/apikey"
                        >
                            Airtable account
                        </a>
                    </div>
                    <div style={{ height: 2 }} />
                    <InputField
                        style={{ maxWidth: '350px' }}
                        disabled={phase.id !== 0}
                        className={props.dataBlock ? STYLE_CLASSES.DATA_BLOCK : ''}
                        id="apiKey"
                        placeholder={'Paste your API key here'}
                        highlight={props.highlightedFields?.includes('apiKey') && !fields.apiKey}
                        {...fieldProps}
                    />
                </>
            )}
            <Collapse
                isOpen={!!(phase.id === 0 && props.showSharingLink)}
                style={{ marginTop: 16 }}
            >
                <Button
                    onClick={() => handlePhaseChange(phases.last)}
                    disabled={!validationRules.apiKey.some((regex) => regex.test(fields['apiKey']))}
                    variant="Primary"
                    buttonSize="md"
                >
                    Proceed
                </Button>
            </Collapse>
            <Collapse isOpen={!!(phase.id === 1 && props.showSharingLink)}>
                <div style={{ maxWidth: '350px' }}>
                    <div style={{ height: 14 }} />
                    <div
                        style={{
                            ...V4DesignSystem.label,
                            color: V4DesignSystem.colors.text,
                            display: 'flex',
                        }}
                    >
                        Shared link to your Airtable base
                    </div>
                    <div style={{ height: 8 }} />
                    <div style={V4DesignSystem.pageSubtitle}>
                        Click{' '}
                        <span style={{ color: 'rgba(0,0,0,0.8)', fontWeight: 700 }}>Share</span> in
                        the top right corner of your base, select{' '}
                        <span style={{ color: 'rgba(0,0,0,0.8)', fontWeight: 700 }}>
                            Share publicly
                        </span>{' '}
                        and copy the{' '}
                        <span style={{ color: 'rgba(0,0,0,0.8)', fontWeight: 700 }}>
                            Shared base link
                        </span>
                        .
                    </div>
                    <div style={{ height: 2 }} />
                    <InputField
                        id="sharingLink"
                        placeholder="Paste your shared base link here"
                        highlight={
                            props.highlightedFields?.includes('sharingLink') && !fields.sharingLink
                        }
                        {...fieldProps}
                    />
                    <Text fontSize="sm" color="gray.400" mt={4}>
                        Note: email-restricted sharing links are not supported.
                    </Text>
                </div>
            </Collapse>
            <div style={{ height: 8 }} />
            {(phase.id === 1 || props.forceShowPassword) && (
                <>
                    <Flex>
                        <Text>My sharing link is password protected</Text>
                        <Switch
                            size="sm"
                            isChecked={showPasswordInput}
                            onChange={togglePasswordInput}
                            marginLeft={2}
                        />
                    </Flex>
                    <Collapse isOpen={!!showPasswordInput}>
                        <InputField
                            id="password"
                            placeholder="Enter your sharing link password"
                            highlight={
                                props.highlightedFields?.includes('password') && !fields.password
                            }
                            type="password"
                            onKeyDown={onKeyDown}
                            {...fieldProps}
                        />
                    </Collapse>
                    <>
                        {isSaving ? (
                            <div style={{ marginTop: 16 }}>Loading...</div>
                        ) : (
                            <Flex style={{ marginTop: '16px' }}>
                                {!props.forceShowPassword && (
                                    <Button
                                        onClick={() => handlePhaseChange(phases.initial)}
                                        variant="Secondary"
                                        style={{ marginRight: 8 }}
                                        buttonSize="sm"
                                    >
                                        Back
                                    </Button>
                                )}
                                <Button
                                    onClick={saveConnection}
                                    disabled={
                                        !props.forceShowPassword &&
                                        !validationRules.sharingLink.some((regex) =>
                                            regex.test(fields['sharingLink'])
                                        )
                                    }
                                    variant="Primary"
                                    buttonSize="sm"
                                >
                                    Connect
                                </Button>
                            </Flex>
                        )}
                        {showErrors ? (
                            <div style={{ marginTop: 16 }}>
                                A problem occurred when trying to connect
                            </div>
                        ) : null}
                    </>
                </>
            )}
        </div>
    )
}
