import { keywordMap } from 'features/formulas/constants/formulaEditorTextConstants'
import { Keyword } from 'features/formulas/formulaTypes'

import { StackerFormulaSyntaxTree } from './formulaParserTypes'

const exclusiveFunctionFormulas: Partial<
    Record<StackerFormulaSyntaxTree['function'], DataConnectionType[]>
> = {
    CREATED_AT: ['native_tables'],
    CREATED_BY: ['native_tables'],
    LAST_UPDATED_AT: ['native_tables'],
    LAST_UPDATED_BY: ['native_tables'],
}

export const getIsFunctionAvailableForConnectionType = ({
    dataConnectionType,
    func,
}: {
    dataConnectionType?: DataConnectionType
    func: StackerFormulaSyntaxTree['function']
}): boolean => {
    const allowedConnectionTypes = exclusiveFunctionFormulas[func]
    return (
        !allowedConnectionTypes ||
        (!!dataConnectionType && allowedConnectionTypes.includes(dataConnectionType))
    )
}

// Memoize the result of getAllAvailableFunctions
const allAvailableFunctionsMap: Partial<Record<DataConnectionType | 'undefined', Keyword[]>> = {}

export const getAllAvailableFunctionKeys = (dataConnectionType?: DataConnectionType) => {
    return Object.keys(keywordMap).filter((func) =>
        getIsFunctionAvailableForConnectionType({
            dataConnectionType,
            func: func as keyof typeof keywordMap,
        })
    ) as (keyof typeof keywordMap)[]
}

export const getAllAvailableFunctions = (dataConnectionType?: DataConnectionType): Keyword[] => {
    const key = dataConnectionType ?? 'undefined'
    if (!allAvailableFunctionsMap[key]) {
        allAvailableFunctionsMap[key] = getAllAvailableFunctionKeys(dataConnectionType).map(
            (func) => keywordMap[func]
        )
    }
    return allAvailableFunctionsMap[key] as Keyword[]
}
