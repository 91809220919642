import React from 'react'

import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

type EmptyThumbnailProps = {
    effectiveSize: 's' | 'm' | 'manual'
    placeholder?: string
}

const sizeMapping = {
    s: 's',
    m: 'm',
}

const MAX_LENGTH_TITLE = 60
const MAX_LINES_TITLE = 2

export const EmptyThumbnailContent = ({ effectiveSize, placeholder }: EmptyThumbnailProps) => {
    const noImageIconSize = sizeMapping[effectiveSize]

    if (placeholder) {
        return (
            <Body
                weight="medium"
                color="gray400"
                maxLength={MAX_LENGTH_TITLE}
                maxLines={MAX_LINES_TITLE}
                textAlign="center"
                px="4xl"
            >
                {placeholder}
            </Body>
        )
    }
    return <Icon name="ImageOff" color="icon" size={noImageIconSize} />
}
