import React, { forwardRef } from 'react'

import classNames from 'classnames'
import { icons, LucideProps } from 'lucide-react'

import { StandardComponentProps, transformStandardProps } from 'ui/helpers/styles'

import { IconStyles, IconVariants } from './Icon.css'

export type IconName = keyof typeof icons

export type IconNameVariantType = { name: IconName }

type IconRef = SVGSVGElement

type IconProps = Omit<LucideProps, 'ref' | 'size' | 'color'> &
    StandardComponentProps &
    IconNameVariantType &
    IconVariants & {}

export const Icon = forwardRef<IconRef, IconProps>(({ name, size = 's', ...props }, ref) => {
    const LucideIcon = icons[name]

    const { className, ...transformedProps } = transformStandardProps(props)

    return (
        <LucideIcon
            absoluteStrokeWidth={true}
            {...transformedProps}
            className={classNames(IconStyles.styleFunction({ size }), className)}
            ref={ref}
        />
    )
})
