type UseTextAttributeDisplayStateProps = {
    value?: string
    maxLength?: number
    isLoading?: boolean
}

export function useTextAttributeDisplayState({
    value = '',
    maxLength,
    isLoading,
}: UseTextAttributeDisplayStateProps) {
    const effectiveValue = isLoading ? PLACEHOLDER_VALUE : value

    const isOverflowing = maxLength && value.length > maxLength

    return { isOverflowing, value: effectiveValue }
}

const PLACEHOLDER_VALUE: string = 'Loading record...'
