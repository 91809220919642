import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Spinner } from '@chakra-ui/react'

import { Heading, Icon, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import Button from 'ui/deprecated/atoms/Button'
import SubmitButton from 'ui/deprecated/forms/SubmitButton'

export function Spacer({ style = {} }) {
    return <div style={{ flexBasis: 15, flexShrink: 0, ...style }} />
}

const Wrapper = (props) => (
    <SimpleModalCompat
        size={400}
        height={434}
        showCloseButton={false}
        isOpen
        onClose={() => {}}
        zIndex={9999}
        {...props}
    />
)

/*
 Returns modalState which can be passed to the UnsavedChangesModal, and a
 saveChanges(fn) method which checks for changes/shows the modal, and if proceeding
 calls fn().  Use like this:


    const { modalState: displayChangesModal, saveChanges } = useUnsavedChangesModalCallback(formRef)

    // check for unsaved changes, then close the modal
    function onClose() {
        saveChanges(onRequestClose)
    }
    ....

    <UnsavedChangesModal {...displayChangesModal} />
*/
export const useUnsavedChangesModalCallback = (formRef) => {
    const [modalState, setModalState] = useState()
    // similar to onClose
    // If there has changes, do not change pages
    const saveChanges = useCallback(
        async (fn) => {
            // trigger a validation here to ensure that the inputs have all been validated
            await formRef.current?.trigger?.()

            return new Promise((resolve) => {
                formRef.current?.trigger?.()

                // wait until any ongoing request finishes before closing
                if (formRef.current.formState?.isSubmitting || formRef.current.hasChanges) {
                    return setModalState({
                        type: formRef.current.formState?.isSubmitting ? 'submitting' : 'changes',
                        onProceed: () => {
                            resolve(fn())
                            setModalState(null)
                        },
                        onCancel: () => {
                            setModalState(null)
                        },
                        onSave: () => {},
                    })
                }

                resolve(fn())
            })
        },
        [formRef]
    )

    return {
        modalState,
        saveChanges,
    }
}
// if there are unsaved changes OR a request is ongoing
export function UnsavedChangesModal({
    type,
    onProceed,
    onCancel,
    onSubmitClick,
    usePortal = false,
}) {
    const formContext = useFormContext()
    const { formState } = formContext

    // automatically proceed to the next page / close the modal when the ongoing query succeeds
    useEffect(() => {
        // if a query was ongoing and it succeeded
        if (type === 'submitting' && !formState.isSubmitting && !formState.isSubmitted) {
            onProceed()
        }
        // if there was changes but not anymore, and if no query is ongoing, then the save query succeeded
        if (type === 'changes' && !formState.isSubmitting) {
            const hasChanges = Object.keys(formState.dirtyFields).length > 0
            if (!hasChanges) onProceed()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.isSubmitting, formState.isSubmitted, formState.isDirty, type])

    if (type === 'submitting') {
        // if it succeeded, we display an empty state while the useEffect above runs
        // This is counter intuitive but if isSubmitted = true, then it failed.
        // Thats because when a query succeeds, we reset the form, which turns isSubmitted = false
        if (!formState.isSubmitting && !formState.isSubmitted) {
            return (
                <Wrapper usePortal={usePortal}>
                    <div style={{ minHeight: 225 }} />
                </Wrapper>
            )
        }

        return (
            <Wrapper usePortal={usePortal}>
                {formState.isSubmitting ? (
                    <>
                        <Spinner />
                        <Spacer />
                        <Text variant="modalBody" style={{ textAlign: 'center' }}>
                            Save In Progress
                        </Text>
                    </>
                ) : (
                    <>
                        <Icon
                            size="56px"
                            icon="warning"
                            mb={4}
                            color="grey.200"
                            display="inline-block"
                        />
                        <Text variant="modalBody" style={{ textAlign: 'center' }}>
                            An error occured while saving
                        </Text>
                    </>
                )}
                <Spacer />
                <Spacer />

                <Button
                    type="secondary"
                    style={{ alignSelf: 'stretch' }}
                    size="lg"
                    onClick={() => onCancel()}
                >
                    Stay on this page.
                </Button>
            </Wrapper>
        )
    }

    return (
        <Wrapper
            onClose={() => {
                onCancel()
            }}
            usePortal={usePortal}
        >
            <div style={{ textAlign: 'center' }}>
                <Icon size="56px" icon="warning" mb={4} color="grey.200" display="inline-block" />
                <Heading variant="modal" value="Unsaved Changes" />
                <Text variant="modalBody">
                    You are about to leave this screen with unsaved changes. Would you like to save
                    the changes?
                </Text>
            </div>

            <Spacer />
            <Spacer />
            <SubmitButton style={{ alignSelf: 'stretch' }} size="lg" onClick={onSubmitClick}>
                Save and continue
            </SubmitButton>
            <Spacer />
            <Button
                style={{ alignSelf: 'stretch' }}
                size="lg"
                type="secondary"
                onClick={() => onProceed()}
                disabled={formState.isSubmitting}
            >
                Discard and continue
            </Button>
            <Spacer />
            <Button
                style={{ alignSelf: 'stretch' }}
                size="lg"
                type="link"
                onClick={() => onCancel()}
            >
                Stay on this page
            </Button>
        </Wrapper>
    )
}
