import React, { useMemo } from 'react'

import { BoxProps } from '@chakra-ui/react'
import { orderBy } from 'lodash'

import { useAppContext } from 'app/AppContext'
import { useObjectCache } from 'data/hooks/cacheInfo'
import { useObjects } from 'data/hooks/objects'
import DATA_PROVIDERS from 'features/admin/data-connector/dataProviderConfig'
import { isUsingAirtableRealtimeUpdates } from 'features/admin/data-connector/isUsingAirtableRealtimeUpdates'
import { ProviderIcon } from 'features/admin/data-connector/ProviderIcon'

import { Box, Flex, Text } from 'v2/ui'
import { colorBaseConfig } from 'v2/ui/theme/styles/default/colors'
import useDisplayTimeFrom from 'v2/ui/utils/useDisplayTimeFrom'

type SyncingTimesLabelProps = BoxProps & {
    dataConnection: DataConnectionDto
    textStyle: any
    showDataSourceInfo?: boolean
}

const SyncingTimesLabel: React.FC<SyncingTimesLabelProps> = ({
    dataConnection,
    textStyle,
    showDataSourceInfo,
    ...props
}) => {
    const { data: objects }: { data: ObjectDto[] | undefined } = useObjects()
    const { selectedStack } = useAppContext()
    const { type } = dataConnection
    const { objectStatuses, isLoading: cacheStatusLoading } = useObjectCache()

    const { allowDataSync, getDescriptionOverride } = DATA_PROVIDERS[type] || {}

    const descriptionOverride = useMemo(
        () => dataConnection && getDescriptionOverride?.(dataConnection),
        [getDescriptionOverride, dataConnection]
    )

    const fastSyncEnabled = selectedStack?.combined_optional_features?.fast_sync
    const connectorSupportsFastSync = ['airtable', 'salesforce'].includes(dataConnection.type)
    const syncFrequency = fastSyncEnabled && connectorSupportsFastSync ? '5' : '15'

    const lastFillTime = orderBy(
        objects
            ?.map((o) => objectStatuses?.[o._sid]?.latest_cache_time)
            .filter((x) => Boolean(x)) || [],
        undefined,
        'desc'
    )?.[0]
    const lastSyncTime = useDisplayTimeFrom(lastFillTime)

    let description = ''
    const shouldDisplayError = dataConnection.type === 'airtable' && dataConnection.version === 2
    const isError = shouldDisplayError && dataConnection.status !== 'valid'

    if (isError) {
        description = `This ${
            dataConnection.type === 'airtable' ? 'Airtable base' : 'connector'
        } is no longer accessible`
    } else if (isUsingAirtableRealtimeUpdates(dataConnection)) {
        description = 'Syncs in real time'
    } else {
        description = `Syncs ${
            showDataSourceInfo ? `from ${dataConnection.label}` : ''
        } every ${syncFrequency} minutes`
        if (dataConnection.type === 'fivetran') {
            description = `Syncs from the data source every ${syncFrequency} minutes`
        }
        if (!cacheStatusLoading) {
            description += lastFillTime ? ` · Last synced ${lastSyncTime}` : ' · Not yet synced'
        }
    }

    if (isError) {
        textStyle = { ...textStyle, color: colorBaseConfig.brandColors.stacker.red }
    }

    return (
        <Box {...props}>
            {(allowDataSync || descriptionOverride) && (
                <Flex alignItems="center">
                    {showDataSourceInfo && (
                        <ProviderIcon mr={1} type={dataConnection.type} size="16px" />
                    )}{' '}
                    <Text {...textStyle}>
                        {descriptionOverride ? descriptionOverride : description}
                    </Text>
                </Flex>
            )}
        </Box>
    )
}

export default SyncingTimesLabel
