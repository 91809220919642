import React, { forwardRef, useCallback } from 'react'

import { Box } from 'ui/components/Box'
import { Icon, IconNameVariantType } from 'ui/components/Icon/Icon'
import { Body } from 'ui/components/Text'
import { makeComponent } from 'ui/helpers/recipes'
import { ColorScheme } from 'ui/styling/baseVariables/colors/colorPalette'
import { useTheme } from 'ui/styling/themes/ThemeProvider'

import { IconStyles, LabelStyle, RemoveButtonStyle, TagStyles } from './Tag.css'

const Container = makeComponent(Box, TagStyles)
const StartIcon = makeComponent(Icon, IconStyles)
const RemoveButton = makeComponent(Icon, IconStyles)

type IconProps = IconNameVariantType

type TagRef = HTMLDivElement

type Color = 'Neutral' | ColorScheme

type TagProps = Omit<React.ComponentPropsWithoutRef<typeof Container>, 'asChild'> & {
    color?: Color
    startIcon?: IconProps
    showRemoveButton?: boolean
    onRemove?: () => void
    maxLength?: React.ComponentPropsWithoutRef<typeof Body>['maxLength']
    maxLines?: React.ComponentPropsWithoutRef<typeof Body>['maxLines']
}

export const Tag = forwardRef<TagRef, TagProps>(
    (
        {
            children,
            startIcon,
            type = 'solid',
            shade = 'light',
            color = 'Neutral',
            showRemoveButton,
            onRemove,
            onKeyDown,
            disabled,
            maxLength,
            maxLines,
            ...props
        },
        ref
    ) => {
        const { themeRawVariables } = useTheme()

        const size = props.size ?? 'm'

        const tagVariables = themeRawVariables.tag
        const textSize = tagVariables.textSize[size]
        const textWeight = tagVariables.textWeight[size]

        const iconSize = tagVariables.size[`${size}Icon`] as React.ComponentPropsWithoutRef<
            typeof Icon
        >['size']

        const handleKeyDown = useCallback(
            (e: React.KeyboardEvent) => {
                onKeyDown?.(e)

                if (showRemoveButton && (e.key === 'Delete' || e.key === 'Backspace')) {
                    onRemove?.()
                }
            },
            [onKeyDown, onRemove, showRemoveButton]
        )

        const handleClickRemove = useCallback(
            (e: React.MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()

                onRemove?.()
            },
            [onRemove]
        )

        return (
            <Container
                tabIndex={0}
                type={type}
                shade={shade}
                color={color}
                aria-disabled={disabled}
                {...props}
                onKeyDown={handleKeyDown}
                ref={ref}
            >
                {startIcon && (
                    <StartIcon
                        size={iconSize}
                        role="presentation"
                        backgroundType={type}
                        shade={shade}
                        colorScheme={color}
                        aria-disabled={disabled}
                        {...startIcon}
                    />
                )}
                {children && (
                    <Body
                        size={textSize}
                        weight={textWeight}
                        className={LabelStyle}
                        maxLength={maxLength}
                        maxLines={maxLines}
                    >
                        {children}
                    </Body>
                )}
                {showRemoveButton && (
                    <RemoveButton
                        role="button"
                        name="X"
                        size="xs"
                        aria-label="Remove"
                        onClick={handleClickRemove}
                        aria-disabled={disabled}
                        className={RemoveButtonStyle}
                    />
                )}
            </Container>
        )
    }
)
