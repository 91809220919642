import { formatDate } from 'utils/dateUtils'

type UseDateAttributeDisplayStateProps = {
    field: FieldDto
    value?: string
    isLoading?: boolean
}

export function useDateAttributeDisplayState({
    value,
    field,
    isLoading,
}: UseDateAttributeDisplayStateProps) {
    const content = formatDateValue(field, isLoading ? PLACEHOLDER_VALUE : value)

    return { content }
}

function formatDateValue(field: FieldDto, value?: string): string {
    if (!value) return ''

    const showTime = field.type === 'datetime'

    const { dateValue } = formatDate(value, field.options?.timezone, showTime)
    if (!dateValue) return ''

    const formattedValue = dateValue.format(showTime ? 'MMM D, YYYY LT' : 'MMM D, YYYY')

    return formattedValue
}

const PLACEHOLDER_VALUE = '2020-01-01T00:00:00Z'
