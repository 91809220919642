import React from 'react'

import { isEqual } from 'lodash'

import { CurrencyAttributeDisplay } from 'features/views/attributes/CurrencyAttributeDisplay'
import { NumberAttributeDisplay } from 'features/views/attributes/NumberAttributeDisplay'
import { PercentageAttributeDisplay } from 'features/views/attributes/PercentageAttributeDisplay'
import * as Parts from 'features/views/ListView/TableView/TableView.parts'
import { TableViewCalculationType, TableViewColumn } from 'features/views/ListView/TableView/types'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { theme } from 'ui/styling/Theme.css'

import {
    CalculationItem,
    useTableViewCalculationItemState,
} from './hooks/useTableViewCalculationItemState'
import { isCountCalculationType } from './utils'

type TableViewCalculationItemProps = React.ComponentPropsWithoutRef<typeof Parts.Header> & {
    column: TableViewColumn
}

export const TableViewCalculationItem: React.FC<TableViewCalculationItemProps> = React.memo(
    function TableViewCalculationItem({ column, ...props }) {
        const { isPinned, align } = column

        const justifyContent = (() => {
            switch (align) {
                case 'left':
                    return 'flex-start'
                case 'center':
                    return 'center'
                case 'right':
                    return 'flex-end'
            }
        })()

        const tooltipAlign = (() => {
            switch (align) {
                case 'left':
                    return 'start'
                case 'center':
                    return 'center'
                case 'right':
                    return 'end'
            }
        })()

        const {
            label,
            value,
            isFetchingSlow,
            calculations,
            shouldRender,
            isError,
            calculationType,
        } = useTableViewCalculationItemState({
            column,
        })

        const field = column.field

        const content = (
            <CalculationItemContent
                calculationType={calculationType}
                field={field}
                label={label}
                value={value}
                isFetchingSlow={isFetchingSlow}
                isError={isError}
            />
        )

        return (
            <Parts.CalculationItem
                isPinned={isPinned}
                isDisabled={isFetchingSlow || isError}
                {...props}
            >
                {shouldRender && (
                    <>
                        {isFetchingSlow || isError ? (
                            <Box grow flex center justifyContent={justifyContent} noShrink>
                                {content}
                            </Box>
                        ) : (
                            <Tooltip
                                zIndex={99}
                                content={
                                    <CalculationItemTooltipContent
                                        field={field}
                                        calculations={calculations}
                                    />
                                }
                                side="bottom"
                                flex
                                center
                                align={tooltipAlign}
                                justifyContent={justifyContent}
                                grow
                                noShrink
                            >
                                {content}
                            </Tooltip>
                        )}
                    </>
                )}
            </Parts.CalculationItem>
        )
    },
    isEqual
)

type CalculationItemContentProps = {
    field: FieldDto
    label: string
    value: number
    isFetchingSlow: boolean
    isError: boolean
    calculationType?: TableViewCalculationType
}

const CalculationItemContent: React.FC<CalculationItemContentProps> = ({
    calculationType,
    field,
    label,
    value,
    isFetchingSlow,
    isError,
}) => {
    return (
        <Box flex center gap="s">
            <Body
                size="xs"
                color="textWeaker"
                style={{
                    textTransform: 'uppercase',
                }}
            >
                {label}
            </Body>
            {isError ? (
                <Icon name="AlertCircle" color="iconError" size="xs" />
            ) : (
                <Box fontSize="bodyS" fontWeight="bodyBold" lineHeight="bodyS">
                    <RenderValue
                        calculationType={calculationType}
                        field={field}
                        isLoading={isFetchingSlow}
                    >
                        {value}
                    </RenderValue>
                </Box>
            )}
        </Box>
    )
}

type CalculationItemTooltipContentProps = {
    field: FieldDto
    calculations: CalculationItem[]
}

const CalculationItemTooltipContent: React.FC<CalculationItemTooltipContentProps> = ({
    field,
    calculations,
}) => {
    return (
        <Box
            display="grid"
            style={{
                gridTemplateColumns: 'auto auto',
                gap: theme.space.s,
                textTransform: 'uppercase',
            }}
            textAlign="left"
        >
            {calculations.map((calculation) => (
                <React.Fragment key={calculation.type}>
                    <Box>{calculation.label}:</Box>
                    <RenderValue calculationType={calculation.type} field={field} textAlign="right">
                        {calculation.value}
                    </RenderValue>
                </React.Fragment>
            ))}
        </Box>
    )
}

type RenderValueProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'children'> & {
    field: FieldDto
    children?: number
    calculationType?: TableViewCalculationType
    isLoading?: boolean
}

const RenderValue: React.FC<RenderValueProps> = React.memo(function RenderValue({
    calculationType,
    field,
    children,
    isLoading,
    ...props
}) {
    if (typeof children === 'undefined') return null

    const isCount = isCountCalculationType(calculationType)

    let content: React.ReactNode
    if (!isCount) {
        switch (field.type) {
            case 'currency':
                return (
                    <CurrencyAttributeDisplay
                        value={children}
                        field={field}
                        style={{
                            fontSize: 'inherit',
                            lineHeight: 'inherit',
                            letterSpacing: 'inherit',
                            fontWeight: 'inherit',
                        }}
                        isLoading={isLoading}
                    />
                )
            case 'percentage':
                return (
                    <PercentageAttributeDisplay
                        value={children}
                        field={field}
                        valueDisplay="percentNumber"
                        whiteSpace="nowrap"
                        style={{
                            fontSize: 'inherit',
                            lineHeight: 'inherit',
                            letterSpacing: 'inherit',
                            fontWeight: 'inherit',
                        }}
                        isLoading={isLoading}
                    />
                )
        }
    }
    if (!content) {
        return (
            <NumberAttributeDisplay
                value={children}
                field={field}
                style={{
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                    letterSpacing: 'inherit',
                    fontWeight: 'inherit',
                }}
                isLoading={isLoading}
            />
        )
    }

    return (
        <Box noShrink {...props}>
            {content}
        </Box>
    )
},
isEqual)
