import React, { FC, useMemo } from 'react'

import styled from '@emotion/styled'
import { METRIC_NARROW_WIDTH } from 'v2/blocks/blockTypes/view/aggregationBlocks/common/InvalidBlock'

import { useObject } from 'data/hooks/objects'

import { Text } from 'v2/ui'
import { ChartsBlockError } from 'v2/ui/svgs'

type ChartBlockLoadingError = {
    metric: ChartBlockAttributes
    error: string[]
}

const StyledTitle = styled(Text)`
    align-self: center;
    font-weight: bold;

    @container (width <= ${METRIC_NARROW_WIDTH}px) {
        font-weight: normal;
    }
`

export const ChartBlockLoadingError: FC<ChartBlockLoadingError> = ({ metric, error }) => {
    const { object } = useObject(metric.objectId)

    const fieldLabel = useMemo(
        () => object?.fields.find(({ _sid }) => _sid === metric.groupByFieldId)?.label,
        [metric.groupByFieldId, object?.fields]
    )

    return (
        <>
            <ChartsBlockError style={{ width: '100%', alignSelf: 'center' }} />
            <StyledTitle mt={5} alignSelf="center" textAlign="center">
                We couldn&apos;t load this chart.
            </StyledTitle>
            {error[0].includes('max number of groups limit reached') && (
                <>
                    <Text mt={2} alignSelf="center" textAlign="center">
                        There are two many fields to group by:
                    </Text>
                    <Text alignSelf="center" fontWeight="bold" textAlign="center">
                        {fieldLabel}
                    </Text>
                </>
            )}
        </>
    )
}
