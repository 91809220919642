import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { getUrl } from 'app/UrlService'
import { useObject } from 'data/hooks/objects'
import { useViews } from 'data/hooks/views'

import { Modal, Text } from 'v2/ui'
import useModalToggle from 'v2/ui/utils/useModalToggle'

import { Box } from 'ui/components/Box'
import { LinkButton } from 'ui/components/LinkButton'

import CreateView from './CreateView'
import { persistRecord } from './inlineCreateExpand'
import { InlineCreateViewModalData } from './types'

import { InlineCreateViewModalStyle } from './InlineCreateViewModal.css'

const MODAL_BODY_STYLE = {
    padding: '2px',
    textAlign: 'left',
}

export const InlineCreateViewModal = ({ modalKey }: { modalKey: string }) => {
    const [actions, setActions] = useState([])

    const { isOpen, toggle, data } = useModalToggle<InlineCreateViewModalData>(modalKey)
    const isOpenRef = useRef(isOpen)
    isOpenRef.current = isOpen
    const toggleRef = useRef(toggle)
    toggleRef.current = toggle

    const { data: views } = useViews()
    const { object } = useObject(data?.objectId)

    const view = useMemo(() => {
        return views?.find((view) => {
            return view.object_id === data?.objectId && view.type === 'create'
        })
    }, [data?.objectId, views])

    const config = useMemo(() => {
        return view?.options ?? null
    }, [view])

    const onInlineCreate = useCallback(
        (record_id) => {
            if (data?.onCreate) {
                data.onCreate(record_id, 'Record Created')
            }
            toggle()
        },
        [data, toggle]
    )

    // Close the modal if we navigate away from the current page.
    const location = useLocation()
    useEffect(() => {
        if (isOpenRef.current) {
            toggleRef.current()
        }
    }, [location.pathname])

    const recordDraft = useRef<Partial<RecordDto>>({})
    const onRecordChange = useCallback((record: Partial<RecordDto>) => {
        recordDraft.current = record
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            scrollBehavior="inside"
            size="600px"
            body={
                <CreateView
                    queryParams={data?.queryParams}
                    hideTitle
                    view={view}
                    showRelated
                    objectId={data?.objectId}
                    showControls={false}
                    config={config}
                    onCreate={onInlineCreate}
                    isCreate
                    doNotRedirect
                    closeModal={toggle}
                    inlineCreate
                    setModalActions={setActions}
                    onRecordChange={onRecordChange}
                />
            }
            onClose={toggle}
            actions={actions}
            bodyStyle={MODAL_BODY_STYLE}
            headerVariant="custom"
            title={
                <InlineCreateViewModalHeader
                    title={config?.title || `${object?.name}: add new`}
                    object={object}
                    my="m"
                    getRecordDraft={() => recordDraft.current}
                />
            }
            contentProps={{
                className: InlineCreateViewModalStyle,
            }}
        />
    )
}

type InlineCreateViewModalHeaderProps = React.ComponentPropsWithoutRef<typeof Box> & {
    title: string
    object?: ObjectDto
    getRecordDraft: () => Partial<RecordDto>
}

const InlineCreateViewModalHeader: React.FC<InlineCreateViewModalHeaderProps> = ({
    title,
    object,
    getRecordDraft,
    ...props
}) => {
    const createUrl = useMemo(() => {
        if (!object) return ''

        return getUrl(`${object.url}/new`)
    }, [object])

    return (
        <Box
            flex
            center
            grow
            justifyContent="space-between"
            pr="xs"
            style={{ maxWidth: 'calc(100% - 32px)' }}
            {...props}
        >
            <Text variant="modalTitle">{title}</Text>
            <LinkButton
                variant="dotted"
                startIcon={{ name: 'Maximize2' }}
                size="s"
                onClick={() => {
                    // Serialize the record to local storage, so it can be restored if the user navigates away.
                    persistRecord(createUrl, getRecordDraft())
                }}
                to={(location) => ({
                    pathname: createUrl,
                    state: {
                        ...(location.state as Record<string, unknown>),
                        bypassUnsavedChangesModal: true,
                    },
                })}
                style={{ marginLeft: 'auto' }}
                noShrink
            >
                Expand
            </LinkButton>
        </Box>
    )
}
