import React from 'react'

import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'

import { useCurrencyAttributeDisplayState } from './hooks/useCurrencyAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type CurrencyAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    isLoading?: boolean
}

export const CurrencyAttributeTag: AttributeDisplayComponent<number, CurrencyAttributeTagProps> =
    React.memo(function CurrencyAttributeTag({ value, field, isLoading, style, ...props }) {
        const { content } = useCurrencyAttributeDisplayState({ value, field, isLoading })

        return (
            <Skeleton isLoading={isLoading}>
                <Tag
                    size="2xs"
                    type="outline"
                    weight="regular"
                    style={{ wordBreak: 'break-word', ...style }}
                    pointerEvents="none"
                    noShrink
                    {...props}
                >
                    {content}
                </Tag>
            </Skeleton>
        )
    })
