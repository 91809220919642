import merge from 'lodash/merge'

/**
 * Merges the new and current record
 * @param {object} record
 * @param {object} currentRecord
 */

export const getMergedRecord = (record: RecordDto, currentRecord: RecordDto) => {
    const partial = !!record._partial && (!currentRecord || !!currentRecord?._partial)
    // _local_display_order needs preserved as it is a local
    // field and shouldn't be lost just because the incoming record
    // doesn't have it.
    const _local_display_order =
        record._local_display_order !== undefined
            ? record._local_display_order
            : currentRecord._local_display_order
    const updatedRecord = !!record._partial
        ? merge({}, currentRecord, record, {
              _partial: partial,
              _local_display_order,
          })
        : { ...record, _local_display_order }
    return updatedRecord
}
