import React, { memo } from 'react'

import DetailView from 'v2/views/Detail/DetailView'

import { withStack } from 'data/wrappers/WithStacks'
import { SaveGuardContextProvider } from 'features/records/SaveGuardContext'
const DetailViewWrapper = ({ ...props }) => {
    return (
        <SaveGuardContextProvider>
            <DetailView {...props} />
        </SaveGuardContextProvider>
    )
}

export default memo(withStack(DetailViewWrapper))
