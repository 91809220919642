import { getUrl, Urls } from 'app/UrlService'
import { fetchWithAuth } from 'data/utils/utils'

export const fillCacheForObject = async (objectId: string) => {
    await fetchWithAuth(`cache/object/${objectId}/fill`, {
        method: 'POST',
    })
}

export const fillCacheForConnection = async (connectionId: string) => {
    await fetchWithAuth(`cache/connection/${connectionId}/fill`, {
        method: 'POST',
    })
}

export const fillCacheForStack = async () => {
    await fetchWithAuth(`cache/stack/fill`, {
        method: 'POST',
    })
}

const READ_ONLY_FIELDS_AIRTABLE = new Set([
    'lookup',
    'formula',
    'count',
    'rollup',
    'createdTime',
    'autoNumber',
    'lastModifiedTime',
    'barcode',
    'collaborator',
    'multiCollaborator',
])
const READ_ONLY_SYNTHETIC_FIELDS_STACKER_DATA = new Set<SyntheticFieldType>([
    'created_at',
    'updated_at',
    'created_by',
    'updated_by',
    'record_id',
])

export const isReadOnlyField = (field: FieldDto): boolean => {
    const connectionOptions = field.connection_options
    if (!connectionOptions) return false

    const airtableType = connectionOptions.airtable_column_type

    // Get Airtable read only types
    if (airtableType) {
        return READ_ONLY_FIELDS_AIRTABLE.has(airtableType)
    }

    const stackerDataType = field.synthetic_field_type
    if (stackerDataType && READ_ONLY_SYNTHETIC_FIELDS_STACKER_DATA.has(stackerDataType)) {
        return true
    }

    // Check if field is marked read only (i.e. Stacker data fields)
    return field.is_read_only
}

export function getOAuthReturnUrl(
    dataConnection: DataConnectionDto,
    options: {
        redirectUrl?: string
        stack?: StackDto | null
        workspaceAccount?: Account | null
        currentUrl?: URL
    } = {}
): string {
    const { redirectUrl, stack } = options
    const currentUrl = options.currentUrl || new URL(window.location.href)
    const adminUrl =
        redirectUrl || (stack?.options?.enable_new_app_settings ? Urls.AdminModal : Urls.Admin)

    const delimiter = adminUrl.includes('?') ? '&' : '?'

    let origin = window.location.origin

    const params = new URLSearchParams()
    params.append('connected', dataConnection._sid)
    params.append('step', 'sync')
    params.append('provider', dataConnection.type)

    // forward existing url params to the redirect url
    if (currentUrl.search) {
        currentUrl.searchParams.forEach((value, key) => params.append(key, value))
    }

    // Redirect to the OAuth flow, which will redirect back to us when finished.
    return encodeURIComponent(
        `${origin}${getUrl(adminUrl, undefined)}${delimiter}${params.toString()}`
    )
}
