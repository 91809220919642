// @ts-strict-ignore
import React, { useCallback, useContext, useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'

import { AppUserContext } from 'app/AppUserContext'
import { DataConnectionEditorContextType } from 'features/admin/data-connector/dataConnectionEditorContext'

// @ts-ignore
import { Button, Checkbox, Text } from 'v2/ui'

import FullSchemaSync from './FullSchemaSync'
import { InitializeNewConnection } from './SimpleOAuthConnectionEditor'

const STEPS = {
    syncSchema: 'sync',
    importError: 'error',
}

const HANDLED_ERRORS = ['API_NOT_ENABLED_EXCEPTION']

/**
 * Connection editor for Salesforce.
 *
 * This editor uses the OAuth flow for creating the initial data connection and
 * then performs a full schema sync as opossed to the simple schema sync in
 * SimpleOAuthConnectionEditor.
 */
const SalesforceConnectionEditor: React.FC<{
    context: DataConnectionEditorContextType
}> = (props) => {
    const context = props.context
    const { createDataConnection, dataConnection, step, setStep, schemaSyncOnly } = context
    const [setImportError] = useState<any>()
    const [isSandbox, setIsSandbox] = useState(false)
    const { user } = useContext(AppUserContext)

    useEffect(() => {
        // if this is a freshly created data connection, start syncing
        if (dataConnection && !dataConnection.options.import_progress) {
            setStep(STEPS.syncSchema)
        }
    }, [dataConnection, setStep])

    const doCreateDataConnection = useCallback(() => {
        const options = { is_sandbox: isSandbox, import_all_tables: false }
        return createDataConnection({ options })
    }, [createDataConnection, isSandbox])

    // If we don't yet have a data connection, render the UI for creating one.
    // This will start the oauth flow
    if (!dataConnection) {
        context.setHideButtons(true)
        return (
            <InitializeNewConnection
                user={user}
                context={context}
                createDataConnection={doCreateDataConnection}
            >
                {({ initializeConnection }) => (
                    <>
                        <Checkbox
                            onChange={(e) => {
                                setIsSandbox(e.target.checked)
                            }}
                            value={isSandbox ? 'true' : undefined}
                            mb={4}
                        >
                            <Text variant="hint">Use Sandbox environment</Text>
                        </Checkbox>
                        <Text variant="hint">
                            We will import 12 standard objects from your Salesforce, you can enable
                            other tables later.
                        </Text>
                        <div>
                            <Button
                                style={{ marginTop: 16 }}
                                onClick={initializeConnection}
                                variant="adminPrimaryV4"
                                buttonSize="md"
                            >
                                Proceed
                            </Button>
                        </div>
                    </>
                )}
            </InitializeNewConnection>
        )
    } else if (step === STEPS.importError) {
        // we only handle the case when rest api is not enabled, everything else
        // will show up as a generic import error, this is handled by
        // FullSchemaSync below
        return (
            <Text>
                {' '}
                The REST API is not enabled for your Salesforce organization. Stacker uses the REST
                API to access your Salesforce data and can&apos;t connect without it.
            </Text>
        )
        // schemaSyncOnly is set when we are opening the UI just do do a schema sync
    } else if (step === STEPS.syncSchema || schemaSyncOnly) {
        // Ready to sync the schema
        return (
            <FullSchemaSync
                dataConnection={dataConnection}
                onStartSync={context.onStartSync}
                onError={(error, errorDetails) => {
                    context.onSyncError()
                    if (HANDLED_ERRORS.includes(error)) {
                        setStep(STEPS.importError)
                        setImportError({ error, errorDetails })
                    }
                }}
                onComplete={context.onSyncComplete}
            />
        )
    }

    // We're just editing an existing connection, DataConnectionEditor will display the name input
    return <SalesforceDetailEditor dataConnection={dataConnection} context={context} />
}

export default SalesforceConnectionEditor

type SalesforeDetailEditorProps = {
    dataConnection: DataConnectionDto
    context: DataConnectionEditorContextType
}
const SalesforceDetailEditor: React.FC<SalesforeDetailEditorProps> = (props) => {
    const { dataConnection, context } = props
    const { setNextHandler, updateDataConnection, setIsSaving, onClose } = context
    const [shouldImportAll, setShouldImportAll] = useState(
        dataConnection.options?.import_all_tables || false
    )
    const slowImport = shouldImportAll || dataConnection.options?.import_all_tables

    useEffect(() => {
        setNextHandler(() => {
            updateDataConnection({
                ...dataConnection,
                options: { ...dataConnection.options, import_all_tables: shouldImportAll },
            })
                .then(() => {
                    setIsSaving(false)
                    onClose()
                })
                .catch(Sentry.captureException)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsSaving, dataConnection, setNextHandler, updateDataConnection, shouldImportAll])

    return (
        <>
            <Checkbox
                onChange={(e) => {
                    setShouldImportAll(e.target.checked)
                }}
                value={shouldImportAll ? 'true' : undefined}
                defaultIsChecked={shouldImportAll}
                mb={4}
            >
                <Text variant="hint">Import all tables</Text>
            </Checkbox>
            <Text
                variant="hint"
                style={{ maxWidth: 313 }}
                fontWeight={slowImport ? 'bold' : undefined}
            >
                If your Salesforce environment has many custom tables, importing all tables can take
                a while.
            </Text>
        </>
    )
}
