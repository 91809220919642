import React from 'react'

import { Menu, MenuList } from '@chakra-ui/react'

import { MenuItem, Text } from 'v2/ui'

import { FloatingAddNewButtonTrigger } from './FloatingAddNewButtonTrigger'

type FloatingAddNewButtonMenuProps = Omit<
    React.ComponentPropsWithoutRef<typeof Menu>,
    'children'
> & {
    isDark?: boolean
    onClickAddTable?: () => void
    onClickAddDocument?: () => void
    onClickAddPage?: () => void
    onClickTrigger?: () => void
}

export const FloatingAddNewButtonMenu: React.FC<FloatingAddNewButtonMenuProps> = ({
    isDark,
    onClickAddTable,
    onClickAddPage,
    onClickTrigger,
    ...props
}) => {
    return (
        <Menu placement="top-start" {...props} offset={[0, 16]}>
            {({ isOpen }) => (
                <>
                    <FloatingAddNewButtonTrigger
                        isActive={isOpen}
                        isDark={isDark}
                        onClick={onClickTrigger}
                    />
                    <MenuList
                        boxShadow="1px 1px 3px 1px rgba(0,0,0,0.3)"
                        pt={4}
                        px={3}
                        pb={1}
                        minWidth="15.5rem"
                    >
                        <Text fontWeight="bold" mb={3} fontSize={300}>
                            What would you like to add?
                        </Text>

                        {onClickAddTable && (
                            <FloatingMenuItem icon="table" onClick={onClickAddTable}>
                                Table
                            </FloatingMenuItem>
                        )}
                        {onClickAddPage && (
                            <FloatingMenuItem icon="file" onClick={onClickAddPage}>
                                Custom page
                            </FloatingMenuItem>
                        )}
                    </MenuList>
                </>
            )}
        </Menu>
    )
}

const FloatingMenuItem = React.forwardRef<
    HTMLButtonElement,
    React.ComponentPropsWithRef<typeof MenuItem>
>((props, ref) => {
    return (
        <MenuItem
            fontSize="sm"
            px={2}
            my={1}
            iconColor="userInterface.accent.1000"
            _focus={{
                bg: 'userInterface.accent.100',
            }}
            rounded={4}
            {...props}
            ref={ref}
        />
    )
})
