import { invalidateDataConnections } from 'data/hooks/dataConnections'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'

export const unlinkAirtableToken = async (dc: DataConnectionDto): Promise<DataConnectionDto> => {
    return await fetchWithAuth(`data-connections/${dc._sid}/unlink_token/`, {
        method: 'POST',
    }).then((response) => {
        invalidateDataConnections()
        return response.json()
    })
}
