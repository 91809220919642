// @ts-strict-ignore
import React, { useCallback, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useAuthContext } from 'app/AuthContext/AuthContext'
import { useDataConnectionActions } from 'data/hooks/dataConnections'
import DataConnectionEditor from 'features/admin/data-connector/DataConnectionEditor'
import { getProvidersForOnboarding } from 'features/admin/data-connector/DataConnectors'
import { ProviderTypes } from 'features/admin/data-connector/dataConnectorTypes'
import DATA_PROVIDERS from 'features/admin/data-connector/dataProviderConfig'
import analytics from 'utils/analytics'
import useTrack from 'utils/useTrack'

import { Flex } from 'v2/ui'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'
import useUrlParam from 'v2/ui/utils/useUrlParam'

import { ConnectionModalMode, ProviderNameWithIconHeader } from './ConnectionModalHeader'
import { ModalWithHint } from './ModalWithHint'
import { ProviderPicker } from './ProviderPicker'
import { ProviderPickerItem } from './providerPickerTypes'
import { ResponsiveNewConnectionModal } from './ResponsiveNewConnectionModal'

export type NewConnectionModalProps = {
    isOpen: boolean
    provider: ProviderTypes
    onClose: (data?: DataConnectionDto) => void
    onUpdateDataConnectionError?: () => void
    dataConnection: DataConnectionDto | null
    redirectUrl?: string
    isAppCreateFlow?: boolean
    disableClose?: boolean
    stack: StackDto | null
    onSyncStarted?: () => Promise<void>
    workspaceAccount?: any
    demoApps?: {
        onFinishedCreatingDemoApp: (stack: any) => void
        apps: ProviderPickerItem<string>[] | 'LOADING'
    }
    lastStepButtonText?: string
}

export const NewConnectionModal: React.FC<NewConnectionModalProps> = ({
    isOpen,
    provider: initialProvider,
    onClose,
    onUpdateDataConnectionError,
    dataConnection: suppliedConnection,
    redirectUrl,
    isAppCreateFlow,
    disableClose,
    stack,
    onSyncStarted,
    workspaceAccount,
    demoApps,
    lastStepButtonText = 'Done',
}) => {
    const [dataConnectionParam, setDataConnectionParam] = useUrlParam<ProviderTypes>(
        'data',
        initialProvider
    )
    const [, setConnected] = useUrlParam('connected', '')
    const [dataConnection, setDataConnection] = useState<DataConnectionDto | null>(
        suppliedConnection
    )
    const [isSyncing, setIsSyncing] = useState(false)

    React.useEffect(() => setDataConnection(suppliedConnection), [suppliedConnection])

    const provider = dataConnectionParam || initialProvider
    const isMobile = useIsMobile()
    const [mobileState, setMobileState] = useState(isMobile)
    const { selectedStack } = useAppContext()
    const { user } = useAuthContext()
    const { track } = useTrack()

    const handleClose = useCallback(
        (data?: DataConnectionDto) => {
            track('WIP - Frontend - Data Connection - Creation - Closed', {
                data_connection_type: data?.type,
            })

            onClose(data)
        },
        [track, onClose]
    )
    // This prevents the sync from restarting when the screen resizes
    if (isMobile !== mobileState && !isSyncing) {
        setMobileState(isMobile)
    }

    const getInitialHintImage = (id: ProviderTypes) => {
        const provider = DATA_PROVIDERS[id]
        const nonResponsiveImage =
            provider.hintImagesUrls !== undefined ? provider.hintImagesUrls[0] : undefined
        if (mobileState) {
            return provider.responsiveHintImageUrls !== undefined
                ? provider.responsiveHintImageUrls[0]
                : nonResponsiveImage
        }
        return nonResponsiveImage
    }

    const [selectedProvider, setSelectedProvider] = useState<ProviderTypes>(provider)

    const [hintImageUrl, setHintImageUrl] = useState<string | undefined>(
        getInitialHintImage(selectedProvider)
    )

    const [modalHidden, setModalHidden] = useState(false)
    const {
        create: createDataConnection,
        update: updateDataConnection,
    }: { create: any; update: any } = useDataConnectionActions()

    const body = (
        <Flex column align="stretch" width="100%">
            <DataConnectionEditor
                key={selectedProvider}
                onStartSync={() => {
                    setIsSyncing(true)
                    if (onSyncStarted) onSyncStarted()
                }}
                onChangeHintImage={setHintImageUrl}
                provider={selectedProvider}
                createDataConnection={createDataConnection}
                dataConnection={dataConnection}
                updateDataConnection={updateDataConnection}
                setModalHidden={setModalHidden}
                onClose={handleClose}
                onUpdateDataConnectionError={onUpdateDataConnectionError}
                isNew={true}
                redirectUrl={redirectUrl}
                isAppCreateFlow={isAppCreateFlow}
                lastStepButtonText={lastStepButtonText}
            />
        </Flex>
    )
    if (mobileState) {
        return (
            <ResponsiveNewConnectionModal
                isOpen={isOpen}
                allowBackButton={!isSyncing}
                onClose={handleClose}
                showCloseButton={!disableClose}
                isAppCreateFlow={isAppCreateFlow}
                workspaceAccount={workspaceAccount}
                hintImageUrl={hintImageUrl}
                stack={stack}
                onProviderChange={(id: ProviderTypes | 'NOT_SELECTED') => {
                    setConnected('')
                    setDataConnection(null)
                    if (id !== 'NOT_SELECTED') {
                        setDataConnectionParam(id, true)
                        setSelectedProvider(id)
                        setHintImageUrl(getInitialHintImage(id))
                    }
                }}
                provider={dataConnectionParam}
                serviceName={dataConnection?.service_name}
                demoApps={demoApps}
            >
                {body}
            </ResponsiveNewConnectionModal>
        )
    }

    return (
        <ModalWithHint
            showCloseButton={!disableClose}
            isOpen={isOpen}
            isHidden={modalHidden}
            onClose={handleClose}
            hintImageUrl={hintImageUrl}
            headerText={isAppCreateFlow && !isSyncing ? 'Add data from...' : null}
        >
            {(!isAppCreateFlow || isSyncing) && (
                <ProviderNameWithIconHeader
                    customTitle={dataConnection?.label}
                    serviceName={dataConnection?.service_name}
                    type={selectedProvider}
                    mode={isSyncing ? ConnectionModalMode.Sync : ConnectionModalMode.Create}
                />
            )}
            {isAppCreateFlow && !isSyncing && (
                <Flex alignItems="center" style={{ width: '100%', minWidth: 400 }}>
                    <ProviderPicker
                        items={getProvidersForOnboarding(stack)}
                        selected={selectedProvider}
                        onClick={(id) => {
                            setConnected('')
                            setDataConnectionParam(id, true)
                            setDataConnection(null)
                            setSelectedProvider(id)
                            setDataConnection(null)
                            setHintImageUrl(getInitialHintImage(id))

                            analytics.track('app data source clicked', {
                                workspace_id: user?.account_id,
                                user_id: user?._sid,
                                app_id: selectedStack?._sid,
                                event_category: 'app',
                                event_description: 'App data source was clicked from onboarding ',
                                datasource_type: selectedProvider,
                            })
                        }}
                    />
                </Flex>
            )}
            {body}
        </ModalWithHint>
    )
}

export default NewConnectionModal
