import React, { useContext, useEffect, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import AppUserContext from 'app/AppUserContext'
import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getAbsoluteWorkspaceRootUrl } from 'app/UrlService'
import { useCreateAccount } from 'data/hooks/accounts'
import analytics from 'utils/analytics'

import { Button, Collapse, Flex, Input, Modal, Text } from 'v2/ui'
import { NewWorkspace as NewWorkspaceIcon } from 'v2/ui/svgs'
import useModalToggle, { useModalDeclaration } from 'v2/ui/utils/useModalToggle'

// List of features to keep from existing account
const KEEP_FEATURES = ['stacker_data_onboarding'] as const

const MODAL_KEY = 'CreateWorkspace'
export const useCreateWorkspaceModal = () => {
    return useModalDeclaration(MODAL_KEY, CreateWorkspaceModal)
}

const CreateWorkspaceModal = () => {
    const modal = useModalToggle(MODAL_KEY)
    const { isOpen, toggle } = modal
    const { user } = useAuthContext()
    const { workspaceAccount } = useAppContext()
    const [workspaceName, setWorkspaceName] = useState<string | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const { mutateAsync: createAccount } = useCreateAccount()
    const { isPreviewingAsUserOrRole } = useContext(AppUserContext)
    const [isDisabled, setIsDisabled] = useState(false)

    const handleSave = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setIsDisabled(true)
        e.preventDefault()

        if (!workspaceName) {
            setErrorMessage('Please enter a workspace name')
            return
        }

        analytics.track('workspace created submitted', {
            workspace_name: workspaceName,
            is_impersonating: isPreviewingAsUserOrRole,
            user_id: user?._sid,
            event_description: 'New workspace created submitted',
            event_category: 'workspace',
        })

        // Copy over features that we would like to keep
        const features: Partial<AccountOptionalFeatures> = {}
        KEEP_FEATURES.forEach((feature) => {
            if (workspaceAccount) {
                features[feature] = workspaceAccount.optional_features[feature]
            }
        })

        // Preserve signup params so that the same onboarding flow is triggered
        const signupFlow = workspaceAccount?.options?.signup_params?.flow

        const data = {
            name: workspaceName,
            options: {
                signup_params: {
                    for: workspaceAccount?.options?.signup_params?.for ?? 'stacker_data',
                    ...(signupFlow ? { flow: signupFlow } : {}),
                },
            },
            optional_features: features,
        }
        return createAccount(data)
            .then((newAccount) => {
                const redirectUrl = getAbsoluteWorkspaceRootUrl(newAccount)
                window.location.assign(redirectUrl)
            })
            .catch((error) => {
                console.error(error)
                if (error.json) {
                    error.json().then((body: any) => {
                        setErrorMessage(body?.name?.join(', ') || 'An error occurred')
                    })
                } else {
                    setErrorMessage('An error occured')
                }
                setIsDisabled(false)
            })
    }

    useEffect(() => {
        if (isOpen) {
            setErrorMessage(null)
            setWorkspaceName(null)
        }
    }, [isOpen])

    return (
        <Modal
            isSecondLayer
            isOpen={isOpen}
            onClose={toggle}
            noDividers
            size={'400px'}
            body={
                <form>
                    <Flex justifyContent="center">
                        <NewWorkspaceIcon />
                        <Text variant="modalTitle" mt={6} fontWeight="bold">
                            Create new workspace
                        </Text>
                        <Text mt={4}>
                            Create a new workspace with custom apps and invite users.{' '}
                        </Text>

                        {workspaceAccount && (
                            <Text mt={4}>Your current apps remain unaffected.</Text>
                        )}

                        <Text mt={6} width="100%" textAlign="left" fontWeight="bold">
                            Workspace name
                        </Text>
                        <Input
                            mt={2}
                            size="sm"
                            alignSelf="stretch"
                            placeholder="Enter name"
                            value={workspaceName}
                            maxLength={30}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setWorkspaceName(e.target.value)
                            }
                            autoFocus
                            variant="admin"
                            width="100%"
                            data-testid="create-workspace-modal.name-input"
                        />
                        <Collapse isOpen={!!errorMessage} mt={2} width="100%">
                            <Text variant="error" size="sm" width="100%" textAlign="left">
                                {errorMessage}
                            </Text>
                        </Collapse>
                        <Button
                            variant="adminPrimary"
                            buttonSize="sm"
                            mt={4}
                            width="100%"
                            type="submit"
                            onClick={handleSave}
                            disabled={isDisabled}
                        >
                            Create workspace
                        </Button>
                        <Text mt={4} color="gray.400" fontSize="sm">
                            Enjoy a free 30-day trial; add billing information later to maintain
                            access.
                        </Text>
                    </Flex>
                </form>
            }
        ></Modal>
    )
}
