export type AccountRight =
    | 'create_apps'
    | 'invite_users'
    | 'manage_users'
    | 'manage_billing'
    | 'contact_support'
    | 'view_settings'
    | 'manage_settings'
    | 'custom_scripts'
    | 'delete_workspace'

export type AccountRoleType = 'member' | 'owner' | 'portal_user'

export const BuiltInAccountRoleNames: { [key in Uppercase<AccountRoleType>]: AccountRoleType } = {
    MEMBER: 'member',
    OWNER: 'owner',
    PORTAL_USER: 'portal_user',
}
