// @ts-strict-ignore
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { fillCacheForConnection, fillCacheForObject } from 'features/admin/data-connector/utils'

import { CACHE_INFO_LIST_NAME } from './cacheInfo/cacheInfoConstants'
import { invalidateObjects } from './objects/objectOperations'
import { queryClient, useQuery } from './_helpers'

const ENDPOINT = 'cache/status'

const DEFAULT_REFETCH_INTERNAL = 60000

type ObjectCacheStatus = {
    cached: boolean
    enabled: boolean
    latest_cache_duration?: string
    latest_cache_records?: number
    latest_cache_time?: string
    load_in_progress: boolean
    manual_fill_requested: boolean
    name: string
    records_cached: number
    sid: string
    fill_pending: boolean
}

type Return = {
    objectStatuses: { [keyof: string]: ObjectCacheStatus }
    isLoading: boolean
    isFetching: boolean
    startCacheFill: (dataConnectionid: string) => void
    startCacheFillForObject: (objectId: string) => void
}
type Options = {
    refetchInterval?: number
    disabled?: boolean
}
const DefaultOptions: Options = {
    refetchInterval: DEFAULT_REFETCH_INTERNAL,
}
export function useObjectCache({
    refetchInterval: suppliedRefetchInterval = DEFAULT_REFETCH_INTERNAL,
    disabled,
}: Options = DefaultOptions): Return {
    const fillPendingBegin = useRef<number>(0)
    const [refetchInterval, setRefetchInterval] = useState<number>(suppliedRefetchInterval)
    const { data, isLoading, isFetching, dataUpdatedAt } = useQuery<{
        objects: ObjectCacheStatus[]
    }>(
        CACHE_INFO_LIST_NAME,
        ENDPOINT,
        {
            refetchInterval,
            refetchIntervalInBackground: true,
            enabled: !disabled,
        },
        { bypassPreviewAs: true }
    )

    const objects = data?.objects
    const startCacheFill = useCallback((dataConnectionid) => {
        fillCacheForConnection(dataConnectionid).then(() => {
            queryClient.invalidateQueries(CACHE_INFO_LIST_NAME)
        })
    }, [])
    const startCacheFillForObject = useCallback((objectId) => {
        fillCacheForObject(objectId).then(() => {
            queryClient.invalidateQueries(CACHE_INFO_LIST_NAME)
        })
    }, [])

    useEffect(() => {
        // If we have a pending fill, update local state so we start
        // refreshing more often.
        const fillPending = Boolean(
            data?.objects?.find((o) => o.load_in_progress || o.manual_fill_requested)
        )
        // Set a timestamp when we first begin waiting on a fill
        if (fillPending && !fillPendingBegin.current) {
            fillPendingBegin.current = fillPending ? Date.now() : 0
        } else if (!fillPending) {
            if (fillPendingBegin.current !== 0) {
                // refetch the objects because flags such as fill_needed_for_field_changes
                // may have been changed post cache fill
                invalidateObjects()
            }
            // and clear it when we're no longer waiting
            fillPendingBegin.current = 0
        }

        // If the caller doesn't want us to automatically refetch, then exit now
        if (suppliedRefetchInterval === 0) return

        if (fillPending) {
            // If fill is pending we want to check for updates more frequently.
            // Otherwise pinging once per minute is enough.
            // The check interval is half of the time we've been waiting for the fill.
            // with a minimum of 1s and a max of 20 seconds.
            const newInterval = Math.min(
                Math.max(1000, (Date.now() - fillPendingBegin.current) / 2),
                20000
            )
            setRefetchInterval(newInterval)
        } else {
            setRefetchInterval(suppliedRefetchInterval)
        }
    }, [data, dataUpdatedAt, suppliedRefetchInterval])

    const result = useMemo(
        () => ({
            objectStatuses: objects
                ? objects.reduce(
                      (dict, obj) => ({
                          ...dict,
                          [obj.sid]: {
                              ...obj,
                              // manual_fill_requested is set when the fill is requested,
                              // whereas load_in_progress isn't set until the celery job is
                              // picked up
                              fill_pending: obj.manual_fill_requested || obj.load_in_progress,
                          },
                      }),
                      {}
                  )
                : {},
            isLoading,
            isFetching,
            startCacheFill,
            startCacheFillForObject,
        }),

        [objects, isLoading, startCacheFill, isFetching, startCacheFillForObject]
    )

    return result
}
