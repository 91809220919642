import React from 'react'

import get from 'lodash/get'

import { useAppContext } from 'app/AppContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { useDeleteStack, useStacks } from 'data/hooks/stacks'
import useLDFlags from 'data/hooks/useLDFlags'
import { Divider, Section } from 'legacy/v1/ui'

import { Toast } from 'v2/ui'

import { AppearanceAppColorAndIcon } from './AppearanceAppColorAndIcon'
import { ChangeUrlSetting } from './ChangeUrlSetting'
import { DeleteAppSetting } from './DeleteAppSetting'
import { ExposePermanentLoginLinksSetting } from './ExposePermanentLoginLinks'
import { RenameAppSetting } from './RenameAppSetting'

const AppSettingsModalGeneralSettings = () => {
    const { mutateAsync: deleteStack } = useDeleteStack()
    const { data: stacks } = useStacks()
    const { selectedStack } = useAppContext()

    const { workspaceZone } = useWorkspaceContext()

    const { flags } = useLDFlags()
    const [state, setState] = React.useState({
        name: selectedStack.name,
        url: selectedStack.url_slug,
        supportLogin: get(selectedStack, 'options.permit_support_login'),
        newCreateForm: get(selectedStack, 'options.new_create_form'),
        createFormModal: get(selectedStack, 'options.create_form_modal'),
        showSavedToast: false,
    })

    if (!selectedStack._sid) return ''

    const isStackSubscribed = selectedStack.isSubscribed

    return (
        <>
            <Section style={{ width: '100%' }}>
                <AppearanceAppColorAndIcon selectedStack={selectedStack} />
                <Divider margin="none" />

                <RenameAppSetting
                    selectedStack={selectedStack}
                    onSaveError={() => setState((prevState) => ({ ...prevState, error: true }))}
                    onSave={() => setState((prevState) => ({ ...prevState, showSavedToast: true }))}
                ></RenameAppSetting>
                <Divider margin="none" />

                <ChangeUrlSetting
                    selectedStack={selectedStack}
                    stacks={stacks}
                    confirmModalOpen={() =>
                        setState((prevState) => ({ ...prevState, confirmModalOpen: true }))
                    }
                ></ChangeUrlSetting>
                <Divider margin="none" />

                <DeleteAppSetting
                    selectedStack={selectedStack}
                    stacks={stacks}
                    deleteStack={deleteStack}
                    isStackSubscribed={isStackSubscribed}
                />
                <Divider margin="none" />

                {flags?.permanentLoginLinks && workspaceZone.enable_permanent_login_links && (
                    <>
                        <ExposePermanentLoginLinksSetting selectedStack={selectedStack} />
                        <Divider margin="none" />
                    </>
                )}
            </Section>
            <Toast
                status="error"
                show={state.error}
                title="Sorry, there was an error. Please try again."
            />
            <Toast
                show={state.showSavedToast}
                onCloseComplete={() => {
                    setState((prevState) => ({ ...prevState, showSavedToast: false }))
                }}
                title="Changes saved"
            />
        </>
    )
}

export default AppSettingsModalGeneralSettings
