import { useCallback, useMemo, useRef } from 'react'

import { ListViewSort } from 'features/views/ListView/Sort/types'
import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'
import { TableViewColumn } from 'features/views/ListView/TableView/types'

import { IconName } from 'ui/components/Icon/Icon'

export type TableHeaderSortDirection = 'asc' | 'desc' | undefined

type UseTableViewHeaderSortStateOptions = {
    column: TableViewColumn
}

export function useTableViewHeaderSortState(options: UseTableViewHeaderSortStateOptions) {
    const { column } = options

    const { sortBy, setSortBy, defaultSortBy } = useTableViewContext()

    const sortDirection = getSortDirection(column, sortBy)
    const sortDirectionRef = useRef(sortDirection)
    sortDirectionRef.current = sortDirection

    const sortByRef = useRef(sortBy)
    sortByRef.current = sortBy

    const defaultSortByRef = useRef(defaultSortBy)
    defaultSortByRef.current = defaultSortBy

    const toggleSortDirection = useCallback(() => {
        const sortBy = sortByRef.current
        const sortDirection = sortDirectionRef.current
        const defaultSortBy = defaultSortByRef.current

        switch (sortDirection) {
            case 'asc':
                setSortBy({ id: column.field.api_name, desc: true })
                break
            case 'desc':
                if (isDefaultSortBy(sortBy, defaultSortBy)) {
                    // If this is the default sort column, the sort field will not be removed
                    // if we reset the override. The default sortBy will be used instead of
                    // having an empty `sortBy`. Setting it to asc instead.
                    setSortBy({ id: column.field.api_name, desc: false })
                } else {
                    setSortBy(undefined)
                }
                break
            default:
                setSortBy({ id: column.field.api_name, desc: false })
        }
    }, [column.field.api_name, setSortBy])

    const sortIcon = getSortIcon(sortDirection)

    return useMemo(
        () => ({
            sortDirection,
            toggleSortDirection,
            sortIcon,
        }),
        [sortDirection, toggleSortDirection, sortIcon]
    )
}

function getSortDirection(
    column: TableViewColumn,
    sortBy?: ListViewSort
): TableHeaderSortDirection {
    if (sortBy?.id !== column.field.api_name) return undefined

    if (sortBy.desc) return 'desc'
    return 'asc'
}

function getSortIcon(sortDirection: TableHeaderSortDirection): IconName {
    switch (sortDirection) {
        case 'asc':
            return 'ArrowUpWideNarrow'
        case 'desc':
            return 'ArrowDownWideNarrow'
        default:
            return 'ArrowUpDown'
    }
}

function isDefaultSortBy(sortBy?: ListViewSort, defaultSortBy?: ListViewSort) {
    if (!sortBy || !defaultSortBy) return false

    return sortBy.id === defaultSortBy.id && sortBy.desc === defaultSortBy.desc
}
