import { getLocale } from 'utils/dateUtils'

type UsePercentageAttributeDisplayStateProps = {
    value?: number
    isLoading?: boolean
}

export function usePercentageAttributeDisplayState({
    value,
    isLoading,
}: UsePercentageAttributeDisplayStateProps) {
    const effectiveValue = isLoading ? PLACEHOLDER_VALUE : value

    const content = formatPercentageValue(effectiveValue)
    const cappedValue = (effectiveValue ?? 0) * 100.0

    return { content, cappedValue }
}

const MAX_FRACTION_DIGITS = 2

function formatPercentageValue(initialValue?: number): string {
    let value = initialValue
    if (typeof value !== 'number' || Number.isNaN(value)) {
        value = 0
    }

    const locale = getLocale()
    const formatter = new Intl.NumberFormat(locale, {
        useGrouping: true,
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: MAX_FRACTION_DIGITS,
    })
    const formattedValue = formatter.format(value)

    return formattedValue
}

const PLACEHOLDER_VALUE = 0.1234
