import { useCallback, useMemo, useState } from 'react'

import { ActionMenuEditProps } from 'features/views/ListView/Actions/types'

import useDebounce from 'v2/ui/utils/useDebounce'

import { truncateText } from 'ui/helpers/utilities'

const MAX_LABEL_LENGTH = 25
const DEBOUNCE_RATE = 200

type UseActionMenuEditPercentageStateOptions = ActionMenuEditProps<number> & {}

export function useActionMenuEditPercentageState(options: UseActionMenuEditPercentageStateOptions) {
    const { field, updateRecord, value } = options

    const fieldLabel = truncateText(field.label, MAX_LABEL_LENGTH)

    const debouncedUpdateRecord = useDebounce(updateRecord, DEBOUNCE_RATE, [updateRecord])

    const onEditValue = useCallback(
        (value: number) => {
            const formattedValue = value / 100

            setCurrentValue(value)
            debouncedUpdateRecord({ [field.api_name]: formattedValue })
        },
        [field.api_name, debouncedUpdateRecord]
    )

    const effectiveValue = value ? value * 100 : 0
    const [currentValue, setCurrentValue] = useState<number>(effectiveValue)

    return useMemo(
        () => ({ fieldLabel, onEditValue, value: currentValue }),
        [fieldLabel, onEditValue, currentValue]
    )
}
