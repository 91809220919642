import React, { FC } from 'react'

import {
    InvalidBlock,
    InvalidBlockProps,
} from 'v2/blocks/blockTypes/view/aggregationBlocks/common/InvalidBlock'

import { EmptyMetricsBlockEdit } from 'v2/ui/svgs'

type InvalidMetricBlockProps = Pick<
    InvalidBlockProps,
    'numberOfMetrics' | 'isEditing' | 'isActive' | 'isOnListView' | 'onClick'
>

import { METRIC_BLOCK_HEIGHT } from './constants'

export const InvalidMetricBlock: FC<InvalidMetricBlockProps> = ({
    numberOfMetrics,
    isEditing,
    isActive,
    isOnListView,
    onClick,
}) => (
    <InvalidBlock
        blockType="metric"
        height={METRIC_BLOCK_HEIGHT}
        numberOfMetrics={numberOfMetrics}
        isEditing={isEditing}
        isActive={isActive}
        isOnListView={isOnListView}
        narrowTitle="Configure your settings to display this number."
        wideTitle="This number is not ready yet"
        wideSubtitle="Configure your settings to display it."
        Illustration={EmptyMetricsBlockEdit}
        onClick={onClick}
    />
)

export default InvalidMetricBlock
