import React from 'react'

import { ProviderIcon } from 'features/admin/data-connector/ProviderIcon'

import { Icon } from 'v2/ui'

import { LinkFieldLabel } from './LinkFieldLabel'

/**
 *
 * @param {{ style?:object, field: FieldReferenceView, source?: boolean, to?: string}} param0
 * @returns
 */
export const LinkFieldReference = ({ style, field, source = false }) => (
    <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
            <ProviderIcon
                type={field.connectionType}
                serviceName={field.connectionServiceName}
                display="inline-block"
                verticalAlign="middle"
                mr={3}
            />
            <Icon
                size="14px"
                display="inline-block"
                verticalAlign="middle"
                mr={3}
                color="grey.300"
                icon="caretRight"
            />
            <b style={{ fontSize: 14 }}>{field.objectName}</b>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <LinkFieldLabel showLink={source} muted={true} bold={true}>
                <small>{field.fieldName}</small>
            </LinkFieldLabel>
        </div>
    </div>
)
