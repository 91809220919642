import React from 'react'

import { Spinner } from '@chakra-ui/react'

import STYLE_CLASSES from 'v2/ui/styleClasses'

import Button from './Button'
import Dropdown from './Dropdown'
import Flex from './Flex'
import Text from './Text'

const Pagination = ({
    pageIndex,
    canPreviousPage,
    canNextPage,
    pageCount,
    compact,
    gotoPage,
    isLoading,
    pageSizeOptions,
    setPageSize,
    pageSize,
}) => {
    const ArrowText = ({ children, ...props }) => (
        <Text
            p={0}
            fontWeight="pagination.textArrow"
            size={['pagination.textArrow', null, 'pagination.textArrowLg']}
            color="pagination.textArrow"
            as="span"
            {...props}
        >
            {children}
        </Text>
    )

    const PrevArrow = () => (
        <>
            <Button
                variant="clear"
                icon="arrowLeft"
                pr={2}
                disabled={!canPreviousPage}
                onClick={() => gotoPage(pageIndex - 1)}
            >
                {!compact && (
                    <ArrowText className={STYLE_CLASSES.PAGINATION_BUTTON_BACK} pl={3}>
                        Back
                    </ArrowText>
                )}
            </Button>
        </>
    )

    const NextArrow = () => (
        <>
            <Button
                variant="clear"
                icon="arrowRight"
                iconAlign="right"
                disabled={!canNextPage}
                onClick={() => gotoPage(pageIndex + 1)}
                pl={2}
            >
                {!compact && (
                    <ArrowText className={STYLE_CLASSES.PAGINATION_BUTTON_NEXT} pr={3}>
                        Next
                    </ArrowText>
                )}
            </Button>
        </>
    )

    const PageOf = () => (
        <Flex>
            <Text variant="pagination">
                {pageCount > 0 ? pageIndex + 1 : 0} of {pageCount}
            </Text>

            {pageSizeOptions && (
                <Flex mx={2}>
                    <Text mr={2}>Show</Text>

                    <Dropdown
                        value={pageSize}
                        options={pageSizeOptions.map((x) => ({ label: x, value: x }))}
                        onChange={setPageSize}
                        width={75}
                        padding="2px"
                        isSearchable={false}
                        isClearable={false}
                        optionHeight={24}
                    />
                    <Text mx={2}>records per page</Text>
                </Flex>
            )}

            {isLoading && <Spinner size="sm" color="gray.300" />}
        </Flex>
    )

    if (compact)
        return (
            <Flex justify="space-between">
                <PrevArrow />
                <PageOf />
                <NextArrow />
            </Flex>
        )

    return (
        <Flex justify="space-between">
            <PageOf />
            <Flex py="1rem">
                <PrevArrow />
                <NextArrow />
            </Flex>
        </Flex>
    )
}

export default React.memo(Pagination)
