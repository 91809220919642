// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import queryString, { ParsedQs } from 'qs'

import { useAppContext } from 'app/AppContext'
import { useAuthContext } from 'app/AuthContext/AuthContext'
import { assertIsDefined } from 'data/utils/ts_utils'
import { buildUrl } from 'data/utils/utils'
import analytics from 'utils/analytics'

// @ts-ignore
import { Button, Collapse, Flex, Input, Link, LoadingScreen, Text } from 'v2/ui'

import {
    DataConnectionEditorContext,
    DataConnectionEditorContextType,
} from './dataConnectionEditorContext'
import { isProviderDisabled, RenderEditor } from './DataConnectors'
import { ProviderTypes } from './dataConnectorTypes'
import DATA_PROVIDERS from './dataProviderConfig'
import { getOAuthReturnUrl } from './utils'

export type DataConnectionEditorProps = {
    onChangeHintImage: (url?: string) => void
    provider: ProviderTypes
    dataConnection: DataConnectionDto | null
    onClose: (data?: DataConnectionDto) => void
    onUpdateDataConnectionError?: () => void
    setModalHidden: (isHidden: boolean) => void
    isNew: boolean
    createDataConnection: (dc: DataConnectionDto) => Promise<DataConnectionDto>
    updateDataConnection: (sid: string, data: any, options?: any) => Promise<DataConnectionDto>

    onDelete?: () => void
    onStartSync?: () => void
    redirectUrl?: string
    isAppCreateFlow?: boolean
    // TODO this can not be removed ATM because handled errors would now show correctly
    // FIX: show erros inside <FullSchemaSync /> by adding those erros to the DataConnector
    // something like this: dataConnector.handledErrors.includes(error) ? custom_errro : generic error
    schemaSyncOnly?: boolean
    lastStepButtonText?: string
}

/*
This is component provides the DataConnection editing frame and context. It handles
editing the display name of the DC and creating/updating the DC. It renders the
DC editor component as specified in DataConnectors.js and supplies that component
with context.

See further documentation here:
https://www.notion.so/stacker/Data-Connection-UI-e245ec42b2134e64b3412514d3c8f6f0
*/
const DataConnectionEditor: React.FC<DataConnectionEditorProps> = ({
    onChangeHintImage,
    provider,
    dataConnection: suppliedConnection,
    createDataConnection,
    updateDataConnection,
    onClose,
    onUpdateDataConnectionError,
    isNew,
    onDelete,
    setModalHidden,
    redirectUrl,
    isAppCreateFlow,
    onStartSync,
    schemaSyncOnly,
    lastStepButtonText = 'Done',
}) => {
    const { name: providerName, splashMessage } = DATA_PROVIDERS[provider]
    const [nextHandler, setNextHandler] = useState<(() => void) | undefined>()
    const [dataConnection, setDataConnection] = useState(suppliedConnection)
    const [initializationComplete, setInitializationComplete] = useState<boolean>(false)
    const [saveError, setSaveError] = useState<string | undefined>()
    const [title, setTitle] = useState<string>(dataConnection?.label || providerName)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [isTesting, setIsTesting] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(false)
    const [loadingText, setLoadingText] = useState<string | undefined>()
    const [step, setStep] = useState<undefined | string | string[] | ParsedQs | ParsedQs[]>()
    const [nextButtonText, setNextButtonText] = useState(
        suppliedConnection ? 'Save changes' : 'Next'
    )
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
    const [showCancel, setShowCancel] = useState(true)
    const [hideButtons, setHideButtons] = useState<boolean>(false)
    // control whether clicking next on schema-sync only will always close the modal
    const [shouldCloseOnSchemaSyncOnly, setShouldCloseOnSchemaSyncOnly] = useState(true)
    const [showSplash, setShowSplash] = useState(!!(!dataConnection && splashMessage))
    const history = useHistory()
    const location = useLocation()
    const { selectedStack, workspaceAccount } = useAppContext()
    const { user } = useAuthContext()
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        // If our querystring has a step arg, pull that out now and set state.
        // This step state can be used by the DC editor to pick up at the right
        // location after an OAuth redirect, for instance.
        const step = queryParams.get('step')
        if (step) {
            setStep(step)
        }
    }, [history, location.search])

    const setStepInternal = useCallback(
        (step: undefined | string | string[] | ParsedQs | ParsedQs[], updateHistory: boolean) => {
            setStep(step)
            if (updateHistory) {
                const query = queryString.parse(window.location.search, {
                    ignoreQueryPrefix: true,
                })
                query.step = step
                history.replace(buildUrl(window.location.pathname, query))
            }
        },
        [history]
    )

    // If a data connection was supplied, pull that into local state
    useEffect(() => setDataConnection(suppliedConnection), [suppliedConnection])

    const doCreateDataConnection = useCallback(
        (data): Promise<DataConnectionDto> => {
            // Note: we do not take the initiative of calling setDataConnection here after creating the connection.
            // We leave that up to the provider-specific editor to do when it is ready, because it may have things
            // it needs to do before updating state with the new DC.
            return createDataConnection({
                type: provider,
                label: title || providerName,
                updated_secret_options: {},
                ...data,
            })
                .then((dc) => {
                    analytics.track('app data source added', {
                        workspace_id: user?.account_id,
                        user_id: user?._sid,
                        app_id: selectedStack?._sid,
                        event_category: 'app',
                        event_description: 'App data source was added',
                        datasource_type: provider,
                    })
                    return dc
                })
                .catch((err) => {
                    setIsSaving(false)
                    setSaveError('An error occurred while creating the connection.')
                    analytics.track('app data source failed', {
                        workspace_id: user?.account_id,
                        user_id: user?._sid,
                        app_id: selectedStack?._sid,
                        event_category: 'app',
                        event_description: 'App data source failed to be added',
                        datasource_type: provider,
                    })
                    // Must be handled
                    throw err
                })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [createDataConnection, provider, title, providerName]
    )
    const doUpdateDataConnection = useCallback(
        (data?: any): Promise<DataConnectionDto> => {
            assertIsDefined(dataConnection)
            const titleChanged = title !== dataConnection.label

            if (data || titleChanged) {
                setIsSaving(true)
                assertIsDefined(updateDataConnection)
                return updateDataConnection(dataConnection._sid, {
                    label: title,
                    ...data,
                })
                    .then(() => dataConnection)
                    .catch((err) => {
                        setIsSaving(false)
                        setSaveError('An error occurred while saving changes.')

                        onUpdateDataConnectionError?.()
                        // Must be handled
                        throw err
                    })
            } else {
                return Promise.resolve(dataConnection)
            }
        },
        [
            updateDataConnection,
            onUpdateDataConnectionError,
            dataConnection,
            title,
            setIsSaving,
            setSaveError,
        ]
    )

    const handleNextClick = useCallback(() => {
        if (schemaSyncOnly && shouldCloseOnSchemaSyncOnly) {
            return onClose()
        }
        // If the DC editor has specified a handler for the Next button,
        // use it.
        if (nextHandler) {
            nextHandler()
            // Otherwise, we assume we should just update the data connection.
        } else {
            doUpdateDataConnection().then(onClose).catch(Sentry.captureException)
        }
    }, [schemaSyncOnly, shouldCloseOnSchemaSyncOnly, nextHandler, onClose, doUpdateDataConnection])

    const internalSetNextHandler = useCallback(
        (handler) => setNextHandler(() => handler),
        [setNextHandler]
    )

    // Build up our context state object which we supplied to the DC editing components
    const context = useMemo<DataConnectionEditorContextType>(
        (): DataConnectionEditorContextType => ({
            schemaSyncOnly,
            providerKey: provider,
            provider: DATA_PROVIDERS[provider],
            dataConnection,
            step,
            initializationComplete,
            setInitializationComplete,
            setNextHandler: internalSetNextHandler,
            setNextButtonText,
            setStep: setStepInternal,
            isSaving,
            setIsSaving,
            isTesting,
            setIsTesting,
            setDataConnection,
            updateDataConnection: doUpdateDataConnection,
            createDataConnection: doCreateDataConnection,
            setNextButtonDisabled,
            setModalHidden,
            hideButtons,
            setHideButtons,
            setShouldCloseOnSchemaSyncOnly,
            onClose,
            onStartSync: (loadingText?: string) => {
                if (loadingText) {
                    setIsLoading(true)
                    setLoadingText(loadingText)
                }
                onChangeHintImage(undefined)
                setHideButtons(true)
                setNextButtonDisabled(true)
                setShowCancel(false)
                if (onStartSync) {
                    onStartSync()
                }
            },
            onSyncComplete: () => {
                context.setIsSaving(false)
                context.setHideButtons(false)
                context.setNextButtonDisabled(false)
                setIsLoading(false)
                context.setInitializationComplete(true)
                setShowCancel(false)
            },
            onSyncError: () => {
                context.setHideButtons(false)
                context.setNextButtonDisabled(true)
                setShowCancel(true)
                context.setIsSaving(false)
                setIsLoading(false)
            },
            getOAuthReturnUrl: (dataConnection: DataConnectionDto) => {
                return getOAuthReturnUrl(dataConnection, {
                    redirectUrl,
                    stack: selectedStack,
                    workspaceAccount,
                })
            },
        }),
        [
            provider,
            dataConnection,
            step,
            initializationComplete,
            setInitializationComplete,
            setNextButtonText,
            setIsLoading,
            setLoadingText,
            setStepInternal,
            isSaving,
            setIsSaving,
            setDataConnection,
            doUpdateDataConnection,
            doCreateDataConnection,
            setNextButtonDisabled,
            setShowCancel,
            setModalHidden,
            hideButtons,
            setHideButtons,
            redirectUrl,
            onStartSync,
            internalSetNextHandler,
            onChangeHintImage,
            selectedStack,
            schemaSyncOnly,
            onClose,
            workspaceAccount,
            isTesting,
        ]
    )

    useEffect(() => {
        if (initializationComplete) {
            setNextButtonText(lastStepButtonText)
            setNextHandler(undefined)
        }
    }, [initializationComplete, lastStepButtonText])
    const handleCancel = () => {
        onClose()
    }

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === 'Enter') {
                handleNextClick()
            }
        },
        [handleNextClick]
    )

    // Get the specified Editor component from the DataConnectors.js definitions.
    // The editor must exist or we're in an invalid state

    if (!dataConnection && isProviderDisabled(selectedStack, provider)) {
        return <DisabledScreen provider={provider} onContinue={() => onClose()} />
    }
    if (showSplash) {
        return (
            <SplashScreen
                provider={provider}
                onContinue={() => {
                    return setShowSplash(false)
                }}
            />
        )
    }

    // only show the display name input if
    //  - we aren't on a DC-specific step and we're not showing the intialization complete message
    //  - this isn't the app creation flow (we just default the value to the DC name on app creation flow so as to remove this question from the flow)
    const showSetNameControl =
        !isAppCreateFlow && !step && !initializationComplete && !schemaSyncOnly

    return (
        <DataConnectionEditorContext.Provider value={context}>
            <Flex column align="stretch" w="100%" mt={6}>
                <LoadingScreen
                    isLoading={isLoading}
                    loadingText={loadingText}
                    keepChildrenMounted
                    minHeight={50}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                >
                    {showSetNameControl && (
                        <>
                            <Text fontWeight="bold">Data source name</Text>
                            <Input
                                style={{ height: 40 }}
                                autoFocus
                                size="sm"
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                }}
                                onKeyDown={onKeyDown}
                                value={title}
                                mt={1}
                                mb={4}
                            />
                        </>
                    )}
                    <EditorWrapper onChangeHintImage={onChangeHintImage} />
                </LoadingScreen>

                {!hideButtons && (
                    <>
                        <Flex justifyContent="center" style={{ gap: 8 }}>
                            {!initializationComplete && (
                                <Flex>
                                    {onDelete && !isNew && (
                                        <Button
                                            onClick={onDelete}
                                            variant="Secondary"
                                            icon="trash"
                                            buttonSize="medium"
                                        >
                                            Delete source
                                        </Button>
                                    )}
                                </Flex>
                            )}
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                flexGrow={initializationComplete ? 1 : null}
                            >
                                {showCancel && !initializationComplete && (
                                    <Button
                                        style={{ flex: 1 }}
                                        onClick={handleCancel}
                                        variant="adminSecondaryV4"
                                        buttonSize="medium"
                                        disabled={isSaving || isTesting}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <Button
                                    style={{ flex: 1 }}
                                    ml={initializationComplete ? null : 2}
                                    width={initializationComplete ? '100%' : null}
                                    onClick={handleNextClick}
                                    isLoading={isSaving || isTesting}
                                    variant="Primary"
                                    buttonSize="medium"
                                    disabled={isLoading || nextButtonDisabled}
                                >
                                    {nextButtonText}
                                </Button>
                            </Flex>
                        </Flex>

                        {DATA_PROVIDERS[provider] && DATA_PROVIDERS[provider].helpLink && (
                            <Flex justify={'center'}>
                                <Flex
                                    marginTop={2}
                                    flexDirection={'column'}
                                    alignItems={'flex-start'}
                                >
                                    {
                                        <Link
                                            fontSize="xs"
                                            borderBottom="1px solid"
                                            variant="noHover"
                                            color="userInterface.accent.800"
                                            href={DATA_PROVIDERS[provider].helpLink}
                                        >
                                            Learn more
                                        </Link>
                                    }
                                </Flex>
                            </Flex>
                        )}
                    </>
                )}

                <Collapse isOpen={!!saveError}>
                    <Text variant="error" fontSize="sm" mt={2}>
                        {saveError}
                    </Text>
                </Collapse>
            </Flex>
        </DataConnectionEditorContext.Provider>
    )
}

function SplashScreen({
    provider,
    onContinue,
}: {
    provider: ProviderTypes
    onContinue: () => void
}) {
    return (
        <Flex column align="stretch" mt={6}>
            <Text>{DATA_PROVIDERS[provider].splashMessage}</Text>
            <Button
                width="100%"
                onClick={onContinue}
                variant="adminPrimaryV4"
                buttonSize="sm"
                mt={6}
            >
                Continue
            </Button>
        </Flex>
    )
}

function DisabledScreen({ provider, onContinue }) {
    const { name, disabledMessage } = DATA_PROVIDERS[provider]
    return (
        <Flex column align="stretch" mt={6}>
            <Text>{disabledMessage}</Text>

            <Text mt={4}>
                The <strong>{name}</strong> data source is currently available by request and has
                limitations. Click below to request access.
            </Text>
            <Button
                width="100%"
                onClick={onContinue}
                href={'https://stackerhq.com/data/' + provider}
                variant="adminPrimaryV4"
                buttonSize="sm"
                mt={6}
            >
                Get Access
            </Button>
        </Flex>
    )
}

const EditorWrapper: React.FC<{ onChangeHintImage: (url: string | undefined) => void }> = (
    props
) => {
    const context: DataConnectionEditorContextType = React.useContext(DataConnectionEditorContext)
    return (
        <RenderEditor
            providerType={context.providerKey}
            onChangeHintImage={props.onChangeHintImage}
            context={context}
        />
    )
}

export default DataConnectionEditor
