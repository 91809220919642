import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'

import QueryString from 'qs'
import { signUp } from 'supertokens-auth-react/recipe/emailpassword'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getUrl, Urls } from 'app/UrlService'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Divider } from 'ui/components/Divider'
import { Input } from 'ui/components/Input'
import { Link } from 'ui/components/Link'
import { Body } from 'ui/components/Text'

import { AuthFrame } from './AuthFrame'
import { GoogleLoginButton } from './GoogleLoginButton'

type SignupFormData = {
    name: string
    email: string
    password: string
}
export function SignUpPage() {
    const { user } = useAuthContext()
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignupFormData>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',

        defaultValues: { email: query.email?.toString() ?? '' },
    })

    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    async function onSubmit({ name, email, password }: SignupFormData) {
        try {
            setIsLoading(true)
            const response = await signUp({
                formFields: [
                    {
                        id: 'name',
                        value: name,
                    },
                    {
                        id: 'email',
                        value: email,
                    },
                    {
                        id: 'password',
                        value: password,
                    },
                ],
            })
            if (response.status !== 'OK') {
                setErrorMessage('Sign up failed. Please try again.')
            }
        } catch (ex) {
            console.error(ex)
            setErrorMessage('Sign up failed. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    if (user) {
        return <Redirect to={getUrl(Urls.Root)} />
    }
    return (
        <AuthFrame title="Sign up to Stacker">
            <GoogleLoginButton />
            <Box flex gap="m" center my="2xl" color="textWeakest">
                <Divider />
                <Box>or</Box>
                <Divider />
            </Box>
            <Box as="form" flex column gap="m" onSubmit={handleSubmit(onSubmit)}>
                <Input
                    placeholder="Full name"
                    {...register('name', { required: true })}
                    isError={errors?.name}
                    helperText={errors?.name && 'Name is required'}
                    autoFocus
                />
                <Input
                    placeholder="Email address"
                    {...register('email', { required: true })}
                    isError={errors?.email}
                    helperText={errors?.email && 'Email is required'}
                />
                <Input
                    placeholder="Password"
                    type="password"
                    {...register('password', { required: true })}
                    isError={errors?.password}
                    helperText={errors?.password && 'Password is required'}
                />
                {errorMessage && (
                    <Box color="textError" fontSize="bodyM" alignSelf="center" mb="m">
                        {errorMessage}
                    </Box>
                )}
                <Button variant="primary" mt="m" type="submit" isLoading={isLoading}>
                    Sign up
                </Button>
                <Body size="s" mt="m" alignSelf="center">
                    Already have an account?{' '}
                    <Link size="s" to={getUrl(Urls.Login) + window.location.search}>
                        Log in
                    </Link>
                </Body>
            </Box>
        </AuthFrame>
    )
}
