import React, { ReactNode, useCallback } from 'react'

import styled from '@emotion/styled'

import useTrack from 'utils/useTrack'

import { Box, Button, Flex, Image, Text } from 'v2/ui'
import stackerTheme from 'v2/ui/theme/styles/default'

const colors = stackerTheme().colors

type DataSourceCardProps = {
    type: string
    label: string
    backgroundImage: string | undefined
    cta: string | undefined
    description: string | undefined
    onClick: (dataSync: any) => void
    icon?: ReactNode
    tags?: Array<string>
}

const DataSourceCard = ({
    type,
    label,
    icon,
    backgroundImage,
    description,
    cta: callToAction,
    tags,
    onClick,
}: DataSourceCardProps) => {
    const { track } = useTrack()

    const handleCTAClick = useCallback(() => {
        track('WIP - Frontend - App Settings - Data Source Selection - Add button - Clicked', {
            integration_name: label,
            // the service name and the type are the same for native connectors which are presented outside data sync gallery
            integration_service_name: type,
            integration_type: type,
            event_location: 'admin_side_tray',
        })
        onClick(label)
    }, [track, onClick, label, type])

    return (
        <Box
            h="122px"
            bg={backgroundImage ? 'transparent' : 'white'}
            minWidth="120px"
            position="relative"
            overflow="hidden"
            data-testid={`${label}-data-source.card`}
            borderRadius="6px"
            border={`1px solid ${colors.userInterface.neutral[400]}`}
        >
            {backgroundImage && (
                <Image
                    src={backgroundImage}
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    position="absolute"
                />
            )}

            <DataSourceCardInfo>
                <Flex
                    row
                    wrap="nowrap"
                    alignItems="flex-start"
                    width="100%"
                    justifyContent="space-between"
                    p="12px 12px 6px 12px"
                >
                    <Flex column alignItems="flex-start">
                        {icon && <Box mb="16px">{icon}</Box>}

                        <Flex column alignItems="flex-start" pt={!icon ? '32px' : null}>
                            {tags && (
                                <Flex row mb={1}>
                                    {tags.map((tag, index) => (
                                        <Tag key={index}>{tag}</Tag>
                                    ))}
                                </Flex>
                            )}

                            <Text
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                fontSize="16px"
                                fontWeight="bold"
                                color={backgroundImage ? '#ffffff' : 'neutral.950'}
                            >
                                {label}
                            </Text>
                        </Flex>
                    </Flex>
                    <CallToActionButton
                        variant="Secondary"
                        buttonSize="medium"
                        onClick={handleCTAClick}
                    >
                        {callToAction}
                    </CallToActionButton>
                </Flex>
                <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    fontSize="14px"
                    fontWeight="bold"
                    color={backgroundImage ? '#ffffff' : 'neutral.800'}
                    pl="12px"
                >
                    {description}
                </Text>
            </DataSourceCardInfo>
        </Box>
    )
}

const DataSourceCardInfo = styled.div`
    flex-grow: 1;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
`

const CallToActionButton = styled(Button)`
    position: absolute;
    right: 12px;
`

const Tag = styled.div`
    display: flex;
    align-items: center;
    border-radius: 1px;
    padding: 2px 2px 2px 0px;
    color: white;
    font-weight: 600;
    font-size: 14px;
`

export default DataSourceCard
