// @ts-ignore
import Color from 'color'

const blankScale = {
    50: '',
    100: '',
    200: '',
    300: '',
    400: '',
    500: '',
    600: '',
    700: '',
    800: '',
    900: '',
    950: '',
}

export type ColorScaleKey = keyof typeof blankScale

// Yields a 0-1000 (100 step) color scale with the base color being 500
export const getColorScale = (baseColor: string) => {
    const colorScale = { ...blankScale }
    const color = new Color(baseColor)
    colorScale[500] = color.hsl().string()

    const colorScaleKeys = Object.keys(colorScale).map(Number) as ColorScaleKey[]

    const itemCountBeforeBase = colorScaleKeys.indexOf(500) + 1
    const itemCountAfterBase = colorScaleKeys.length - itemCountBeforeBase

    for (let i = itemCountBeforeBase - 1; i >= 0; i--) {
        const key = colorScaleKeys[i]

        colorScale[key] = color
            // 400 - 0 goes to 95% lighter than base color
            .lighten(((itemCountBeforeBase - i) / itemCountBeforeBase) * 0.97)
            .hsl()
            .string()
    }

    for (let i = itemCountAfterBase + 1; i <= colorScaleKeys.length; i++) {
        const key = colorScaleKeys[i]

        colorScale[key] = color
            // 600-100 goes to -80% darker than base color
            .lighten(((i - itemCountBeforeBase) / -itemCountBeforeBase) * 0.8)
            .hsl()
            .string()
    }

    return colorScale
}

/**
 * The `source` object has keys grouped by string prefix with a number suffix.
 * This function returns an object with keys for each group prefix and values
 * which are objects with the suffixes as keys and the corresponding values.
 */
export function groupByPrefix(source: Record<string, string>) {
    const result: Record<string, Record<string, string>> = {}
    for (const [key, value] of Object.entries(source)) {
        const match = key.match(/^([a-z]+)(\d+)$/)
        if (match) {
            const [, prefix, suffix] = match
            if (!result[prefix]) {
                result[prefix] = {}
            }
            result[prefix][suffix] = value
        }
    }
    return result
}
