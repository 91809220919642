import React, { useCallback, useEffect, useRef } from 'react'

import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'
import { getSupportedThumbnailFields } from 'features/views/ListView/utils'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'
import { Select, SelectOption } from 'ui/components/Select'
import { Toggle } from 'ui/components/Toggle'

import { useBoardViewFieldInputState } from './hooks/useBoardViewFieldInputState'

type BoardViewCardHeaderControlsProps = {
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
}

export const BoardViewCardHeaderControls: React.FC<BoardViewCardHeaderControlsProps> = ({
    fields,
    config,
    setConfig,
}) => {
    return (
        <ListViewControlItem label="Card header" icon="PanelTop">
            <Box maxWidth="full" minWidth="300px" flex flexDirection="column" gap="l">
                <CoverImageControls config={config} setConfig={setConfig} fields={fields} />
                <ProfileImageControls config={config} setConfig={setConfig} fields={fields} />
                <EyebrowControls config={config} setConfig={setConfig} fields={fields} />
                <TitleControls config={config} setConfig={setConfig} fields={fields} />
                <SubtitleControls config={config} setConfig={setConfig} fields={fields} />
            </Box>
        </ListViewControlItem>
    )
}

const CoverImageControls: React.FC<BoardViewCardHeaderControlsProps> = ({
    config,
    setConfig,
    fields,
}) => {
    const supportedFields = getSupportedThumbnailFields(fields)

    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    let fieldApiName = config.coverImage?.id
    // If the field is not in the list of fields, reset the fieldApiName.
    if (!supportedFields.find((field) => field.api_name === fieldApiName)) {
        fieldApiName = undefined
    }

    const onChangeFieldApiName = useCallback(
        (value: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['coverImage'] = value
                ? {
                      ...existingConfig.coverImage,
                      id: value,
                  }
                : undefined

            setConfig({
                coverImage: newValue,
            })
        },
        [setConfig]
    )

    return (
        <Select
            placeholder="Select field..."
            label="Cover image"
            value={fieldApiName}
            onChange={onChangeFieldApiName}
            isClearable
            isSearchable
        >
            {supportedFields.map((field) => (
                <SelectOption key={field._sid} value={field.api_name} label={field.label} />
            ))}
        </Select>
    )
}

const ProfileImageControls: React.FC<BoardViewCardHeaderControlsProps> = ({
    config,
    setConfig,
    fields,
}) => {
    const supportedFields = getSupportedThumbnailFields(fields)

    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    let fieldApiName = config.profileImage?.fieldApiName
    // If the field is not in the list of fields, reset the fieldApiName.
    if (!supportedFields.find((field) => field.api_name === fieldApiName)) {
        fieldApiName = undefined
    }

    const onChangeFieldApiName = useCallback(
        (value: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['profileImage'] = value
                ? {
                      ...existingConfig.profileImage,
                      fieldApiName: value,
                  }
                : undefined

            setConfig({
                profileImage: newValue,
            })
        },
        [setConfig]
    )

    return (
        <Select
            placeholder="Select field..."
            label="Profile image"
            value={fieldApiName}
            onChange={onChangeFieldApiName}
            isClearable
            isSearchable
        >
            {supportedFields.map((field) => (
                <SelectOption key={field._sid} value={field.api_name} label={field.label} />
            ))}
        </Select>
    )
}

const EyebrowControls: React.FC<BoardViewCardHeaderControlsProps> = ({
    config,
    setConfig,
    fields,
}) => {
    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    let fieldSid = config.boardCardEyebrow?.fieldSid
    // If the field is not in the list of fields, reset the fieldSid.
    if (!fields.find((field) => field._sid === fieldSid)) {
        fieldSid = undefined
    }

    const onChangeFieldSid = useCallback(
        (value: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['boardCardEyebrow'] = value
                ? {
                      ...existingConfig.boardCardEyebrow,
                      fieldSid: value,
                  }
                : undefined

            setConfig({
                boardCardEyebrow: newValue,
            })
        },
        [setConfig]
    )

    return (
        <Select
            placeholder="Select field..."
            label="Eyebrow"
            value={fieldSid}
            onChange={onChangeFieldSid}
            isClearable
            isSearchable
        >
            {fields.map((field) => (
                <SelectOption key={field._sid} value={field._sid} label={field.label} />
            ))}
        </Select>
    )
}
const TitleControls: React.FC<BoardViewCardHeaderControlsProps> = ({
    config,
    setConfig,
    fields,
}) => {
    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    const field = fields.find((field) => field._sid === config.boardCardTitle?.fieldSid)

    const onChangeFieldSid = useCallback(
        (value?: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['boardCardTitle'] = value
                ? {
                      ...existingConfig.boardCardTitle,
                      fieldSid: value,
                  }
                : undefined

            setConfig({
                boardCardTitle: newValue,
            })
        },
        [setConfig]
    )
    const onDisplayOptionsChange = useCallback(
        (value) => {
            setConfig({
                boardCardTitle: {
                    ...config.boardCardTitle,
                    fieldDisplayOptions: value,
                },
            })
        },
        [setConfig, config.boardCardTitle]
    )

    return (
        <FieldInput
            label="Title"
            fields={fields}
            value={field?._sid ?? undefined}
            displayOptions={config.boardCardTitle?.fieldDisplayOptions}
            onChange={onChangeFieldSid}
            onDisplayOptionsChange={onDisplayOptionsChange}
        />
    )
}

const SubtitleControls: React.FC<BoardViewCardHeaderControlsProps> = ({
    config,
    setConfig,
    fields,
}) => {
    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    let fieldSid = config.boardCardSubtitle?.fieldSid
    // If the field is not in the list of fields, reset the fieldSid.
    if (!fields.find((field) => field._sid === fieldSid)) {
        fieldSid = undefined
    }

    useEffect(() => {
        const firstFieldSid = config.columns[0]?.fieldId
        if (!config.boardCardTitle?.fieldSid && firstFieldSid) {
            setConfig({
                ...config,
                boardCardTitle: {
                    ...config.boardCardTitle,
                    fieldSid: firstFieldSid,
                },
                columns: config.columns.slice(1),
                showAllFields: false,
            })
        }
    }, [config, config.columns, setConfig])

    const onChangeFieldSid = useCallback(
        (value?: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['boardCardSubtitle'] = value
                ? {
                      ...existingConfig.boardCardSubtitle,
                      fieldSid: value,
                  }
                : undefined

            setConfig({
                boardCardSubtitle: newValue,
            })
        },
        [setConfig]
    )
    const onDisplayOptionsChange = useCallback(
        (value) => {
            setConfig({
                boardCardSubtitle: {
                    ...config.boardCardSubtitle,
                    fieldDisplayOptions: value,
                },
            })
        },
        [setConfig, config.boardCardSubtitle]
    )

    return (
        <FieldInput
            label="Subtitle"
            fields={fields}
            value={fieldSid}
            displayOptions={config.boardCardSubtitle?.fieldDisplayOptions}
            onChange={onChangeFieldSid}
            onDisplayOptionsChange={onDisplayOptionsChange}
            isClearable
        />
    )
}

type FieldInputProps = React.ComponentPropsWithoutRef<typeof Input> & {
    value?: BoardHeaderField
    displayOptions?: BoardHeaderDisplayOptions
    onChange: (value?: BoardHeaderField) => void
    onDisplayOptionsChange: (value: BoardHeaderDisplayOptions) => void
    fields: FieldDto[]
}

const FieldInput: React.FC<FieldInputProps> = ({
    fields,
    value,
    displayOptions,
    onDisplayOptionsChange,
    ...props
}) => {
    const { selectedField, isUsersObjectLink } = useBoardViewFieldInputState({ fields, value })

    return (
        <Box>
            <Select size="m" placeholder="Select field..." value={value} isSearchable {...props}>
                {fields.map((field) => (
                    <SelectOption key={field._sid} value={field._sid} label={field.label} />
                ))}
            </Select>
            {selectedField?.type === 'multi_file' && (
                <Box mt="m">
                    <Toggle
                        checked={displayOptions?.displayAsImage}
                        onCheckedChange={(isChecked) => {
                            onDisplayOptionsChange({ displayAsImage: isChecked })
                        }}
                    >
                        Display as image
                    </Toggle>
                </Box>
            )}
            {(isUsersObjectLink || selectedField?.type === 'user_ref') && (
                <Box mt="m">
                    <Toggle
                        checked={!displayOptions?.hideUserName}
                        onCheckedChange={(isChecked) => {
                            onDisplayOptionsChange({ hideUserName: !isChecked })
                        }}
                    >
                        Show user name
                    </Toggle>
                </Box>
            )}
        </Box>
    )
}
