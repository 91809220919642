// @ts-strict-ignore
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useDataConnections } from 'data/hooks/dataConnections'
import { useObject, useObjects, useSharedWorkspaceObjects } from 'data/hooks/objects'
import { importSharedObject } from 'data/hooks/objects/objectOperations'
import { refetchObjects } from 'data/hooks/objects/refetchObjects'
import useLDFlags from 'data/hooks/useLDFlags'
import { useNewNativeTableModal } from 'features/admin/data-connector/editors/native-tables/NewNativeTableModal'
import EditConnectionModal from 'features/AppSettings/DataSources/EditConnectionModal'
import { ImportCsvTableModal } from 'features/ImportData/importCsv/ImportCsvTableModal'
import ImportTableDataModal from 'features/ImportData/importTableData/ImportTableDataModal'
import { SlidingPaneFrame } from 'features/workspace/AdminSideTray/common/SlidingPaneFrame'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import { useUpdateDirtyPanes } from 'features/workspace/AdminSideTray/hooks/useUpdateDirtyPanes'
import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'
import { useAvailableDataSources } from 'features/workspace/useAvailableDataSources'

import { ActionList, Box, Icon } from 'v2/ui'
import { ActionListItem } from 'v2/ui/components/ActionList'
import stackerTheme from 'v2/ui/theme/styles/default'
import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { ManageDataEmptyState } from './tabs/ManageDataEmptyState'
import { ManageDataTabs } from './tabs/ManageDataTabs'
import { ManageTableDataSourcesDropdown } from './tabs/ManageTableDataSourcesDropdown'
import { DataGridContextProvider } from './DataGridContext'

const { colors } = stackerTheme()

type ManageDataSidePaneProps = {
    dataConnectionId?: string
    objectId?: string
    shouldShowNewTableModal?: boolean
}

const filterObject = ({
    o,
    dataConnectionId,
    selectedStack,
}: {
    o: ObjectDto
    dataConnectionId: string
    selectedStack: StackDto
}): boolean =>
    o.data_connection === dataConnectionId &&
    ((getIsStackerUserObject(o) && selectedStack.options?.always_show_stacker_user_table) ||
        !o.connection_options?.data_mapping_disabled)

export const ManageDataSidePane: React.VFC<ManageDataSidePaneProps> = ({
    dataConnectionId,
    objectId,
    shouldShowNewTableModal,
}) => {
    const { setDirty } = useSlidingPane()
    const { data: workspaceObjects } = useSharedWorkspaceObjects()
    const [selectedDataSource, setSelectedDataSource] = useState<string>()
    const [selectedObjectId, setSelectedObjectId] = useState(objectId)
    const [addTableEnabled, setAddTableEnabled] = useState<boolean>()
    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [editedConnection, _setEditedConnection] = useState<DataConnectionDto>()
    const [isUploadCsvModalOpen, setIsUploadCsvModalOpen] = useState(false)
    const [isUploadTableDataModalOpen, setIsUploadTableDataModalOpen] = useState(false)
    const { updateDirtyPanes, isDirtyPanes, setIsDirtyPanes } = useUpdateDirtyPanes()

    const dataConnections = useAvailableDataSources()
    const { selectedStack } = useAppContext()

    const { show: showNewTableModal } = useNewNativeTableModal()
    const { object } = useObject(selectedObjectId)
    const {
        data: objects,
        isLoading: isObjectsLoading,
    }: { data: ObjectDto[] | undefined; isLoading: boolean } = useObjects()
    const { isLoading: isConnectionsLoading }: { isLoading: boolean } = useDataConnections()

    const isLoading = isConnectionsLoading || isObjectsLoading

    useEffectOnlyOnUpdate(() => setSelectedObjectId(objectId), [objectId])

    const selectedDataConnection = useMemo(() => {
        return dataConnections.find((dc) => dc._sid === selectedDataSource)
    }, [selectedDataSource, dataConnections])

    // Select the first data connection by default
    useEffect(() => {
        if (!selectedObjectId && !selectedDataSource) {
            setSelectedDataSource(dataConnections[0]?._sid)
        }
    }, [dataConnections, selectedDataSource, selectedObjectId])

    // On component startup, show the new table modal if needed
    useEffect(() => {
        if (shouldShowNewTableModal) {
            setTimeout(() => {
                // Give some time for the data grid to open before opening the modal
                onAddTable()
            }, 200)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * List of tables matching the current datasource
     */
    const filteredObjects = useMemo(() => {
        return objects && selectedDataSource && selectedStack
            ? objects?.filter((o) =>
                  filterObject({ o, dataConnectionId: selectedDataSource, selectedStack })
              ) ?? []
            : []
    }, [objects, selectedDataSource, selectedStack])

    const allDataSourceTables = useMemo(
        () => objects?.filter((object) => object.data_connection === selectedDataSource) || [],
        [objects, selectedDataSource]
    )

    const changeDataSource = useCallback(
        (dataSource) => {
            if (!objects) return
            setSelectedDataSource(dataSource)
            setSelectedObjectId(
                dataSource && selectedStack
                    ? objects.filter((o) =>
                          filterObject({ o, dataConnectionId: dataSource, selectedStack })
                      )[0]?._sid
                    : undefined
            )
        },
        [objects, selectedStack]
    )

    // Reset the selected data source when object changes
    useEffect(() => {
        if (object && selectedObjectId) {
            setSelectedDataSource(object?.data_connection)
        } else if (selectedDataSource) {
            changeDataSource(selectedDataSource)
        } else if (dataConnectionId) {
            changeDataSource(dataConnectionId)
        } else {
            // When no object or datasource is selected, we select the first datasource in the list
            setSelectedDataSource(dataConnections[0]?._sid)
        }
    }, [
        object,
        dataConnections,
        selectedDataSource,
        changeDataSource,
        dataConnectionId,
        selectedObjectId,
    ])

    // If the datasource is Stacker Data, enable the add button
    useEffect(() => {
        const dc = dataConnections.find((dc) => dc._sid === selectedDataSource)
        if (dc) {
            setAddTableEnabled(dc.type === 'native_tables')
        }
    }, [selectedDataSource, dataConnections])

    useEffect(() => {
        // we update the dirty flags each time the objects have been updated
        // it ensures that each object has an entry in isDirtyPanes but more importantly
        // it cleans the deleted objects, preventing to be blocked in a dirty state in case
        // a deleted one was dirty
        setIsDirtyPanes((prevIsDirtyPanes) =>
            filteredObjects.reduce(
                (currentDirtyPanes, { _sid }) => ({
                    ...currentDirtyPanes,
                    [_sid]: prevIsDirtyPanes[_sid] ?? false,
                }),
                {}
            )
        )
    }, [filteredObjects, setIsDirtyPanes])

    useEffect(() => {
        const isDirty = !!Object.values(isDirtyPanes).find((dirty) => dirty)
        setDirty(isDirty)
    }, [isDirtyPanes, setDirty])

    /**
     * Opens the modal to create a new table
     */
    const onAddTable = useCallback((): void => {
        showNewTableModal({
            // @ts-ignore
            onObjectCreated: (_tableName, objectId) => {
                setSelectedObjectId(objectId)
            },
        })
    }, [showNewTableModal])

    const selectedTabIndex =
        object?.data_connection === selectedDataSource
            ? filteredObjects.findIndex((o) => o._sid === selectedObjectId)
            : 0

    const onDeleteDataSource = () => {
        setSelectedDataSource(undefined)
    }

    const { flags } = useLDFlags()

    const buildActions = useCallback(() => {
        const actions: ActionListItem[] = [
            { label: 'Add new table', icon: 'add', action: onAddTable },
        ]
        actions.push({
            label: 'Import from CSV',
            icon: 'faFileCsv',
            action: () => {
                setIsUploadCsvModalOpen(true)
            },
            iconColor: 'accent.100',
        })

        actions.push({
            label: 'Paste data from spreadsheet',
            icon: 'table',
            action: () => {
                setIsUploadTableDataModalOpen(true)
            },
        })

        const filteredObjects = workspaceObjects?.filter(
            (obj) => !objects.find((existing) => existing._sid === obj._sid)
        )
        if (flags.sharedTables && filteredObjects?.length) {
            actions.push({ divider: true })
            actions.push({ header: true, label: 'Shared tables' })

            for (const workspaceObject of filteredObjects) {
                actions.push({
                    label: workspaceObject.name,
                    icon: 'table',
                    action: async () => {
                        try {
                            const importedObject = await importSharedObject(workspaceObject._sid)
                            setSelectedObjectId(importedObject?._sid)
                            await refetchObjects()
                        } catch (resp) {
                            if (resp.status === 400) {
                                alert('A table with this name already exists in this app.')
                            } else {
                                throw resp
                            }
                        }
                    },
                })
            }
        }
        return actions
    }, [objects, onAddTable, workspaceObjects, flags.sharedTables])

    return (
        <DataGridContextProvider>
            {editedConnection && (
                <EditConnectionModal
                    onClose={() => setIsEditModalOpen(false)}
                    provider={editedConnection?.type}
                    dataConnection={editedConnection}
                    isOpen={isEditModalOpen}
                    withDelete={true}
                    onDeleteDataSource={onDeleteDataSource}
                />
            )}
            <SlidingPaneFrame
                title="Manage data"
                description="Manage the tables and data which power your app."
                contentAreaProps={{ bg: 'userInterface.accent.1200' }}
            >
                <ManageDataTabs
                    objects={filteredObjects}
                    isLoading={isLoading}
                    selectedTabIndex={selectedTabIndex}
                    isDirtyPanes={isDirtyPanes}
                    updateDirtyPanes={updateDirtyPanes}
                    leftTabsHeader={
                        <ManageTableDataSourcesDropdown
                            allDataSourceTables={allDataSourceTables}
                            selectedDataConnection={selectedDataConnection}
                            selectedObjectId={selectedObjectId}
                            setSelectedObjectId={setSelectedObjectId}
                        />
                    }
                    onSelectObject={setSelectedObjectId}
                    actions={
                        <>
                            {addTableEnabled ? (
                                <Box mr={4} ml={1} mt={1}>
                                    <ActionList
                                        variant="iconButton"
                                        buttonSize="smallSquare"
                                        menuPlacement="bottom-start"
                                        icon={null}
                                        data-testid="manage-data.tab-bar.add-table-button"
                                        actions={buildActions()}
                                    >
                                        <Icon
                                            icon="add"
                                            size="sm"
                                            color={colors.userInterface.neutral[0]}
                                        />
                                    </ActionList>
                                </Box>
                            ) : null}
                        </>
                    }
                    emptyState={
                        <ManageDataEmptyState
                            dataConnection={selectedDataConnection}
                            hasAllTablesDeactivated={allDataSourceTables.length > 0}
                            onAddTable={onAddTable}
                            onImportCsvTable={() => {
                                setIsUploadCsvModalOpen(true)
                            }}
                            onImportTableData={() => {
                                setIsUploadTableDataModalOpen(true)
                            }}
                            showImportCsvTableButton={true}
                            showImportTableDataButton={true}
                        />
                    }
                />
            </SlidingPaneFrame>
            <ImportCsvTableModal
                isOpen={isUploadCsvModalOpen}
                onClose={() => setIsUploadCsvModalOpen(false)}
                onSuccess={(objectId) => {
                    setSelectedObjectId(objectId)
                    setIsUploadCsvModalOpen(false)
                }}
                onError={() => setIsUploadCsvModalOpen(false)}
            />
            <ImportTableDataModal
                isOpen={isUploadTableDataModalOpen}
                onClose={() => setIsUploadTableDataModalOpen(false)}
                onSuccess={(objectId) => {
                    setSelectedObjectId(objectId)
                    setIsUploadTableDataModalOpen(false)
                }}
            />
        </DataGridContextProvider>
    )
}
