import React from 'react'

import { Box } from '@chakra-ui/react'

import RolesSelect from 'features/pages/blocks/settings/attributes/items/form/RolesSelect'

type DocumentRolesEditorProps = {
    pageRoles: PageRole[]
    setPageRoles: (role: PageRole[]) => void
}

const DocumentRolesEditor: React.FC<DocumentRolesEditorProps> = ({ pageRoles, setPageRoles }) => {
    return (
        <Box width="100%" position="absolute">
            <RolesSelect
                onChange={setPageRoles}
                value={pageRoles}
                border="0px"
                noHighlights
                fontSize="sm"
            />
        </Box>
    )
}

export default DocumentRolesEditor
