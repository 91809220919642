import React, { useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { useAppUserContext } from 'app/AppUserContext'
import { getUrl, Urls } from 'app/UrlService'
import { GetTasksParams, TaskPayload } from 'data/hooks/tasks/types'
import Frame from 'features/core/Frame'
import useWindowTitle from 'utils/useWindowTitle'

import { SearchInput } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { theme } from 'ui/styling/Theme.css'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { groupByAssigneeCategory } from './grouping/byAssigneeCategory'
import { groupByDueDateAndCompleted } from './grouping/byDueDateAndCompleted'
import { DefaultTaskListContext, TaskListContext, TaskListContextProvider } from './TaskListContext'
import { TasksList } from './TasksList'
import { TasksPageErrorState } from './TasksPageErrorState'
import { TasksPageLoadingAnimation } from './TasksPageLoadingAnimation'
import { TasksQuickCreatePopoverButton } from './TasksQuickCreatePopoverButton'
import { TasksSection } from './TasksSection'
import { CategorizedTasks } from './types'
import { useCategorizedTasks } from './useCategorizedTasks'
import { useTasksEnabled } from './useTasksEnabled'

type TasksPageProps = {}
type PreconfiguredView = {
    id: string
    title: string
    params: GetTasksParams
    context: Partial<TaskListContext>
    categorizer: (tasks: TaskPayload[]) => CategorizedTasks
}

export const TasksPage: React.FC<TasksPageProps> = () => {
    const { user } = useAppUserContext()
    const [searchValue, setSearchValue] = useState('')
    const tasksEnabled = useTasksEnabled()

    const Views: PreconfiguredView[] = [
        {
            id: 'mine',
            title: 'My Tasks',
            params: { assignees: user ? [user._sid] : undefined },
            context: { showAssignees: 'whenNotMe', showSource: 'whenNotMe' },
            categorizer: groupByDueDateAndCompleted,
        },
        {
            id: 'created',
            title: "Tasks I've Created",
            params: { created_by: user?._sid },
            context: { showAssignees: 'always', showSource: 'never' },
            categorizer: (tasks) => groupByAssigneeCategory(tasks, user),
        },
    ]

    const [selectedViewId, setSelectedViewId] = useState<string>(Views[0].id)
    const selectedView = Views.find((view) => view.id === selectedViewId)
    useWindowTitle('Tasks', false)

    if (!tasksEnabled) {
        return <Redirect to={getUrl(Urls.Root)} />
    }

    return (
        <ThemeProvider theme="admin">
            <Frame
                hideSideNav={true}
                background={theme.color.surface}
                width="100%"
                paddingOverride="0px"
            >
                <Box py="4xl" width="full" style={{ maxWidth: '900px' }}>
                    <Box px="3xl" flex center width="full" mb="4xl">
                        <Dropdown>
                            <DropdownButton variant="ghost" size="m">
                                <Box as="h1" fontSize="headlineM" fontWeight="bodyBold">
                                    {selectedView?.title}
                                </Box>
                                <Icon name="ChevronDown" size="xl" />
                            </DropdownButton>
                            <DropdownContent>
                                {Views.map((view) => (
                                    <DropdownItem
                                        key={view.id}
                                        multiSelect
                                        checked={selectedViewId === view.id}
                                        onCheckedChange={() => setSelectedViewId(view.id)}
                                        label={view.title}
                                    />
                                ))}
                            </DropdownContent>
                        </Dropdown>
                        <Box grow />
                        <SearchInput
                            value={searchValue}
                            variant="admin_white"
                            onChange={setSearchValue}
                            mr={3}
                        />
                        <TasksQuickCreatePopoverButton
                            buttonProps={{
                                children: 'Add task',
                                startIcon: { name: 'Plus' },
                                size: 'm',
                            }}
                        />
                    </Box>

                    {selectedView && (
                        <TaskPageView
                            key={selectedView.id}
                            searchValue={searchValue}
                            view={selectedView}
                        />
                    )}
                </Box>
            </Frame>
        </ThemeProvider>
    )
}

function TaskPageView({ view, searchValue }: { view: PreconfiguredView; searchValue: string }) {
    const {
        data: taskData,
        isLoading,
        isLoadingError,
        actions,
    } = useCategorizedTasks(view.categorizer, { ...view.params, searchValue })

    const { categorizedTasks: tasks = {} } = taskData || {}

    const taskCategories = useMemo(() => Object.keys(tasks), [tasks])
    const context = taskData ? { ...taskData, ...actions } : DefaultTaskListContext
    const isEmpty = Object.values(tasks).every((category) => !category.tasks.length)
    return (
        <TaskListContextProvider value={{ ...context, ...view.context }}>
            {isLoading && <TasksPageLoadingAnimation px="3xl" />}
            {isLoadingError && <TasksPageErrorState px="3xl" />}
            {searchValue && isEmpty && (
                <Container mx="l" p="l" variant="neutral">
                    No tasks match this search.
                </Container>
            )}
            {!isLoading && !isLoadingError && (
                <Box flex column gap="l">
                    {taskCategories.map((category) => {
                        const { tasks: tasksForCategory, label, defaultExpanded } = tasks[category]
                        if (!tasksForCategory.length) return null
                        return (
                            <TasksSection
                                key={category}
                                name={category}
                                label={label}
                                counter={tasksForCategory.length}
                                defaultExpanded={defaultExpanded}
                            >
                                {tasksForCategory.length > 0 && (
                                    <TasksList tasks={tasksForCategory} />
                                )}
                            </TasksSection>
                        )
                    })}
                </Box>
            )}
        </TaskListContextProvider>
    )
}
