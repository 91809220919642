/* Code Quality: Not audited */

import React, { useCallback, useContext } from 'react'

import PropTypes from 'prop-types'

import AppContext from 'app/AppContext'
import { useCreateStack, useDeleteStack, useStacks, useUpdateStack } from 'data/hooks/stacks'

function WithStacks({ children }) {
    const { mutateAsync: createStack } = useCreateStack()
    const { mutateAsync: updateStack } = useUpdateStack()
    const { mutateAsync: deleteStack } = useDeleteStack()

    const { status, data: stacks } = useStacks()
    const { selectedStack, workspaceAccount: account } = useContext(AppContext)

    const handleChange = useCallback(
        (...args) => {
            if (args.length === 2) return updateStack({ id: args[0], patch: args[1] })
            return updateStack(args[0])
        },
        [updateStack]
    )
    return children({
        stacks,
        createStack,
        onChange: handleChange,
        updateStack,
        deleteStack: deleteStack,
        selectStack: (...args) => {
            console.log('selectStack', args)
            alert('selected stack')
        },
        selectedStack: selectedStack || emptyStack,
        fetchedStacks: status === 'success',
        workspaceAccount: account,
        stackActions: undefined, // this shouldn't be used anymore
    })
}

const emptyStack = { options: {} }

WithStacks.propTypes = {
    children: PropTypes.func.isRequired,
}

const MemoizedWithStacks = React.memo(WithStacks)
export default MemoizedWithStacks

export const withStacks = (Child) => (props) => {
    const fn = React.useCallback((viewProps) => <Child {...viewProps} {...props} />, [props])

    return <MemoizedWithStacks>{fn}</MemoizedWithStacks>
}

export const withStack = (Child) => (props) => {
    const fn = React.useCallback(
        ({ selectedStack, onChange, workspaceAccount, updateStack }) => (
            <Child
                stackOptions={selectedStack.options}
                stack={selectedStack}
                onChangeStack={onChange}
                updateStack={updateStack}
                workspaceAccount={workspaceAccount}
                {...props}
            />
        ),
        [props]
    )
    return <MemoizedWithStacks>{fn}</MemoizedWithStacks>
}
