import React from 'react'

import { FlexProps } from '@chakra-ui/react'

import { AIRTABLE_REPAIR_BUTTON_TEST_ID } from 'features/AppSettings/DataSources/Airtable/airtableTestIds'

import { Button, Flex } from 'v2/ui'

import { useRepairAirtableFlow } from './useRepairAirtableFlow'

type AirtableV2RepairButtonProps = FlexProps & {
    dataConnection: DataConnectionDto
}

export const AirtableV2RepairButton: React.FC<AirtableV2RepairButtonProps> = ({
    dataConnection,
    ...props
}) => {
    const { modal, onStart } = useRepairAirtableFlow(dataConnection)

    // we don't want to unmount the modal after the status changes, we always render it
    return (
        <>
            {modal}
            {dataConnection.status !== 'valid' && (
                <Flex flexWrap="nowrap" {...props}>
                    <Button
                        flexShrink={0}
                        variant="Primary"
                        buttonSize="sm"
                        ml={2}
                        icon="faMagicWandSparkles"
                        onClick={onStart}
                        data-testid={AIRTABLE_REPAIR_BUTTON_TEST_ID}
                    >
                        Repair
                    </Button>
                </Flex>
            )}
        </>
    )
}
