import { createSelector } from 'reselect'

import {
    APP_CREATED,
    APP_REMOVED,
    APP_SELECTED,
    APP_UPDATED,
    APPS_FETCHED,
} from 'data/utils/constants'
import { StackerActions } from 'data/utils/stackerActions'
import { StackerAPI } from 'data/utils/utils'

// API
class AppApi extends StackerAPI {
    path = 'apps/'
}
const appApi = new AppApi()

class AppActions extends StackerActions {
    api = appApi

    reducer = 'apps'

    name = 'app'

    actionTypes = {
        fetched: APPS_FETCHED,
        removed: APP_REMOVED,
        created: APP_CREATED,
        updated: APP_UPDATED,
    }

    selectApp = (id) => {
        return (dispatch) => {
            dispatch({ type: APP_SELECTED, id })
        }
    }
}
const AA = new AppActions()
AA.actions = { ...AA.actions, selectApp: AA.selectApp }
export const appActions = AA.actions

// SELECTORS
export const getAppEntities = (state) => state.apps.entities
export const getAppList = (state) => state.apps.apps

export const getAppsMemoized = createSelector(getAppList, getAppEntities, (apps, entities) =>
    apps.map((appId) => entities[appId])
)
// HELPERS
