import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, Redirect } from 'react-router-dom'

import { DndContext, DragEndEvent, useDraggable, useDroppable } from '@dnd-kit/core'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { getUrl, Urls } from 'app/UrlService'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import {
    createNewStackData,
    useGroupedWorkspaceStacks,
    useStacks,
    useUpdateStackOptions,
} from 'data/hooks/stacks'
import NewAppButton from 'features/AppBar/NewAppButton'
import { StackIcon } from 'features/AppBar/StackIcon'
import Frame from 'features/core/Frame'
import { openWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Button, ButtonProps } from 'ui/components/Button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Divider } from 'ui/components/Divider'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { Input } from 'ui/components/Input'
import { stopAllPointerPropagation, stopPropagation } from 'ui/helpers/utilities'
import { HoverContainerStyle, OpacityOnContainerHover } from 'ui/styles/hoverUtils.css'
import { theme } from 'ui/styling/Theme.css'

import { AddButtonIconStyle, StackGripStyle } from './WorkspaceHomeStyles.css'

// name of the very first group which is shown by default, even though it has no apps by default
const GENERAL_GROUP_NAME = 'General'

type WorkspaceHomeProps = {}

export const WorkspaceHome: React.FC<WorkspaceHomeProps> = () => {
    const { data: groups } = useGroupedWorkspaceStacks()
    const { isOnPortalDomain, workspaceZone } = useWorkspaceContext()
    const [blankGroups, setBlankGroups] = useState<string[]>([])
    const [isArchivedGroupsOpen, setIsArchivedGroupsOpen] = useState(false)
    const updateStack = useUpdateStackOptions(true)
    const { hasRight } = useAccountUserContext()
    const { data: stacks } = useStacks()

    const shouldShowAddNewButton = hasRight(Rights.CreateApps)

    if (groups) {
        if (groups.length === 0) {
            groups.push({ name: GENERAL_GROUP_NAME, stacks: [] as StackDto[] })
        }
        const newGroups = blankGroups
            .filter((name) => !groups.find((g) => g.name === name))
            .map((name) => ({ name, stacks: [] as StackDto[] }))

        if (newGroups.length > 0) {
            groups.push(...newGroups)
        }
    }

    const handleAddGroup = (name: string) => {
        setBlankGroups((prev) => [...prev, name])
    }
    const handleDragEnd = (event: DragEndEvent) => {
        if (event.active && event.over) {
            updateStack(event.active.data.current as StackDto, { group: event.over.id as string })
        }
    }

    const archivedGroups = groups?.filter(
        (x) =>
            x.stacks.every((y) => y.options.is_archived) &&
            !blankGroups.includes(x.name) &&
            x.name != GENERAL_GROUP_NAME
    )

    const title = workspaceZone?.type === 'Portal' ? workspaceZone.name : 'Workspace'

    if (isOnPortalDomain && stacks?.length) {
        return <Redirect to={getUrl(Urls.Home, stacks[0])} />
    }

    return (
        <Frame
            background={theme.color.surface}
            width="100%"
            paddingOverride="0px"
            hideSideNav={true}
        >
            <Helmet>
                <title>{title} Overview</title>
            </Helmet>
            <DndContext onDragEnd={handleDragEnd}>
                <Box flex column stretch style={{ maxWidth: '900px' }}>
                    <Box flex center ml="xs" px={{ mobile: 'm', tablet: 0 }}>
                        <Box
                            as="h1"
                            m={0}
                            ml={{ mobile: 'xs', tablet: 'l' }}
                            fontSize={{ mobile: 'headlineXs', tablet: 'headlineL' }}
                            style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}
                            py="m"
                        >
                            {title}
                        </Box>
                        <Box grow />
                        {hasRight(Rights.ViewSettings) && !isOnPortalDomain && (
                            <Button
                                startIcon={{ name: 'Settings' }}
                                mr="l"
                                size="s"
                                onClick={() => openWorkspaceSettingsModal()}
                                variant="secondary"
                            >
                                Settings
                            </Button>
                        )}
                    </Box>
                    <Divider mb="m" />
                    <Box flex column mx="xl">
                        {groups
                            ?.filter((x) => !archivedGroups.includes(x))
                            .map((group) => (
                                <WorkspaceGroup
                                    key={group.name}
                                    shouldShowAddNewButton={shouldShowAddNewButton}
                                    {...group}
                                />
                            ))}
                        {shouldShowAddNewButton && (
                            <AddGroupButton onAddGroup={handleAddGroup} mt="xl" />
                        )}

                        {archivedGroups?.length > 0 && (
                            <Collapsible
                                open={isArchivedGroupsOpen}
                                onOpenChange={setIsArchivedGroupsOpen}
                            >
                                <CollapsibleTrigger asChild>
                                    <Button
                                        variant="ghost"
                                        size="l"
                                        mt="4xl"
                                        mb="xl"
                                        style={{
                                            padding: 0,
                                            height: 'auto',
                                            overflow: 'unset',
                                            borderRadius: 0,
                                        }}
                                    >
                                        <Box
                                            flex
                                            center
                                            fontSize={{ mobile: 'headlineXs', tablet: 'headlineS' }}
                                            gap="m"
                                            fontWeight="bodyRegular"
                                            color="textWeakest"
                                        >
                                            <Icon
                                                name={
                                                    isArchivedGroupsOpen
                                                        ? 'ChevronDown'
                                                        : 'ChevronRight'
                                                }
                                                size="2xl"
                                                noShrink
                                            />
                                            Archived Groups
                                        </Box>
                                    </Button>
                                </CollapsibleTrigger>
                                <CollapsibleContent>
                                    {archivedGroups.map((group) => (
                                        <WorkspaceGroup
                                            key={group.name}
                                            shouldShowAddNewButton={shouldShowAddNewButton}
                                            {...group}
                                            alwaysShowArchived
                                        />
                                    ))}
                                </CollapsibleContent>
                            </Collapsible>
                        )}
                    </Box>
                </Box>
            </DndContext>
        </Frame>
    )
}

function AddGroupButton({
    onAddGroup,
    ...props
}: BoxProps & { onAddGroup: (name: string) => void }) {
    const [newGroupName, setNewGroupName] = useState('')
    const [showingNewGroupInput, setShowingNewGroupInput] = useState(false)
    const commitChanges = () => {
        if (newGroupName) {
            onAddGroup(newGroupName)
        }
        setNewGroupName('')
        setShowingNewGroupInput(false)
    }
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            commitChanges()
        } else if (e.key === 'Escape') {
            setNewGroupName('')
            setShowingNewGroupInput(false)
        }
    }
    return (
        <Box {...props} px="m" py="xs">
            {!showingNewGroupInput && (
                <Button
                    variant="ghost"
                    size="l"
                    opacity={0.6}
                    onClick={() => setShowingNewGroupInput(true)}
                    style={{ padding: 0, height: 'auto', overflow: 'unset', borderRadius: 0 }}
                >
                    <Box
                        flex
                        center
                        fontSize={{ mobile: 'headlineXs', tablet: 'headlineS' }}
                        gap="m"
                        fontWeight="bodyRegular"
                    >
                        <Icon name="Plus" size="2xl" noShrink />
                        Add a group
                    </Box>
                </Button>
            )}
            {showingNewGroupInput && (
                <Box
                    as={Input}
                    value={newGroupName}
                    onChange={(e: any) => setNewGroupName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={commitChanges}
                    autoFocus
                    placeholder="group name"
                />
            )}
        </Box>
    )
}
function WorkspaceGroup({
    name,
    stacks,
    alwaysShowArchived,
    shouldShowAddNewButton,
}: BoxProps & {
    name: string
    stacks: StackDto[]
    alwaysShowArchived?: boolean
    shouldShowAddNewButton?: boolean
}) {
    const { setNodeRef, isOver } = useDroppable({ id: name })
    const [showArchived, setShowArchived] = useState(alwaysShowArchived)

    const updateStack = useUpdateStackOptions(true)

    const archiveAll = () => {
        stacks
            .filter((x) => !x.options.is_archived)
            .forEach((stack) => {
                updateStack(stack, { is_archived: true })
            })
    }
    const unarchiveAll = () => {
        stacks
            .filter((x) => x.options.is_archived)
            .forEach((stack) => {
                updateStack(stack, { is_archived: false })
            })
    }

    const archivedCount = stacks.filter((x) => x.options.is_archived).length
    return (
        <Box flex column>
            <Box flex center mt="xl">
                <Box
                    as="h1"
                    fontSize={{ mobile: 'headlineXs', tablet: 'headlineM' }}
                    mx="l"
                    noShrink
                >
                    {name}
                </Box>
                <Divider grow />
                {archivedCount > 0 && !alwaysShowArchived && (
                    <Button onClick={() => setShowArchived((v) => !v)} variant="secondary" size="s">
                        {showArchived ? 'Hide ' : 'Show '} {archivedCount} archived app
                        {archivedCount > 1 ? 's' : ''}
                    </Button>
                )}
                <Divider style={{ width: theme.space['6xl'] }} />
                {shouldShowAddNewButton && (
                    <Dropdown>
                        <DropdownButton
                            startIcon={{ name: 'MoreVertical' }}
                            variant="ghost"
                            size="s"
                            ml="m"
                        />
                        <DropdownContent align="end">
                            {archivedCount > 0 ? (
                                <DropdownItem onClick={unarchiveAll} label="Unarchive all" />
                            ) : (
                                <DropdownItem
                                    onClick={archiveAll}
                                    disabled={stacks.length < 1}
                                    label="Archive all"
                                />
                            )}
                        </DropdownContent>
                    </Dropdown>
                )}
            </Box>
            <Box
                py="m"
                rounded="l"
                flex
                wrap
                ref={setNodeRef}
                background={isOver ? 'surfaceStronger' : undefined}
            >
                {stacks
                    .filter((x) => !x.options.is_archived || showArchived)
                    .map((stack) => (
                        <StackButton key={stack._sid} stack={stack} />
                    ))}
                {shouldShowAddNewButton && (
                    <AddAppButton
                        group={name}
                        pinToAppBar={
                            stacks.length === 0 || stacks.every((s) => s.options.pin_to_app_bar)
                        }
                    />
                )}
            </Box>
        </Box>
    )
}

function StackButton({ stack }: ButtonProps & { stack: StackDto }) {
    const { setNodeRef, attributes, transform, listeners } = useDraggable({
        id: stack._sid,
        data: stack,
    })

    const { hasRight } = useAccountUserContext()

    const canModify = hasRight(Rights.CreateApps)

    const updateStack = useUpdateStackOptions(true)
    const isPinned = stack.options.pin_to_app_bar

    const updateIsPinned = (value: boolean) => {
        updateStack(stack, { pin_to_app_bar: value })
    }

    const updatedIsArchived = (value: boolean) => {
        updateStack(stack, { is_archived: value })
    }

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : 'unset',
    }

    return (
        <Box
            ref={setNodeRef}
            pl="xs"
            pr="xs"
            fontSize="bodyS"
            className={HoverContainerStyle}
            {...attributes}
            style={style}
            position="relative"
        >
            {canModify && (
                <Box className={StackGripStyle} {...stopAllPointerPropagation()}>
                    <Icon name="GripVertical" size="s" {...listeners} />
                </Box>
            )}
            <Box flex column center width="7xl" maxWidth="7xl" overflow="hidden">
                <Box
                    flex
                    column
                    as={Link}
                    center
                    to={getUrl(Urls.Home, stack)}
                    height="6xl"
                    width="6xl"
                    rounded="l"
                    mb="m"
                    style={{ backgroundColor: stack.options.theme.brandColor }}
                    opacity={stack.options.is_archived ? 0.5 : 1}
                    flexShrink={0}
                >
                    <StackIcon stack={stack} isSelected theme={{ theme: 'light' }} size="32px" />

                    {stack.options.pin_to_app_bar && (
                        <Box
                            position="absolute"
                            style={{
                                top: '-4px',
                                right: '8px',
                                background: stack.options.theme.brandColor,
                                color: 'white',
                            }}
                            rounded="s"
                            flex
                            center
                            column
                            justifyContent="center"
                            width="xl"
                            height="xl"
                            color="text"
                            fontSize="bodyXs"
                        >
                            <Icon name="Pin" size="xs" />
                        </Box>
                    )}
                    {canModify && (
                        <Dropdown>
                            <DropdownButton
                                position="absolute"
                                size="xs"
                                style={{
                                    top: '-4px',
                                    right: '8px',
                                    background: stack.options.theme.brandColor,
                                    color: 'white',
                                    padding: 0,
                                    width: theme.space.xl,
                                    height: theme.space.xl,
                                }}
                                className={OpacityOnContainerHover}
                                variant="ghost"
                            >
                                <Icon name="MoreVertical" size="xs" />
                            </DropdownButton>
                            <DropdownContent onClick={stopPropagation}>
                                <DropdownItem
                                    checked={isPinned}
                                    onCheckedChange={updateIsPinned}
                                    label="Pin to workspace"
                                    multiSelect
                                />
                                <Divider my="xs" />
                                {!stack.options.is_archived && (
                                    <DropdownItem
                                        startIcon={{ name: 'Archive' }}
                                        onClick={() => updatedIsArchived(true)}
                                        label="Archive"
                                    />
                                )}
                                {stack.options.is_archived && (
                                    <DropdownItem
                                        startIcon={{ name: 'Inbox' }}
                                        onClick={() => updatedIsArchived(false)}
                                        label="Unarchive"
                                    />
                                )}
                            </DropdownContent>
                        </Dropdown>
                    )}
                </Box>
                <Box
                    style={{ maxHeight: '1.75rem' }}
                    textAlign="center"
                    maxWidth="full"
                    textOverflow="clip"
                    overflow="hidden"
                >
                    {stack.name}
                </Box>
            </Box>
        </Box>
    )
}

function StyledNewAppButton({ onClick }: { onClick: () => void }) {
    return (
        <Box
            style={{ paddingTop: 0 }}
            px="l"
            role="button"
            cursor="pointer"
            onClick={onClick}
            fontSize="bodyM"
            className={HoverContainerStyle}
        >
            <Box flex column center width="6xl" maxWidth="6xl" overflow="hidden" color="textWeaker">
                <Box
                    flex
                    column
                    center
                    justifyContent="center"
                    width="6xl"
                    height="6xl"
                    rounded="l"
                    mb="m"
                    background="surfaceStrongest"
                    color="text"
                    flexShrink={0}
                    className={AddButtonIconStyle}
                >
                    <Icon name="Plus" opacity={0.6} size="3xl" />
                </Box>
                Add
            </Box>
        </Box>
    )
}
function AddAppButton({ group, pinToAppBar }: { group: string; pinToAppBar: boolean }) {
    const handleBeforeCreateStack = (stack: ReturnType<typeof createNewStackData>) => {
        stack.options.group = group
        stack.options.pin_to_app_bar = pinToAppBar
    }
    return <NewAppButton as={StyledNewAppButton} onBeforeCreateStack={handleBeforeCreateStack} />
}
