import React from 'react'

import { TableViewColumn } from 'features/views/ListView/TableView/types'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Tooltip } from 'ui/components/Tooltip'
import { theme } from 'ui/styling/Theme.css'

import { useTableViewHeaderSortState } from './hooks/useTableViewHeaderSortState'

type TableViewHeaderSortProps = {
    column: TableViewColumn
}

export const TableViewHeaderSort: React.FC<TableViewHeaderSortProps> = React.memo(
    function TableViewHeaderSort({ column }) {
        const { sortDirection, toggleSortDirection, sortIcon } = useTableViewHeaderSortState({
            column,
        })

        return (
            <Box flex center noShrink>
                <Tooltip content="Change sort direction" side="bottom" zIndex={99} asChild>
                    <button
                        type="button"
                        aria-label="Change sort direction"
                        onClick={toggleSortDirection}
                    >
                        <Icon
                            name={sortIcon}
                            size="s"
                            style={{
                                color: sortDirection ? 'inherit' : theme.color.iconDisabled,
                            }}
                        />
                    </button>
                </Tooltip>
            </Box>
        )
    }
)
