import React from 'react'
import { ControllerRenderProps, FieldError } from 'react-hook-form'

import { Select, SelectOption } from 'ui/components/Select'

type RoleOption = { value: string; label: string }

export const RolePicker = ({
    roles,
    error,
    ...rest
}: {
    roles: RoleOption[]
    error?: FieldError
    modal?: boolean
    rest?: ControllerRenderProps
}) => {
    return (
        <Select
            label="Role"
            startIcon={{ name: 'Lock' }}
            placeholder="Select role..."
            isError={!!error}
            helperText={!!error ? error.message : null}
            isClearable={roles.length > 0}
            readOnly={roles.length === 1}
            {...rest}
        >
            {roles.map(({ value, label }) => (
                <SelectOption key={value} value={value} label={label} />
            ))}
        </Select>
    )
}
