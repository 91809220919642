import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import styled from '@emotion/styled'
import { keyBy } from 'lodash'

import { useAppContext } from 'app/AppContext'
import { useDataConnection } from 'data/hooks/dataConnections'
import { getAvailableFieldTypes } from 'features/admin/fields/logic/availableFieldOperationUtils'
import { useSortingByFieldsOrder } from 'features/datagrid/hooks/useDefaultFieldsOrder'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import LayoutObjectEditor from 'features/utils/LayoutObjectEditor'

import { Box, Flex, Heading, Slide, Text } from 'v2/ui'
import AllFieldsModeRadio from 'v2/ui/components/FieldsEditor/AllFieldsModeRadio'
import FieldAttributesEditor from 'v2/ui/components/FieldsEditor/FieldAttributesEditor'
import FieldListEditor, { SECTION } from 'v2/ui/components/FieldsEditor/FieldListEditor'

import { generateAllFields } from './common'
import { CreateFieldPopover } from './CreateFieldPopover'
import { FieldsEditorContextProvider, useFieldsEditorContext } from './FieldsEditorContext'
import { Item } from './types'

type HideOn = React.ComponentPropsWithoutRef<typeof Slide>['hideOn']

const StaticSlide = styled.div<{ hideOn?: HideOn }>`
    display: ${({ hideOn }) => (hideOn ? 'none' : 'block')};
`

type FieldsEditorProps = {
    object: ObjectDto
    items: Item[]
    setItems: (items: Item[]) => void
    filterField?: (field: FieldDto) => boolean
    allowEditSections?: boolean
    autoHideEditButton?: boolean
    disallowSections?: boolean
    includePrimaryFieldInAllFieldsMode?: boolean
    hideColumns?: boolean
    hideCreateButtonSetting?: boolean
    hideDisabledSearchSetting?: boolean
    hideFieldRequiredSetting?: boolean
    hideFieldFullWidthSetting?: boolean
    hideFieldDescriptionSetting?: boolean
    hideHeading?: boolean
    hideRichTextSetting?: boolean
    hideTopSection?: boolean
    maxItemsSelected?: number
    hideUrlOptions?: boolean
    setIsEditingItem?: (isEditingItem: boolean) => void
    showAllFieldsModeSetting?: boolean
    showAllFields?: boolean
    // Designates that the component is being displayed as a layout editor component
    // and should let the LayoutEditorContext know when its root view is being displayed
    // for navigation purposes.
    isLayoutEditorComponent?: boolean
    addNewLabel?: string
    dataTestId?: string
    display?: string
    description?: string
    overwriteEditFieldFn?: (item: Item, event: Record<string, any>) => void // TODO: better type
    style?: React.CSSProperties
    onFieldCreated?: (field: FieldDto) => Promise<unknown>
    onFieldConfigChanged?: (patch: Record<string, any>) => void // TODO: better type
    onFieldListChanged?: () => void
    onShowAllFieldsModeChange?: (showAllFields: boolean) => void
    banner?: JSX.Element
    showDefaultValueSetting?: boolean
    showPlaceholderSetting?: boolean
    isCreate?: boolean
    allowPortals?: boolean
    animate?: boolean
    showConditionalVisibility?: boolean
    conditionalVisibilityFilters?: { [fieldSid: string]: Filter[] }
    onConditionalVisibilityFiltersChange?: (filtersMap: { [fieldSid: string]: Filter[] }) => void
}

const FieldsEditor = ({
    object,
    items,
    setItems,
    filterField,
    hideHeading = false,
    disallowSections,
    hideFieldRequiredSetting,
    hideFieldFullWidthSetting,
    hideFieldDescriptionSetting,
    hideRichTextSetting,
    hideCreateButtonSetting,
    hideDisabledSearchSetting,
    hideTopSection = false,
    showAllFieldsModeSetting = false,
    maxItemsSelected,
    setIsEditingItem,
    hideUrlOptions,
    hideColumns,
    isLayoutEditorComponent,
    overwriteEditFieldFn,
    autoHideEditButton = true,
    style = {},
    display = '',
    onFieldCreated,
    onFieldConfigChanged = undefined,
    onFieldListChanged = () => {},
    showAllFields = undefined,
    onShowAllFieldsModeChange = () => {},
    includePrimaryFieldInAllFieldsMode = false,
    addNewLabel = 'Add new',
    allowEditSections = true,
    description,
    dataTestId = '',
    banner,
    isCreate,
    showDefaultValueSetting = false,
    showPlaceholderSetting = false,
    allowPortals = true,
    animate = true,
    conditionalVisibilityFilters = {},
    onConditionalVisibilityFiltersChange,
    showConditionalVisibility = false,
}: FieldsEditorProps) => {
    const {
        editingFieldItem,
        setEditingFieldItem,
        isEditorOpen,
        setIsEditorOpen,
        openEditorInPortal,
        setOpenEditorInPortal,
        previousEditorTitle,
        setPreviousEditorTitle,
    } = useFieldsEditorContext()

    const layoutEditorContext = useContext(LayoutEditorContext)
    const sortByFieldsOrder = useSortingByFieldsOrder(object)

    const { selectedStack: stack, workspaceAccount: account } = useAppContext()
    const { data: dataConnection } = useDataConnection(object.data_connection)

    items = items || []

    const availableFields = useMemo(
        () =>
            stack && account
                ? getAvailableFieldTypes({
                      connectionType: dataConnection ? dataConnection.type : undefined,
                      existingField: undefined,
                  })
                : [],
        [account, dataConnection, stack]
    )

    // Filter out any disabled or disallowed fields
    const fields: FieldDto[] = useMemo(
        () =>
            sortByFieldsOrder(object.fields).filter((field) => {
                const enabled = !(field.connection_options && field.connection_options.is_disabled)
                const hidePrimary =
                    field.is_primary && showAllFields && !includePrimaryFieldInAllFieldsMode
                return enabled && (!filterField || filterField(field)) && !hidePrimary
            }),
        [
            sortByFieldsOrder,
            object.fields,
            showAllFields,
            includePrimaryFieldInAllFieldsMode,
            filterField,
        ]
    )

    const selectedItems = useMemo(() => {
        const allFieldsModeEnabled = showAllFields && !!showAllFieldsModeSetting
        const fieldsById = keyBy(fields, (field) => field._sid)

        if (!allFieldsModeEnabled) {
            return items.filter((item) => !item.fieldId || fieldsById[item.fieldId])
        }

        return generateAllFields(fields, items, includePrimaryFieldInAllFieldsMode)
    }, [showAllFields, showAllFieldsModeSetting, fields, items, includePrimaryFieldInAllFieldsMode])

    const handleUpdate = useCallback(
        (items) => {
            setItems(items)
        },
        [setItems]
    )

    const handleListUpdate = useCallback(
        (items) => {
            handleUpdate(items)
            onFieldListChanged?.()
        },
        [handleUpdate, onFieldListChanged]
    )

    const getEditorTitle = () => {
        if (editingFieldItem?.fieldId) {
            return fields.find((x) => x._sid === editingFieldItem.fieldId)?.label ?? ''
        } else if (editingFieldItem?.type === SECTION) {
            return 'Section'
        }
    }
    const onFieldEditorClose = () => {
        setIsEditorOpen(false)
        if (setIsEditingItem) setIsEditingItem(false)
    }

    useEffect(() => {
        if (isEditorOpen) {
            // If we alredy have an active editor displayed in the side bar (as is the case
            // in detail pages where the field container editor is already open), then we
            // want to simply update the layoutEditorContext with a new title, and override
            // the behavior of the sidebar's Back button.
            if (layoutEditorContext?.activeEditor) {
                // when the field editing pane opens, tell the
                // editor context we want to handle the "back" button
                // and also update the title to show the field name
                layoutEditorContext.setcloseHandler(onFieldEditorClose)
                setPreviousEditorTitle(layoutEditorContext.activeEditor.title)
                // @ts-expect-error
                layoutEditorContext.setActiveEditorTitle(getEditorTitle())
            } else if (allowPortals) {
                // Otherwise, we will open the fields editor via the layout context portaling system
                // to display it in the side bar.
                setOpenEditorInPortal(true)
            }
        } else if (isLayoutEditorComponent && previousEditorTitle) {
            // Restore the previous editor title if the field editor pane is closed
            layoutEditorContext.setActiveEditorTitle(previousEditorTitle)
            // and reliquish control of the back button/close handler
            layoutEditorContext.setcloseHandler(null)
            setPreviousEditorTitle(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditorOpen])

    const handleSaveField = (id: string, patch: Record<string, any>) => {
        handleUpdate(
            selectedItems.map((x) =>
                (x.fieldId && x.fieldId === id) || (x.id && x.id === id) ? { ...x, ...patch } : x
            )
        )
        onFieldConfigChanged?.(patch)
    }

    const handleEditField = useCallback((item, event) => {
        if (overwriteEditFieldFn) {
            return overwriteEditFieldFn(item, event)
        }
        // We don't want to display other attributes (like conditionalVisibility) if we're editing a field,
        // so we will tell the RenderBlockTypeAttributes class to display only this.
        if (setIsEditingItem) setIsEditingItem(true)
        setEditingFieldItem(item)
        setIsEditorOpen(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const SlideComponent = animate ? Slide : StaticSlide

    const fieldEditor = (
        <Box position="relative">
            <SlideComponent paddingLeft="5px" hideOn={isEditorOpen ? undefined : 'right'}>
                {editingFieldItem && (
                    <FieldAttributesEditor
                        fields={fields}
                        key={editingFieldItem.fieldId}
                        item={editingFieldItem}
                        onUpdate={handleSaveField}
                        hideFieldRequiredSetting={hideFieldRequiredSetting}
                        hideFieldFullWidthSetting={hideFieldFullWidthSetting}
                        hideFieldDescriptionSetting={hideFieldDescriptionSetting}
                        hideCreateButtonSetting={hideCreateButtonSetting}
                        hideRichTextSetting={hideRichTextSetting}
                        hideUrlOptions={hideUrlOptions}
                        hideColumns={hideColumns}
                        hideDisabledSearchSetting={hideDisabledSearchSetting}
                        display={display}
                        showDefaultValueSetting={showDefaultValueSetting}
                        showPlaceholderSetting={showPlaceholderSetting}
                        isCreate={isCreate}
                    />
                )}
            </SlideComponent>
        </Box>
    )

    return (
        <>
            <Box
                position="relative"
                height={animate ? '100%' : undefined}
                style={style}
                data-testid={dataTestId}
                overflowX="hidden"
            >
                <SlideComponent
                    // @ts-expect-error
                    hideOn={isEditorOpen ? 'left' : undefined}
                    display="flex"
                    flexDirection="column"
                    paddingLeft="5px"
                >
                    <Box mb={2}>
                        {description && (
                            <Text color="gray.600" fontSize="12px" marginBottom="0.5rem">
                                {description}
                            </Text>
                        )}
                        <Flex
                            m={0}
                            p={0}
                            flexDirection="row-reverse"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {availableFields.length > 0 && (
                                <CreateFieldPopover
                                    object={object}
                                    buttonVariant="Primary"
                                    cta={addNewLabel}
                                    usePortal
                                    onFieldCreated={onFieldCreated}
                                ></CreateFieldPopover>
                            )}
                            {!hideHeading && (
                                <Heading variant="sideMenuSubHeading" value="Fields" />
                            )}
                        </Flex>
                    </Box>
                    {!!showAllFieldsModeSetting && (
                        <AllFieldsModeRadio
                            allFieldsModeEnabled={!!showAllFields}
                            onChange={onShowAllFieldsModeChange}
                        />
                    )}
                    {banner}
                    <FieldListEditor
                        object={object}
                        fields={fields}
                        selectedItems={selectedItems}
                        onUpdate={handleListUpdate}
                        onEdit={handleEditField}
                        disallowSections={disallowSections}
                        maxItemsSelected={maxItemsSelected}
                        autoHideEditButton={autoHideEditButton}
                        allFieldsModeEnabled={showAllFields && !!showAllFieldsModeSetting}
                        allowEditSections={allowEditSections}
                        hideTopSection={hideTopSection}
                        showConditionalVisibility={showConditionalVisibility}
                        conditionalVisibilityFilters={conditionalVisibilityFilters}
                        onConditionalVisibilityFiltersChange={onConditionalVisibilityFiltersChange}
                    />
                </SlideComponent>
                {openEditorInPortal ? (
                    <LayoutObjectEditor
                        isOpen={isEditorOpen}
                        title={getEditorTitle()}
                        onClose={onFieldEditorClose}
                    >
                        {fieldEditor}
                    </LayoutObjectEditor>
                ) : (
                    fieldEditor
                )}
            </Box>
        </>
    )
}

type FieldsEditorWrapperProps = FieldsEditorProps & {
    useContext?: boolean
}

const FieldsEditorWrapper: React.FC<FieldsEditorWrapperProps> = ({ useContext, ...props }) => {
    if (useContext) {
        return <FieldsEditor {...props} />
    }

    return (
        <FieldsEditorContextProvider>
            <FieldsEditor {...props} />
        </FieldsEditorContextProvider>
    )
}

export default React.memo(FieldsEditorWrapper)
