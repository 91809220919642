import React from 'react'

import { ActivityType } from 'data/hooks/activityTypes'

import { Box } from 'ui/components/Box'
import { StandardComponentProps } from 'ui/helpers/styles'

import { TypeIcon } from './ActivityTypeIcon'
import { FeedFilter } from './FeedFilter'

import { SelectedFilterItemIcon } from './FeedPage.css'

type ActivityTypesFilterProps = StandardComponentProps & {
    activityTypes: { id: ActivityType; label: string }[]
    selectedTypes?: number[]
    setSelectedTypes: (types: number[]) => void
}
export function ActivityTypesFilter({
    activityTypes,
    selectedTypes,
    setSelectedTypes,
    ...props
}: ActivityTypesFilterProps) {
    const effectiveIds = !selectedTypes?.length
        ? activityTypes?.map((item) => item.id) ?? []
        : selectedTypes

    return (
        <FeedFilter
            items={activityTypes}
            idField="id"
            selectedIds={effectiveIds}
            setSelectedIds={setSelectedTypes}
            renderItem={(item) => (
                <Box flex center>
                    <TypeIcon key={item.id} type={item.id} mr="m" />
                    {item.label}
                </Box>
            )}
            renderContent={(items) => (
                <>
                    <Box flex flexDirection="row-reverse" mr="xs">
                        {items
                            .slice(0, 10)
                            .reverse()
                            .map((item, index) => (
                                <TypeIcon
                                    key={item.id}
                                    type={item.id}
                                    className={index > 0 ? SelectedFilterItemIcon : undefined}
                                    style={{
                                        zIndex: index,
                                    }}
                                />
                            ))}
                    </Box>
                    {items.length === 1
                        ? items[0].label
                        : items.length === activityTypes.length
                        ? 'all activities'
                        : `${items.length} types`}
                </>
            )}
            {...props}
        />
    )
}
