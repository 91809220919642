const CACHE_BACKEND_DEFAULT = 'data_store'

const CACHE_BACKEND_INFER_WORKSPACE = null

export function getCacheBackendType(
    stackOptions?: StackDto['options'],
    accountOptions?: Account['options']
): string {
    const stackCacheBackend = stackOptions?.caching__backend

    if (stackCacheBackend === CACHE_BACKEND_INFER_WORKSPACE) {
        return accountOptions?.caching_backend || CACHE_BACKEND_DEFAULT
    }

    return stackCacheBackend ?? CACHE_BACKEND_DEFAULT
}
