import { useEffect, useMemo, useState } from 'react'

import { useRecords } from 'data/hooks/records'
import { getAvatarFieldApiNames } from 'features/views/attributes/hooks/useUserRecordLinksAvatars'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

const MAX_RECORDS = 100
const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

type useLookupRecordsOptions = {
    field: FieldDto
    searchQuery?: string
}

export function useLookupRecords(options: useLookupRecordsOptions) {
    const { field, searchQuery } = options

    const objectSid = field.link_target_object_id

    const usersObject = useStackerUsersObject()
    const isUsersObject = objectSid === usersObject._sid

    const includedFields: string[] = ['_primary']
    if (isUsersObject) {
        // Include the avatar fields for the users object.
        const avatarApiNames = getAvatarFieldApiNames(usersObject)
        includedFields.push(...avatarApiNames)
    }

    const fetchOptions = {
        includeFields: includedFields,
        pageSize: MAX_RECORDS,
        forcePartials: true,
    }

    const filters: Filter[] = []
    if (searchQuery) {
        filters.push({
            field: { api_name: '_search' },
            options: { value: searchQuery, operator: 'AND' },
        })
    }

    const { data, isError, isFetching } = useRecords({
        objectSid: objectSid!,
        filters,
        fetchOptions,
        options: {
            enabled: !!objectSid,
        },
    })

    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const memoizedRecords = useDeepEqualsMemoValue(data?.records as RecordDto[] | undefined)

    return useMemo(
        () => ({
            records: memoizedRecords,
            isError,
            isFetchingSlow,
        }),
        [memoizedRecords, isError, isFetchingSlow]
    )
}
