import React from 'react'

import { AttachmentsAttributeDisplay } from 'features/views/attributes/AttachmentsAttributeDisplay'
import { AttachmentsAttributeTag } from 'features/views/attributes/AttachmentsAttributeTag'
import { AttachmentValue } from 'features/views/attributes/types'

import { useAttachmentsAttributeState } from './hooks/useAttachmentsAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { CardViewAttributeComponent } from './types'

const MAX_ATTACHMENTS = 1
const MAX_ATTACHMENTS_IMAGE = 3
const MAX_ITEM_LENGTH = 20
const MAX_ITEM_LENGTH_SINGLE = 25
const MAX_LINES = 1

type AttachmentsAttributeProps = {}

export const AttachmentsAttribute: CardViewAttributeComponent<
    AttachmentValue[],
    AttachmentsAttributeProps
> = ({ value, attribute, isLoading, ...props }) => {
    const { field, rawOptions, type } = attribute

    const isTitle = type === 'title'

    const { displayAsImage, displayAsTag, isEmpty, isSingle, size } = useAttachmentsAttributeState({
        value,
        isLoading,
        attribute,
    })

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} {...props}>
            {displayAsTag ? (
                <AttachmentsAttributeTag
                    size={size}
                    value={value}
                    field={field}
                    maxItemLength={isSingle ? MAX_ITEM_LENGTH_SINGLE : MAX_ITEM_LENGTH}
                    isLoading={isLoading}
                    displayAsImage={displayAsImage}
                    imageAltText={rawOptions.imageAltText as string}
                />
            ) : (
                <AttachmentsAttributeDisplay
                    size={size}
                    value={value}
                    field={field}
                    maxAttachments={displayAsImage ? MAX_ATTACHMENTS_IMAGE : MAX_ATTACHMENTS}
                    maxItemLength={isSingle && !isTitle ? MAX_ITEM_LENGTH_SINGLE : MAX_ITEM_LENGTH}
                    maxLines={MAX_LINES}
                    displayAsImage={displayAsImage}
                    imageAltText={rawOptions.imageAltText as string}
                    isLoading={isLoading}
                />
            )}
        </BaseAttribute>
    )
}
