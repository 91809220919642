import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useListHeaderContext } from 'features/views/ListView/ListHeader/ListHeaderContext'
import { ListViewSort } from 'features/views/ListView/Sort/types'

import { IconName } from 'ui/components/Icon/Icon'
import { truncateText } from 'ui/helpers/utilities'

const MAX_SORT_LABEL_LENGTH = 20

type SortDirection = 'asc' | 'desc'

type SortControlOption = {
    label: string
    value: string
    onSet: () => void
}

export function useListHeaderSortControlState() {
    const { sortBy, setSortBy, availableSortFields, sortType, view } = useListHeaderContext()

    const sortDirection = getSortDirection(sortBy)
    const sortDirectionRef = useRef(sortDirection)
    sortDirectionRef.current = sortDirection

    const sortFieldApiName = sortBy?.id
    const sortFieldApiNameRef = useRef(sortBy?.id)
    sortFieldApiNameRef.current = sortBy?.id

    const toggleSortDirection = useCallback(() => {
        const sortDirection = sortDirectionRef.current

        const sortFieldApiName = sortFieldApiNameRef.current
        if (!sortFieldApiName) return

        switch (sortDirection) {
            case 'asc':
                setSortBy({ id: sortFieldApiName, desc: true })
                break
            case 'desc':
                setSortBy({ id: sortFieldApiName, desc: false })
                break
        }
    }, [setSortBy])

    const sortIcon = getSortIcon(sortDirection)

    const setSortField = useCallback(
        (apiName: string) => {
            const sortDirection = sortDirectionRef.current
            const isDesc = sortDirection === 'desc'
            setSortBy({ id: apiName, desc: isDesc })
        },
        [setSortBy]
    )

    const sortOptions = useMemo(
        () => makeSortControlOptions(availableSortFields, setSortField, view.options.columns),
        [availableSortFields, setSortField, view.options.columns]
    )

    const resetSort = useCallback(() => {
        setSortBy(undefined)
    }, [setSortBy])

    const formattedSortLabel = formatSortLabel(sortFieldApiName, sortOptions)

    const allowSort = view.options.display === 'boardV2' ? sortType !== 'manual' : true
    // Reset sort if sort type is changed to manual.
    useEffect(() => {
        if (!allowSort) {
            resetSort()
        }
    }, [allowSort, resetSort])

    return useMemo(
        () => ({
            sortOptions,
            sortIcon,
            toggleSortDirection,
            sortFieldApiName,
            resetSort,
            formattedSortLabel,
            allowSort,
        }),
        [
            sortFieldApiName,
            sortIcon,
            sortOptions,
            toggleSortDirection,
            resetSort,
            formattedSortLabel,
            allowSort,
        ]
    )
}

function makeSortControlOptions(
    availableFields: FieldDto[],
    setSortField: (apiName: string) => void,
    viewColumns: ListViewColumnConfig[]
): SortControlOption[] {
    const columnsByFieldSid = viewColumns.reduce(
        (acc, column) => acc.set(column.fieldId, column),
        new Map<string, ListViewColumnConfig>()
    )

    return availableFields.map((field) => {
        const column = columnsByFieldSid.get(field._sid)

        const label = truncateText(column?.label || field.label, MAX_SORT_LABEL_LENGTH)

        return {
            label,
            value: field.api_name,
            onSet: () => setSortField(field.api_name),
        }
    })
}

function getSortDirection(sortBy?: ListViewSort): SortDirection {
    if (sortBy?.desc) return 'desc'
    return 'asc'
}

function getSortIcon(sortDirection: SortDirection): IconName {
    switch (sortDirection) {
        case 'asc':
            return 'ArrowUpWideNarrow'
        case 'desc':
            return 'ArrowDownWideNarrow'
    }
}

function formatSortLabel(sortFieldApiName?: string, sortOptions?: SortControlOption[]): string {
    if (!sortFieldApiName) return 'Sort'

    const option = sortOptions?.find((o) => o.value === sortFieldApiName)
    if (!option) return 'Sort'

    const formattedOptionLabel = option.label.toLowerCase()

    return `Sort by ${formattedOptionLabel}`
}
