import { useEffect, useMemo, useState } from 'react'

import { useAppContext } from 'app/AppContext'
import { useGrants } from 'data/hooks/accessGrants'
import { workspaceGroups } from 'data/hooks/accounts'
import { useObjects } from 'data/hooks/objects'
import { useStackRoles } from 'data/hooks/roleHelpers'
import { AppGroup } from 'features/users/AppUsers/types'

import { truncateText } from 'ui/helpers/utilities'

const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000
const OBJECT_NAME_MAX_LENGTH = 30

export function useAppUsersDefaultPaneState() {
    const {
        data: grants,
        isFetching: isFetchingGrants,
        isError: isGrantsError,
    } = useGrants({
        refetchOnMount: true,
    })

    const {
        data: roles,
        isFetching: isFetchingRoles,
        isError: isRolesError,
    } = useStackRoles({
        refetchOnMount: true,
    })

    const { data: objects } = useObjects()
    const objectsBySid = useMemo(() => {
        return new Map(objects?.map((object) => [object._sid, object]))
    }, [objects])

    const isFetching = isFetchingGrants || isFetchingRoles

    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const { selectedStack } = useAppContext()
    const sharingSettings = selectedStack?.sharing

    const groups = useMemo(() => {
        if (isFetchingSlow) return PLACEHOLDER_GROUPS
        if (!grants) return []

        return mapGroups(grants, sharingSettings, objectsBySid)
    }, [isFetchingSlow, grants, sharingSettings, objectsBySid])

    const isError = isGrantsError || isRolesError

    const noGroups = groups && groups.length < 1 && !isError

    const appRoles = useMemo(() => {
        if (!roles) return []

        return roles.map((role) => ({
            id: role.api_name,
            name: role.label,
        }))
    }, [roles])

    return {
        groups,
        appRoles,
        isLoading: isFetchingSlow,
        noGroups,
        isError,
    }
}

function mapGroups(
    grants: AccessGrant[],
    sharingSettings?: StackDto['sharing'],
    objects?: Map<string, ObjectDto>
) {
    const groups: AppGroup[] = []

    for (const group of workspaceGroups) {
        const role = sharingSettings?.groups?.[group._sid]
        if (!role) continue

        groups.push({
            name: group.name,
            role,
            id: group._sid,
            isWorkspaceGroup: true,
        })
    }

    for (const grant of grants) {
        if (grant.type !== 'table') continue

        const object = objects?.get(grant.object_sid ?? '')
        const objectName = object ? truncateText(object?.name, OBJECT_NAME_MAX_LENGTH) : undefined

        groups.push({
            name: grant.group_name ?? '',
            role: grant.role_api_name ?? '',
            id: grant._sid,
            isWorkspaceGroup: false,
            tableGrant: grant,
            objectName,
        })
    }

    return groups
}

const PLACEHOLDER_GROUPS: AppGroup[] = [
    {
        id: '1',
        name: 'Marketing',
        isWorkspaceGroup: true,
        role: 'internal_admin',
    },
    {
        id: '2',
        name: 'Engineering',
        isWorkspaceGroup: false,
        role: 'user',
    },
    {
        id: '3',
        name: 'Sales',
        isWorkspaceGroup: false,
        role: 'user',
    },
]
