import { getLocale } from 'utils/dateUtils'

type UseNumberAttributeDisplayStateProps = {
    value?: number
    isLoading?: boolean
}

export function useNumberAttributeDisplayState({
    value,
    isLoading,
}: UseNumberAttributeDisplayStateProps) {
    const content = formatNumberValue(isLoading ? PLACEHOLDER_VALUE : value)

    return { content }
}

const MAX_FRACTION_DIGITS = 5

function formatNumberValue(value?: number): string {
    if (typeof value !== 'number' || Number.isNaN(value)) return '0'

    const locale = getLocale()
    const formatter = new Intl.NumberFormat(locale, {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: MAX_FRACTION_DIGITS,
    })
    const formattedValue = formatter.format(value)

    return formattedValue
}

const PLACEHOLDER_VALUE = 123456.78901
