import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { useDropdownContext } from 'ui/components/Dropdown/DropdownContext'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useListHeaderSortControlState } from './hooks/useListHeaderSortControlState'

type ListHeaderSortControlProps = {}

export const ListHeaderSortControl: React.FC<ListHeaderSortControlProps> = () => {
    const {
        sortIcon,
        sortOptions,
        toggleSortDirection,
        sortFieldApiName,
        resetSort,
        formattedSortLabel,
        allowSort,
    } = useListHeaderSortControlState()

    const hasSort = !!sortFieldApiName

    const showDirection = useResponsiveValue({
        mobile: false,
        tablet: hasSort,
    })

    if (!allowSort) return null

    return (
        <Box>
            {showDirection && (
                <Button
                    variant="ghost"
                    size="s"
                    startIcon={{
                        name: sortIcon,
                    }}
                    aria-label="Set sort direction"
                    onClick={toggleSortDirection}
                />
            )}
            <Dropdown>
                <FieldDropdownButton hasSort={hasSort}>{formattedSortLabel}</FieldDropdownButton>
                <DropdownContent
                    align="end"
                    style={{
                        minWidth: '200px',
                    }}
                >
                    {sortOptions.map((option) => (
                        <DropdownItem
                            key={option.value}
                            checked={option.value === sortFieldApiName}
                            onCheckedChange={option.onSet}
                            label={option.label}
                            multiSelect
                            closeOnSelect
                        />
                    ))}
                    <DropdownItem variant="action" label="Reset to default" onClick={resetSort} />
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}

type FieldDropdownButtonRef = React.ForwardedRef<HTMLButtonElement>

type FieldDropdownButtonProps = React.ComponentPropsWithoutRef<typeof DropdownButton> & {
    hasSort?: boolean
}

const FieldDropdownButton = React.forwardRef<FieldDropdownButtonRef, FieldDropdownButtonProps>(
    ({ children, hasSort, ...props }) => {
        const showLabel = useResponsiveValue({
            mobile: false,
            tablet: true,
        })

        const showActivatedState = useResponsiveValue({
            mobile: hasSort,
            tablet: false,
        })

        const showStartIcon = useResponsiveValue({
            mobile: true,
            tablet: !hasSort,
        })

        const { isOpen } = useDropdownContext()!

        return (
            <DropdownButton
                variant={showActivatedState ? 'activated' : 'ghost'}
                size="s"
                aria-label="Sort table by column"
                startIcon={showStartIcon ? { name: 'ArrowUpWideNarrow' } : undefined}
                endIcon={
                    showLabel
                        ? {
                              name: isOpen ? 'ChevronUp' : 'ChevronDown',
                          }
                        : undefined
                }
                {...props}
            >
                {showLabel && children}
            </DropdownButton>
        )
    }
)
