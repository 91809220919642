import React from 'react'

import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

import { useNumberAttributeDisplayState } from './hooks/useNumberAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type NumberAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Body> & {
    isLoading?: boolean
}

export const NumberAttributeDisplay: AttributeDisplayComponent<
    number,
    NumberAttributeDisplayProps
> = React.memo(function NumberAttributeDisplay({ value, isLoading = false, ...props }) {
    const { content } = useNumberAttributeDisplayState({ value, isLoading })

    return (
        <Body size="m" weight="regular" {...props}>
            {isLoading ? <Skeleton isLoading={isLoading}>{content}</Skeleton> : content}
        </Body>
    )
})
