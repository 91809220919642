import React, { useCallback, useState } from 'react'

import { Button, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'
import { AirtableUpgradeError } from 'v2/ui/svgs'

import { StyledFlex } from './StyledComponents'

type UpgradeFailureModalProps = {
    isOpen: boolean
    onClose: () => void
}

export const UpgradeFailureModal = ({ isOpen, onClose }: UpgradeFailureModalProps) => {
    const [hasOpenedSupport, setHasOpenedSupport] = useState(false)

    const openSupport = useCallback(
        (e) => {
            e.preventDefault()
            // @ts-expect-error
            if (!hasOpenedSupport && window.groove) {
                setHasOpenedSupport(true)
                // @ts-expect-error
                window.groove.widget.open()
            }
        },
        [hasOpenedSupport]
    )

    return (
        <SimpleModalCompat
            size="400px"
            height="400px"
            isSecondLayer
            noDividers
            padding="0 32px 40px 32px"
            zIndex={1501}
            actionsBorder={false}
            isOpen={isOpen}
            onClose={onClose}
            hideTitleBottomBorder
            data-testid="airtable-upgrade.failure-modal"
        >
            <StyledFlex>
                <AirtableUpgradeError />
                <Text variant="heading" marginTop={8} marginBottom={2}>
                    There was an error with your upgrade
                </Text>
                <Text fontSize="14px">
                    We&apos;re sorry, there was an error upgrading this connection. <br /> <br />
                    Please{' '}
                    <a href="#" onClick={openSupport}>
                        get in touch with Support
                    </a>{' '}
                    and we&apos;ll be able to help resolve the issue.
                </Text>
                <Button
                    flexGrow={1}
                    variant="Secondary"
                    maxW={221}
                    mr={5}
                    buttonSize="medium"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </StyledFlex>
        </SimpleModalCompat>
    )
}
