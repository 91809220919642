// @ts-strict-ignore
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { bindActionCreators } from 'redux'

import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'
import store from 'app/store'
import { dataConnectionActions } from 'data/api/dataConnectionApi'
import { getDataConnectionDebugInfo } from 'data/api/getDataConnectionDebugInfo'

export function useDataConnectionActions() {
    const dispatch = useDispatch()
    const actions = bindActionCreators(dataConnectionActions, dispatch)
    return actions
}
export function useDataConnection(dataConnectionId: string | null | undefined = null): {
    data: DataConnectionDto | null
    isLoading: boolean
} {
    const { data: dataConnections, isLoading } = useDataConnections()

    const data =
        dataConnections.find(({ _sid }) => !!dataConnectionId && _sid === dataConnectionId) || null

    return { isLoading, data }
}

function sortDataConnections(connections: DataConnectionDto[]): DataConnectionDto[] {
    const isUsersTable = (x: DataConnectionDto) => x.type === 'stacker_users'
    return connections.sort((a, b) => {
        if (isUsersTable(a) && !isUsersTable(b)) {
            return 1
        }
        if (isUsersTable(b) && !isUsersTable(a)) {
            return -1
        }
        return 0
    })
}

export function useDataConnections(): { data: DataConnectionDto[]; isLoading: boolean } {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const { selectedStack } = useAppContext()
    const { isAdmin } = useAppUserContext()

    const boundActions = bindActionCreators(dataConnectionActions, dispatch)
    const data = useSelector((state: { dataConnections: { entities: DataConnectionDto[] } }) =>
        sortDataConnections(Object.values(state?.dataConnections?.entities || {}))
    )
    const hasData = data?.length > 0 ?? false

    useEffect(() => {
        if (!isAdmin || !selectedStack) {
            return
        }

        // if we have no data, kick of a fetch. If a fetch is already
        // in progress, the action will ignore this request
        if (!hasData) {
            setIsLoading(true)
            boundActions.fetch()
        } else {
            // Once we have data, we can set loading to false
            setIsLoading(false)
        }
    }, [isAdmin, selectedStack, hasData, boundActions])

    return useMemo(() => ({ data, isLoading }), [isLoading, data])
}

export function refetchDataConnections() {
    // Refetch data connections
    // todo: Move to react-query
    const dispatch = store.dispatch
    const boundDataConnectionActions = bindActionCreators(dataConnectionActions, dispatch)
    return boundDataConnectionActions.fetch(undefined, { force: true, overrideLocalCache: false })
}

export function invalidateDataConnections() {
    // Invalidate
    // todo: Move to react-query - for now it just calls refetch
    return refetchDataConnections()
}

const DATA_CONNECTION_DEBUG_INFO_CACHE_KEY = 'DATA_CONNECTION_DEBUG_INFO_CACHE_KEY'

export const useDataConnectionDebugInfo = (dataConnectionSid?: string) => {
    return useQuery(
        [DATA_CONNECTION_DEBUG_INFO_CACHE_KEY, dataConnectionSid],
        () => getDataConnectionDebugInfo(dataConnectionSid ?? ''),
        {
            enabled: !!dataConnectionSid,
            // we don't the output of this endpoint to be cached as change in external factors can impact the repose
            staleTime: 0,
            cacheTime: 0,
        }
    )
}
