import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Redirect, useHistory } from 'react-router-dom'

import { getUrl, Urls } from 'app/UrlService'
import { useAccounts, useCreateAccount } from 'data/hooks/accounts'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'

import { AuthFrame } from './AuthFrame'

type FormData = {
    name: string
}

export function OnboardingStartPage() {
    const [error, setError] = useState(false)
    const { mutateAsync: createAccount } = useCreateAccount()
    const { data: accounts } = useAccounts()
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: { name: '' },
    })

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        try {
            setIsLoading(true)
            const account = await createAccount(data)
            history.push(getUrl(Urls.Root, undefined, account))
        } catch (ex) {
            console.error(ex)
            setError(true)
        } finally {
            setIsLoading(false)
        }
    }

    if (accounts?.length) {
        return <Redirect to={getUrl(Urls.Root)} />
    }
    return (
        <AuthFrame title="Welcome to Stacker!">
            <Box pt="3xl">Let&apos;s create your workspace.</Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                    mt="l"
                    {...register('name', { required: true })}
                    placeholder="enter workspace name"
                    isError={errors?.name}
                    helperText={errors?.name && 'Name is required'}
                    autoFocus
                />
                {error && (
                    <Box mt="l" color="error600">
                        An error occured.
                    </Box>
                )}
                <Button type="submit" mt="l" variant="primary" width="full" isLoading={isLoading}>
                    Get Started
                </Button>
            </form>
        </AuthFrame>
    )
}
