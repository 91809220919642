import React from 'react'

import styled from '@emotion/styled'
import * as Sentry from '@sentry/react'
import { css, cx } from 'emotion'
import { getUserLocale } from 'get-user-locale'
import moment from 'moment'
import PropTypes from 'prop-types'

import { formatValue } from 'data/utils/utils'
import WithObjects from 'data/wrappers/WithObjects'
import KeyValueList from 'features/studio/ui/KeyValueList'
import { Button, DatePicker, Dropdown, Icon, Input, Section, Text, TextArea } from 'legacy/v1/ui'
import Toggle from 'legacy/v1/ui/components/Toggle'
import EditableWrapper from 'legacy/v1/ui/utils/EditableWrapper'
import ReactFilestack from 'utils/lazyComponents/ReactFilestack'

import { Checkbox } from 'ui/components/Checkbox'

import 'moment/min/locales'

import { RecordDropdown } from './RecordDropdown'
import RecordLookup from './RecordLookup'

import 'react-datepicker/dist/react-datepicker.css'

const EditIcon = styled(Icon)`
    position: absolute;
    right: -7px;
    top: unset;
    font-size: 13px;
    display: none !important;
    cursor: pointer;
    padding: 5px 10px;
    text-shadow: 0px 0px 7px white;

    &:hover {
        display: inline-block !important;
    }
`

const ClearIcon = styled(Icon)`
    position: absolute;
    right: -3px;
    top: 25px;
    font-size: 13px;
    display: none !important;
    cursor: pointer;
    padding: 5px 10px;
    text-shadow: 0px 0px 7px white;

    &:hover {
        display: inline-block !important;
    }
`

const hover = css`
    &:hover + i {
        display: inline-block !important;
    }
`
const ellipsis = css`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250px;
`

const hoverChildren = css`
    &:hover > i {
        display: inline-block !important;
    }
    width: 100%;
    height: 100%;
    padding-right: 10px;
    display: inline-block;
`

const InlineImage = styled('img')`
    max-height: 150px;
    max-width: 150px;
    width: ${(props) => props.width || 'unset'};
    height: ${(props) => props.height || 'unset'};
    border-radius: ${(props) => (props.circle ? '100%' : 'unset')};
    vertical-align: middle;
`

class Attribute extends React.Component {
    // This component handles rendering attributes of records,
    // either as static display, inline-editable, pre-filled form
    // fields, or even blank form fields

    render() {
        if (!this.props.field) {
            return this.renderTextAttribute()
        }
        if (this.props.field.type === 'string') {
            return this.renderTextAttribute()
        }
        if (this.props.field.type === 'key_value') {
            return this.renderKeyValueAttribute()
        }
        if (this.props.field.type === 'long_text') {
            return this.renderLongTextAttribute()
        }
        if (this.props.field.type === 'document') {
            return this.renderDocumentAttribute()
        }
        if (this.props.field.type === 'number') {
            // TODO: Need to add validation here
            return this.renderTextAttribute()
        }
        if (this.props.field.type === 'autoid') {
            // TODO: Need to add validation here
            return this.renderAutoIDAttribute()
        }
        if (this.props.field.type === 'dropdown') {
            return this.renderDropdownAttribute()
        }
        if (this.props.field.type === 'multi_select') {
            return this.renderMultiSelectAttribute()
        }
        if (this.props.field.type === 'url') {
            return this.renderUrlAttribute()
        }
        if (this.props.field.type === 'image') {
            return this.renderImageAttribute()
        }
        if (this.props.field.type === 'date') {
            return this.renderDateAttribute()
        }
        if (this.props.field.type === 'copy') {
            return this.renderCopyAttribute()
        }
        if (this.props.field.type === 'lookup') {
            return this.renderLookupAttribute()
        }
        if (this.props.field.type === 'object_lookup') {
            return this.renderObjectLookupAttribute()
        }
        if (this.props.field.type === 'multi_lookup') {
            return this.renderMultiLookupAttribute()
        }
        if (this.props.field.type === 'checkbox') {
            return this.renderCheckboxAttribute()
        }
        if (this.props.field.type === 'password') {
            return this.renderPasswordAttribute()
        }
        if (this.props.field.type === 'file') {
            return this.renderFileAttribute()
        }
        if (this.props.field.type === 'multi_file') {
            return this.renderMultiFileAttribute()
        }
        if (this.props.field.type === 'datetime') {
            return this.renderDateTimeAttribute()
        }
        if (this.props.field.type === 'json') {
            return this.renderJSONAttribute()
        }
        if (this.props.field.type === 'currency') {
            return this.renderCurrencyAttribute()
        }
        if (this.props.field.type === 'currency_varying') {
            return this.renderCurrencyVaryingAttribute()
        }
        if (this.props.field.type === 'percentage') {
            return this.renderPercentageAttribute()
        }
        return this.renderTextAttribute()
    }

    // TODO:
    // currency

    renderCheckboxAttribute() {
        if (this.props.field.options && this.props.field.options.is_toggle) {
            return (
                <Toggle
                    disabled={!this.props.editable}
                    onChange={(val) => {
                        this.props.editable && this.onChange(val)
                    }}
                    value={this.props.children}
                />
            )
        }
        return (
            <Checkbox
                onCheckedChange={(val) => {
                    this.props.editable && this.onChange(val)
                }}
                checked={!!this.props.children}
            />
        )
    }

    renderLookupAttribute() {
        const form = (
            <RecordDropdown
                style={this.props.style}
                margin="none"
                objectId={this.props.field.link_target_object_id}
                value={this.props.children}
                onChange={this.onChange}
                autoFocus={this.props.inline}
            />
        )
        const display = this.props.children ? (
            <RecordLookup
                margin="none"
                recordId={this.props.children}
                noLink={this.props.noLinks}
            />
        ) : (
            '-'
        )

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end }) => React.cloneElement(form, { onBlur: end })}
                    value={({ start }) => (
                        <span className={hoverChildren}>
                            <EditIcon icon="pencil" onClick={start} />
                            {this.props.children && (
                                <ClearIcon icon="times" onClick={() => this.onChange('')} />
                            )}
                            {display}
                        </span>
                    )}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderObjectLookupAttribute() {
        let trueValue = this.props.children
        const iconWrap = (option) => (
            <>
                {option.icon}
                {option.label}
            </>
        )

        const form = (
            <WithObjects>
                {({ objects }) => {
                    const options = objects.map((x) => ({ label: x.name, value: x._sid }))

                    options.forEach((option) => {
                        if (option.value === this.props.children) trueValue = iconWrap(option)
                    })

                    return (
                        <Dropdown
                            style={this.props.style}
                            margin="none"
                            autoFocus={this.props.inline}
                            options={options}
                            onChange={this.onChange}
                            value={this.props.children}
                            optionRenderer={iconWrap}
                            valueRenderer={iconWrap}
                            id={this.props.id}
                        />
                    )
                }}
            </WithObjects>
        )

        const display = <Text margin="none">{trueValue || '-'}</Text>

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end }) => React.cloneElement(form, { onBlur: end })}
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderMultiLookupAttribute() {
        const form = (
            <RecordDropdown
                style={this.props.style}
                margin="none"
                objectId={this.props.field.link_target_object_id}
                value={this.props.children}
                onChange={this.onChange}
                autoFocus={this.props.inline}
                isMulti
            />
        )
        let display = '-'
        if (this.props.children && Array.isArray(this.props.children)) {
            let lookups = this.props.children.map((id) => (
                <RecordLookup
                    key={id}
                    margin="none"
                    recordId={id}
                    inline
                    noLink={this.props.noLinks}
                />
            ))
            let overflowEllipsis = false
            if (this.props.keepSmall) {
                if (lookups.length > 5) {
                    overflowEllipsis = true
                    lookups = lookups.slice(0, 6)
                }
            }
            display = (
                <Text>
                    {lookups.length ? lookups.reduce((prev, curr) => [prev, ', ', curr]) : '-'}
                    {overflowEllipsis ? '...' : ''}
                </Text>
            )
        }

        if (this.props.children && !Array.isArray(this.props.children)) {
            Sentry.withScope((scope) => {
                scope.setExtra('children', this.props.children)
                scope.setExtra('type', typeof this.props.children)

                scope.setLevel('warning')
                Sentry.captureMessage(
                    'Children is not an array on renderMultiLookupAttribute attribute '
                )
            })
        }

        if (this.props.editable && this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end }) => React.cloneElement(form, { onBlur: end })}
                    value={({ start }) => (
                        <span className={hoverChildren}>
                            <EditIcon icon="pencil" onClick={start} />
                            {this.props.children && (
                                <ClearIcon icon="times" onClick={() => this.onChange([])} />
                            )}
                            {display}
                        </span>
                    )}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderCopyAttribute() {
        const form = (
            <Input
                margin="none"
                onChange={(event) => this.onChange(event.target.value)}
                value={this.props.children || ''}
                disabled
            />
        )
        const display = <Text margin="none">{this.props.children || '-'}</Text>

        if (this.props.editable & this.props.inline) {
            return display
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderDateAttribute() {
        let dateval
        try {
            dateval = moment(this.props.children, 'YYYY-MM-DD')
        } catch (e) {
            // Also parse the ones we stored the old way
            dateval = moment(this.props.children, 'DD/MM/YYYY')
        }
        // let showVal = dateval.isValid() ? dateval.format("DD/MM/YYYY") : this.props.children + "."
        if (!dateval.isValid()) dateval = null

        const form = (
            <DatePicker
                selected={dateval || null}
                onChange={(item) => {
                    this.onChange(item.format('YYYY-MM-DD'))
                }}
                locale={getUserLocale()}
            />
        )
        let text = '-'
        if (dateval) {
            dateval.locale(getUserLocale())
            text = dateval.format('L')
        }
        const display = <Text margin="none">{text}</Text>

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(form, { innerRef: (me) => (ref.ref = me), onBlur: end })
                    }
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderDateTimeAttribute() {
        let dateval = moment(this.props.children).local()

        // let showVal = dateval.isValid() ? dateval.format("DD/MM/YYYY") : this.props.children + "."
        if (!dateval.isValid()) dateval = null
        if (dateval) dateval.locale(getUserLocale())

        const form = (
            <DatePicker
                showTimeSelect
                timeFormat="LT"
                timeIntervals={5}
                dateFormat="L LT"
                timeCaption="time"
                selected={dateval || null}
                onChange={(item) => {
                    console.log(item.format())
                    console.log(item.utcOffset(0).format())
                    this.onChange(item.utcOffset(0))
                }}
                locale={getUserLocale()}
            />
        )
        const display = (
            <Text margin="none">{(this.props.children && dateval.format('L LT')) || '-'}</Text>
        )

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(form, {
                            innerRef: (me) => (ref.ref = me),
                            afterChange: end,
                            onBlur: end,
                        })
                    }
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderImageAttribute() {
        const form = (
            <ReactFilestack
                options={{
                    accept: 'image/*',
                }}
                onSuccess={(value) => {
                    this.onChange(value.filesUploaded[0].url)
                }}
                customRender={({ onPick }) => (
                    <Button padding="small" onClick={onPick}>
                        Upload
                    </Button>
                )}
            />
        )
        let url = this.props.children
        try {
            if (url && url.indexOf('http') === -1 && url.indexOf('data:') === -1) {
                url = `http://${url}`
            }
        } catch (e) {
            url = null
        }

        const { circle, height, width } = this.props.options || {}

        const display = url ? (
            <InlineImage
                circle={circle}
                height={height}
                width={width}
                className={hover}
                src={url}
            />
        ) : (
            '-'
        )

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(form, {
                            innerRef: (me) => (ref.ref = me),
                            onBlur: end,
                            onSuccess: (value) => {
                                this.onChange(value.filesUploaded[0].url)
                                end()
                            },
                        })
                    }
                    value={({ start }) => (
                        <span className={hoverChildren}>
                            {display}
                            {this.props.children && (
                                <ClearIcon icon="times" onClick={() => this.onChange('')} />
                            )}
                            <EditIcon icon="pencil" onClick={start} />
                        </span>
                    )}
                />
            )
        }
        if (this.props.editable) {
            return (
                <Section margin="none" padding="none">
                    {this.props.children && display}
                    {form}
                </Section>
            )
        }
        return display
    }

    renderFileAttribute() {
        const form = (
            <ReactFilestack
                onSuccess={(value) => {
                    this.onChange(value.filesUploaded[0].url)
                }}
                customRender={({ onPick }) => (
                    <Button padding="small" onClick={onPick}>
                        Upload
                    </Button>
                )}
            />
        )
        let url = this.props.children
        try {
            if (url && url.indexOf('http') === -1 && url.indexOf('data:') === -1) {
                url = `http://${url}`
            }
        } catch (e) {
            console.log('Expected a string when displaying File')
        }

        const display = url ? (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={url} target="_blank">
                Download
            </a>
        ) : (
            '-'
        )

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(form, {
                            innerRef: (me) => (ref.ref = me),
                            onBlur: end,
                            onSuccess: (value) => {
                                this.onChange(value.filesUploaded[0].url)
                                end()
                            },
                        })
                    }
                    value={({ start }) => (
                        <span className={hoverChildren}>
                            {display}
                            {this.props.children && (
                                <ClearIcon icon="times" onClick={() => this.onChange('')} />
                            )}
                            <EditIcon icon="pencil" onClick={start} />
                        </span>
                    )}
                />
            )
        }
        if (this.props.editable) {
            return (
                <Section margin="none" padding="none">
                    {this.props.children && display}
                    {form}
                </Section>
            )
        }
        return display
    }

    renderMultiFileAttribute() {
        const form = (
            <ReactFilestack
                options={{
                    maxFiles: 5,
                }}
                onSuccess={(value) => {
                    let urls = value.filesUploaded.map((f) => f.url)
                    // append to current files
                    if (Array.isArray(this.props.children)) {
                        urls = this.props.children.concat(urls)
                    }
                    this.onChange(urls)
                }}
                customRender={({ onPick }) => (
                    <Button padding="small" onClick={onPick}>
                        Upload
                    </Button>
                )}
            />
        )

        const getFile = (attachement) => {
            const PreviewImg = styled('img')`
                width: 30px;
                max-height: 30px;
                margin-right: 10px;
            `
            let url
            let file

            // For airtable, we return a list of json with lots of information like img thumbnails
            if (typeof attachement === 'object') {
                url = attachement.url
                if (attachement.thumbnails) {
                    file = <PreviewImg src={attachement.thumbnails.small.url} alt="" />
                }
            } else {
                // If multi_file contains an array of urls, we don't display any preview image because that doesn't contain thumbnails
                url = attachement
            }

            if (!file) {
                // Display custom icon for common file extensions (supported by icons8)
                if (/\.(pdf|zip|doc|txt|html|xls|ppt)$/i.test(url)) {
                    // File type is not that useful for making icons8 icons
                    const extension = url.split('.').slice(-1).pop()
                    file = (
                        <PreviewImg
                            src={`https://img.icons8.com/ios/30/000000/${extension}.png`}
                            alt=""
                        />
                    )
                } else {
                    // Try to load the file as image, this will preview images for images without thumbnails, or no extensions
                    // If it fails, then we show a default icon
                    file = (
                        <PreviewImg
                            src={url}
                            alt=""
                            onError={(e) => {
                                e.target.onerror = null
                                e.target.src = 'https://img.icons8.com/ios/30/000000/document.png'
                            }}
                        />
                    )
                }
            }
            return { url, file }
        }

        const displayFiles = (canDelete) => {
            const displayUrls = this.props.children
            if (!displayUrls) return '-'

            if (!Array.isArray(displayUrls)) {
                Sentry.captureMessage(
                    `Expected a list of urls when using multi_file, but got: ${displayUrls}`
                )
                return '-'
            } else if (displayUrls.length > 0) {
                // Multiple attachements  (multi_file)
                return displayUrls.map((attachement) => {
                    const { url, file } = getFile(attachement)

                    return (
                        <div key={url} style={{ display: 'inline-block' }}>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={url} target="_blank">
                                {file}
                            </a>
                            {canDelete ? (
                                <Icon
                                    icon="trash"
                                    isButton
                                    style={{ marginBottom: '8px' }}
                                    onClick={() => {
                                        const urls = displayUrls.filter((at) => {
                                            if (typeof attachement === 'object') {
                                                // Airtable attachements can be a json with thumbnails
                                                return at.url !== url
                                            } else {
                                                // Array of strings
                                                return at !== url
                                            }
                                        })
                                        this.onChange(urls)
                                    }}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    )
                })
            } else return '-'
        }

        if (this.props.editable & this.props.inline) {
            // This will show up on the studio/tables
            return (
                <EditableWrapper
                    input={() => (
                        <Section margin="none" padding="none">
                            {displayFiles(true)}
                            {form}
                        </Section>
                    )}
                    value={({ start }) => (
                        <div className={hoverChildren} style={{ overflow: 'auto' }}>
                            {displayFiles(false)}

                            <EditIcon icon="pencil" onClick={start} />
                        </div>
                    )}
                />
            )
        }
        if (this.props.editable) {
            // This will show up on the admin and detail/create pages
            return (
                <Section margin="none" padding="none">
                    {displayFiles(true)}
                    {form}
                </Section>
            )
        }
        // Just display the attachements with thumbnails, without remove icon or Upload button (form)
        return displayFiles(false)
    }

    renderUrlAttribute() {
        const inlineForm = <Input margin="none" defaultValue={this.props.children || ''} />

        const form = (
            <Input
                margin="none"
                onChange={(event) => this.onChange(event.target.value)}
                value={this.props.children || ''}
            />
        )
        let url = this.props.children
        try {
            if (url && url.indexOf('http') === -1) {
                url = `http://${url}`
            }
        } catch (e) {
            console.log('Expected a string when displaying URL')
        }
        const display = (
            <Text className={hover}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a
                    className={cx(hover, ellipsis)}
                    href={url}
                    target="_blank"
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {this.props.children}
                </a>
            </Text>
        )

        if (this.props.editable && this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(inlineForm, {
                            innerRef: (me) => {
                                ref.ref = me
                            },
                            onBlur: end,
                        })
                    }
                    value={({ start }) => {
                        let displayInline = (
                            <Text margin="none" onClick={start} className={hover}>
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a
                                    className={hover}
                                    href={url}
                                    target="_blank"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    {this.props.children}
                                </a>
                            </Text>
                        )
                        const displayEmpty = (
                            <Text margin="none" onClick={start} className={hover}>
                                -
                            </Text>
                        )
                        if (!this.props.children) {
                            displayInline = displayEmpty
                        }
                        return (
                            <>
                                {displayInline}
                                <EditIcon icon="pencil" onClick={start} />
                            </>
                        )
                    }}
                    onChange={this.onChange}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderAutoIDAttribute() {
        const form = (
            <Input
                margin="none"
                onChange={(event) => this.onChange(event.target.value)}
                value={this.props.children || ''}
                disabled
                placeholder="generated value"
            />
        )
        const display = <Text margin="none">{this.props.children || '-'}</Text>

        if (this.props.editable & this.props.inline) {
            return display
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderLongTextAttribute() {
        const inlineForm = <TextArea margin="none">{this.props.children || ''}</TextArea>
        const form = (
            <TextArea
                fullWidth={this.props.options && this.props.options.fullWidth}
                margin="none"
                onChange={(event) => this.onChange(event.target.value)}
                style={this.props.style}
            >
                {this.props.children || ''}
            </TextArea>
        )
        let textToDisplay = this.props.children
        if (this.props.inline && textToDisplay && textToDisplay.length > 140) {
            textToDisplay = `${textToDisplay.slice(0, 140)}…`
        }
        const display = (
            <Text
                noOverflow={this.props.inline}
                style={{ whiteSpace: this.props.inline ? 'inherit' : 'pre-line' }}
                margin="none"
            >
                {textToDisplay || '-'}
            </Text>
        )

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(inlineForm, {
                            innerRef: (me) => (ref.ref = me),
                            onBlur: end,
                        })
                    }
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                    onChange={this.onChange}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderDocumentAttribute() {
        const inlineForm = <TextArea margin="none">{this.props.children || ''}</TextArea>
        const form = (
            <TextArea
                fullWidth={this.props.options && this.props.options.fullWidth}
                margin="none"
                onChange={(event) => this.onChange(event.target.value)}
                style={this.props.style}
            >
                {this.props.children || ''}
            </TextArea>
        )
        let textToDisplay = this.props.children
        if (this.props.inline && textToDisplay && textToDisplay.length > 140) {
            textToDisplay = `${textToDisplay.slice(0, 140)}…`
        }
        const display = (
            <Text
                noOverflow={this.props.inline}
                style={{ whiteSpace: this.props.inline ? 'inherit' : 'pre-line' }}
                margin="none"
            >
                {textToDisplay || '-'}
            </Text>
        )

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(inlineForm, {
                            innerRef: (me) => (ref.ref = me),
                            onBlur: end,
                        })
                    }
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                    onChange={this.onChange}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderJSONAttribute() {
        const inlineForm = (
            <TextArea margin="none">{JSON.stringify(this.props.children) || ''}</TextArea>
        )
        const form = inlineForm
        const display = <Text margin="none">{JSON.stringify(this.props.children) || '-'}</Text>

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(inlineForm, {
                            innerRef: (me) => (ref.ref = me),
                            onBlur: end,
                        })
                    }
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                    onChange={(v) => this.onChange(JSON.parse(v))}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderKeyValueAttribute() {
        const options = this.props.field.options || {}
        const form = (
            <KeyValueList
                keyName={options.keyName || 'label'}
                valueName={options.valueName || 'value'}
                onChange={this.onChange}
                value={this.props.children || []}
            />
        )

        return form
    }

    renderTextAttribute(options = {}) {
        let value = 'formattedValue' in options ? options.formattedValue : this.props.children
        const unpackForEditing = 'unpackForEditing' in options ? options.unpackForEditing : (x) => x
        const repackForSaving = 'repackForSaving' in options ? options.repackForSaving : (x) => x
        const editValue = unpackForEditing(this.props.children)
        if (value && !(typeof value === 'string' || value instanceof String))
            value = JSON.stringify(value)
        const inlineForm = <Input margin="none" defaultValue={editValue || ''} />

        const form = (
            <Input
                margin="none"
                onChange={(event) => this.onChange(repackForSaving(event.target.value))}
                defaultValue={editValue || ''}
                value={editValue || ''}
                placeholder={this.props.placeholder}
                style={this.props.style}
            />
        )

        const display = (
            <Text noOverflow margin="none">
                {value || '-'}
            </Text>
        )

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(inlineForm, {
                            innerRef: (me) => (ref.ref = me),
                            onBlur: end,
                        })
                    }
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                    onChange={this.onChange}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderPasswordAttribute() {
        const inlineForm = <Input type="password" margin="none" defaultValue="" />

        const form = (
            <Input
                type="password"
                margin="none"
                onChange={(event) => this.onChange(event.target.value)}
                defaultValue={this.props.children || ''}
                value={this.props.children || ''}
            />
        )

        const display = <Text margin="none">********</Text>

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end, ref }) =>
                        React.cloneElement(inlineForm, {
                            innerRef: (me) => (ref.ref = me),
                            onBlur: end,
                        })
                    }
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                    onChange={(value) => {
                        // Only update password if the value is not empty
                        if (value) this.onChange(value)
                    }}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderDropdownAttribute() {
        let trueValue = this.props.children
        const iconWrap = (option) => (
            <>
                {option.icon}
                {option.label}
            </>
        )
        const options = this.props.field?.options?.options || []
        options.forEach((option) => {
            if (option.value === this.props.children) trueValue = iconWrap(option)
        })
        const form = (
            <Dropdown
                style={this.props.style}
                margin="none"
                autoFocus={this.props.inline}
                options={options}
                onChange={this.onChange}
                value={this.props.children}
                optionRenderer={iconWrap}
                valueRenderer={iconWrap}
                id={this.props.id}
            />
        )
        const display = <Text margin="none">{trueValue || '-'}</Text>

        if (this.props.editable & this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end }) => React.cloneElement(form, { onBlur: end })}
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderMultiSelectAttribute() {
        const stringValue = Array.isArray(this.props.children)
            ? this.props.children.join(', ')
            : this.props.children
        const options = this.props.field?.options?.options || []

        const form = (
            <Dropdown
                style={this.props.style}
                margin="none"
                autoFocus={this.props.inline}
                options={options}
                onChange={this.onChange}
                value={this.props.children}
                isMulti
            />
        )
        const display = <Text margin="none">{stringValue || '-'}</Text>

        if (this.props.editable && this.props.inline) {
            return (
                <EditableWrapper
                    input={({ end }) => React.cloneElement(form, { onBlur: end })}
                    value={({ start }) => React.cloneElement(display, { onClick: start })}
                />
            )
        }
        if (this.props.editable) {
            return form
        }
        return display
    }

    renderCurrencyAttribute() {
        return this.renderTextAttribute({
            formattedValue: formatValue(this.props.field, this.props.children),
        })
    }

    renderCurrencyVaryingAttribute() {
        const symbol = this.props.children ? this.props.children.currency_symbol : null
        return this.renderTextAttribute({
            formattedValue: formatValue(this.props.field, this.props.children),
            unpackForEditing: (currencyBlob) => currencyBlob.value,
            repackForSaving: (currencyAmount) => ({
                value: currencyAmount,
                currency_symbol: symbol,
            }),
        })
    }

    renderPercentageAttribute() {
        return this.renderTextAttribute({
            formattedValue: formatValue(this.props.field, this.props.children),
        })
    }

    onChange = (value) => {
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }
}

Attribute.propTypes = {
    editable: PropTypes.bool,
    inline: PropTypes.bool,
    field: PropTypes.object,
    onChange: PropTypes.func,
    noLinks: PropTypes.bool,
}

Attribute.defaultProps = {
    noLinks: false,
}

export default Attribute
