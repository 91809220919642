import isRichTextField from 'utils/isRichTextField'

import { AddNewRecordCell } from './AddNewRecordCell'
import { AttachmentsCell } from './AttachmentsCell'
import { DateCell } from './DateCell'
import { DocumentCell } from './DocumentCell'
import { DropdownCell } from './DropdownCell'
import { LegacyRichTextCell } from './LegacyRichTextCell'
import { MagicUserFieldCell } from './MagicUserFieldCell'
import { PercentageCell } from './PercentageCell'
import { RecordLinkCell } from './RecordLinkCell'
import { DataGridCell } from './types'

type Kind = FieldType | 'legacy_rich_text' | 'add_new_record_button'

export const cells: Partial<Record<Kind, DataGridCell>> = {
    dropdown: DropdownCell,
    multi_select: DropdownCell,
    lookup: RecordLinkCell,
    multi_lookup: RecordLinkCell,
    long_text: LegacyRichTextCell,
    image: AttachmentsCell,
    multi_file: AttachmentsCell,
    file: AttachmentsCell,
    date: DateCell,
    datetime: DateCell,
    percentage: PercentageCell,
    user_ref: MagicUserFieldCell,
    legacy_rich_text: LegacyRichTextCell,
    document: DocumentCell,
    add_new_record_button: AddNewRecordCell,
}

export function getCellForField(field: FieldDto): DataGridCell | undefined {
    if (field.type === 'string' && isRichTextField(field)) {
        return cells.legacy_rich_text
    }

    return cells[field.type]
}
