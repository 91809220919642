type CacheBackend = 'postcache' | 'data_store'

/**
 * Returns an optional override value for the cache backend based on local storage key 'cacheBackendOverride'
 */
export const getCacheBackendOverride = (): CacheBackend | null => {
    const cacheBackendOverride = window.localStorage.getItem('cacheBackendOverride')
    return ['postcache', 'data_store'].includes(cacheBackendOverride ?? '')
        ? (cacheBackendOverride as CacheBackend)
        : null
}

export const setCacheBackendOverride = (value: CacheBackend | '') => {
    window.localStorage.setItem('cacheBackendOverride', value)
}
