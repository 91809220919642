import { useCallback, useMemo, useRef, useState } from 'react'

import { getAvatarFromUserRecord } from 'features/views/attributes/hooks/useUserRecordLinksAvatars'
import { ActionMenuEditProps } from 'features/views/ListView/Actions/types'
import { useLookupRecords } from 'features/views/ListView/hooks/useLookupRecords'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { truncateText } from 'ui/helpers/utilities'

const MAX_LABEL_LENGTH = 25
const DEBOUNCE_RATE = 200

type OptionValue = {
    label: string
    value: string
    avatar?: {
        imageUrl: string
        firstName: string
        lastName: string
        type: 'initial' | 'image'
    }
    isRecent?: boolean
}

type UseActionMenuEditRecordLinksStateOptions = ActionMenuEditProps<string | string[]> & {}

export function useActionMenuEditRecordLinksState(
    options: UseActionMenuEditRecordLinksStateOptions
) {
    const { field, updateRecord, value, recentValues } = options

    const fieldLabel = field.label

    const onClear = useCallback(() => {
        updateRecord({ [field.api_name]: null })
    }, [field.api_name, updateRecord])

    const currentValue = getCurrentValue(value)
    const internalValue = useDeepEqualsMemoValue(currentValue)
    const effectiveValue = useMemo(() => new Set(internalValue), [internalValue])
    const effectiveValueRef = useRef(effectiveValue)
    effectiveValueRef.current = effectiveValue

    const [searchQuery, setSearchQuery] = useState('')
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
    const applyDebouncedSearchQuery = useDebounce(setDebouncedSearchQuery, DEBOUNCE_RATE)

    const handleChangeSearchQuery = useCallback(
        (query: string) => {
            setSearchQuery(query)
            applyDebouncedSearchQuery(query)
        },
        [applyDebouncedSearchQuery]
    )

    const { isFetchingSlow, isError, records } = useLookupRecords({
        field,
        searchQuery: debouncedSearchQuery,
    })

    const usersObject = useStackerUsersObject()
    const editOptions = isFetchingSlow
        ? PLACEHOLDER_OPTIONS
        : makeOptions(records, usersObject, recentValues)

    const isSingle = field.type === 'lookup'

    const onEditValue = useCallback(
        (value: string, isChecked: boolean) => {
            if (field.type === 'lookup') {
                // Just replace the value.
                updateRecord({ [field.api_name]: value })
            } else {
                const existingValue = effectiveValueRef.current
                const newValue = new Set(existingValue)

                // Add or remove the value from the list of values.
                if (isChecked) {
                    newValue.add(value)
                } else {
                    newValue.delete(value)
                }

                updateRecord({ [field.api_name]: Array.from(newValue) })
            }
        },
        [field.api_name, field.type, updateRecord]
    )

    return useMemo(
        () => ({
            fieldLabel,
            editOptions,
            onEditValue,
            onClear,
            isError,
            isFetchingSlow,
            value: effectiveValue,
            isSingle,
            searchQuery,
            setSearchQuery: handleChangeSearchQuery,
        }),
        [
            fieldLabel,
            editOptions,
            onEditValue,
            onClear,
            isError,
            isFetchingSlow,
            effectiveValue,
            isSingle,
            searchQuery,
            handleChangeSearchQuery,
        ]
    )
}

function makeOptions(
    records?: RecordDto[],
    usersObject?: ObjectDto,
    recentValues?: (string | string[])[]
): OptionValue[] {
    if (!records) {
        return []
    }

    const uniqueRecentValues = new Set(recentValues?.flat(2))

    const options = records.map((record) => {
        const avatar = getAvatarFromUserRecord(record, usersObject)

        const recordPrimary = record?._primary || ''
        const label = typeof recordPrimary === 'string' ? recordPrimary : recordPrimary.toString()

        const value = record._sid
        const isRecent = uniqueRecentValues.has(value)

        return {
            label: truncateText(label, MAX_LABEL_LENGTH),
            value: record._sid,
            avatar,
            isRecent,
        }
    })

    options.sort((a, b) => {
        // Display recent values first.
        if (a.isRecent && !b.isRecent) return -1
        if (!a.isRecent && b.isRecent) return 1

        // Sort by label alphabetically.
        if (a.label.localeCompare(b.label) < 0) return -1
        if (a.label.localeCompare(b.label) > 0) return 1

        return 0
    })

    return options
}

const PLACEHOLDER_OPTIONS: OptionValue[] = [
    { label: 'Loading all options...', value: 'loading1' },
    { label: 'Loading all options...', value: 'loading2' },
    { label: 'Loading all options...', value: 'loading3' },
    { label: 'Loading all options...', value: 'loading4' },
    { label: 'Loading all options...', value: 'loading5' },
    { label: 'Loading all options...', value: 'loading6' },
]

function getCurrentValue(value?: string | string[]) {
    if (Array.isArray(value)) {
        return value
    }

    return value ? [value] : []
}
