import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFilters, usePagination, useTable } from 'react-table'

import { useTheme } from '@chakra-ui/react'
import { css } from '@emotion/core'
import find from 'lodash/find'
import get from 'lodash/get'
import queryString from 'query-string'
import CalendarView from 'v2/views/Calendar/CalendarView'
import InlineFilters from 'v2/views/List/InlineFilters'
import { TableCell } from 'v2/views/List/TableCell'
import { useUrlFilters } from 'v2/views/utils/useUrlFilters'

import ActionButtonsList from 'features/actions/ActionButtonsList'
import { useFavoritesEnabled } from 'features/favorites/useFavoritesEnabled'
import { NavigationItemFavoriteButton } from 'features/NewAppBar/FavoriteButton'
import {
    DefaultColumnFilter,
    filterBoolean,
    filterDropdown,
    fuzzyTextFilterFn,
} from 'features/views/List/Filters'
import { ListCallout } from 'features/views/List/ui/utils'

import { Button, ConditionalWrapper, Flex } from 'v2/ui'
import Cards from 'v2/ui/components/Cards'
import ContainerLabel from 'v2/ui/components/ContainerLabel'
import Dropdown from 'v2/ui/components/Dropdown'
import EmptyState from 'v2/ui/components/EmptyState'
import Kanban from 'v2/ui/components/Kanban'
import { KanbanSettings } from 'v2/ui/components/KanbanSettings'
import { useKanbanSettings } from 'v2/ui/components/kanbanUtils'
import Pagination from 'v2/ui/components/Pagination'
import Rows from 'v2/ui/components/Rows'
import Table from 'v2/ui/components/Table'
import Text from 'v2/ui/components/Text'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import { SolidDownload } from 'v2/ui/svgs'
import * as SvgIcons from 'v2/ui/svgs'
import { useIsMobile } from 'v2/ui/utils/useIsMobile'
import useRunOnce from 'v2/ui/utils/useRunOnce'

import { Box } from 'ui/components/Box'

import ListSearch from './ListSearch'
import { getDefaultPageSize, getPageSizeOptions } from './utils'

function List({
    columns = [],
    hiddenColumnIds = [],
    data = [],
    totalResults,
    display,
    rowLink,
    topFilters,
    buttons,
    title,
    showControls,
    object,
    isRecordList,
    setConfig,
    history,
    location,
    showFilters,
    userFilterFieldIds,
    hideSearch,
    viewOptions,
    setEndUserFilters,
    setEndUserFilters__NotForBackend, // used in charts
    enableBackendFiltering,
    isServerLoading,
    showPageSizeOptions,
    selectedRow,
    isInbox,
    brandColor,
    emptyStateAdditionalActions,
    additionalListContainerContent,
    dereferencedRecords,
    setCalendarFilter,
    calendarFilter,
    onOrderByChange,
    orderBy,
    getPageSizeOptions: computePageSizeOptions = getPageSizeOptions,
    getDefaultPageSize: computeDefaultPageSize = getDefaultPageSize,
    customRenderer: CustomRenderer,
    allowDownload,
    onDownloadCsv,
    lastFilters,
    setLastFilters,
    view,
    relatedListFieldId,
    relatedListFieldValue,
    relatedListViewId,
    openRecord,
    setServerPageIndex,
    setServerPageSize,
    serverPageSize,
    serverPageIndex,
    ignorePagination = false,
}) {
    const { colors } = useTheme()
    const [visibleData, setData] = useState([...data])
    const [emptySearchResult, setEmptySearchResult] = useState(false)
    const effectiveKanbanSettings = useKanbanSettings({ viewId: view?._sid, viewOptions })
    const query = useMemo(
        () => queryString.parse(isRecordList ? undefined : location?.search),
        [location?.search, isRecordList]
    )

    useMemo(() => setData([...data]), [data])

    const [searchValue, setSearchValue] = useState(query.search)

    const shouldUsePagination = !ignorePagination

    // We want to be able to search on self references too, so we pass through
    // both the main records and the dereferenced records
    const allListRecords = useMemo(() => {
        if (dereferencedRecords && Array.isArray(dereferencedRecords)) {
            return [...data, ...dereferencedRecords]
        }
        return data
    }, [data, dereferencedRecords])

    const renderCell = useCallback(
        (props) => {
            return TableCell({ ...props, dereferencedRecords: allListRecords })
        },
        [allListRecords]
    )

    //We override the renderCell method here so that we can pass dereferenced records
    const memoColumns = useMemo(() => {
        if (dereferencedRecords) return columns.map((c) => ({ ...c, Cell: renderCell }))
        return columns
    }, [columns, renderCell, dereferencedRecords])
    const memoData = useMemo(() => visibleData, [visibleData])
    const allData = useMemo(() => data, [data])
    const locationState = location?.state || {}

    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            dropdown: filterDropdown,
            boolean: filterBoolean,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                              .toLowerCase()
                              .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const tableOptions = useMemo(() => {
        let initialPageSize = 10

        // No paging on kanban view
        if (display === 'kanban') {
            initialPageSize = 9999999
        } else if (display === 'calendar') {
            initialPageSize = 200
        } else {
            initialPageSize = viewOptions?.pageSize || computeDefaultPageSize(display, view?.type)
        }

        return { pageSize: initialPageSize }
    }, [display, viewOptions?.pageSize, computeDefaultPageSize, view])

    const tableGetResetPageDeps = useCallback(({ state: { filters } }) => [filters], [])

    const { filters: inlineFilters, setFilters: setInlineFilters } = useUrlFilters(
        object,
        !isRecordList
    )

    useEffect(() => {
        const mergedFilters = [...inlineFilters]
        if (searchValue) {
            mergedFilters.push({ field: { api_name: '_search' }, options: { value: searchValue } })
        }
        setEndUserFilters?.(mergedFilters)
        setEndUserFilters__NotForBackend?.(mergedFilters)
        setLastFilters?.(mergedFilters)
    }, [
        setEndUserFilters,
        setEndUserFilters__NotForBackend,
        inlineFilters,
        searchValue,
        setLastFilters,
    ])
    const {
        headerGroups,
        prepareRow,
        page,
        gotoPage: setPageId,
        previousPage,
        nextPage,
        setPageSize: setListPageSize,
        canPreviousPage,
        canNextPage,
        setFilter,
        pageCount,
        setAllFilters,
        columns: tableColumns,
        state: { pageIndex, filters, pageSize: listPageSize },
    } = useTable(
        {
            columns: memoColumns,
            data: memoData,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: {
                ...tableOptions,
                ...(shouldUsePagination
                    ? {
                          pageIndex: serverPageIndex,
                      }
                    : {}),
                //in current version of react-table this is not working, but instead column.show = false used.
                // It's deprecated, so I leave this one here in case of the react table update, so we don't
                // accidentally have leaked columns
                hiddenColumns: hiddenColumnIds,
            },
            ...(shouldUsePagination
                ? {
                      manualPagination: true,
                      pageCount: Math.max(Math.ceil(totalResults / serverPageSize), 1),
                  }
                : {}),
            getResetPageDeps: tableGetResetPageDeps,
            disablePageResetOnDataChange: true,
        },
        useFilters,
        usePagination
    )

    const handleSetPageSize = useCallback(
        (pageSize) => {
            if (shouldUsePagination && display !== 'kanban') {
                setServerPageSize(pageSize)
            }
            setListPageSize(pageSize)
        },
        [setServerPageSize, setListPageSize, shouldUsePagination, display]
    )

    const handleSetPageIndex = useCallback(
        (idx) => {
            if (shouldUsePagination) {
                setServerPageIndex(idx)
            }
            setPageId(idx)
        },
        [setServerPageIndex, setPageId, shouldUsePagination]
    )

    const gotoPage = useCallback(
        (pageId) => {
            if (isRecordList || !history || !location) {
                handleSetPageIndex(pageId)
                return
            }
            let newQuery = query
            newQuery['page_num'] = pageId + 1
            if (!pageId) delete newQuery.page_num
            history?.push({
                pathname: location?.path,
                search: queryString.stringify(newQuery),
                state: { ...locationState },
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [handleSetPageIndex, isRecordList, location]
    )

    useEffect(() => {
        handleSetPageSize(tableOptions.pageSize)
    }, [tableOptions.pageSize, handleSetPageSize])
    // if the page number is set then move to this page
    useEffect(() => {
        if (isRecordList) return
        // If they try to go to a page that doesn't exist, update the page num
        if (query.page_num > pageCount) {
            gotoPage(pageCount - 1)
        }
        if (query.page_num - 1 != pageIndex) {
            const pageIdx = parseInt(query.page_num ? query.page_num - 1 : 0)
            handleSetPageIndex(pageIdx)
        }
    }, [query.page_num, pageIndex, pageCount, handleSetPageIndex, isRecordList, gotoPage])

    const firstUpdate = useRef(true)

    // When they set the filters, update the url
    useEffect(() => {
        // Stops a loop the first time the component is loaded
        if (firstUpdate.current) {
            firstUpdate.current = false
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, searchValue])

    useRunOnce(() => {
        // If persisted filters are supplied, load those now. (Persisted filters are ignored if
        // this is a record list)
        if (!lastFilters || isRecordList) return

        // Extract the search filter out. We'll handle that separately.
        let searchFilter
        const filters = lastFilters.filter((f) => {
            const isSearch = f.field?.api_name === '_search'
            if (isSearch) searchFilter = f

            return !isSearch
        })

        window.requestAnimationFrame(() => {
            setInlineFilters(filters)
            if (searchFilter) setSearchValue(searchFilter.options.value)
        })
    }, [lastFilters])

    const setSearchQuery = useCallback(
        (value) => {
            if (isRecordList || !history || !location) return
            let newQuery = query
            newQuery['search'] = value
            if (!value) delete newQuery.search
            delete newQuery.page_num
            handleSetPageIndex(0)
            history?.push({
                pathname: location?.path,
                search: queryString.stringify(newQuery),
                state: { ...locationState },
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRecordList, location, handleSetPageIndex]
    )

    const doSearch = useCallback(
        (value, results) => {
            setData(results)
            if (searchValue !== value) {
                setSearchValue(value)
                gotoPage(0)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [gotoPage, setSearchValue, setData]
    )

    // Note: if we're using back-end filtering we will use the total available result
    // count supplied from the server. This may be different than the number of results
    // returned due to server-side pagination.
    const actualPageCount =
        enableBackendFiltering && totalResults !== undefined
            ? Math.max(Math.ceil(totalResults / listPageSize), pageCount)
            : pageCount

    const pagination = (
        <Pagination
            pageCount={actualPageCount}
            pageIndex={pageIndex}
            previousPage={previousPage}
            pageSizeOptions={showPageSizeOptions && computePageSizeOptions(display)}
            setPageSize={handleSetPageSize}
            pageSize={listPageSize}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            gotoPage={gotoPage}
            isLoading={isServerLoading}
        />
    )

    const pageRows = page || []

    const lastHeaderGroup = headerGroups.slice(-1)[0]
    const mainHeader = lastHeaderGroup ? lastHeaderGroup.headers : []

    const clearAllFilters = () => {
        mainHeader.forEach((column) => {
            column.setFilter(null)
        })
        setSelectedFilter(null)
    }

    const [selectedFilter, setSelectedFilter] = useState(null)

    const getTopFilters = () => {
        const options = []

        const topFilterSelect = () => {
            topFilters.forEach((item) => {
                options.push({ value: item.label })
            })

            return (
                <Dropdown
                    placeholder="Select Filter"
                    fontSize="0.9em"
                    color="black"
                    width="200px"
                    padding="4px"
                    value={selectedFilter}
                    options={topFilters}
                    returnObject={true}
                    onChange={(selected) => {
                        clearAllFilters()

                        if (selected == null) {
                            return
                        }

                        const { value, id } = selected

                        if (id) {
                            setSelectedFilter(selected)
                            setFilter(id, value)
                        }
                    }}
                    isSearchable={false}
                />
            )
        }

        if (topFilters && topFilters.length > 0) {
            return topFilterSelect()
        }

        return null
    }

    const getDownloadButton = () => {
        if (!allowDownload) {
            return null
        }

        return (
            <Button
                variant="Secondary"
                buttonSize="mediumSquare"
                label="Download data as CSV"
                onClick={onDownloadCsv}
                hideSpinner
            >
                <SolidDownload color={colors.userInterface.neutral[800]} />
            </Button>
        )
    }

    //Check if there is a cover image column
    const coverImageEnabled = useMemo(() => {
        return find(columns, { id: 'stackCoverImageSetting' })
    }, [columns])

    //Check if there is a cover image column
    const shouldFitCoverImage = useMemo(() => {
        const cover = find(columns, { id: 'stackCoverImageSetting' })
        return cover && cover.fitImage
    }, [columns])

    const getCoverImage = (row) => {
        if (coverImageEnabled) return get(row, 'values.stackCoverImageSetting')
    }

    const renderHeader = mainHeader.map((header) => {
        return {
            props: header.getHeaderProps(),
            label: header.render('Header'),
            id: header.id,
            renderOptions: header.renderOptions,
            canSort:
                ['lookup', 'multi_lookup', 'multi_select'].indexOf(header.field?.type) < 0 &&
                !!onOrderByChange,
        }
    })

    const selectedButtons = viewOptions?.actionButtons
    const style = viewOptions?.actionButtonsStyle

    const getActionButtonsList = useCallback(
        (record) => (
            <ActionButtonsList
                record={record}
                buttons={selectedButtons}
                object={object}
                style={style}
                showControls={showControls}
            />
        ),
        [object, selectedButtons, style, showControls]
    )

    const renderData = pageRows.map((row) => {
        prepareRow(row)
        const sid = row?.original?._sid
        const rowProps = row.getRowProps() || {}
        return {
            row,
            props: { ...rowProps, key: sid || rowProps.key },
            coverImage: coverImageEnabled
                ? {
                      url: getCoverImage(row),
                      fitImage: shouldFitCoverImage ? 'contain' : 'cover',
                  }
                : false,
            cells: row.cells.map((cell, i) => {
                return {
                    value: cell.render('Cell'),
                    label: mainHeader[i].render('Header'),
                    column: cell.column,
                    originalValue: cell.value,
                }
            }),
            actionButtons:
                selectedButtons && selectedButtons.length
                    ? getActionButtonsList(row?.original)
                    : null,
        }
    })

    const search = (
        <ListSearch
            dereferencedRecords={allListRecords}
            useUrl={!isRecordList}
            columns={memoColumns}
            doSearch={doSearch}
            data={allData}
            value={searchValue}
            setEmptySearchResult={setEmptySearchResult}
            setSearchQuery={setSearchQuery}
            disableCtrlF={isRecordList}
            query={query}
            totalResults={totalResults}
            widthOverride={isInbox ? '100%' : undefined}
        />
    )

    const emptyViewCta = () => {
        const hasFilters = inlineFilters.length > 0
        return (
            <Flex column>
                <Text variant="emptyState" mb={5}>
                    {hasFilters ? 'Clear your filters and try again.' : ''}
                </Text>
                {!hasFilters ? null : (
                    <Button variant="sm" onClick={() => setInlineFilters([])}>
                        Clear Filters
                    </Button>
                )}
            </Flex>
        )
    }

    const emptyContent = () => {
        if (emptySearchResult) {
            return emptySearchResult
        }

        const hasFilters = inlineFilters.length > 0
        const customMessage = hasFilters ? 'No records match your filters' : ''

        return (
            <div>
                <EmptyState
                    compact={isRecordList}
                    cta={emptyViewCta}
                    custom={customMessage}
                    svg={hasFilters ? SvgIcons.EmptyFilters : SvgIcons.EmptyView}
                    additionalActions={!hasFilters ? emptyStateAdditionalActions : ''}
                />
            </div>
        )
    }

    const onTitleChange = useCallback((value) => setConfig({ title: value }), [setConfig])

    const isMobile = useIsMobile()

    //force card view on smaller screens
    if (isMobile && display != 'rows' && display != 'calendar') {
        display = 'card'
    }

    const favoritesEnabled = useFavoritesEnabled()

    const renderTitle = useMemo(
        () => (
            <ContainerLabel
                borderStyle="noBorder"
                variant="pageHeading"
                isEditable={showControls}
                topFilters={getTopFilters()}
                value={title}
                valueDecorator={
                    !isRecordList && favoritesEnabled ? (
                        <Box display="inline-flex" verticalAlign="middle" pb="xs">
                            <NavigationItemFavoriteButton fontSize="bodyM" ml="xs" />
                        </Box>
                    ) : undefined
                }
                buttons={buttons}
                downloadButton={getDownloadButton()}
                search={!hideSearch && search}
                onChange={onTitleChange}
                forceRenderHeading
                bodyContainerStyle={
                    isInbox
                        ? {
                              marginTop: 8,
                              paddingLeft: 12,
                              paddingRight: 12,
                          }
                        : undefined
                }
                inboxMode={isInbox}
                contentsCanWrap={true}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [title, search, buttons, showControls]
    )

    const userFilters = () => {
        if (!showFilters) return
        return (
            <ConditionalWrapper
                condition={isInbox}
                wrapper={(children) => (
                    <div
                        style={{
                            padding: '0 12px',
                            display: 'flex',
                        }}
                    >
                        {children}
                    </div>
                )}
            >
                <Flex
                    mb={4}
                    className={STYLE_CLASSES.INLINE_FILTERS}
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="flex-end"
                >
                    <InlineFilters
                        filters={inlineFilters}
                        object={object}
                        columns={tableColumns}
                        userFilterFieldIds={userFilterFieldIds}
                        onChange={setInlineFilters}
                    />
                    {inlineFilters.length > 0 && (
                        <Box>
                            <Button
                                fontSize={['button.sm', null, null, 'button.smLg']}
                                variant="clear"
                                m={0}
                                p={2}
                                pr={0}
                                onClick={() => setInlineFilters([])}
                                _hover={{
                                    color: 'userInterface.neutral.900',
                                }}
                            >
                                Clear all filters
                            </Button>
                        </Box>
                    )}
                </Flex>
            </ConditionalWrapper>
        )
    }

    const tableView = () => {
        return (
            <React.Fragment key={display}>
                {renderTitle}
                {userFilters()}
                <Table
                    emptyContent={emptyContent()}
                    header={renderHeader}
                    data={renderData}
                    pagination={pagination}
                    rowLink={rowLink}
                    additionalListContainerContent={additionalListContainerContent}
                    viewOptions={viewOptions}
                    setConfig={setConfig}
                    object={object}
                    onOrderByChange={onOrderByChange}
                    orderBy={orderBy}
                    isEditMode={showControls}
                    openRecord={openRecord}
                />
            </React.Fragment>
        )
    }

    const cardView = () => {
        return (
            <React.Fragment key={display}>
                {renderTitle}
                {userFilters()}
                <Cards
                    emptyContent={emptyContent()}
                    header={renderHeader}
                    data={renderData}
                    object={object}
                    pagination={pagination}
                    rowLink={rowLink}
                    additionalListContainerContent={additionalListContainerContent}
                    viewOptions={viewOptions}
                    setConfig={setConfig}
                    openRecord={openRecord}
                />
            </React.Fragment>
        )
    }

    const rowView = () => {
        return (
            <React.Fragment key={display}>
                {renderTitle}
                {userFilters()}
                <Rows
                    emptyContent={emptyContent()}
                    header={renderHeader}
                    data={renderData}
                    object={object}
                    pagination={pagination}
                    rowLink={rowLink}
                    additionalListContainerContent={additionalListContainerContent}
                    viewOptions={viewOptions}
                    setConfig={setConfig}
                    openRecord={openRecord}
                />
            </React.Fragment>
        )
    }

    const inboxView = () => {
        if (isInbox) {
            return (
                <React.Fragment key={display}>
                    {renderTitle}
                    {userFilters()}
                    <div style={{ position: 'relative', marginRight: '10px' }}>
                        {additionalListContainerContent}
                    </div>
                    <div style={{ overflowY: 'auto' }}>
                        <Rows
                            emptyContent={emptyContent()}
                            header={renderHeader}
                            data={renderData}
                            object={object}
                            pagination={pagination}
                            rowLink={rowLink}
                            selectedRow={selectedRow}
                            inboxView={true}
                            brandColor={brandColor}
                            viewOptions={viewOptions}
                            setConfig={setConfig}
                        />
                    </div>
                </React.Fragment>
            )
        }
    }

    const kanbanView = () => {
        const showSettings =
            viewOptions.userCanUpdateStatusColumns || viewOptions.userCanUpdateStatusField
        return (
            <React.Fragment key={display}>
                {renderTitle}
                {viewOptions?.statusField &&
                object.fields.find((x) => x._sid === viewOptions?.statusField) ? (
                    <>
                        <Flex alignItems="flex-start">
                            {showSettings && (
                                <Flex mr={2}>
                                    <KanbanSettings
                                        {...effectiveKanbanSettings}
                                        viewOptions={viewOptions}
                                        object={object}
                                    />
                                </Flex>
                            )}
                            {userFilters()}
                        </Flex>
                        <Kanban
                            emptyContent={emptyContent()}
                            data={renderData}
                            object={object}
                            pagination={pagination}
                            rowLink={rowLink}
                            statusFieldId={effectiveKanbanSettings.selectedStatusFieldId}
                            statusColumns={effectiveKanbanSettings.selectedStatusColumns}
                            additionalListContainerContent={additionalListContainerContent}
                            viewOptions={viewOptions}
                            setConfig={setConfig}
                            view={view}
                            relatedListFieldId={relatedListFieldId}
                            relatedListFieldValue={relatedListFieldValue}
                            relatedListViewId={relatedListViewId}
                            openRecord={openRecord}
                        />
                    </>
                ) : (
                    <ListCallout>
                        <Text
                            _css={css`
                                color: #9da3ab;
                                font-weight: 500;
                            `}
                            my={4}
                        >
                            Please select the status field for the board
                        </Text>
                    </ListCallout>
                )}
            </React.Fragment>
        )
    }

    const calendarView = () => {
        return (
            <React.Fragment key={display}>
                {renderTitle}
                {userFilters()}
                <CalendarView
                    data={renderData}
                    object={object}
                    rowLink={rowLink}
                    startDate={viewOptions?.startDateField}
                    endDate={viewOptions?.endDateField}
                    viewOptions={viewOptions}
                    setConfig={setConfig}
                    setCalendarFilter={setCalendarFilter}
                    calendarFilter={calendarFilter}
                    weekStartDay={
                        isNaN(viewOptions?.weekStartDay)
                            ? undefined
                            : parseInt(viewOptions?.weekStartDay)
                    }
                />
            </React.Fragment>
        )
    }

    if (CustomRenderer) {
        return (
            <CustomRenderer
                object={object}
                additionalContainerContent={additionalListContainerContent}
                viewOptions={viewOptions}
                setViewOptions={setConfig}
                tableColumns={tableColumns}
                data={renderData}
                headers={renderHeader}
                rowLinkFunction={rowLink}
                title={title}
                showFilters={showFilters}
                setAllFilters={setAllFilters}
                filters={filters}
                inlineFilters={inlineFilters}
                setInlineFilters={setInlineFilters}
                userFilterFieldIds={userFilterFieldIds}
                pageCount={actualPageCount}
                pageIndex={pageIndex}
                pageSize={listPageSize}
                setPageSize={handleSetPageSize}
                canGoToPreviousPage={canPreviousPage}
                canGoToNextPage={canNextPage}
                goToPage={gotoPage}
                showPageSizeOptions={showPageSizeOptions}
                isServerLoading={isServerLoading}
                totalResultCount={totalResults}
                searchQuery={searchValue}
                handleSearch={doSearch}
                records={allData}
                searchColumns={memoColumns}
                dereferencedRecords={allListRecords}
                emptySearchResult={emptySearchResult}
                setEmptySearchResult={setEmptySearchResult}
                orderBy={orderBy}
                setOrderBy={onOrderByChange}
                openRecord={openRecord}
                downloadCsv={onDownloadCsv}
            />
        )
    }

    switch (display) {
        case 'card':
            return cardView()
        case 'kanban':
            return kanbanView()
        case 'rows':
            return rowView()
        case 'calendar':
            return calendarView()
        case 'table':
            return tableView()
    }

    if (isInbox) {
        return inboxView()
    }

    return tableView()
}

export default memo(List)
