// @ts-strict-ignore
import React, { useCallback, useContext, useEffect } from 'react'

import * as Sentry from '@sentry/react'

import { AppUserContext } from 'app/AppUserContext'
import { getUserApiToken } from 'data/utils/getUserToken'
import { DataConnectionEditorContextType } from 'features/admin/data-connector/dataConnectionEditorContext'
import { getOAuthBounceUrl } from 'features/AppSettings/DataSources/Airtable/utils/getOAuthBounceUrl'

// @ts-ignore
import { Text } from 'v2/ui'

import ConnectionComplete from './ConnectionComplete'
import SimpleSchemaSync from './SimpleSchemaSync'

type SimpleOAuthconnectionEditorProps = {
    createDataConnection?: () => Promise<any>
    context: DataConnectionEditorContextType
}
const SimpleOAuthconnectionEditor: React.FC<SimpleOAuthconnectionEditorProps> = (props) => {
    const context = props.context
    const { dataConnection, initializationComplete } = context
    const { user } = useContext(AppUserContext)

    // If we don't yet have a data connection, or the one we have has never been authed
    // render the initialize UI
    if (!dataConnection || !dataConnection.options?.auth_complete) {
        return (
            <InitializeNewConnection
                createDataConnection={props.createDataConnection}
                context={context}
                user={user}
            />
        )
    } else if (!dataConnection?.options?.initial_config_complete) {
        return (
            <SimpleSchemaSync
                dataConnection={dataConnection}
                onSyncComplete={context.onSyncComplete}
                onSyncError={context.onSyncError}
                onSyncStart={context.onStartSync}
            />
        )
    } else if (initializationComplete) {
        return <ConnectionComplete provider={context.provider} />
    } else {
        // We're just editing an existing connection and we don't show anything
        return null
    }
}
type InitializeNewConnectionProps = {
    context: DataConnectionEditorContextType
    user: any
    createDataConnection?: () => Promise<any>
}

export const InitializeNewConnection: React.FC<InitializeNewConnectionProps> = (props) => {
    const {
        dataConnection,
        provider,
        setNextHandler,
        setIsSaving,
        setNextButtonText,
        hideButtons,
        updateDataConnection,
    } = props.context
    const createDataConnection = props.createDataConnection || props.context.createDataConnection

    const startOAuth = useCallback(
        async (dataConnection) => {
            const token = await getUserApiToken(props.user)

            if (!token) {
                setIsSaving(false)
                return
            }

            const bounceUrl = getOAuthBounceUrl({
                dataConnection,
                apiToken: token,
                returnUrl: props.context.getOAuthReturnUrl(dataConnection),
                shouldOverwriteToken: false,
            })

            window.location.assign(bounceUrl)
        },
        [props.user, props.context, setIsSaving]
    )

    // Create or update the connection, then start the OAuth flow
    const initializeConnection = useCallback(async () => {
        setIsSaving(true)
        if (!dataConnection) {
            createDataConnection().then(startOAuth).catch(Sentry.captureException)
        } else {
            updateDataConnection().then(startOAuth).catch(Sentry.captureException)
        }
    }, [startOAuth, createDataConnection, setIsSaving, updateDataConnection, dataConnection])

    useEffect(() => {
        setNextHandler(initializeConnection)
        setNextButtonText('Next')
    }, [initializeConnection, setNextHandler, setNextButtonText])

    return (
        <>
            {typeof props.children == 'function'
                ? props.children({ initializeConnection })
                : props.children}
            {!hideButtons ? (
                <Text>
                    Click <strong>Next</strong> to connect your {provider.name} account.
                </Text>
            ) : null}
        </>
    )
}

export default SimpleOAuthconnectionEditor
