// @ts-strict-ignore
import { compact } from 'lodash'

import { objectApi } from 'data/api/objectApi'
import { buildQueryKey, queryCache, queryClient } from 'data/hooks/_helpers'
import { invalidateAccounts } from 'data/hooks/accounts'
import { groupFieldsByObjectId } from 'data/hooks/fields/fieldOperations'
import { byID } from 'data/utils/byID'
import { fetchAndReturn } from 'data/utils/utils'

import { OBJECT_LIST_NAME, SHARED_OBJECT_LIST_NAME } from './objectConstants'

/**
 * Updates an object (or objects) in the query cache
 */
export const updateObjectQuery = (object: ObjectDto | ObjectDto[]): ObjectDto[] | undefined => {
    if (!object) return
    const objects = Array.isArray(object) ? object : [object]
    const objectIds = objects.map((obj) => obj._sid)
    const key = getQueryKey()
    const objectQuery = queryCache.find(key)
    if (!objectQuery) return
    const { queryKey } = objectQuery
    queryClient.setQueryData(queryKey, (old: ObjectDto[] | undefined) => {
        if (!old) {
            return undefined
        }

        let updatedIds: string[] = []
        // Update any existing object
        const newData = old.map((o: any) => {
            if (objectIds.includes(o._sid)) {
                updatedIds.push(o._sid)
                const updatedObject = objects.find((obj) => obj._sid === o._sid) || o
                return { ...updatedObject, fields: o.fields }
            }
            return o
        })
        // Check to see if there are any items that need adding to the cache
        const newObjects = objects
            .filter(function (obj) {
                return !updatedIds.includes(obj._sid)
            })
            .map((o) => ({ ...o, fields: o.fields || [] }))
        return [...newData, ...newObjects]
    })

    return queryClient.getQueryData(queryKey)
}

export const addFieldsToObjects = (fields: FieldDto[]) => {
    if (!fields) return
    const key = getQueryKey()
    const objectQuery = queryCache.find(key)
    if (!objectQuery) return
    const { queryKey } = objectQuery
    const fieldsByObjectId = groupFieldsByObjectId(fields)
    queryClient.setQueryData(queryKey, (old: [] = []) => {
        // Update any existing object
        const schemaTimestamp = window.performance.now()
        const newData = old.map((o: any) => {
            if (fieldsByObjectId[o._sid]) {
                return { ...o, fields: fieldsByObjectId[o._sid] || [], schemaTimestamp }
            }
            return o
        })
        return newData ? newData : undefined
    })
}

export const removeObjectQuery = (objectId: string) => {
    if (!objectId) return
    const queryKey = getQueryKey()
    queryClient.setQueryData(queryKey, (old: []) => {
        return old.filter((o: ObjectDto) => o._sid != objectId)
    })
    return queryClient.getQueryData(queryKey)
}

/**
 * Updates an object and updates the query cache
 */
export const updateObject = (
    objectId: string | undefined,
    data: object,
    options?: {
        allowOptimisticUpdates?: boolean
        bypassPreviewAs?: boolean
    }
) => {
    if (options?.allowOptimisticUpdates) {
        const previousObject = getCachedObject(objectId)

        updateObjectQuery({ ...previousObject, ...data } as ObjectDto)
        return objectApi
            .patch(objectId, data, options)
            .then((object: ObjectDto) => {
                const updatedObject = { ...object, fields: compact(previousObject?.fields) }
                return updateObjectQuery(updatedObject)
            })
            .catch(() => {
                updateObjectQuery(previousObject as ObjectDto)
            })
    }

    return objectApi.patch(objectId, data, options).then((object: ObjectDto) => {
        return updateObjectQuery(object)
    })
}

/**
 * Creates an object and updates the query cache
 */
export const createObject = (data: object, options?: any): Promise<ObjectDto | undefined> => {
    return objectApi.post(data, options).then((object: ObjectDto) => {
        return updateObjectQuery(object)?.find((o) => o._sid === object._sid) || object
    })
}

/**
 * Creates an object and updates the query cache
 */
export const importSharedObject = (objectId: string): Promise<ObjectDto | undefined> => {
    return fetchAndReturn(`objects/${objectId}/import`, { method: 'POST' }).then(
        (object: ObjectDto) => {
            return updateObjectQuery(object)?.find((o) => o._sid === object._sid) || object
        }
    )
}

/**
 * Removes an object and updates the query cache
 */
export const deleteObject = (objectId: string, options?: any) => {
    return objectApi.delete(objectId, null, options).then(() => {
        return removeObjectQuery(objectId)
    })
}

/**
 * Invalidates the objects so that it will be refetched by any components using the query
 */
export const invalidateObjects = () => {
    return queryClient.invalidateQueries(getQueryKey())
}

export const getCachedObjects = (): ObjectDto[] | undefined => {
    return queryClient.getQueryData(getQueryKey())
}

export const getCachedObject = (objectId?: string): ObjectDto | undefined => {
    const objects: ObjectDto[] | undefined = queryClient.getQueryData(getQueryKey())
    return objects?.find((o: ObjectDto) => o._sid === objectId)
}

export const getCachedObjectsById = (): object | undefined => {
    return byID(queryClient.getQueryData(getQueryKey()))
}
// Should set the includeAuthKeys to false if queries for all users need to be selected
// e.g if invalidating queries
export function getQueryKey({ includeAuthKeys = true }: { includeAuthKeys?: boolean } = {}) {
    return buildQueryKey(OBJECT_LIST_NAME, { includeAuthKeys, includeStackId: true })
}

export async function publishSharedTable(
    objectId: string,
    sharedResourcesStackSid: string | undefined,
    onSuccess: () => void,
    onError: () => void
) {
    const result = await fetchAndReturn(`objects/${objectId}/publish`, { method: 'POST' })
        .then((object: ObjectDto) => {
            return updateObjectQuery(object)?.find((o) => o._sid === object._sid) || object
        })
        .then(onSuccess, onError)

    // if sharedResourcesStackSid is undefined, then this is the first shared table being published, so we want to
    // refetch accounts so the shared resource stack sid is now available and the list of objs available to share are available
    if (!sharedResourcesStackSid) invalidateAccounts()
    queryClient.invalidateQueries([SHARED_OBJECT_LIST_NAME])
    return result
}

export async function unpublishSharedTable(
    objectId: string,
    onSuccess: () => void,
    onError: () => void
) {
    const result = await fetchAndReturn(`objects/${objectId}/unpublish`, { method: 'POST' })
        .then((object: ObjectDto) => {
            return updateObjectQuery(object)?.find((o) => o._sid === object._sid) || object
        })
        .then(onSuccess, onError)

    // invalidate the list of shared tables as well as the cache for objects. This object will no longer be flagged
    // as shared or is_shared_original, so cache invalidation is needed
    queryClient.invalidateQueries([SHARED_OBJECT_LIST_NAME])
    invalidateObjects()
    return result
}
