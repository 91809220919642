import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useWorkspaceContext } from 'app/WorkspaceContext'
import { workspaceModalState } from 'features/utils/__hackyGlobalModalControls'

import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'

import Flex from 'ui/deprecated/atoms/Flex'

import { WorkspaceModalGeneralSettings as GeneralSettings } from './GeneralSettings/WorkspaceModalGeneralSettings'
import WorkspaceUsers from './WorkspaceUsers/WorkspaceUsers'
import { UnsavedChangesModal } from './UnsavedChangesModal'
import WorkspaceAdvancedSettings from './WorkspaceAdvancedSettings'
import BillingSettings from './WorkspaceBillingSettings'
import { WorkspaceModalAppearanceSettings as AppearanceSettings } from './WorkspaceModalAppearanceSettings'
import { Sidebar } from './WorkspaceSettingsModalSidebar'
import UpgradeSettings from './WorkspaceUpgradeSettings'

const GeneralSettingsMemo = React.memo(GeneralSettings)

// handle the open / closed state of the modal
export default function WorkspaceSettingsModal() {
    const [isOpen, setIsOpen] = useState()
    const [defaultState, setDefaultState] = useState()
    const { hasRight } = useAccountUserContext()

    useEffect(() => {
        workspaceModalState.subscribers.push({ setIsOpen, setDefaultState })
        return () => {
            workspaceModalState.subscribers = workspaceModalState.subscribers.filter(
                (fn) => fn !== setIsOpen
            )
        }
    }, [])

    if (!isOpen || !hasRight(Rights.ViewSettings)) return null

    return (
        <WorkspaceSettingsContent
            onRequestClose={() => setIsOpen(false)}
            defaultState={defaultState}
        />
    )
}

/**
 *
 * @param {{onRequestClose: () => void, defaultState: {page: WorkspaceSettingsPage}}} param0
 */
function WorkspaceSettingsContent({ onRequestClose, defaultState = { page: 'general' } }) {
    const formRef = useRef({})
    const [displayChangesModal, setDisplayChangesModal] = useState(false)

    const [page, setPage] = useState(defaultState.page)

    const { workspaceZone } = useWorkspaceContext()

    // Allows us to open the workspace settings modal on a certain page
    useEffect(() => {
        if (defaultState?.page) onChangePage(defaultState?.page)
    }, [defaultState?.page])

    // similar to onChangePage()
    // Can we close the modal yet?
    function onClose() {
        // wait until any ongoing request finishes before closing
        if (formRef.current.formState?.isSubmitting) {
            return setDisplayChangesModal({
                type: 'submitting',
                onProceed: onRequestClose, // function called when the function finishes
                onCancel: () => setDisplayChangesModal(false), // function called when clicking on Cancel
            })
        }

        // there are changes, don't close yet
        if (formRef.current.hasChanges) {
            return setDisplayChangesModal({
                type: 'changes',
                onProceed: onRequestClose,
                onCancel: () => setDisplayChangesModal(false),
            })
        }

        onRequestClose()
    }

    // similar to onClose
    // If there has changes, do not change pages
    function onChangePage(name) {
        // wait until any ongoing request finishes before closing
        if (formRef.current.formState?.isSubmitting) {
            return setDisplayChangesModal({
                type: 'submitting',
                onProceed: () => {
                    // called after the save query // or when clicking on Discard and continue
                    setPage(name)
                    setDisplayChangesModal(false)
                },
                onCancel: () => setDisplayChangesModal(false),
            })
        }

        // there are unsaved changes, don't close yet
        if (formRef.current.hasChanges) {
            return setDisplayChangesModal({
                type: 'changes',
                onProceed: () => {
                    setPage(name)
                    setDisplayChangesModal(false)
                },
                onCancel: () => setDisplayChangesModal(false),
            })
        }

        setPage(name)
    }

    // which page have we selected?
    let Component
    switch (page) {
        case 'general':
            Component = GeneralSettingsMemo
            break
        case 'appearance':
            Component = AppearanceSettings
            break
        case 'billing':
            Component = BillingSettings
            break
        case 'upgrade':
            Component = UpgradeSettings
            break
        case 'users':
            Component = WorkspaceUsers
            break
        case 'advanced':
            Component = WorkspaceAdvancedSettings
            break
    }

    if (!workspaceZone) return null

    return (
        <SimpleModalCompat
            isOpen
            size={1008}
            height={700}
            noPadding
            title={`${workspaceZone.type === 'Portal' ? 'Portal' : 'Workspace'} Settings`}
            onClose={onClose}
        >
            <Flex column style={{ height: '100%' }}>
                <Flex
                    style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        minHeight: 0,
                        overflow: 'hidden',
                    }}
                >
                    <Sidebar onChange={onChangePage} active={page} onClose={onClose} />

                    {Component ? (
                        <Component formRef={formRef} onClose={onClose}>
                            {displayChangesModal && (
                                <UnsavedChangesModal {...displayChangesModal} />
                            )}
                        </Component>
                    ) : (
                        "can't render " + page
                    )}
                </Flex>
            </Flex>
        </SimpleModalCompat>
    )
}
