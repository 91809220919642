import { useMemo } from 'react'

import { useDropdownAttributeDisplayState } from './useDropdownAttributeDisplayState'

type UseDropdownAttributeTagStateProps = {
    field: FieldDto
    value?: string | string[]
    maxItemLength?: number
    isLoading?: boolean
    maxOptions?: number
}

export function useDropdownAttributeTagState({
    field,
    value,
    maxItemLength,
    isLoading,
    maxOptions,
}: UseDropdownAttributeTagStateProps) {
    const {
        options,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelFocus,
        onOverflowLabelBlur,
    } = useDropdownAttributeDisplayState({
        field,
        value,
        maxItemLength,
        isLoading,
    })

    const label = formatLabel(options, maxOptions)

    return useMemo(
        () => ({
            options,
            isOverflowPopupOpen,
            onOverflowPopupOpenChange,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelFocus,
            onOverflowLabelBlur,
            label,
        }),
        [
            isOverflowPopupOpen,
            label,
            onOverflowLabelBlur,
            onOverflowLabelFocus,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowPopupOpenChange,
            options,
        ]
    )
}

function formatLabel(
    options: ReturnType<typeof useDropdownAttributeDisplayState>['options'],
    maxOptions?: number
) {
    const finalOptions = options.slice(0, maxOptions)
    const overflowingCount = options.length - finalOptions.length

    if (overflowingCount === 0) {
        return finalOptions.map((option) => option.label).join(', ')
    }

    return `${finalOptions.map((option) => option.label).join(', ')} +${overflowingCount}`
}
