/**
 * Pricing tiers ordered by ascending price
 */
export const TIERS = {
    Starter: 1,
    Plus: 2,
    Pro: 3,
    Enterprise: 4,
}

/**
 * Matches a feature to the reasons it might not be allowed or its user-friendly name
 */
export type FeatureRequirementsMap = { [feature: string]: (_: any) => string | null }
