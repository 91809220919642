import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItemTag,
} from 'ui/components/Dropdown'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAdvancedFilterValueDropdownState } from './hooks/useAdvancedFilterValueDropdownState'
import { AdvancedFilter } from './types'

import { AdvancedFiltersButtonStyle } from './AdvancedFilters.css'

type AdvancedFilterValueDropdownProps = {
    filter: AdvancedFilter
    onChange: (value: AdvancedFilter['options']['value']) => void
    onRemoveFilter: () => void
    className?: string
    style?: React.CSSProperties
}

export const AdvancedFilterValueDropdown: React.FC<AdvancedFilterValueDropdownProps> = ({
    filter,
    onChange,
    onRemoveFilter,
    className,
    style,
}) => {
    const {
        value,
        valueOptions,
        overflowLabel,
        onSetFilterValue,
        filterOptions,
        isSingle,
        isOpen,
        setIsOpen,
    } = useAdvancedFilterValueDropdownState({
        filter,
        onChange,
        onRemoveFilter,
    })

    const size = useResponsiveValue<React.ComponentPropsWithoutRef<typeof DropdownButton>['size']>({
        mobile: 'xs',
        tablet: 's',
    })

    return (
        <Dropdown open={isOpen} onOpenChange={setIsOpen}>
            <DropdownButton
                size={size}
                variant="ghost"
                aria-label="Select value"
                className={classNames(className, AdvancedFiltersButtonStyle)}
                style={style}
            >
                <Box flex center gap="xs">
                    {valueOptions.map((item, idx) => (
                        <Box flex center key={item.value}>
                            <Body
                                size={{ mobile: 's', tablet: 'm' }}
                                style={{ fontWeight: 'inherit', color: item.textColor }}
                            >
                                {item.label}
                            </Body>
                            {idx < valueOptions.length - 1 && (
                                <Body
                                    size={{ mobile: 's', tablet: 'm' }}
                                    style={{ fontWeight: 'inherit', color: 'inherit' }}
                                >
                                    ,
                                </Body>
                            )}
                        </Box>
                    ))}
                </Box>
                {overflowLabel && (
                    <Body
                        size={{ mobile: 's', tablet: 'm' }}
                        style={{ fontWeight: 'inherit', color: 'inherit' }}
                    >
                        {overflowLabel}
                    </Body>
                )}
            </DropdownButton>
            <DropdownContent align="end" onClick={stopPropagation} head={<DropdownHeadSearch />}>
                {filterOptions.map((item) => (
                    <DropdownItemTag
                        key={item.value}
                        size="2xs"
                        multiSelect
                        color={item.color}
                        label={item.label}
                        checked={value.has(item.value)}
                        closeOnSelect={isSingle}
                        onCheckedChange={(checked) => {
                            onSetFilterValue(item.value, checked)
                        }}
                    />
                ))}
            </DropdownContent>
        </Dropdown>
    )
}
