import React from 'react'

import { ButtonProps } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { FormField, FormFieldProps } from 'ui/deprecated/forms/FormField'

const INPUT_CHARACTER_LIMIT = 30

type FormInputProps = Omit<FormFieldProps, 'as' | 'registerOptions'> &
    ButtonProps & {
        name: string
        registerOptions?: FormFieldProps['registerOptions']
    }

export const SettingsInput = ({
    name,
    label,
    formRef,
    registerOptions,
    ...props
}: FormInputProps) => {
    const defaultRegisterOptions = {
        maxLength: {
            value: INPUT_CHARACTER_LIMIT,
            message: `Exceeded maximum character limit of ${INPUT_CHARACTER_LIMIT}.`,
        },
    }

    const mergedRegisterOptions = {
        ...defaultRegisterOptions,
        ...(registerOptions || {}),
    }

    return (
        <FormField
            as={Input}
            name={name}
            label={label}
            formRef={formRef}
            registerOptions={mergedRegisterOptions}
            size="m"
            mb="s"
            {...props}
        />
    )
}
