import React from 'react'

import { Box } from 'ui/components/Box'
import { Pagination } from 'ui/components/Pagination'

import { useCardViewPaginationState } from './hooks/useCardViewPaginationState'

type CardViewPaginationProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const CardViewPagination: React.FC<CardViewPaginationProps> = (props) => {
    const { pageIndex, onChangePageIndex, pageSize, totalItemsCount } = useCardViewPaginationState()

    return (
        <Box flex center alignSelf="stretch" px={{ mobile: 'xl', tablet: 0 }} {...props}>
            <Pagination
                grow
                pageSize={pageSize}
                totalItemsCount={totalItemsCount}
                pageIndex={pageIndex}
                onChangePageIndex={onChangePageIndex}
            />
        </Box>
    )
}
