import React, { FC } from 'react'

import { Button, EmptyState, Flex } from 'v2/ui'
import * as SvgIcons from 'v2/ui/svgs'
import { ArrowsRotate } from 'v2/ui/svgs'
import stackerTheme from 'v2/ui/theme/styles/default'
type Props = {
    onReset: () => void
}
const colors = stackerTheme().colors
const NavigationEmptyState: FC<Props> = ({ onReset }) => (
    <Flex height="100%" justifyContent="center" alignItems="center" flexDirection="column">
        <EmptyState
            m="0px"
            p="0px"
            custom="We can't load navigation right now. Please try again in a few moments."
            svg={SvgIcons.IllustrationConnectivity}
            name="navigation"
        ></EmptyState>
        <Button
            size="small"
            onClick={onReset}
            buttonSize="extraSmall"
            svgIcon={<ArrowsRotate />}
            variant="linkNoUnderline"
            color={colors.userInterface.accent[1000]}
            background="transparent"
        >
            Refresh navigation
        </Button>
    </Flex>
)

export default NavigationEmptyState
