import React from 'react'

import useConfirmModal from 'app/ConfirmModal'

import { Icon, Text } from 'v2/ui'
import { Modal } from 'v2/ui/utils/useModalToggle'

import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { LinkFieldReference } from './LinkFieldReference'

export const LinkListItem = ({
    link,
    onDelete,
    onEdit,
}: {
    link: LinkView
    onDelete: () => void | Promise<unknown>
    onEdit: () => void
}) => {
    const { show: showConfirm } = useConfirmModal({
        message: (
            <>
                <Text>Are you sure you want to delete this link?</Text>
            </>
        ),
        onConfirm: async (modal: Modal<unknown>) => {
            await onDelete()
            modal.toggle()
        },
    })
    return (
        <LinkListItemContainer>
            <LinkFieldReference field={link.source} style={{ flex: 1 }} source={true} />
            <LinkFieldReference field={link.target} style={{ flex: 1 }} />
            <LinkListItemActionList>
                <LinkListItemAction icon="cog" onClick={onEdit} />
                <LinkListItemAction icon="trash" onClick={() => showConfirm({})} />
            </LinkListItemActionList>
        </LinkListItemContainer>
    )
}

const LinkListItemContainer = ({ children }: { children: React.ReactNode }): React.ReactElement => (
    <div
        style={{
            display: 'flex',
            border: `1px solid ${V4DesignSystem.colors.gray[100]}`,
            padding: 10,
            borderRadius: 4,
        }}
    >
        {children}
    </div>
)

const LinkListItemActionList = ({
    children,
}: {
    children: React.ReactNode
}): React.ReactElement => (
    <div
        style={{
            marginLeft: 40,
            display: 'flex',
            justifyContent: 'flex-end',
        }}
    >
        {React.Children.map(children, (child) => (
            <div style={{ marginLeft: 15 }}>{child}</div>
        ))}
    </div>
)

const LinkListItemAction = ({
    icon,
    onClick,
}: {
    icon: string
    onClick: () => void
}): React.ReactElement => (
    <Icon
        style={{ cursor: 'pointer' }}
        size="14px"
        icon={icon}
        color="grey.300"
        onClick={onClick}
    />
)
