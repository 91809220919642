import { useMemo } from 'react'

import { useTableViewContext } from 'features/views/ListView/TableView/TableViewContext'
import { TableViewColumn } from 'features/views/ListView/TableView/types'

export function useTableViewGridState() {
    const { columns, isRecordActionsColumnPinned } = useTableViewContext()

    const gridTemplateColumns = makeGridTemplateColumns(columns, isRecordActionsColumnPinned)

    return useMemo(() => ({ gridTemplateColumns }), [gridTemplateColumns])
}

function makeGridTemplateColumns(columns: TableViewColumn[], isRecordActionsColumnPinned: boolean) {
    const spanWidth = 'minmax(max-content, auto)'

    const configParts = columns.reduce((acc, column) => {
        if (column.maxWidth) {
            acc.push(`minmax(max-content, ${column.maxWidth}px)`)
        } else {
            acc.push(spanWidth)
        }

        return acc
    }, [] as string[])

    // If the record action menu is not pinned, add a column for it.
    if (!isRecordActionsColumnPinned) {
        configParts.push(spanWidth)
    }

    return configParts.join(' ')
}
