import React, { useCallback } from 'react'

import { Link } from 'ui/components/Link'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { makeLineTruncationStyle } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useUrlAttributeDisplayState } from './hooks/useUrlAttributeDisplayState'
import { Thumbnail } from './Thumbnail'
import { AttributeDisplayComponent } from './types'

const sizeMapping = {
    thumbnail: {
        s: 's',
        m: 'm',
        l: 'm',
    },
    link: {
        s: 's',
        m: 'm',
        l: 'l',
    },
} as const

type UrlAttributeDisplayProps = Omit<React.ComponentPropsWithoutRef<typeof Link>, 'size'> & {
    openInNewTab?: boolean
    buttonTitle?: string
    displayAsImage?: boolean
    imageAltText?: string
    maxLines?: number
    maxLength?: number
    maxOverflowLength?: number
    size?: ResponsiveValue<'s' | 'm' | 'l'>
    showOverflowTooltip?: ResponsiveValue<boolean>
    alwaysShowOverflowTooltip?: ResponsiveValue<boolean>
    isLoading?: boolean
}

export const UrlAttributeDisplay: AttributeDisplayComponent<string, UrlAttributeDisplayProps> =
    React.memo(function UrlAttributeDisplay({
        value,
        style,
        openInNewTab,
        buttonTitle,
        displayAsImage,
        imageAltText,
        onClick,
        maxLength,
        maxLines,
        size = 'm',
        showOverflowTooltip,
        alwaysShowOverflowTooltip,
        maxOverflowLength,
        isLoading,
        ...props
    }) {
        const effectiveSize = useResponsiveValue(size)
        const thumbnailSize = sizeMapping.thumbnail[effectiveSize]
        const linkSize = sizeMapping.link[effectiveSize]

        const effectiveShowOverflowTooltip = useResponsiveValue(showOverflowTooltip)
        const effectiveAlwaysShowOverflowTooltip = useResponsiveValue(alwaysShowOverflowTooltip)

        const handleClick = useCallback(
            (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                onClick?.(e as React.MouseEvent<HTMLAnchorElement>)
            },
            [onClick]
        )

        const { target, to, href, label, isOverflowing, overflowLabel } =
            useUrlAttributeDisplayState({
                value,
                openInNewTab,
                buttonTitle,
                maxLength,
                isLoading,
            })

        if (!href && !to) return null

        if (displayAsImage) {
            return (
                <Skeleton isLoading={isLoading}>
                    <Thumbnail
                        as="a"
                        size={thumbnailSize}
                        href={href}
                        target="_blank"
                        src={value}
                        alt={imageAltText}
                        maxWidth="full"
                        maxHeight="full"
                        noShrink
                    />
                </Skeleton>
            )
        }

        const content = (
            <Skeleton isLoading={isLoading}>
                <Link
                    href={href}
                    to={to}
                    size={linkSize}
                    target={target}
                    style={{ ...makeLineTruncationStyle(maxLines), ...style }}
                    onClick={handleClick}
                    tabIndex={0}
                    {...props}
                >
                    {label}
                </Link>
            </Skeleton>
        )

        if (effectiveAlwaysShowOverflowTooltip || (isOverflowing && effectiveShowOverflowTooltip)) {
            return (
                <Tooltip
                    content={
                        <Body
                            style={{
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                lineHeight: 'inherit',
                            }}
                            maxLength={maxOverflowLength}
                        >
                            {overflowLabel}
                        </Body>
                    }
                    zIndex={99}
                    side="bottom"
                    asChild
                >
                    {content}
                </Tooltip>
            )
        }

        return content
    })
