import * as Sentry from '@sentry/react'
import get from 'lodash/get'

import { getUrl, Urls } from 'app/UrlService'
import { queryClient } from 'data/hooks/_helpers'
import { invalidateAggregates } from 'data/hooks/aggregates'
import { invalidateObjectCache } from 'data/hooks/cacheInfo/cacheInfoOperations'
import { invalidateDataConnections } from 'data/hooks/dataConnections'
import { invalidateFields } from 'data/hooks/fields'
import { invalidateMetrics } from 'data/hooks/metrics/legacyMetrics'
import { invalidateNavigation } from 'data/hooks/navigation'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { refetchPages } from 'data/hooks/pages'
import { invalidatePermissions } from 'data/hooks/permissions/permissionOperations'
import { invalidateStacks } from 'data/hooks/stacks/stackOperations'
import { invalidateViews } from 'data/hooks/views'
import {
    Events,
    pusherDebugLog,
    subscribeToRealtimeImportProgressUpdates,
} from 'data/realtime/realtimeUpdates'
import { REACT_QUERY } from 'data/utils/constants'
import { fetchWithAuth } from 'data/utils/utils'
import { SCHEMA_ERROR_PASSWORD } from 'features/admin/data-connector/ImportErrors'

export const STATUS_STARTED = 'STATUS_STARTED'
export const STATUS_COMPLETE = 'STATUS_COMPLETE'
export const STATUS_ERROR = 'STATUS_ERROR'

export const STEP_AIRTABLE_CONNECTION = 'STEP_AIRTABLE_CONNECTION'
export const STEP_TABLE_CREATION = 'STEP_TABLE_CREATION'
export const STEP_COLUMN_CREATION = 'STEP_COLUMN_CREATION'
export const STEP_COMPLETE = 'STEP_COMPLETE'
export const STEP_STATUS_ERROR = 'STEP_STATUS_ERROR'

export const SCHEMA_IMPORT_ERROR = 'SCHEMA_IMPORT_ERROR'

// NAMING STANDARDS
//
// Table = Airtable Table
// Column = Airtable Column
// Object = Stacker Object
// Field = Stacker Field

export const updateMetadata = async (
    dataConnection,
    updateStep,
    onError,
    isOnboarding,
    onComplete,
    multiDataSource
) => {
    let importProgressEventsChannelName
    let unsubscribeFromRealtimeImportProgressUpdates

    const reportError = (error, errorDetails) => {
        let description
        let link
        let linkLabel

        switch (error) {
            case SCHEMA_ERROR_PASSWORD:
                description = multiDataSource
                    ? 'Invalid or missing password.'
                    : 'Invalid or missing password. Configure your password in '
                linkLabel = multiDataSource ? 'Edit your connection settings' : 'App Settings'
                link = multiDataSource
                    ? `${getUrl(Urls.Admin)}?edit=${dataConnection?._sid}&step=edit`
                    : getUrl(Urls.AdminPortalSettings)
                break
            default:
                description = `There was an error during the import (${error}), please contact support`
                break
        }

        updateStep({
            step: STEP_STATUS_ERROR,
            status: STATUS_ERROR,
            description: description,
            link: link,
            linkLabel: linkLabel,
            errorDetails: errorDetails,
        })

        if (onError) onError(error, errorDetails)
        else console.error(error, errorDetails)
    }

    const completeProgress = (progressUpdate) => {
        updateStep({
            step: STEP_AIRTABLE_CONNECTION,
            status: STATUS_COMPLETE,
        })
        updateStep({
            step: STEP_TABLE_CREATION,
            status: STATUS_COMPLETE,
            description: `${progressUpdate.objects} Tables: ${progressUpdate.unchanged_objects} unchanged, ${progressUpdate.created_objects} created, ${progressUpdate.updated_objects} updated, ${progressUpdate.remove_objects} removed`,
        })
        updateStep({
            step: STEP_COLUMN_CREATION,
            status: STATUS_COMPLETE,
            description: `${progressUpdate.fields} Columns: ${progressUpdate.unchanged_fields} unchanged, ${progressUpdate.created_fields} created, ${progressUpdate.updated_fields} updated, ${progressUpdate.remove_fields} removed, ${progressUpdate.unsupported_fields} unsupported`,
            unsupported: progressUpdate.unsupported_fields,
        })
        updateStep({
            step: STEP_COMPLETE,
            status: STATUS_COMPLETE,
        })

        // Refetch all objects, fields and permissions
        invalidateObjects()
        invalidateFields()
        invalidatePermissions()
        invalidateDataConnections()

        queryClient.invalidateQueries(REACT_QUERY.roles.listName)
        invalidateViews()
        invalidateStacks()
        invalidateNavigation()
        invalidateMetrics()
        invalidateAggregates()
        invalidateObjectCache()
        refetchPages().then(() => {
            if (onComplete) onComplete()
        })
    }

    const handleProgressUpdate = (progressUpdate) => {
        if (progressUpdate.step === STEP_TABLE_CREATION) {
            updateStep({
                step: STEP_AIRTABLE_CONNECTION,
                status: STATUS_COMPLETE,
            })
            updateStep({
                step: STEP_TABLE_CREATION,
                status: STATUS_STARTED,
                description: `${progressUpdate.schema_objects} to import: ${progressUpdate.unchanged_objects} unchanged, ${progressUpdate.created_objects} created, ${progressUpdate.updated_objects} updated, ${progressUpdate.remove_objects} removed`,
            })
        } else if (progressUpdate.step === STEP_COLUMN_CREATION) {
            updateStep({
                step: STEP_AIRTABLE_CONNECTION,
                status: STATUS_COMPLETE,
            })
            updateStep({
                step: STEP_TABLE_CREATION,
                status: STATUS_COMPLETE,
                description: `${progressUpdate.objects} Tables: ${progressUpdate.unchanged_objects} unchanged, ${progressUpdate.created_objects} created, ${progressUpdate.updated_objects} updated, ${progressUpdate.remove_objects} removed`,
            })
            updateStep({
                step: STEP_COLUMN_CREATION,
                status: STATUS_STARTED,
                description: `${progressUpdate.schema_fields} to import: ${progressUpdate.unchanged_fields} unchanged, ${progressUpdate.created_fields} created, ${progressUpdate.updated_fields} updated, ${progressUpdate.remove_fields} removed, ${progressUpdate.unsupported_fields} unsupported`,
            })
        } else if (progressUpdate.step === STEP_COMPLETE) {
            if (unsubscribeFromRealtimeImportProgressUpdates) {
                unsubscribeFromRealtimeImportProgressUpdates()
            }
            completeProgress(progressUpdate)
        } else if (progressUpdate.step === STEP_STATUS_ERROR) {
            if (unsubscribeFromRealtimeImportProgressUpdates) {
                unsubscribeFromRealtimeImportProgressUpdates()
            }

            Sentry.withScope((scope) => {
                scope.setExtra('backend_error_details', get(progressUpdate, 'error_details'))
                Sentry.captureMessage(
                    `There was an error during the airtable import ${progressUpdate.error}`
                )
            })
            invalidateDataConnections()
            reportError(progressUpdate.error, get(progressUpdate, 'error_details'))
        }
    }

    // handler is defined as separate function so that its reference can be passed to unsubscribe function
    const importProgressEventHandler = (eventData) => {
        pusherDebugLog(
            `${Events.data_connection_import_progress} event received for dataConnection
            [${dataConnection._sid}] -- eventData: `,
            eventData
        )
        handleProgressUpdate(eventData.progress_update)
    }

    // Run the import on the backend and subscribe to real time updates on the import progress.
    const params = isOnboarding ? '?is_onboarding=true' : ''
    fetchWithAuth(`import/${dataConnection._sid}${params}`, { method: 'POST' })
        .then((response) => {
            if (response.status >= 400) {
                response.text().then((error) => {
                    let event_id
                    Sentry.withScope((scope) => {
                        scope.setTags({
                            stacker_error: 'import_error',
                            blocking_error: true,
                            data_source: get(dataConnection, 'type'),
                            onboarding: !!isOnboarding,
                            interactive: true,
                        })
                        scope.setLevel('error')
                        event_id = Sentry.captureMessage(
                            `Error from import API (${response.status}): ${error}`
                        )
                    })

                    reportError(SCHEMA_IMPORT_ERROR, { sentry_event_id: `F-${event_id}` })
                })
                return
            }

            response
                .json()
                .then((json) => {
                    if (json.error) {
                        if (json.error.includes('already in-progress')) {
                            console.log(`${json.error} Skipping to the progress indicator part`)
                        } else {
                            reportError(json.error, get(json, 'error_details'))
                        }
                    }

                    importProgressEventsChannelName = dataConnection.channel_name
                    unsubscribeFromRealtimeImportProgressUpdates =
                        subscribeToRealtimeImportProgressUpdates({
                            channelName: importProgressEventsChannelName,
                            handler: importProgressEventHandler,
                        })
                })
                .catch((error) => {
                    let event_id
                    Sentry.withScope((scope) => {
                        scope.setTags({
                            stacker_error: 'import_error',
                            blocking_error: true,
                            data_source: get(dataConnection, 'type'),
                            onboarding: !!isOnboarding,
                            interactive: true,
                        })
                        scope.setLevel('error')
                        event_id = Sentry.captureMessage(
                            `Unexpected response from import API: ${error}`
                        )
                    })
                    reportError(SCHEMA_IMPORT_ERROR, { sentry_event_id: `F-${event_id}` })
                })
        })
        .catch((error) => {
            let event_id
            Sentry.withScope((scope) => {
                scope.setTags({
                    stacker_error: 'import_error',
                    blocking_error: true,
                    data_source: get(dataConnection, 'type'),
                    onboarding: !!isOnboarding,
                    interactive: true,
                })
                scope.setLevel('error')
                event_id = Sentry.captureMessage(`Unable to import schema, error: ${error}`)
            })

            reportError(SCHEMA_IMPORT_ERROR, { sentry_event_id: `F-${event_id}` })
        })

    // Set the step as started - we'll receive import progress events through pusher as the import progresses
    updateStep({ step: STEP_AIRTABLE_CONNECTION, status: STATUS_STARTED })
}
