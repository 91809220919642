import { useCallback, useMemo } from 'react'

import { ActionMenuEditProps } from 'features/views/ListView/Actions/types'

import { truncateText } from 'ui/helpers/utilities'

type UseActionMenuEditCheckboxStateOptions = ActionMenuEditProps<boolean> & {}

const MAX_LABEL_LENGTH = 25

type OptionValue = {
    label: string
    value: boolean
    isRecent?: boolean
}

export function useActionMenuEditCheckboxState(options: UseActionMenuEditCheckboxStateOptions) {
    const { field, updateRecord, recentValues } = options

    const fieldLabel = truncateText(field.label, MAX_LABEL_LENGTH)

    const editOptions = useMemo(() => {
        const uniqueRecentValues = new Set(recentValues?.flat(2))

        return EDIT_OPTIONS.map((option) => {
            const isRecent = uniqueRecentValues.has(option.value!)

            return {
                ...option,
                isRecent,
            }
        })
    }, [recentValues])

    const onEditValue = useCallback(
        (value: boolean, isChecked: boolean) => {
            if (!isChecked) return

            updateRecord({ [field.api_name]: value })
        },
        [field.api_name, updateRecord]
    )

    return useMemo(
        () => ({ fieldLabel, editOptions, onEditValue }),
        [fieldLabel, editOptions, onEditValue]
    )
}

const EDIT_OPTIONS: OptionValue[] = [
    {
        label: 'is checked',
        value: true,
    },
    {
        label: 'is unchecked',
        value: false,
    },
]
