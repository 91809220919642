import React from 'react'

import PropTypes from 'prop-types'

import { useAppContext } from 'app/AppContext'
import WithApp from 'data/wrappers/WithApp'
import WithObject from 'data/wrappers/WithObject'
import WithRecord from 'data/wrappers/WithRecord'

import { getLinkComponentFromUrl } from './link_utils'

const cancelDoubleSlash = (url) => url.replace('//', '/')

const RecordLinkDisplay = ({ recordId, record, noLink, linkText = '', layout }) => {
    const { selectedStack } = useAppContext()

    return (
        <RecordLinkWrapper record={record} recordId={recordId} loading="-">
            {({ record, primaryField }) => {
                // If there was an error on the fetch, we don't try to load the object
                if (record._fetch_failed || !record._object_id) return '-'

                if (record._object_id === 'page') {
                    // If it's a page, we need to get the app's url to link directly to it
                    return (
                        <WithApp appId={record.app_id}>
                            {({ app }) => {
                                return getLinkComponentFromUrl({
                                    display: record[primaryField.api_name],
                                    url: cancelDoubleSlash(app.url + record.url),
                                    noLink,
                                    layout,
                                })
                            }}
                        </WithApp>
                    )
                }
                return (
                    <WithObject objectId={record._object_id} shouldRenderEvenWithEmptyObject={true}>
                        {({ object, isObjectEmpty }) => {
                            // By convention record detail page will be whatever app we're in /object.api_name/{id}
                            const url = `${object?.url}/view/${record._sid}`

                            return getLinkComponentFromUrl({
                                display: linkText || record._primary,
                                url,
                                layout,
                                stack: selectedStack,
                                // if object is empty but we're still rendering this component, then this is data on
                                // a shared data with the target object not imported, so we don't want the links to be clickable
                                noLink: noLink || isObjectEmpty,
                            })
                        }}
                    </WithObject>
                )
            }}
        </RecordLinkWrapper>
    )
}

// If we already have a record sent through as a prop then we don't need to use withRecord
const RecordLinkWrapper = ({ record, children, ...props }) => {
    if (record) {
        return <>{children({ record, primaryField: record?._primary })}</>
    }
    return (
        <WithRecord {...props} includeFields={[]}>
            {children}
        </WithRecord>
    )
}

RecordLinkWrapper.propTypes = {
    record: PropTypes.object,
    children: PropTypes.node.isRequired,
}

RecordLinkDisplay.propTypes = {
    recordId: PropTypes.string.isRequired,
    noLink: PropTypes.bool,
}

RecordLinkDisplay.defaultProps = {
    noLink: false,
}

export default RecordLinkDisplay
