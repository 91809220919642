import { getIsSyntheticField } from 'utils/fieldUtils'
import { isAirtableRichTextField } from 'utils/isRichTextField'

import { CreatableFieldTypeKey } from './fieldTypeDefinitions'

export type EditableFieldTypeDefinition = {
    label: string
    value: CreatableFieldTypeKey
    description?: string
    field_template: Partial<
        Pick<FieldDto, 'type' | 'options' | 'connection_options' | 'synthetic_field_type'>
    >
    search_tags: string[]
    default_label: string
}

export const editableFieldTypeDefinitionMap: Record<
    CreatableFieldTypeKey,
    EditableFieldTypeDefinition
> = {
    checkbox: {
        label: 'Checkbox',
        value: 'checkbox',
        description: 'An on/off checkbox.',
        field_template: {
            type: 'checkbox',
        },
        search_tags: ['checkbox'],
        default_label: 'Completed',
    },
    created_at: {
        label: 'Created Date',
        value: 'created_at',
        description: 'Date and time when this record was created.',
        field_template: {
            synthetic_field_type: 'created_at',
        },
        search_tags: ['created time', 'time', 'created date', 'date'],
        default_label: 'Created Date',
    },
    created_by: {
        label: 'Created By',
        value: 'created_by',
        description: 'Person who created this record.',
        field_template: {
            synthetic_field_type: 'created_by',
        },
        search_tags: ['created by', 'person'],
        default_label: 'Created By',
    },
    currency: {
        label: 'Currency',
        value: 'currency',
        description: 'A currency-formatted number.',
        field_template: {
            type: 'currency',
        },
        search_tags: ['currency', 'money'],
        default_label: 'Value',
    },
    currency_varying: {
        label: 'Currency (per record)',
        value: 'currency_varying',
        description: 'A currency-formatted number.',
        field_template: {
            type: 'currency_varying',
        },
        search_tags: ['currency per record'],
        default_label: 'Value',
    },
    date: {
        label: 'Date',
        value: 'date',
        description: 'A date.',
        field_template: {
            type: 'date',
        },
        search_tags: ['date', 'time', 'year', 'month', 'day'],
        default_label: 'Date',
    },
    datetime: {
        label: 'Date and Time',
        value: 'datetime',
        description: 'A date and time.',
        field_template: {
            type: 'datetime',
        },
        search_tags: ['date and time', 'time', 'year', 'month', 'day'],
        default_label: 'Date',
    },
    dropdown: {
        label: 'Single Select',
        value: 'dropdown',
        description: 'Select a single value from a list of options.',
        field_template: {
            type: 'dropdown',
        },
        search_tags: ['single select', 'dropdown', 'status'],
        default_label: 'Status',
    },
    file: {
        label: 'File',
        value: 'file',
        description: 'A single uploaded file.',
        field_template: {
            type: 'file',
        },
        search_tags: ['file'],
        default_label: 'File',
    },
    formula: {
        label: 'Formula',
        value: 'formula',
        description: 'Generated calculated values based on a formula',
        field_template: {
            synthetic_field_type: 'formula',
        },
        search_tags: ['formula', 'equation'],
        default_label: 'Calculation',
    },
    image: {
        label: 'Image',
        description: 'A single image.',
        value: 'image',
        field_template: {
            type: 'image',
        },
        search_tags: ['image'],
        default_label: 'Image',
    },
    string: {
        label: 'Short Text',
        value: 'string',
        description: 'A single line of text',
        field_template: {
            type: 'string',
            options: {
                render_variant: undefined,
            },
        },
        search_tags: ['short text'],
        default_label: 'Label',
    },
    long_text: {
        label: 'Long Text',
        value: 'long_text',
        description: 'A long text field allows multiple lines',
        field_template: {
            type: 'long_text',
        },
        search_tags: ['long text'],
        default_label: 'Notes',
    },
    lookup: {
        label: 'Link to Another Record',
        value: 'lookup',
        description: 'Link to a record in another table.',
        field_template: {
            type: 'lookup',
        },
        search_tags: ['link to another field', 'related'],
        default_label: 'Record link',
    },
    lookup_related_value: {
        label: 'Lookup Related Value',
        value: 'lookup_related_value',
        description: 'Display the value of a field from a related record.',
        field_template: {
            synthetic_field_type: 'lookup',
        },
        search_tags: ['lookup related value'],
        default_label: 'Lookup',
    },
    multi_file: {
        label: 'Attachments',
        description: 'A field allowing image and file attachments.',
        value: 'multi_file',
        field_template: {
            type: 'multi_file',
        },
        search_tags: ['attachments', 'image', 'photo', 'file'],
        default_label: 'Attachments',
    },
    multi_lookup: {
        label: 'Link to Multiple Records',
        description: 'Link to records from another table.',
        value: 'multi_lookup',
        field_template: {
            type: 'multi_lookup',
        },
        search_tags: ['link to multiple records', 'related'],
        default_label: 'Multi-Record Link',
    },
    multi_select: {
        label: 'Multiple Select',
        value: 'multi_select',
        description: 'Select multiple values from a list of options.',
        field_template: {
            type: 'multi_select',
        },
        search_tags: ['multiple select', 'dropdown', 'status'],
        default_label: 'Tags',
    },
    number: {
        label: 'Number',
        value: 'number',
        description: 'Numeric values',
        field_template: {
            type: 'number',
        },
        search_tags: ['number'],
        default_label: 'Number',
    },
    percentage: {
        label: 'Percentage',
        value: 'percentage',
        description: 'A percentage-formatted number.',
        field_template: {
            type: 'percentage',
        },
        search_tags: ['percentage'],
        default_label: 'Percent',
    },
    record_id: {
        label: 'Record ID',
        value: 'record_id',
        description: 'Unique identifier of the record',
        field_template: {
            synthetic_field_type: 'record_id',
        },
        search_tags: ['record id', 'person'],
        default_label: 'Record ID',
    },
    autonumber: {
        label: 'Autonumber',
        value: 'autonumber',
        description: 'An incrementing numeric ID for each record',
        field_template: {
            synthetic_field_type: 'autonumber',
        },
        search_tags: ['number', 'auto', 'autonumeric', 'autonumber'],
        default_label: 'Autonumber',
    },
    // hide option to create automatic links for now
    // relationship: {
    //     label: 'Automatic link',
    //     value: 'relationship',
    //     description: 'Link to another record based on matching field values.',
    //     field_template: {
    //         synthetic_field_type: 'relationship',
    //     },
    //     search_tags: ['automatic link', 'related'],
    //     default_label: 'Automatic Link',
    // },
    rollup_related_records: {
        label: 'Roll-up Related Records',
        value: 'rollup_related_records',
        description: 'Summarize linked records.',
        field_template: {
            synthetic_field_type: 'rollup',
        },
        search_tags: ['rollup related records'],
        default_label: 'Rollup',
    },
    string_with_rich_text: {
        label: 'Rich Text',
        value: 'string_with_rich_text',
        description: 'A long text field that supports formatted text',
        // rich text is just a string under the hood and should be treated as such by the storage layer,
        // so don't want an actual separate type for it - it just contains special markdown characters
        // that are meaningful to the rich text editor and when rendering it as rich text in the UI, but that's it.
        //
        // in airtable this distinction is set via a checkbox on the text field type but in our cache we also
        // store it as a string type, just with a connection_options.airtable_column_type set to 'rich_text' so the UI knows
        // to treat it as a rich text field -- for stacker data we make that more generic by setting it as an option.render_variant
        // (you could imagine this generic option being used similarly to render other data types in different ways in the UI,
        // for example a boolean as a checkbox or a toggle, etc)
        field_template: {
            type: 'string',
            options: {
                render_variant: 'richText',
            },
        },
        search_tags: ['rich text'],
        default_label: 'Notes',
    },
    document: {
        label: 'Rich Text',
        value: 'document',
        description: 'A long text field that supports formatted text',
        field_template: {
            type: 'document',
        },
        search_tags: ['document', 'text', 'rich text'],
        default_label: 'Notes',
    },
    updated_at: {
        label: 'Last Updated Date',
        value: 'updated_at',
        description: 'Date and time when this record was last modified.',
        field_template: {
            synthetic_field_type: 'updated_at',
        },
        search_tags: [
            'last updated time',
            'last modified time',
            'time',
            'last updated date',
            'last modified date',
            'date',
        ],
        default_label: 'Last Modified Date',
    },
    updated_by: {
        label: 'Last Updated By',
        value: 'updated_by',
        description: 'Person who last modified this record.',
        field_template: {
            synthetic_field_type: 'updated_by',
        },
        search_tags: ['last modified by', 'last updated by', 'person'],
        default_label: 'Last Modified By',
    },
    url: {
        label: 'URL',
        description: 'A web URL.',
        value: 'url',
        field_template: {
            type: 'url',
        },
        search_tags: ['url', 'link'],
        default_label: 'URL',
    },
}

export const editableFieldTypeDefinitionList = Object.values(editableFieldTypeDefinitionMap)

export const getEditableFieldTypeDefinition = (
    field: Partial<FieldDto>
): EditableFieldTypeDefinition | undefined => {
    let result

    // Special handling for Airtable rich text fields
    if (isAirtableRichTextField(field)) {
        return editableFieldTypeDefinitionMap['string_with_rich_text']
    }

    // See if we have a matching render_variant type (such as rich text)
    // Careful with mistaking lookups as pure rich text fields
    if (field.options?.render_variant && !field.synthetic_field_type) {
        result = editableFieldTypeDefinitionList.find(
            (type) =>
                type.field_template.type === field.type &&
                type.field_template.options?.render_variant === field.options?.render_variant
        )
    }

    // Or if this is a dynamic field, see if we have a matching synthetic_field_type
    if (!result && getIsSyntheticField(field)) {
        result = editableFieldTypeDefinitionList.find(
            (type) => type.field_template.synthetic_field_type === field.synthetic_field_type
        )
    }

    if (result) {
        return result
    }
    return editableFieldTypeDefinitionList.find((type) => type.field_template.type === field.type)
}
