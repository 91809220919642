// Should convert utils.js to ts, and move this there.

import { AssertionError } from 'assert'

export function assertNever(value: never): never {
    throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`)
}

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
    if (val === undefined || val === null) {
        console.groupCollapsed('Assertion error stack trace')
        console.trace()
        console.groupEnd()
        throw new AssertionError({
            message: 'A value that was never meant to be undefined is undefined. Stacktrace above.',
        })
    }
}

export function isDefined<T>(arg: T | undefined): arg is T {
    return arg !== undefined
}

// Determines whether two fields are of a compatible type for filtering or assignment.
// Lookup-type fields are matched based on link_target_object_id, and other fields are
// matched based on field.type.
export const doesFieldTypeMatch = (
    field: Partial<FieldDto> | undefined,
    targetField: Partial<FieldDto> | undefined
) => {
    if (!field || !targetField) {
        return false
    }
    return ['lookup', 'multi_lookup'].includes(field.type ?? '')
        ? field.link_target_object_id === targetField.link_target_object_id
        : field.type === targetField.type
}
