import { AdvancedFilter } from './types'

const OPERATION_LABELS: Partial<Record<FilterOperation, string>> = {
    equals: 'is',
    notEquals: 'is not',
    lessThan: 'is less than',
    equalsOrLessThan: 'is equal or less than',
    greaterThan: 'is more than',
    equalsOrGreaterThan: 'is equal or more than',
    is: 'is',
    isnt: 'is not',
    listIs: 'is',
    listIsnt: 'is not',
    oneOf: 'is any of',
    noneOf: 'is none of',
    startsWith: 'starts with',
    endsWith: 'ends with',
    sameDay: 'is',
    beforeDate: 'is before',
    afterDate: 'is after',
    isEmpty: 'is empty',
    isNotEmpty: 'is not empty',
    containsAny: 'is any of',
    containsNone: 'is none of',
    contains: 'contains',
    "doesn't contain": 'does not contain',
}

const stringOps: FilterOperation[] = [
    'is',
    'isnt',
    'contains',
    "doesn't contain",
    'isEmpty',
    'isNotEmpty',
]

const numberOps: FilterOperation[] = [
    'equals',
    'notEquals',
    'equalsOrLessThan',
    'equalsOrGreaterThan',
    'lessThan',
    'greaterThan',
    'isEmpty',
    'isNotEmpty',
]

const dateOps: FilterOperation[] = ['sameDay', 'beforeDate', 'afterDate', 'isEmpty', 'isNotEmpty']

const OPERATION_TYPES: Partial<Record<FieldType, FilterOperation[]>> = {
    checkbox: ['is'],
    dropdown: ['is', 'isnt', 'oneOf', 'noneOf', 'isEmpty', 'isNotEmpty'],
    string: stringOps,
    long_text: stringOps,
    lookup: ['is', 'isnt', 'oneOf', 'noneOf', 'isEmpty', 'isNotEmpty'],
    multi_lookup: ['listIs', 'listIsnt', 'oneOf', 'noneOf', 'isEmpty', 'isNotEmpty'],
    multi_select: ['listIs', 'listIsnt', 'containsAny', 'containsNone', 'isEmpty', 'isNotEmpty'],
    url: stringOps,
    number: numberOps,
    currency: numberOps,
    percentage: numberOps,
    date: dateOps,
    datetime: dateOps,
    multi_file: ['isEmpty', 'isNotEmpty'],
    user_ref: ['equals', 'notEquals', 'isEmpty', 'isNotEmpty'],
    document: stringOps,
}

export function getDefaultOperation(field: FieldDto): FilterOperation | undefined {
    return OPERATION_TYPES[field.type]?.[0]
}

export function getSupportedOperations(field: FieldDto): FilterOperation[] {
    return OPERATION_TYPES[field.type] ?? []
}

export function getOperationLabel(field: FieldDto, operation: FilterOperation): string {
    return OPERATION_LABELS[operation] ?? operation
}

export function operationRequiresUserInput(field: FieldDto, operation: FilterOperation): boolean {
    if (field.type === 'checkbox') {
        return !['is', 'isnt'].includes(operation)
    }

    if (['isEmpty', 'isNotEmpty'].includes(operation)) {
        return false
    }

    return true
}

export function getDefaultValueForOperation(
    field: FieldDto,
    operation: FilterOperation
): string | string[] | undefined {
    if (operation === 'isEmpty' || operation === 'isNotEmpty') {
        return undefined
    }

    if (field.type === 'checkbox') {
        return operation === 'is' ? 'true' : undefined
    }

    if (field.type === 'multi_lookup' || field.type === 'multi_select') {
        return []
    }

    return ''
}

export function changeFilterOperation(
    filter: AdvancedFilter,
    newOp: FilterOperation
): AdvancedFilter {
    const prevOp = filter.options.option
    if (prevOp === newOp) return filter

    let newValue: Filter['options']['value'] = getDefaultValueForOperation(
        filter.field as FieldDto,
        newOp
    )

    switch (true) {
        // Converting from scalar to array operations.
        case prevOp === 'is' && newOp === 'oneOf':
        case prevOp === 'is' && newOp === 'noneOf':
        case prevOp === 'isnt' && newOp === 'oneOf':
        case prevOp === 'isnt' && newOp === 'noneOf':
            {
                const value = filter.options.value
                if (!value) break

                newValue = Array.isArray(value) ? value : [value]
            }
            break
        // Converting from array to scalar operations.
        case prevOp === 'oneOf' && newOp === 'is':
        case prevOp === 'noneOf' && newOp === 'is':
        case prevOp === 'oneOf' && newOp === 'isnt':
        case prevOp === 'noneOf' && newOp === 'isnt':
            {
                const value = filter.options.value
                if (!value) break

                newValue = Array.isArray(value) ? value[0] : value
            }
            break
    }

    const scalarOperations = [
        'is',
        'isnt',
        'contains',
        "doesn't contain",
        'startsWith',
        'endsWith',
        'lessThan',
        'equalsOrLessThan',
        'greaterThan',
        'equalsOrGreaterThan',
        'equals',
        'notEquals',
    ]
    if (prevOp && scalarOperations.includes(prevOp) && scalarOperations.includes(newOp)) {
        newValue = filter.options.value
    }

    const arrayOperations = ['oneOf', 'noneOf', 'listIs', 'listIsnt', 'containsAny', 'containsNone']
    if (prevOp && arrayOperations.includes(prevOp) && arrayOperations.includes(newOp)) {
        newValue = filter.options.value
    }

    const dateOperations = ['sameDay', 'beforeDate', 'afterDate']
    if (prevOp && dateOperations.includes(prevOp) && dateOperations.includes(newOp)) {
        newValue = filter.options.value
    }

    return {
        ...filter,
        options: {
            ...filter.options,
            option: newOp,
            value: newValue,
        },
    }
}
