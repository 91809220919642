import React, { useCallback, useState } from 'react'

import styled from '@emotion/styled'

import { AIRTABLE_REPAIR_ERROR_MODAL_TEST_ID } from 'features/AppSettings/DataSources/Airtable/airtableTestIds'

import { Button, Flex, Text } from 'v2/ui'
import { SimpleModalCompat } from 'v2/ui/components/SimpleModal'
import { AirtableUpgradeError } from 'v2/ui/svgs'

export const StyledFlex = styled(Flex)`
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`
export const AirtableRepairErrorModal = ({ onClose }: { onClose: () => void }) => {
    const [hasOpenedSupport, setHasOpenedSupport] = useState(false)

    const openSupport = useCallback(
        (e) => {
            e.preventDefault()
            // @ts-expect-error
            if (!hasOpenedSupport && window.groove) {
                setHasOpenedSupport(true)
                // @ts-expect-error
                window.groove.widget.open()
            }
        },
        [hasOpenedSupport]
    )

    return (
        <SimpleModalCompat
            size="400px"
            height="400px"
            isSecondLayer
            noDividers
            padding="0 32px 40px 32px"
            zIndex={1501}
            actionsBorder={false}
            isOpen
            onClose={onClose}
            hideTitleBottomBorder
            data-testid={AIRTABLE_REPAIR_ERROR_MODAL_TEST_ID}
        >
            <StyledFlex>
                <AirtableUpgradeError />
                <Flex alignItems="center" justifyContent="center" width="100%">
                    <Text variant="heading" marginTop={1} marginBottom={4}>
                        There was an error
                    </Text>
                </Flex>
                <Text fontSize="14px">
                    We&apos;re sorry, there was an error repairing this connection. <br /> <br />
                    Please{' '}
                    <a href="#" onClick={openSupport}>
                        get in touch with Support
                    </a>{' '}
                    and we&apos;ll be able to help resolve the issue.
                </Text>
                <Button
                    flexGrow={1}
                    variant="Secondary"
                    maxW={221}
                    mr={5}
                    buttonSize="medium"
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </StyledFlex>
        </SimpleModalCompat>
    )
}
