import { recordApi } from 'data/api/recordApi'
import { notifyEvent, ObservableEvents } from 'data/eventObservor/eventObservor'
import { queryClient } from 'data/hooks/_helpers'
import { getCacheBackendOverride } from 'data/utils/getCacheBackendOverride'
import { buildUrl, fetchAndReturn } from 'data/utils/utils'
import { InsightEvent, trackInsightsEvents } from 'features/Search/InsightsEvents'

import { updateRecordQuery } from './updateRecordQuery'

// These methods can be used outside of react components
/**
 * Updates a record and adds to the query cache
 * @param {string} recordId
 * @param {any | undefined} data
 * @param includeFields
 * @param options
 */

export const updateRecord = (
    recordId: string,
    data: Partial<RecordDto>,
    includeFields: string[] | undefined,
    options: any = {}
): Promise<any> => {
    // Optimistically update first
    if (options?.deferStoreUpdate) {
        // for this to work correctly, we need to specify that this is partial data
        // and supply the record id
        updateRecordCache({ ...data, _partial: true, _sid: recordId } as RecordDto, includeFields)
    }

    const headers: Record<string, unknown> = {}

    // Undefined can come when setting a number field to empty, but this is ignored by
    // recordApi.patch - let's convert these into nulls.
    for (const key of Object.keys(data)) {
        if (data[key] === undefined) {
            data[key] = null
        }
    }
    const cacheBackendOverride = getCacheBackendOverride()
    if (cacheBackendOverride) options.cacheBackendOverride = cacheBackendOverride
    if (includeFields) {
        data = { ...data, _include_fields: includeFields }
    }
    return recordApi.patch(recordId, data, options, headers).then((response) => {
        // record an insights event for each record updated
        // to boost that record in our search index for this user
        trackInsightsEvents([
            {
                eventName: InsightEvent.UpdatedRecord,
                objectIds: [recordId],
            },
        ])
        updateRecordCache(response as any, includeFields)
        notifyEvent({
            event: ObservableEvents.RECORD_UPDATED,
            data: { recordSid: recordId },
        })

        return response
    })
}
/**
 * Paste/import raw data to update records and create if necessary
 *
 * @param {string} objectId
 * @param {any} records
 */

export const importRawRecords = async (
    objectId: string,
    records: any,
    options: any = {}
): Promise<void> => {
    const path = `objects/${objectId}/records/import_raw/`
    const url = buildUrl(path)

    await fetchAndReturn(
        url,
        {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(records),
        },
        null,
        null,
        options
    )
}
/**
 * Creates a record and adds to the query cache
 * @param {any} data
 * @param includeFields api_name of fields to be returned as part of create record response
 * @param skipReturningRecord If set to true, the response for record creation will not return any of the fields of the
 *        created record, only _sid will be returned
 * @param options
 */

export const createRecord = (
    data: RecordDtoForCreation,
    includeFields: string[] | undefined,
    skipReturningRecord: boolean = false,
    options: any = {}
): Promise<any> => {
    const cacheBackendOverride = getCacheBackendOverride()
    if (cacheBackendOverride) options.cacheBackendOverride = cacheBackendOverride
    if (skipReturningRecord) data.skip_returning_record = true
    if (includeFields) data.include_fields = includeFields

    return recordApi.post(data, options).then((response) => {
        const records = Array.isArray(response) ? response : [response]
        // record an insights event for each record created
        // to boost that record in our search index for this user
        trackInsightsEvents([
            {
                eventName: InsightEvent.CreatedRecord,
                objectIds: records.map((record) => record._sid),
            },
        ])
        updateRecordQuery(records, true, includeFields)
        // need to invalidate any query caches for objects where new records have been created
        const objects = new Set(records.map((record) => record._object_id))
        objects.forEach((object) => {
            invalidateRecords(object)
        })
        records.forEach((record) =>
            notifyEvent({
                event: ObservableEvents.RECORD_CREATED,
                data: { recordSid: record['_sid'] },
            })
        )

        return response
    })
}
/**
 * Deletes a record and invalidates the query cache
 * @param {string} recordId
 */

export const deleteRecord = (recordId: string): Promise<any> => {
    const queryParams: any = {}
    const cacheBackendOverride = getCacheBackendOverride()
    if (cacheBackendOverride) queryParams._cache_backend_override = cacheBackendOverride
    return recordApi
        .delete(recordId, queryParams)
        .then((response) => response.json())
        .then((response) => {
            queryClient.invalidateQueries(['record', recordId])
            queryClient.invalidateQueries(['records', response?._object_id])
            notifyEvent({
                event: ObservableEvents.RECORD_DELETED,
                data: { recordSid: recordId },
            })
            return response
        })
}
/**
 * Deletes multiple records and invalidates the query cache
 * @param {string} objectId
 * @param {string[]} records
 */

export const deleteRecords = (
    objectId: string,
    records: string[],
    options: any = {}
): Promise<any> => {
    const path = `objects/${objectId}/records/delete/`
    const queryParams: any = {}
    const cacheBackendOverride = getCacheBackendOverride()
    if (cacheBackendOverride) queryParams._cache_backend_override = cacheBackendOverride
    return fetchAndReturn(
        buildUrl(path, queryParams),
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ record_ids: records }),
        },
        null,
        null,
        options
    ).then(() => {
        records.forEach((recordId) => {
            queryClient.invalidateQueries(['record', recordId])
            notifyEvent({
                event: ObservableEvents.RECORD_DELETED,
                data: { recordSid: recordId },
            })
        })
        queryClient.invalidateQueries(['records', objectId])
    })
}

/**
 * Restores multiple records and invalidates the query cache
 * @param {string} objectId
 * @param {string[]} recordIds
 */
export const restoreRecords = (
    objectId: string,
    recordIds: string[],
    options: any = {}
): Promise<any> => {
    const path = `objects/${objectId}/records/restore/`
    const queryParams: any = {}
    const cacheBackendOverride = getCacheBackendOverride()
    if (cacheBackendOverride) queryParams._cache_backend_override = cacheBackendOverride
    return fetchAndReturn(
        buildUrl(path, queryParams),
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(recordIds),
        },
        null,
        null,
        options
    ).then(() => {
        invalidateRecords(objectId)
        recordIds.forEach((recordSid) =>
            notifyEvent({
                event: ObservableEvents.RECORD_UPDATED,
                data: { recordSid },
            })
        )
    })
}

/**
 * Directly the record in the query cache & invalidate the object queries
 * @param data
 * @param includeFields
 */

export const updateRecordCache = (
    data: RecordDto | RecordDto[],
    includeFields: string[] | undefined
): void => {
    const records = Array.isArray(data) ? data : [data]
    updateRecordQuery(records, true, includeFields, undefined)
}
/**
 * Invalidates object records so that it will be refetched by any components using the query
 * @param {string} objectId
 */
export const invalidateRecords = (objectId: string): Promise<any> => {
    return Promise.all([
        queryClient.invalidateQueries(['records', objectId]),
        queryClient.invalidateQueries(['recordCount', objectId]),
    ])
}
/**
 * Invalidates the record so that it will be refetched by any components using the query
 * @param {string} recordId
 */
export const invalidateRecord = (recordId: string): Promise<any> => {
    return queryClient.invalidateQueries(['record', recordId])
}
