import React from 'react'

import { Box, Grid } from '@chakra-ui/react'
import { ViewLayoutContext } from 'v2/blocks/types'
import RightHandSideAttributes from 'v2/views/Detail/RightHandSideAttributes'

import { useFields } from 'data/hooks/fields'
import { assertIsDefined } from 'data/utils/ts_utils'
import useEditMode from 'features/admin/edit-mode/useEditMode'

import AttributeDisplay from 'v2/ui/components/Attribute/AttributeDisplay'
import STYLE_CLASSES, { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

type DocumentDetailLayoutProps = {
    header: React.ReactNode
    config: DetailViewOptions
    setConfig: (config: Partial<DetailViewOptions>, setDirty: boolean) => void
    context: ViewLayoutContext
    profileImage: React.ReactNode
    recordPermissions: EntityPermissions
    buttons: React.ReactNode
    children: React.ReactNode
    showControls?: boolean
    isRecordList?: boolean
}

export const DocumentDetailLayout: React.FC<DocumentDetailLayoutProps> = ({
    header,
    config,
    context,
    showControls,
    setConfig,
    profileImage,
    recordPermissions,
    buttons,
    children,
}) => {
    let { data: allFields } = useFields()
    let documentFieldSid
    const documentFieldApiName = config?.documentField?.id
    if (documentFieldApiName) {
        let documentField = allFields?.find(
            (f) => f.api_name === documentFieldApiName && f.object_id == context.object._sid
        )
        if (documentField) documentFieldSid = documentField._sid
    }

    const { isOpen: isEditMode } = useEditMode()

    assertIsDefined(context.view)

    return (
        <>
            <style>
                {`

            .block.block-center_content {
                margin-top: 0px !important;
            }
            .stk-detail-page .stk-content {
                padding: 0px !important;
                padding-left: 40px !important;
                padding-right: 0px !important;
            }
            .stk-detail-page .stk-content > div {
                padding: 0px 0px 0px 0px !important;
                width: 100% !important;
            }

            .stk-detail-page .stk-content, #chakra-modal-preview-record-modal {
                background-color: white;
            }

            .powered-by {
                display: none;
            }

            #pageHeroContainer {
                max-width: calc(75% + 10px);
                padding-left: 10px;
                height: 50px;
                margin-bottom: -50px;
                border-bottom: 1px solid rgb(238, 240, 241);
                padding-top: 5px;
            }
            .stk-record-header {
                max-width: 1000px;
            }

            #pageHeroContainer > div > div {
                max-width: 100%;
            }

            .field_container-block section {
                box-shadow: none;
                border: 1px solid rgb(238, 240, 241);
            }

            .detail_view_related_list-block .admin-table-container {
                box-shadow: none;
                border: 1px solid rgb(238, 240, 241);
            }

            .detail_view_related_list-block .stk-heading {
                font-size: 1.3em;
            }

            .admin-detail-view-container {
                margin-top: 0px;
                grid-gap: 0px;
            }

            .document-detail-view-document-container .editor-shell {
                width: calc(100% + 40px);
                margin-left: -40px;
            }

            .document-detail-view-document-container {
                max-width: 100%;
                margin-left: auto;
                margin-right: auto;
            }

            .document-detail-view-document-container .editor-shell :not(i) {
                font-family: "Proxima Nova" !important;
            }

            .document-detail-view-document-container .stk-text-fieldLabel {
                display: none;
            }

            .document-detail-view-document-layout .${STYLE_CLASSES.RECORD_HEADER} {
                margin-bottom: 8px;
            }
            `}
            </style>
            <Grid
                templateColumns={['minmax(0, 3fr) minmax(0, 1fr)']}
                gap={4}
                className={ONBOARDING_CLASSES.DETAIL_VIEW_CONTAINER}
                flexGrow={1}
            >
                <Box
                    style={{
                        paddingRight: '12px',
                        height: '50px',
                        borderBottom: '1px solid rgb(238, 240, 241)',
                        justifyContent: 'flex-end',
                        display: 'flex',
                        alignItems: 'center',
                        zIndex: 10,
                        position: 'relative',
                        gridColumn: '1 / span 2',
                        pointerEvents: 'none',
                    }}
                >
                    <div style={{ pointerEvents: 'all' }}>{buttons}</div>
                </Box>
                <Box
                    style={{ paddingRight: 40, paddingTop: '16px' }}
                    position="relative"
                    className="document-detail-view-document-layout"
                >
                    {header}
                    {documentFieldSid && (
                        <div className="document-detail-view-document-container">
                            <AttributeDisplay
                                key={isEditMode ? 'edit' : 'display'}
                                objectId={context.object._sid}
                                fieldId={documentFieldSid}
                                record={context.record}
                                editing={context.view.editing}
                                showErrors={context.view.showErrors}
                                setValue={context.view.actions.setValue}
                                setValid={context.view.actions.setValid}
                                setEditing={context.view.actions.setEditing}
                                setFocusField={context.view.actions.setFocusField}
                                focusField={context.view.focusField}
                                valid={context.view.valid}
                                retainPadding={true}
                                doubleClickToEdit={!isEditMode}
                            />
                        </div>
                    )}
                    {children}
                </Box>
                <Box style={{ borderLeft: '1px solid rgb(238, 240, 241)' }}>
                    <RightHandSideAttributes
                        config={config}
                        context={context}
                        showControls={showControls}
                        setConfig={setConfig}
                        profileImage={profileImage}
                        recordPermissions={recordPermissions}
                        isEditMode={isEditMode}
                    />
                </Box>
            </Grid>
        </>
    )
}
