import React, { useEffect, useRef } from 'react'

import { Spinner } from '@chakra-ui/react'

import AdminFrame from 'features/core/AdminFrame'
import { useAppSettings } from 'features/workspace/AdminSideTray/hooks/useAppSettings'

import { Layout } from './AdminDashboard'

export default function AdminModal() {
    const { open: openAppSettings } = useAppSettings()
    const appSettingsOpened = useRef(false)

    useEffect(() => {
        if (!appSettingsOpened.current) {
            openAppSettings({ page: { name: 'navigation' } })
            appSettingsOpened.current = true
        }
    }, [openAppSettings])

    return (
        <AdminFrame>
            <Layout>
                <div style={{ margin: 50 }}>
                    <Spinner />
                </div>
            </Layout>
        </AdminFrame>
    )
}
