import React, { Component, memo, useEffect, useRef } from 'react'

import styled from '@emotion/styled'
import classNames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { withObject } from 'data/wrappers/WithObject'
import { isBlank } from 'utils/utils'

import { Text } from 'v2/ui'
import Attribute from 'v2/ui/components/Attribute/Attribute'
import LabelledAttribute from 'v2/ui/components/Attribute/LabelledAttribute'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useDebounce from 'v2/ui/utils/useDebounce'

import { Button } from 'ui/components/Button'
import { HoverContainerStyle, OpacityOnContainerHover } from 'ui/styles/hoverUtils.css'

import { attributeShouldDisplayLabel } from './attributeShouldDisplayLabel'

import { AttributeHoverStyle } from './AttributeDisplayStyle.css'

const labelStyle = { margin: 'none', padding: 'none' }

// A wrapper around Attribute and LabelledAttribute which accounts for the extra display logic.
const AttributeDisplay = ({
    object,
    fieldId,
    record,
    required,
    fullWidth,
    enableCopyPaste,
    readOnly,
    editing,
    labelOverride,
    editDescription,
    variant,
    layout,
    isVisible,
    isCreate,
    isInlineCreate,
    showErrors,
    setValue,
    setValid,
    valid,
    blockId,
    placeholder,
    isLoading,
    setEditing,
    setFocusField,
    focusField,
    doubleClickToEdit,
    ...renderOptions
}) => {
    const containerRef = useRef(null)
    const doubleClickOffset = useRef(null)

    const handleOnChange = useDebounce(
        (value, name, options = undefined) => {
            setValue(name, value, options)
        },
        200,
        [setValue]
    )

    const field = object.fields.find((field) => field._sid === fieldId)

    useEffect(() => {
        if (focusField === field?.api_name && editing && doubleClickOffset.current) {
            const containerPos = getViewportCoordinates(containerRef.current)

            // Our app has a root 100vh div that does the scrolling (contentWrapper)
            // so we try to get that here
            const scrollRoot = document.getElementById('contentWrapper') || document.documentElement
            scrollRoot.scrollTop += containerPos.y - doubleClickOffset.current.y
        }
    }, [focusField, editing, field?.api_name])

    let value = null
    // Check field here rather than making use of the check below. We can't put the line
    //   if (!field) return ''
    // before here because is has to be after the useEffect(), which has to come _after_ this block
    // becasue it uses the variable `value`
    if (record && field) {
        // Want to return null here instead of undefined,
        // as would be the case with a new record
        value = record[field?.api_name] !== undefined ? record[field?.api_name] : null
    }

    if (!field) return ''
    if (get(field, 'connection_options.is_disabled', false)) return ''

    const isValid = !isVisible || !required || !isBlank(value, field)

    const showError = showErrors && !isValid
    const isEditing = editing && !readOnly

    let cellStyle = { maxWidth: '100%' }
    if (fullWidth) {
        cellStyle.gridColumn = '1 / -1'
    }

    const hideLabel =
        renderOptions?.hideLabel || !attributeShouldDisplayLabel(field, renderOptions, isEditing)
    const className = `${STYLE_CLASSES.ATTRIBUTE} ${STYLE_CLASSES.ATTRIBUTE}-${field.api_name}`

    const handleDoubleClick = () => {
        if (!isEditing) {
            doubleClickOffset.current = getViewportCoordinates(containerRef.current)
            setFocusField(field.api_name)
            setEditing(true)
        }
    }

    const canDoubleClickToEdit = !readOnly && doubleClickToEdit && !editing
    return (
        <span style={cellStyle} className={className} ref={containerRef}>
            <LabelledAttribute
                dontHideOverflow
                maxWidth={fullWidth && '100%'}
                label={labelOverride || field.label}
                hideLabel={hideLabel}
                editing={isEditing}
                editDescription={editDescription}
                required={editing && required}
                style={labelStyle}
                variant={variant}
                layout={layout}
                noPadding
                recordId={record?._sid}
                onDoubleClickCapture={canDoubleClickToEdit ? handleDoubleClick : undefined}
                className={
                    canDoubleClickToEdit
                        ? classNames(AttributeHoverStyle, HoverContainerStyle)
                        : undefined
                }
                position="relative"
            >
                <Attribute
                    isInlineCreate={isInlineCreate}
                    isCreate={isCreate}
                    field={field}
                    editable={isEditing}
                    onChange={handleOnChange}
                    renderOptions={renderOptions}
                    layout={layout}
                    animateOnValueChange={value}
                    enableCopyPaste={enableCopyPaste}
                    recordId={record?._sid}
                    contextRecord={record}
                    placeholder={placeholder}
                    isLoading={isLoading}
                    hasFocus={focusField === field.api_name}
                >
                    {value}
                </Attribute>
                {showError && (
                    <Error className={STYLE_CLASSES.FIELD_ERROR}>This field is required</Error>
                )}
                {canDoubleClickToEdit && (
                    <Button
                        position="absolute"
                        right="xs"
                        top="xs"
                        size="2xs"
                        startIcon={{ name: 'Pencil' }}
                        onClick={handleDoubleClick}
                        variant="ghost"
                        className={OpacityOnContainerHover}
                    />
                )}
            </LabelledAttribute>
            {isEditing && (
                <ValidityReporter
                    key={isValid}
                    name={field.api_name}
                    isValid={isValid}
                    setValid={setValid}
                    validStore={valid}
                    blockId={blockId}
                />
            )}
        </span>
    )
}

AttributeDisplay.propTypes = {
    objectId: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    enableCopyPaste: PropTypes.bool,
    readOnly: PropTypes.bool,
}

AttributeDisplay.defaultProps = {
    required: false,
    fullWidth: false,
    readOnly: false,
    isLoading: false,
}

export default memo(withObject(AttributeDisplay))

class ValidityReporter extends Component {
    render = () => null

    constructor(props) {
        super(props)
    }

    fieldName = () => {
        const { name, blockId } = this.props
        return `${name}${blockId ? '___' + blockId : ''}`
    }

    // call setValid on mount and on update
    componentDidMount() {
        const { isValid, setValid } = this.props
        setValid(this.fieldName(), isValid)
    }
    componentDidUpdate(prevProps) {
        const { isValid, setValid } = prevProps
        if (isValid === this.props.isValid) return
        setValid && setValid(this.fieldName(), this.props.isValid)
    }
    componentWillUnmount() {
        // If the component unmounts due to conditional visibility then mark the field as
        // valid so that the form will submit
        const { setValid } = this.props
        setValid && setValid(this.fieldName(), true)
    }
}

const Error = styled(Text)`
    color: red;
    margin: 0.5rem;
`

function getViewportCoordinates(element) {
    const rect = element.getBoundingClientRect()
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    const x = rect.left + scrollLeft
    const y = rect.top + scrollTop

    return { x, y }
}
