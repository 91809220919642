import {
    ACCEPTED_TRANSLATIONS_FETCHED,
    TRANSLATION_CREATED,
    TRANSLATION_REMOVED,
    TRANSLATION_UPDATED,
    TRANSLATIONS_FETCHED,
} from 'data/utils/constants'
import { StackerActions } from 'data/utils/stackerActions'
import { fetchAndReturn, StackerAPI } from 'data/utils/utils'

// API
class TranslationApi extends StackerAPI {
    path = 'translations/'
}
const translationApi = new TranslationApi()

class TranslationActions extends StackerActions {
    api = translationApi

    reducer = 'translations'

    name = 'translations'

    actionTypes = {
        fetched: TRANSLATIONS_FETCHED,
        removed: TRANSLATION_REMOVED,
        created: TRANSLATION_CREATED,
        updated: TRANSLATION_UPDATED,
    }

    fetchAcceptedTranslations = () => {
        return (dispatch) => {
            fetchAndReturn('accepted-translations/').then((results) => {
                return dispatch({ type: ACCEPTED_TRANSLATIONS_FETCHED, payload: results })
            })
        }
    }

    afterUpdate = (dispatch) => {
        // eslint-disable-next-line no-use-before-define
        dispatch(translationActions.fetch())
        // dispatch(TA.actions.fetchAcceptedTranslations())
    }

    afterCreate = (dispatch) => {
        // eslint-disable-next-line no-use-before-define
        dispatch(translationActions.fetch())
        // dispatch(TA.actions.fetchAcceptedTranslations())
    }
}

const TA = new TranslationActions()
TA.actions = { ...TA.actions, fetchAcceptedTranslations: TA.fetchAcceptedTranslations }
export const translationActions = TA.actions

// SELECTORS
export const getTranslationEntities = (state) => state.translations.entities
export const getTranslations = (state) => state.translations.translations

export const getAcceptedTranslations = (state) => state.translations.acceptedTranslations
// HELPERS
