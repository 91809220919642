// @ts-strict-ignore
import React, { useEffect, useState } from 'react'

import { useDataConnection } from 'data/hooks/dataConnections'
import DataConnectionEditor from 'features/admin/data-connector/DataConnectionEditor'
import { ProviderIcon } from 'features/admin/data-connector/ProviderIcon'

import { Flex, Modal, Text } from 'v2/ui'

const openFunction: any = {}

// This function can be called from anywhere to start a schema sync
// and show the progress in a modal
export function showSyncDataSourceSchemaModal(dataConnectionId) {
    openFunction.setDataConnectionId(dataConnectionId)
}

export function SyncDataSourceSchemaModal() {
    const [dataConnectionId, setDataConnectionId] = useState<null | any>(null)
    const { data: dataConnection } = useDataConnection(dataConnectionId)

    useEffect(() => {
        openFunction.setDataConnectionId = setDataConnectionId
    }, [])

    if (!dataConnection) return null

    const handleClose = () => {
        setDataConnectionId(null)
    }

    const getModalTitle = () => {
        if (dataConnection.type === 'fivetran') return `Syncing ${dataConnection.label}`

        return `Syncing ${dataConnection.label} Schema`
    }

    return (
        <Modal
            size="390px"
            isOpen={true}
            isSecondLayer
            noDividers
            title={
                <Flex>
                    <ProviderIcon
                        type={dataConnection.type}
                        serviceName={dataConnection.service_name}
                        size="24px"
                        style={{
                            verticalAlign: 'middle',
                            display: 'inline',
                            marginRight: '8px',
                        }}
                    />
                    <Text display="inline">{getModalTitle()}</Text>
                </Flex>
            }
            onClose={handleClose}
        >
            <Flex column align="stretch" width="100%" style={{ padding: 12 }}>
                <DataConnectionEditor
                    setModalHidden={() => undefined}
                    isNew={false}
                    createDataConnection={() => null as any}
                    updateDataConnection={() => null as any}
                    // TODO is there a hint image here?
                    onChangeHintImage={() => undefined}
                    provider={dataConnection.type}
                    dataConnection={dataConnection}
                    onClose={handleClose}
                    schemaSyncOnly={true}
                />
            </Flex>
        </Modal>
    )
}
