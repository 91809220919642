import React from 'react'

import KeyValueList from 'features/studio/ui/KeyValueList'
import { Text, TextArea } from 'legacy/v1/ui'
import EditableWrapper from 'legacy/v1/ui/utils/EditableWrapper'

import 'moment/min/locales'

import 'react-datepicker/dist/react-datepicker.css'

export function renderKeyValueAttribute(onChange, props) {
    const options = props.field.options || {}
    const form = (
        <KeyValueList
            keyName={options.keyName || 'label'}
            valueName={options.valueName || 'value'}
            onChange={onChange}
            value={props.children || []}
        />
    )

    return form
}

export function renderJSONAttribute(onChange, props) {
    const inlineForm = <TextArea margin="none">{JSON.stringify(props.children) || ''}</TextArea>
    const form = inlineForm
    const display = <Text margin="none">{JSON.stringify(props.children) || '-'}</Text>

    if (props.editable & props.inline) {
        return (
            <EditableWrapper
                input={({ end, ref }) =>
                    React.cloneElement(inlineForm, {
                        innerRef: (me) => (ref.ref = me),
                        onBlur: end,
                    })
                }
                value={({ start }) => React.cloneElement(display, { onClick: start })}
                onChange={(v) => onChange(JSON.parse(v))}
            />
        )
    }
    if (props.editable) {
        return form
    }
    return display
}
