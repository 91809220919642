import React from 'react'

import { AttachmentsAttributeDisplay } from 'features/views/attributes/AttachmentsAttributeDisplay'
import { AttachmentsAttributeTag } from 'features/views/attributes/AttachmentsAttributeTag'
import { AttachmentValue } from 'features/views/attributes/types'

import { useAttachmentsAttributeState } from './hooks/useAttachmentsAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { BoardViewAttributeComponent } from './types'

const MAX_ATTACHMENTS = 1
const MAX_ATTACHMENTS_IMAGE = 3
const MAX_ITEM_LENGTH = 20
const MAX_ITEM_LENGTH_SINGLE = 25
const MAX_LINES = 1

type AttachmentsAttributeProps = {}

export const AttachmentsAttribute: BoardViewAttributeComponent<
    AttachmentValue[],
    AttachmentsAttributeProps
> = ({ value, attribute, isLoading, displayStyle, ...props }) => {
    const { field, rawOptions, type } = attribute
    const isTitle = type === 'title'
    const isSubtitle = type === 'subtitle'

    const { displayAsImage, displayAsTag, isEmpty, isSingle, isTagListAttributeStyle } =
        useAttachmentsAttributeState({
            value,
            isLoading,
            displayStyle,
            attribute,
        })

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} {...props}>
            {displayAsTag ? (
                <AttachmentsAttributeTag
                    size={isTagListAttributeStyle ? 's' : 'm'}
                    value={value}
                    field={field}
                    maxItemLength={isSingle ? MAX_ITEM_LENGTH_SINGLE : MAX_ITEM_LENGTH}
                    isLoading={isLoading}
                    displayAsImage={displayAsImage}
                    imageAltText={rawOptions.imageAltText as string}
                />
            ) : (
                <AttachmentsAttributeDisplay
                    size={{
                        mobile: isTitle ? 'm' : 's',
                        tablet: 'm',
                    }}
                    value={value}
                    field={field}
                    maxAttachments={displayAsImage ? MAX_ATTACHMENTS_IMAGE : MAX_ATTACHMENTS}
                    maxItemLength={
                        isSingle && !isTitle && !isSubtitle
                            ? MAX_ITEM_LENGTH_SINGLE
                            : MAX_ITEM_LENGTH
                    }
                    maxLines={MAX_LINES}
                    displayAsImage={displayAsImage}
                    imageAltText={rawOptions.imageAltText as string}
                    isLoading={isLoading}
                />
            )}
        </BaseAttribute>
    )
}
