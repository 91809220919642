import { UseQueryOptions } from 'react-query'

import { useAuthContext } from 'app/AuthContext/AuthContext'

import { invalidateStacks } from './stacks/stackOperations'
import {
    ACCOUNT_QUERY_RETRY_OPTIONS,
    queryClient,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useUpdateItem,
} from './_helpers'
import { invalidateZones } from './zones'

const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useAccounts'
const ENDPOINT = 'accounts/'

export function useAccounts(options: UseQueryOptions<Account[]> = {}) {
    const { user } = useAuthContext()

    return useQuery<Account[]>(
        LIST_NAME,
        ENDPOINT,
        { ...options, ...ACCOUNT_QUERY_RETRY_OPTIONS, enabled: !!user },
        {
            bypassMatchingStackCheck: true,
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
        }
    )
}

export function useAccountBySlug(slug: string) {
    const query = useQuery<Account[]>(
        LIST_NAME + slug,
        `${ENDPOINT}?slug=${slug}`,
        { ...ACCOUNT_QUERY_RETRY_OPTIONS, enabled: !!slug },
        {
            bypassMatchingStackCheck: true,
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
            bypassQueryDeps: true,
        }
    )
    return { ...query, data: query?.data?.[0] }
}

export function useCreateAccount() {
    return useCreateItem<Account>(
        LIST_NAME,
        ENDPOINT,
        { onSuccess: invalidateZones },
        {
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
        },
        false
    )
}

export function useUpdateAccount({
    allowOptimisticUpdates,
}: {
    allowOptimisticUpdates?: boolean
} = {}) {
    return useUpdateItem<Account>(
        LIST_NAME,
        ENDPOINT,
        {
            onSuccess: () => {
                invalidateStacks()
                invalidateZones()
            },
        },
        {
            // Submit this request using the studio user's token
            // and ignore any user or role previewing.
            bypassPreviewAs: true,
        },
        allowOptimisticUpdates
    )
}

export function useDeleteAccount() {
    return useDeleteItem<Account>(LIST_NAME, ENDPOINT, undefined, {
        // Submit this request using the studio user's token
        // and ignore any user or role previewing.
        bypassPreviewAs: true,
    })
}

export function invalidateAccounts() {
    return queryClient.invalidateQueries(LIST_NAME)
}
export function refetchAccounts() {
    return queryClient.refetchQueries(LIST_NAME)
}
// Stubbed in support for user "groups". Currently we only have one group: workspace collaborators.
// But eventually we'll allow custom groups at the workspace level. This code will need to be extended
// then to include those groups.
export const workspaceGroups: WorkspaceGroupDto[] = [
    {
        _sid: 'workspace',
        name: 'Workspace Collaborators',
        type: 'group',
    },
]

export function replaceCachedAccount(account: Account) {
    queryClient.setQueryData(LIST_NAME, (accounts: Account[] | undefined = []) => {
        return accounts.map((acc) => {
            if (acc._sid === account._sid) {
                return account
            }
            return acc
        })
    })
}
