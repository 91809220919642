import { BaseVariablesTheme } from 'ui/styling/baseVariables/BaseVariablesTheme.css'

import { makeAppVariables } from './app'
import { makeAvatarVariables } from './avatar'
import { makeAvatarGroupVariables } from './avatar_group'
import { makeBannerVariables } from './banner'
import { makeBreadcrumbsVariables } from './breadcrumbs'
import { makeButtonVariables } from './button'
import { makeCheckboxVariables } from './checkbox'
import { makeDatePickerVariables } from './date_picker'
import { makeDividerVariables } from './divider'
import { makeDropdownVariables } from './dropdown'
import { makeFieldVariables } from './field'
import { makeIconVariables } from './icon'
import { makeInputVariables } from './input'
import { makeLinkVariables } from './link'
import { makeModalVariables } from './modal'
import { makePaginationVariables } from './pagination'
import { makePopupVariables } from './popup'
import { makeProgressVariables } from './progress'
import { makeRadioVariables } from './radio'
import { makeRangeVariables } from './range'
import { makeSelectVariables } from './select'
import { makeSpinnerVariables } from './spinner'
import { makeSplitButtonVariables } from './split_button'
import { makeTabsVariables } from './tabs'
import { makeTagVariables } from './tag'
import { makeTextareaVariables } from './textarea'
import { makeToastVariables } from './toast'
import { makeToggleVariables } from './toggle'
import { makeTooltipVariables } from './tooltip'

function makeDefaultThemeVariables(baseVariablesOverrides?: Partial<typeof BaseVariablesTheme>) {
    const s = makeAppVariables(baseVariablesOverrides)

    return {
        ...s,
        avatar: makeAvatarVariables(s),
        avatarGroup: makeAvatarGroupVariables(s),
        banner: makeBannerVariables(s),
        breadcrumbs: makeBreadcrumbsVariables(s),
        button: makeButtonVariables(s),
        checkbox: makeCheckboxVariables(s),
        datePicker: makeDatePickerVariables(s),
        divider: makeDividerVariables(s),
        dropdown: makeDropdownVariables(s),
        field: makeFieldVariables(s),
        icon: makeIconVariables(s),
        input: makeInputVariables(s),
        link: makeLinkVariables(s),
        modal: makeModalVariables(s),
        pagination: makePaginationVariables(s),
        popup: makePopupVariables(s),
        progress: makeProgressVariables(s),
        radio: makeRadioVariables(s),
        range: makeRangeVariables(s),
        select: makeSelectVariables(s),
        spinner: makeSpinnerVariables(s),
        splitButton: makeSplitButtonVariables(s),
        tabs: makeTabsVariables(s),
        tag: makeTagVariables(s),
        textarea: makeTextareaVariables(s),
        toast: makeToastVariables(s),
        toggle: makeToggleVariables(s),
        tooltip: makeTooltipVariables(s),
    }
}

export default makeDefaultThemeVariables

// s.color.text.label = {
//     disabled: s.color.text.weakest,
// }

// s.color.text.input = {
//     placeholder: s.color.text.weakest,
// }

// s.color.icon = {
//     default: s.color.gray500,
//     inverse: s.color.white00,
//     disabled: s.color.gray300,
//     error: s.color.error600,
// }

// s.color.border.selector = {
//     default: s.color.border.default,
//     hover: s.color.border.theme.weak,
//     focus: s.color.border.theme.weak,
// }

// s.color.border.input = {
//     default: s.color.border.default,
//     hover: s.color.border.strong,
//     focus: s.color.border.focus,
//     readOnly: s.color.border.default,
// }

// s.radius.input = s.radius.m
