import React from 'react'

import styled from '@emotion/styled'

import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'

import { Button, Icon } from 'v2/ui'
import * as SvgIcons from 'v2/ui/svgs'

type WrapperProps = {
    zIndex: number
}

const Wrapper = styled.div<WrapperProps>`
    position: fixed;
    bottom: 17px;
    right: 20px;
    z-index: ${({ zIndex }) => zIndex};
    margin-left: 30px;
`

export const HelpCenter = () => {
    const SvgComponent = SvgIcons.QuestionMarkBlack

    const { state } = useSlidingPane()
    const isSlidingPaneOpen = Boolean(state.key)

    return (
        <Wrapper zIndex={isSlidingPaneOpen ? 20 : 1400}>
            <Button
                style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#ffffff',
                    boxShadow:
                        'rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px',
                }}
                buttonSize="mdDense"
                type="button"
                variant="help"
                // @ts-expect-error
                onClick={() => window.groove.widget.toggle()}
            >
                <Icon svg={<SvgComponent />} />
            </Button>
        </Wrapper>
    )
}
