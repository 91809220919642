import React, { useContext, useState } from 'react'

import { Global } from '@emotion/react'

import AppContext from 'app/AppContext'
import { resumeSubscription } from 'data/api/subscriptionApi'
import { invalidateAccounts, useUpdateAccount } from 'data/hooks/accounts'
import { formatSubscriptionCancelDate, shouldDisplayCancelDate } from 'features/billing/utils'
import { Divider, Frame } from 'features/workspace/WorkspaceSettingsModalUi'
import useTrack from 'utils/useTrack'

import { Button, Toast } from 'v2/ui'

import Flex from 'ui/deprecated/atoms/Flex'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import BillingInfo from './BillingInfo'
import { isSubscriptionCancelled } from './stackerUserUtils'

export default function WorkspaceBillingSettings({ children }) {
    const { track } = useTrack()
    const { workspaceAccount } = useContext(AppContext)
    const { mutateAsync: updateAccount } = useUpdateAccount()
    const [billingError, setBillingError] = useState()
    const customerId = workspaceAccount?.stripe_customer_id
    const title = customerId ? 'Billing' : 'Choose a plan'
    const customerSubscribed =
        workspaceAccount?.stripe_customer_id &&
        workspaceAccount?.stripe_subscription_id &&
        !isSubscriptionCancelled(workspaceAccount)
    const showCancelButton = !!customerSubscribed

    const subscriptionEndDateISO = workspaceAccount?.subscription_end_date
    const displayCancelDate = shouldDisplayCancelDate(workspaceAccount)
    const formattedCancelDate = formatSubscriptionCancelDate(subscriptionEndDateISO)

    const onCancel = () => {
        track('WIP - Frontend - Billing Settings - Cancellation Started')

        const subscription_end_date = new Date().toISOString()
        setBillingError(null)
        // Update the local copy of account stored in state
        // The version on the server is updated with Stripe webhoooks
        updateAccount({
            id: workspaceAccount._sid,
            patch: { subscription_status: 'cancelled', subscription_end_date },
        })
            .then(() => {
                track('WIP - Frontend - Billing Settings - Cancellation Completed')
                invalidateAccounts()
            })
            .catch(() => {
                track('WIP - Frontend - Billing Settings - Cancellation Failed')
                setBillingError('There was an error. Please try again.')
            })
    }
    return (
        <Frame title={title} flex={1}>
            <BillingInfo />
            {showCancelButton ? (
                <>
                    <Global
                        styles={{
                            '.ck-style': {
                                zIndex: 1500,
                                position: 'relative',
                            },
                        }}
                    />
                    <Divider />
                    <Flex style={{ alignItems: 'center', ...V4DesignSystem.h2 }}>
                        <div style={{ flex: 1 }}>Cancel Subscription</div>
                        <Button variant="moderateSm" onClick={onCancel} icon="alert">
                            Cancel Subscription
                        </Button>
                    </Flex>
                </>
            ) : (
                <>
                    <Divider />
                    {displayCancelDate && <p>{formattedCancelDate}</p>}
                </>
            )}
            {isSubscriptionCancelled(workspaceAccount) && (
                <Button
                    buttonSize="medium"
                    disabled={false}
                    aria-label="Resume subscription"
                    mb={2}
                    width="min-content"
                    variant="Primary"
                    onClick={() => resumeSubscription()}
                >
                    Resume Subscription
                </Button>
            )}
            {children}
            <Toast
                status="error"
                show={!!billingError}
                onCloseComplete={() => {
                    setBillingError(null)
                }}
                title={billingError}
            />
        </Frame>
    )
}
