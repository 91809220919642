const Types = {
    LOOKUP: 'lookup',
    MULTI_LOOKUP: 'multi_lookup',
}

const InternalTypes = {
    RELATIONSHIP: 'relationship',
}

/**
 *
 * @param {LinkCreateData} data
 * @returns
 */
export function buildLinkRequest(objectsById, data) {
    const isReversed = data.linkFieldObject === data.target
    if (data.mode === 'one-to-many' && isReversed) {
        return buildOneToManyLinkRequest(objectsById, {
            label: data.targetFieldLinkName,
            source: data.target,
            target: data.source,
            sourceField: data.targetField,
            targetField: data.sourceField,
            filters: data.filters,
        })
    }
    if (data.mode === 'one-to-many') {
        return buildOneToManyLinkRequest(objectsById, {
            label: data.sourceFieldLinkName,
            source: data.source,
            target: data.target,
            sourceField: data.sourceField,
            targetField: data.targetField,
            filters: data.filters,
        })
    }
    if (data.mode === 'many-to-many' && isReversed) {
        return buildManyToManyLinkRequest(objectsById, {
            label: data.targetFieldLinkName,
            source: data.target,
            target: data.source,
            sourceField: data.targetField,
            targetField: data.sourceField,
            delimiter: data.delimiter,
            filters: data.filters,
        })
    }
    if (data.mode === 'many-to-many') {
        return buildManyToManyLinkRequest(objectsById, {
            label: data.sourceFieldLinkName,
            source: data.source,
            target: data.target,
            sourceField: data.sourceField,
            targetField: data.targetField,
            delimiter: data.delimiter,
            filters: data.filters,
        })
    }
}

function buildOneToManyLinkRequest(
    objectsById,
    { label, source, target, sourceField, targetField, filters }
) {
    return {
        label,
        type: Types.LOOKUP,
        object_id: source,
        link_target_object_id: target,
        synthetic_field_type: InternalTypes.RELATIONSHIP,
        connection_options: {
            relationship_local_lookup_field: sourceField,
            relationship_target_lookup_field: targetField,
            relationship_target_lookup_filters: filters,
        },
    }
}

function buildManyToManyLinkRequest(
    objectsById,
    { label, source, target, sourceField, targetField, delimiter, filters }
) {
    return {
        label,
        type: Types.MULTI_LOOKUP,
        object_id: source,
        link_target_object_id: target,
        synthetic_field_type: InternalTypes.RELATIONSHIP,
        connection_options: {
            relationship_local_lookup_field: sourceField,
            relationship_local_lookup_field_delimiter: delimiter?.trim(),
            relationship_target_lookup_field: targetField,
            relationship_target_lookup_filters: filters,
        },
    }
}
