import React from 'react'

import HELP_LINKS from 'app/helpLinks'
import publicAsset from 'utils/publicAsset'

import { Flex, Text } from 'v2/ui'
import * as SvgIcons from 'v2/ui/svgs'

import { DataConnector, ProviderTypes } from './dataConnectorTypes'

export const DATA_PROVIDERS: { [key in ProviderTypes]: DataConnector } = {
    airtable: {
        name: 'Airtable',
        allowSchemaSync: true,
        allowDataSync: true,
        icon: SvgIcons.Airtable,
        openSourceText: 'Open in Airtable',
        getSourceLink: (dc) => {
            const appId = dc.options?.app_id
            return appId ? `https://airtable.com/${appId}` : undefined
        },
        hintImagesUrls: [
            publicAsset('/static/media/newAirtableHint1.png'),
            publicAsset('/static/media/newAirtableHint2.png'),
        ],
        responsiveHintImageUrls: [publicAsset('/static/media/responsiveAirtableHint1.png')],
        notResponsive: true,
        isProviderShownWhenCreatingAStack: true,
        editor: true,
        isProviderShownInAddNewMenu: true,
        cta: 'Add',
        description: 'Database',
    },
    postgresV2: {
        name: 'PostgreSQL',
        allowSchemaSync: true,
        icon: SvgIcons.PostgreSQL,
        helpLink: HELP_LINKS.POSTGRES_CONNECTION_LEARN_MORE,
        cta: 'Add',
        description: 'Database',
        cacheFillNeededOnFieldChange: true,
        editor: true,
        allowDataSync: true,
        hidden: true,
    },

    stripe: {
        name: 'Stripe',
        icon: (props: any) => (
            <Flex {...props}>
                <img
                    src={publicAsset('/static/media/stripe_logo.png')}
                    style={{ width: '100%', borderRadius: '2px' }}
                />
            </Flex>
        ),
        isBeta: true,
        v4: true,
        splashMessage: (
            <>
                <Text>
                    Connecting to <strong>Stripe</strong> allows you to create apps with your Stripe{' '}
                    <em>customer</em> and <em>subscription</em> data.
                </Text>
                <Text mt={4}>
                    (Note that it doesn’t allow you to take payments from your customers.)
                </Text>
                <Text mt={4}>
                    The <strong>Stripe</strong> data source is currently in beta and has
                    limitations. {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a target="_blank" href="http://stackerhq.com/data/stripe/docs">
                        Learn more…
                    </a>
                </Text>
            </>
        ),
        getSubtitle: (dc) => dc?.options?.stripe_account_name,
        hintImagesUrls: [publicAsset('/static/media/stripeHint1.png')],
        editor: true,
        allowDataSync: true,
        hidden: true,
    },
    intercom: {
        name: 'Intercom',
        icon: SvgIcons.Intercom,
        isBeta: true,
        allowRevoke: true,
        v4: true,
        splashMessage: (
            <>
                <Text>
                    Connecting to <strong>Intercom</strong> allows you to create apps with your
                    Intercom <em>lead</em> and <em>user</em> data.
                </Text>
                <Text mt={4}>
                    (Note that this doesn’t allow you to send messages to your customers.)
                </Text>
                <Text mt={4}>
                    The <strong>Intercom</strong> data source is currently in beta and has
                    limitations. {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a target="_blank" href="http://stackerhq.com/data/intercom/docs">
                        Learn more…
                    </a>
                </Text>
            </>
        ),
        editor: true,
        hintImagesUrls: [publicAsset('/static/media/intercomHint1.png')],
        allowDataSync: true,
        hidden: true,
    },
    salesforce: {
        name: 'Salesforce',
        allowSchemaSync: true,
        v4: true,
        icon: SvgIcons.Salesforce,
        hintImagesUrls: [publicAsset('/static/media/sfHint1.png')],
        responsiveHintImageUrls: [publicAsset('/static/media/responsiveSfHint1.png')],
        editor: true,
        allowDataSync: true,
        cta: 'Add',
        hidden: true,
    },
    fivetran: {
        name: 'Fivetran',
        allowSchemaSync: true,
        allowDataSync: true,
        //It's a fallback logo that is shown while fivetran data source images load.
        // It's no-brand icon so we don't expose fivetran in Data Source view
        icon: SvgIcons.List,
        hidden: true,
        editor: true,
    },
    stacker_users: {
        name: 'Users',
        icon: 'users',
        disallowConfigChanges: true,
    },
    stacker: {
        name: 'Stacker',
        icon: SvgIcons.Stacker_Logo_S_Only,
    },
    native_tables: {
        name: 'Stacker',
        icon: SvgIcons.StackerSBlueOnWhite,
        allowSchemaSync: false,
        allowDataSync: false,
        disallowConfigChanges: true,
        getDescriptionOverride: () => {
            return 'Your custom tables hosted locally'
        },
    },
}
export default DATA_PROVIDERS
