import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css } from 'emotion'

import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import AppContext from 'app/AppContext'
import AppUserContext from 'app/AppUserContext'
import { useWorkspaceUsers } from 'data/hooks/users/main'
import UserMenu from 'features/AppBar/UserMenu'
import CustomPlanPicker from 'features/workspace/CustomPlanPicker'
import PopoutNavBar from 'features/workspace/Sidebar/PopoutNavBar'
import analytics from 'utils/analytics'

import { Button, Flex } from 'v2/ui'
import SimpleModal from 'v2/ui/components/SimpleModal'

const shouldBlock = (location) => {
    return (
        !location.pathname.startsWith('/studio/') &&
        !location.pathname.endsWith('/studio/stack/settings') &&
        !location.pathname.endsWith('/__account')
    )
}

const buttonBaseStyle = { padding: '10px', fontSize: '16px' }

const UserMenuButton = styled(Button)`
    & > span {
        flex: unset;
    }
`

const useButtonIconColor = () => {
    const { colors } = useTheme()
    return colors.neutral[600]
}

const TrialIntercept = () => {
    const { colors } = useTheme()
    const { selectedStack, workspaceAccount } = useContext(AppContext)
    const { isLoggedIn } = useContext(AppUserContext)
    const { hasRight, user } = useAccountUserContext()
    const hasBillingRights = hasRight(Rights.ManageBilling)
    const location = useLocation()
    const trialExpired = selectedStack?.is_trial_expired
    const subscriptionCancelled = selectedStack?.is_cancelled
    const showExpiredModal = trialExpired || subscriptionCancelled
    const { data: users } = useWorkspaceUsers()
    const [adminEmails, setAdminEmails] = useState('mailto:')
    const [showBillingModal, setShowBillingModal] = useState(false)

    useEffect(() => {
        if (showExpiredModal) {
            analytics.track('workspace trial ended', {
                workspace_id: workspaceAccount?._sid,
                user_id: user?._sid,
                trial_start_date: workspaceAccount?.trial_start_date,
                trial_end_date: workspaceAccount?.trial_end_date,
                event_category: 'workspace',
                event_description: 'Trial expired modal is shown',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showExpiredModal])

    useEffect(() => {
        if (users?.length > 0) {
            const adminEmails = users
                .filter((user) => {
                    return user?.account_role_api_name === 'owner'
                })
                .map((user) => {
                    return user.email
                })

            setAdminEmails('mailto:' + adminEmails.join(','))
        }
    }, [users])

    if (!shouldBlock(location) || !isLoggedIn) return null

    if (showExpiredModal) {
        return (
            <>
                <SimpleModal
                    width="500px"
                    height="initial"
                    showCloseButton={false}
                    renderFooter={() => <PopoutNavBar />}
                    open={true}
                    padding="45px"
                >
                    <Flex flexDirection="column" style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon
                            icon={faCreditCard}
                            style={{
                                color: colors.neutral[600],
                                fontSize: 40,
                                alignSelf: 'center',
                                marginBottom: '20px',
                            }}
                        />

                        <StyledTitle>Free trial ended</StyledTitle>
                        <StyleSubtitle>
                            {!hasBillingRights
                                ? `Owners of ${workspaceAccount?.name} workspace need to subscribe to continue using apps in this workspace`
                                : 'Please subscribe to continue using apps in this workspace'}
                        </StyleSubtitle>

                        {!hasBillingRights ? (
                            <Button
                                variant="adminPrimaryV4"
                                mb="6px"
                                href={adminEmails}
                                icon="email"
                                w="100%"
                                style={buttonBaseStyle}
                            >
                                Contact workspace owner
                            </Button>
                        ) : (
                            <Button
                                variant="adminPrimaryV4"
                                mb="6px"
                                onClick={() => setShowBillingModal(true)}
                                w="100%"
                                style={buttonBaseStyle}
                            >
                                Select a Plan
                            </Button>
                        )}

                        <Book1to1Button width="100%" />

                        <ChatWithUsButton width="100%" />
                        <SwitchWorkspaceButton width="100%" showStackerClassicLabel={false} />
                    </Flex>
                </SimpleModal>
                {/* Billing modal */}
                <SimpleModal
                    title="Subscribe to continue"
                    height="850px"
                    showCloseButton={true}
                    onClose={() => setShowBillingModal(false)}
                    renderFooter={() => <PopoutNavBar />}
                    open={showBillingModal}
                    scrollBarCss={css`
                        scrollbar-width: thin;
                        scrollbar-height: thin;
                        scrollbar-color: rgba(0, 0, 0, 0.5);

                        ::-webkit-scrollbar {
                            width: 8px;
                            height: 8px;
                        }
                        ::-webkit-scrollbar-track {
                            background: transparent;
                        }
                        ::-webkit-scrollbar-thumb {
                            background-color: rgba(0, 0, 0, 0.5);
                            border-radius: 20px;
                        }
                    `}
                >
                    <CustomPlanPicker />
                    <Flex justify="space-between" row style={{ marginTop: '10px' }}>
                        <Book1to1Button width="32%" />

                        <ChatWithUsButton width="32%" />
                        <SwitchWorkspaceButton width="32%" />
                    </Flex>
                </SimpleModal>
            </>
        )
    }

    return null
}

export default TrialIntercept

const SwitchWorkspaceButton = ({ width }) => {
    const buttonIconColor = useButtonIconColor()
    return (
        <UserMenu
            maxBoxHeight="210px"
            as={UserMenuButton}
            variant="adminSecondaryV4"
            mb="6px"
            icon="profile"
            w={width}
            iconColor={buttonIconColor}
            style={buttonBaseStyle}
        >
            Switch workspace
        </UserMenu>
    )
}

const Book1to1Button = ({ width }) => {
    const buttonIconColor = useButtonIconColor()
    return (
        <Button
            variant="adminSecondaryV4"
            mb="6px"
            href="https://stackerhq.com/call"
            icon="calendar"
            w={width}
            iconColor={buttonIconColor}
            style={buttonBaseStyle}
        >
            Book a 1:1
        </Button>
    )
}

const ChatWithUsButton = ({ width }) => {
    const buttonIconColor = useButtonIconColor()
    return (
        <Button
            variant="adminSecondaryV4"
            mb="6px"
            onClick={() => {
                window.groove.widget.open()
            }}
            icon="comments"
            w={width}
            iconColor={buttonIconColor}
            style={buttonBaseStyle}
        >
            Contact Us
        </Button>
    )
}

const StyledTitle = styled.span`
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
`

const StyleSubtitle = styled.span`
    display: block;
    font-size: 15px;
    margin-bottom: 20px;
`
