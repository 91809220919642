import React from 'react'

import { LinkFieldLabel } from 'features/links/LinkFieldLabel'

import { Box, Flex } from 'v2/ui'

export const LinkPreviewValue = ({
    fieldName,
    values = [],
    isLink = false,
    isReference = false,
    isReadOnly = false,
}) => {
    const fieldNameLabel = isReadOnly ? `${fieldName} (read-only)` : fieldName
    return (
        <Flex column align="start">
            <Flex>
                <small>
                    <LinkFieldLabel showLink={isLink} muted={true} bold={true}>
                        {fieldNameLabel}
                    </LinkFieldLabel>
                </small>
            </Flex>
            <Box column align="start" mt={1}>
                {values.map((value) => (
                    <Box key={value} textDecoration={isReference ? 'underline' : null}>
                        {value}
                    </Box>
                ))}
            </Box>
        </Flex>
    )
}
