import React from 'react'

import { useNavigationPosition } from 'features/core/nav/useNavigationPosition'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import useTrack from 'utils/useTrack'

import { Box } from 'v2/ui'
import { useIsNavigationDark } from 'v2/ui/hooks/useIsNavigationDark'

import { useCreateEmptyBlankPageModal } from './CreateEmptyBlankPageModal'
import { FloatingAddNewButtonMenu } from './FloatingAddNewButtonMenu'
import { useShouldDisplayAddButton } from './useShouldDisplayAddButton'

type FloatingAddNewButtonProps = React.ComponentPropsWithoutRef<typeof Box> & {
    bypassDisplayCheck?: boolean
}

export const FloatingAddNewButton: React.FC<FloatingAddNewButtonProps> = ({
    bypassDisplayCheck,
    ...props
}) => {
    const { track } = useTrack()

    const { showManageData } = useSlidingPane()
    const onClickAddTable = () => {
        track('Frontend - Menu add new button - Table - Clicked')
        showManageData({ shouldShowNewTableModal: true })
    }

    const onConfirmCreateEmptyBlankPage = async () => {
        track('Frontend - Menu add new button - Create page - Clicked')
    }
    const handleAddPage = useCreateEmptyBlankPageModal({ onConfirm: onConfirmCreateEmptyBlankPage })
    const onClickAddPage = () => {
        track('Frontend - Menu add new button - Page - Clicked')
        handleAddPage()
    }

    const isNavigationDark = useIsNavigationDark()

    const navPosition = useNavigationPosition()
    const isTopNavigation = navPosition === 'top'

    const isButtonDark = !isNavigationDark || isTopNavigation

    const onClickButton = () => {
        track('Frontend - Menu add new button - Clicked')
    }

    const shouldDisplayButton = useShouldDisplayAddButton()
    if (!shouldDisplayButton && !bypassDisplayCheck) return null

    return (
        <Box {...props}>
            <FloatingAddNewButtonMenu
                onClickAddTable={onClickAddTable}
                onClickAddPage={onClickAddPage}
                onClickTrigger={onClickButton}
                isDark={isButtonDark}
            />
        </Box>
    )
}
