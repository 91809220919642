// @ts-strict-ignore
import moment from 'moment'

import { isSubscriptionCancelled } from 'features/workspace/stackerUserUtils'

import { FeatureRequirementsMap } from './types'

/**
 * Calculates the reasons why a plan might be disabled based on features usage
 * @param reasonParts Feature vs requirements configuration
 * @param featureUsage Features usage of current user
 * @param isTierRequested True if tier requested, false otherwise
 * @returns Reasons why a plan might be disabled
 */
export const getAllowedPlansReason = (
    reasonParts: FeatureRequirementsMap,
    featureUsage,
    isTierRequested
) => {
    featureUsage = featureUsage ?? []

    const parts = new Set()
    for (let i = 0; i < featureUsage.length; i++) {
        const feature = featureUsage[i]
        if (!feature.is_skip_tier_restriction) {
            parts.add(reasonParts[feature.name](feature))
        }
    }
    const partsArray = [...Array.from(parts)].filter((part) => part)
    const minTierReason =
        partsArray.length > 0
            ? `because you have ${[
                  ...partsArray.slice(0, -2),
                  partsArray.slice(-2).join(' and '),
              ].join(', ')}`
            : null
    const requiredTierReason = isTierRequested
        ? 'because of the plan you have expressed interest in'
        : null

    if (!minTierReason && !requiredTierReason) return ''
    return `Some plans are disabled ${[minTierReason, requiredTierReason]
        .filter((reason) => reason)
        .join(' or ')}.`
}

const dateFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
})

export function formatSubscriptionCancelDate(date?: string): string {
    if (!date) return 'Cancelled'

    const dateObj = new Date(date)
    if (!isFinite(dateObj.getTime())) return 'Cancelled'

    const formattedDate = dateFormat.format(dateObj)

    const today = new Date()
    const isFuture = moment(date).diff(today, 'minutes') > 0

    if (isFuture) return `Cancels on ${formattedDate}`

    return `Cancelled on ${formattedDate}`
}

export function shouldDisplayCancelDate(workspaceAccount: Account): boolean {
    if (isSubscriptionCancelled(workspaceAccount)) return true

    return Boolean(workspaceAccount?.subscription_end_date)
}
