// @ts-nocheck
import React from 'react'

import { useDataConnection } from 'data/hooks/dataConnections'
import { ProviderIcon } from 'features/admin/data-connector/ProviderIcon'
import ObjectPicker from 'features/studio/ui/ObjectPicker'

import { Icon } from 'v2/ui'

import { LinkFormBackgroundContainer } from './LinkFormBackgroundContainer'

export const LinkFormObjectDropdown = ({
    value,
    onChange,
    variant = 'primary',
    object,
    filter,
}) => {
    const { data: dataConnection } = useDataConnection(object?.data_connection)

    return (
        <LinkFormBackgroundContainer
            style={{ padding: 0, display: 'flex', alignItems: 'center' }}
            variant={variant}
        >
            <ObjectPicker
                style={{ flex: 1, border: 'none', minWidth: 180 }}
                value={value}
                onChange={onChange}
                placeholder="select table"
                controlStyle={{ padding: '2px 6px', border: 'none' }}
                isClearable={false}
                optionHeight={30}
                filter={filter}
                renderValue={({ object }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {dataConnection && (
                            <ProviderIcon
                                marginRight={3}
                                type={dataConnection.type}
                                serviceName={dataConnection.service_name}
                            />
                        )}
                        <div>{dataConnection?.label}</div>
                        <Icon
                            size="14px"
                            display="inline-block"
                            verticalAlign="middle"
                            ml={2}
                            mr={2}
                            color="grey.300"
                            icon="caretRight"
                        />
                        <div>{object?.name}</div>
                    </div>
                )}
            />
        </LinkFormBackgroundContainer>
    )
}
