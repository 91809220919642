import React from 'react'

import FieldPicker from 'features/studio/ui/FieldPicker'

import { Text } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'

import Collapse from './Collapse'
import Dropdown from './Dropdown'
import DropdownButton from './DropdownButton'
import { MAX_COLUMNS } from './kanbanUtils'
type KanbanSettingsProps = {
    object: ObjectDto
    viewOptions: ListViewOptions
    selectedStatusFieldId: string
    selectedStatusColumns: string[]
    setSelectedStatusFieldId: (fieldId: string) => void
    setSelectedStatusColumns: (columns: ListViewColumnConfig[]) => void
}
export function KanbanSettings({
    object,
    selectedStatusFieldId,
    selectedStatusColumns,
    setSelectedStatusFieldId,
    setSelectedStatusColumns,
    viewOptions,
}: KanbanSettingsProps) {
    const field = object?.fields.find((x) => x._sid === selectedStatusFieldId)
    const columnOptions = (field?.options?.options ?? []).concat([{ label: 'None', value: null }])
    return (
        <DropdownButton
            ButtonComponent={Button}
            variant="ghost"
            size="m"
            icon="columns"
            buttonContent={
                <Box fontWeight="bodyRegular">
                    Columns:
                    <Box ml="xs" display="inline" fontWeight="bodyBold">
                        {field?.label}
                    </Box>
                </Box>
            }
        >
            {() => (
                <Box>
                    {viewOptions.userCanUpdateStatusField && (
                        <>
                            <Text variant="adminFieldLabel" mt="0">
                                Board Status
                            </Text>
                            <Text variant="checkbox">Choose a single select status field</Text>
                            <FieldPicker
                                objectId={object._sid}
                                style={{ width: '405px', marginTop: '8px' }}
                                filter={(field) =>
                                    field.type === 'dropdown' &&
                                    !field.connection_options?.is_disabled
                                }
                                placeholder="Select status field"
                                value={selectedStatusFieldId}
                                onChange={(fieldId) => {
                                    console.log('fieldId', fieldId)
                                    setSelectedStatusFieldId(fieldId)
                                }}
                                isDisabled={false}
                                autoSelectSingleOption
                            />
                        </>
                    )}
                    <Collapse isOpen={field}>
                        {field &&
                            (viewOptions.userCanUpdateStatusField ||
                                viewOptions.userCanUpdateStatusColumns) && (
                                <>
                                    <Text variant="adminFieldLabel">Columns</Text>
                                    <Text m={'0 0 8px 0'} variant="checkbox">
                                        Choose what columns you want to display
                                    </Text>
                                    <Dropdown
                                        value={selectedStatusColumns}
                                        options={columnOptions}
                                        isMulti
                                        allowNullValues
                                        onChange={(value: ListViewColumnConfig[]) =>
                                            setSelectedStatusColumns(value)
                                        }
                                    />
                                    <Collapse
                                        isOpen={
                                            selectedStatusColumns &&
                                            selectedStatusColumns.length > MAX_COLUMNS
                                        }
                                    >
                                        <Text variant="error" mt={2}>
                                            Please select a maximum of {MAX_COLUMNS} columns
                                        </Text>
                                    </Collapse>
                                </>
                            )}
                    </Collapse>
                </Box>
            )}
        </DropdownButton>
    )
}
