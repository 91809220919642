import React from 'react'

import { Thumbnail } from 'features/views/attributes/Thumbnail'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'

import { CardCoverImageStyle } from './CardView.css'

type CardViewCoverImageProps = React.ComponentPropsWithoutRef<typeof Box> & {
    src?: string
    isLoading?: boolean
    placeholder?: string
}

export const CardViewCoverImage: React.FC<CardViewCoverImageProps> = ({
    src,
    isLoading,
    placeholder,
    ...props
}) => {
    return (
        <Box height="full" width="full" {...props}>
            <Skeleton noShrink isLoading={isLoading} display="block" width="full" height="full">
                <Thumbnail
                    src={src}
                    placeholder={placeholder}
                    size="manual"
                    className={CardCoverImageStyle}
                    alt="Record cover image"
                />
            </Skeleton>
        </Box>
    )
}
