import { fetchWithAuth } from 'data/utils/fetchWithAuth'

export type LoadPayloadsParams =
    | {
          connection: DataConnectionDto
          fromDatestring: string
          toDatestring: string
      }
    | { connection: DataConnectionDto; fromCursor: string }

export const loadPayloads = async (
    params: LoadPayloadsParams
): Promise<AirtablePayloadsSummary> => {
    const connection = params.connection
    const { fromDatestring, toDatestring, fromCursor } = params as any as Record<string, string>
    const url =
        fromDatestring && toDatestring
            ? `data-connections/${connection._sid}/browse_payloads/?from=${fromDatestring}&to=${toDatestring}`
            : `data-connections/${connection._sid}/browse_payloads/?from_cursor=${fromCursor}`
    return fetchWithAuth(url).then(async (data) => {
        if (data.ok) {
            return data.json()
        }
        throw new Error(await data.text())
    })
}
