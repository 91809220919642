import React from 'react'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
} from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAddNewAdvancedFilterState } from './hooks/useAddNewAdvancedFilterState'

type AddNewAdvancedFilterProps = {
    onFieldSelect?: (field: FieldDto) => void
}

export const AddNewAdvancedFilter: React.FC<AddNewAdvancedFilterProps> = ({ onFieldSelect }) => {
    const { fields } = useAddNewAdvancedFilterState()

    const size = useResponsiveValue<React.ComponentPropsWithoutRef<typeof DropdownButton>['size']>({
        mobile: 'xs',
        tablet: 's',
    })

    return (
        <Dropdown>
            <DropdownButton
                startIcon={{ name: 'Plus' }}
                size={size}
                variant="dotted"
                aria-label="Add new filter"
            />
            <DropdownContent
                align="end"
                head={<DropdownHeadSearch />}
                onClick={stopPropagation}
                onCloseAutoFocus={stopPropagation}
            >
                {fields.map((f) => (
                    <DropdownItem
                        key={f.field._sid}
                        label={f.label}
                        onClick={() => onFieldSelect?.(f.field)}
                    />
                ))}
            </DropdownContent>
        </Dropdown>
    )
}
