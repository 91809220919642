import { useMemo } from 'react'

import { AttachmentValue } from 'features/views/attributes/types'

import { useAttachmentsAttributeDisplayState } from './useAttachmentsAttributeDisplayState'

type UseAttachmentsAttributeTagStateProps = {
    value?: AttachmentValue[]
    maxItemLength?: number
    isLoading?: boolean
}

export function useAttachmentsAttributeTagState({
    value,
    maxItemLength,
    isLoading: providedIsLoading,
}: UseAttachmentsAttributeTagStateProps) {
    const {
        attachments,
        isLoading,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelCloseAutoFocus,
        onOverflowLabelFocus,
        onOverflowLabelBlur,
        onOverflowLabelClick,
        wrapperRef,
    } = useAttachmentsAttributeDisplayState({
        value,
        maxItemLength,
        isLoading: providedIsLoading,
    })

    const isSingle = attachments?.length === 1

    const label = formatLabel(attachments)

    return useMemo(
        () => ({
            attachments,
            isLoading,
            isOverflowPopupOpen,
            onOverflowPopupOpenChange,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowLabelCloseAutoFocus,
            onOverflowLabelFocus,
            onOverflowLabelBlur,
            onOverflowLabelClick,
            wrapperRef,
            isSingle,
            label,
        }),
        [
            attachments,
            isLoading,
            isOverflowPopupOpen,
            onOverflowLabelBlur,
            onOverflowLabelClick,
            onOverflowLabelCloseAutoFocus,
            onOverflowLabelFocus,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowPopupOpenChange,
            wrapperRef,
            isSingle,
            label,
        ]
    )
}

function formatLabel(
    attachments: ReturnType<typeof useAttachmentsAttributeDisplayState>['attachments']
) {
    const attachmentCount = attachments?.length ?? 0
    if (attachmentCount === 1) {
        return attachments![0].name
    }

    return `${attachmentCount} attachments`
}
