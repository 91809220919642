import React from 'react'
import { useForm } from 'react-hook-form'

import { LinkFormFields, LinkFormFieldsMinimal, LinkModalForm } from 'features/links'

export const LinkEditModalView = ({ isOpen, onClose, link, onSubmit, minimal = false }) => {
    const formContext = useForm({
        mode: 'all',
        defaultValues: {
            source: link?.source.objectId,
            sourceField: link?.source.fieldId,
            target: link?.target.objectId,
            targetField: link?.target.fieldId,
            linkFieldObject: link?.source.objectId,
            sourceFieldLinkName: link?.name,
            targetFieldLinkName: link?.symmetric?.fieldName ?? ``,
            filters: link?.filters ?? [],
            enableFilters: link?.filters?.length > 0,
            // We consider links as legacy if they are created before the introduction of
            // the automated creation of symmetric fields
            legacy: !link?.symmetric,
            mode: link?.mode,
            delimiter: link?.delimiter,
        },
    })
    return (
        <LinkModalForm
            isOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            form={formContext}
            isSecondLayer={true}
        >
            {minimal ? (
                <LinkFormFieldsMinimal form={formContext} />
            ) : (
                <LinkFormFields form={formContext} />
            )}
        </LinkModalForm>
    )
}
