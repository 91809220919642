import React from 'react'

import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { stopPropagation } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { AttributeDisplayComponent } from './types'

type LongTextAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    maxOverflowLength?: number
    showOverflowTooltip?: ResponsiveValue<boolean>
    isLoading?: boolean
}

export const LongTextAttributeTag: AttributeDisplayComponent<string, LongTextAttributeTagProps> =
    React.memo(function LongTextAttributeTag({
        value,
        maxOverflowLength,
        showOverflowTooltip = true,
        isLoading,
        ...props
    }) {
        const effectiveShowOverflowTooltip = useResponsiveValue(showOverflowTooltip)

        if (!value) return null

        const children = (
            <Box>
                <Skeleton isLoading={isLoading}>
                    <Tag
                        size="2xs"
                        type="outline"
                        startIcon={{ name: 'Text' }}
                        aria-label="View long text content"
                        onClick={stopPropagation}
                        {...props}
                    />
                </Skeleton>
            </Box>
        )

        if (effectiveShowOverflowTooltip) {
            return (
                <Tooltip
                    content={
                        <Body
                            style={{
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                lineHeight: 'inherit',
                            }}
                            maxLength={maxOverflowLength}
                        >
                            {value}
                        </Body>
                    }
                    zIndex={99}
                    side="bottom"
                    asChild
                >
                    {children}
                </Tooltip>
            )
        }

        return children
    },
    isEqual)
