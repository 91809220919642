import { Rights, useAccountUserContext } from 'app/AccountUserContext'
import { useAppContext } from 'app/AppContext'
import { useAppUserContext } from 'app/AppUserContext'

export const useCanAccessSupport = () => {
    const { isPreviewingAsUserOrRole } = useAppUserContext()
    const { workspaceAccount } = useAppContext()
    const { hasRight } = useAccountUserContext()

    const isWorkspaceAccount = Boolean(workspaceAccount)

    const hasContactSupportRight = isWorkspaceAccount
        ? hasRight(Rights.ContactSupport)
        : !isPreviewingAsUserOrRole

    return hasContactSupportRight
}
