import { memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import get from 'lodash/get'
import queryString from 'qs'

import { Urls } from 'app/UrlService'
import { withStack } from 'data/wrappers/WithStacks'
import { insertCustomCss, removeCustomCss } from 'utils/customCss'

import { FEATURES, isFeatureLocked } from 'v2/ui/utils/ProtectedFeature'

import { getWorkspaceAccount } from './GlobalStaticState'

const IGNORE_PATHS = [Urls.StudioStackSettings]

export const CustomCss = ({ stack, stackOptions }) => {
    const workspaceAccount = getWorkspaceAccount()
    const isCustomerAccessCssLocked = isFeatureLocked(FEATURES.customCss, stack)
    const customCss = get(stackOptions, 'custom_css')
    const CUSTOM_CSS_ATTR = 'data-custom-css'
    const inWorkspace = Boolean(workspaceAccount)
    const isGlobalCssLocked = isFeatureLocked(FEATURES.globalCustomCss, stack)

    const location = useLocation()

    // we always want to do this on page change as we don't want the user to
    // be able to hide important admin elements
    useEffect(() => {
        const query = queryString.parse(location.search, {
            ignoreQueryPrefix: true,
        })
        // ignore if the ignore_css parameter is set
        const ignoreCss =
            (query && query.ignore_css == 1) ||
            IGNORE_PATHS.find((x) => location.pathname.includes(x))
        let shouldInsertCustomCss
        if (!isGlobalCssLocked) {
            shouldInsertCustomCss = !ignoreCss
        } else {
            // don't apply custom CSS when viewing the app in a workspace
            shouldInsertCustomCss = !isCustomerAccessCssLocked && !inWorkspace && !ignoreCss
        }

        removeCustomCss(CUSTOM_CSS_ATTR)

        if (shouldInsertCustomCss) {
            insertCustomCss(customCss, CUSTOM_CSS_ATTR)
        }
    }, [location, customCss, isCustomerAccessCssLocked, isGlobalCssLocked, inWorkspace])

    return null
}
export default withStack(memo(CustomCss))
