import React, { useEffect, useState } from 'react'

import get from 'lodash/get'

import { withObjects } from 'data/wrappers/WithObjects'
import { withStack } from 'data/wrappers/WithStacks'

const _withUserTable = ({ stack, objects, children }) => {
    const objectId = get(stack, 'options.data_mapping.user_object')

    const getUserTable = () => {
        if (objects && objects.length > 0) {
            return objects.find((object) => object._sid === objectId)
        }
    }

    const [userTable, setUserTable] = useState(getUserTable())

    useEffect(() => {
        setUserTable(getUserTable())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objects, stack])

    return children({
        userTable,
    })
}

const WithUserTable = withStack(withObjects(_withUserTable))

export const withUserTable = (Child) => (props) =>
    <WithUserTable>{(roleProps) => <Child {...roleProps} {...props} />}</WithUserTable>
