import { useQuery } from './_helpers'

const LIST_NAME: REACT_QUERY_DEPS_NAME = 'useDataSyncs'
const ENDPOINT = 'data-syncs/'

export type DataSyncDefinition = {
    service_name: string
    name: string
    category: string
    type: 'fivetran' | 'airtable' | 'postgresV2'
    is_native_connector: boolean
    imageUrl: string
}

export const useDataSyncs = (options = {}) => {
    const query = useQuery(LIST_NAME, ENDPOINT, options, {
        bypassMatchingStackCheck: true,
    })
    const dataSyncs: DataSyncDefinition[] = Array.isArray(query.data) ? query.data : []
    const categories: string[] = Array.from(new Set(dataSyncs.map((dataSync) => dataSync.category)))
    return { categories, dataSyncs, ...query }
}
