/* Code Quality: Not audited */

import React from 'react'
import Select from 'react-select'

import PropTypes from 'prop-types'

import { withTheme } from 'v2/ui/theme/components/withTheme'

const Dropdown = (props) => {
    const style = props.style ? props.style : {}
    const { margin, width, flex } = style
    const fontSize = props.fontSize ? props.fontSize : props.theme.fontSize

    const customStyles = {
        container: (provided) => ({
            ...provided,
            margin: margin || '',
            flex: flex || 'none',
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: props.theme.fontFamily,
            fontSize,
            backgroundColor: state.isFocused
                ? props.theme.elementHighlightColor
                : props.theme.inputBackgroundColor,
            color: props.color ? props.color : props.theme.fontColor,
            padding: props.padding ? `${props.padding} 8px` : '8px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
        }),
        placeholder: () => ({
            color: props.color ? props.color : props.theme.fontColor,
        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            fontFamily: props.theme.fontFamily,
            fontSize,
            color: props.color ? props.color : props.theme.fontColor,
            textAlign: 'left',
        }),
        control: (provided) => ({
            ...provided,
            background: props.theme.inputBackgroundColor,
            fontFamily: props.theme.fontFamily,
            fontSize,
            borderRadius: props.theme.elementBorderRadius,
            border: props.border
                ? props.border
                : `${props.theme.dividerThickness}px solid ${props.theme.outlineColor}`,
            outline: 'none',
            color: props.color ? props.color : props.theme.fontColor,
            boxShadow: 'none',
            minWidth: props.width ? props.width : width || '150px',
            minHeight: 'auto',
            '&:hover': {
                borderColor: props.theme.outlineColor,
            },
            ...props.controlStyle,
        }),
        menu: (provided) => ({
            ...provided,
            outline: 'none',
            boxShadow: 'none',
            background: '#fff',
            border: props.border
                ? props.border
                : `${props.theme.dividerThickness}px solid ${props.theme.outlineColor}`,
            margin: 0,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            backgroundColor: props.theme.neutral100,
            ':hover': {
                backgroundColor: props.theme.neutral200,
            },
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            background: props.theme.neutral100,
            cursor: 'pointer',
            ':hover': {
                backgroundColor: props.theme.neutral200,
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: props.padding ? `${props.padding} 2px` : '8px 2px',
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: props.padding ? `${props.padding} 2px` : '8px 2px',
        }),
        ...props.additionalStyles,
    }

    const hasNullOption = props.options.find((option) => option.value === null)

    let selected = props.defaultValue ? props.defaultValue : null
    if (((!hasNullOption && props.value) || hasNullOption) && props.options) {
        if (props.isMulti) {
            selected = []
            for (var val of props.value) {
                const option = props.options.find((option) => option.value === val)
                if (option) selected.push(option)
            }
        } else {
            if (props.returnObject) {
                selected = props.value
            }
            props.options.forEach((option) => {
                if (option.value === props.value) selected = option
            })
        }
    }

    // If we selected an option then unpack its value and pass to onChange
    // Otherwise we cleared the select box, so pass empty to onChange
    // In the multi-select case we receive an array of options and pass out an array of unpacked values.
    let onChange
    if (props.isMulti) {
        onChange = (optionList) => {
            if (optionList) {
                const optionValues = optionList.map((option) =>
                    props.returnObject ? option : option.value
                )
                return props.onChange && props.onChange(optionValues)
            } else {
                return props.onChange && props.onChange([])
            }
        }
    } else {
        onChange = (option) =>
            option
                ? props.onChange && props.onChange(props.returnObject ? option : option.value)
                : props.onChange && props.onChange(null)
    }

    const selectRef = React.createRef()

    return (
        <Select
            ref={selectRef}
            {...props}
            value={selected}
            onChange={onChange}
            options={props.options}
            ignoreCase
            multi={props.isMulti}
            removeSelected={false}
            styles={customStyles}
            isClearable={props.isClearable === false ? false : true}
        />
    )
}

Dropdown.propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    // { value: 'one', label: 'One' },
    //  { value: 'two', label: 'Two' },
    onChange: PropTypes.func,
    isMulti: PropTypes.bool,
}

Dropdown.defaultProps = {
    value: undefined,
    onChange: undefined,
    isMulti: false,
}

export default withTheme(Dropdown)
