import React from 'react'

import * as Sentry from '@sentry/react'

import settings from 'app/settings'
import DefaultHeaderMeta from 'features/core/DefaultHeaderMeta'
import { CenterPanel, CenterPanelWrapper } from 'features/studio/ui/Layout'
import { Button, Heading, Icon8, Section, Text } from 'legacy/v1/ui'

import { Rights, useAppUserContext } from './AppUserContext'

const ErrorPage = ({ sentryEvent }) => {
    return (
        <>
            <DefaultHeaderMeta title="Error" />
            <CenterPanelWrapper hidePoweredBy>
                <CenterPanel>
                    <ErrorComponent sentryEvent={sentryEvent} />
                </CenterPanel>
            </CenterPanelWrapper>
        </>
    )
}

const ErrorComponent = ({ sentryEvent }) => {
    const { hasRight, user } = useAppUserContext()
    const showErrorReportDialog = hasRight(Rights.Admin.All)
    return (
        <Section centered style={{ marginBottom: '30px' }}>
            <Icon8
                icon="sad-cloud"
                iconStyle="material-outlined"
                displaySize="200"
                color="333333"
                noMargin
            />
            <Heading size="medium">Something went wrong</Heading>
            <Text style={{ maxWidth: '300px', textAlign: 'center' }}>
                Sorry, an unrecoverable error occurred.
            </Text>
            {showErrorReportDialog && (
                <Text style={{ maxWidth: '300px', textAlign: 'center' }}>
                    Please get in touch with Stacker support and we&apos;ll be able to help.
                </Text>
            )}
            {/* Show the error reporting for to studio users. */}
            {showErrorReportDialog && !settings.DEBUG && sentryEvent && (
                <Button
                    onClick={() => {
                        Sentry.showReportDialog({
                            eventId: sentryEvent,
                            user: {
                                email: user.email,
                                name: user.name,
                            },
                        })
                    }}
                >
                    Submit an error report
                </Button>
            )}
        </Section>
    )
}

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { caughtError: false }
    }

    // We keep the error in argument here because it is standard React practice
    // eslint-disable-next-line unused-imports/no-unused-vars
    static getDerivedStateFromError(error) {
        return { caughtError: true }
    }

    componentDidCatch(error, errorInfo) {
        // N.B. In prod the exception is automatically caught here, but in dev it still propagates!
        if (!settings.debug) {
            const eventId = Sentry.captureException(error, { extra: errorInfo })
            this.setState({ sentryEvent: eventId })
        }
    }

    render() {
        if (this.state.caughtError) {
            if (this.props.errorContent) {
                return this.props.errorContent
            }
            return this.props.app ? (
                <ErrorPage sentryEvent={this.state.sentryEvent} />
            ) : (
                <ErrorComponent sentryEvent={this.state.sentryEvent} />
            )
        }

        return this.props.children
    }
}

export const catchAppErrors = (children) => <ErrorBoundary app>{children}</ErrorBoundary>

export const catchComponentErrors = (children) => <ErrorBoundary>{children}</ErrorBoundary>
