import React, { useEffect, useState } from 'react'

import { getDataConnectionDebugInfo } from 'data/api/getDataConnectionDebugInfo'
import LabelledAttribute from 'legacy/v1/ui/components/LabelledAttribute'
import TopLabel from 'legacy/v1/ui/components/TopLabel'

import { Flex } from 'v2/ui'
import { Badge } from 'v2/ui/components/Badge'

import { StyledTextArea } from './StyledTextArea'

type PostgresV2AdditionalDebugDataProps = {
    connection: DataConnectionDto
}
export const PostgresV2AdditionalDebugData = ({
    connection,
}: PostgresV2AdditionalDebugDataProps): JSX.Element => {
    const [debugInfo, setDebugInfo] = useState('')

    useEffect(() => {
        getDataConnectionDebugInfo(connection._sid).then(
            (result) => {
                setDebugInfo(JSON.stringify(result, null, 2))
            },
            (e) => {
                console.error('Failed to load debug info', { error: e })
            }
        )
    }, [connection])

    const connectionErrorType = connection.options?.connection_error?.exception_type
    return (
        <>
            <LabelledAttribute label="Connection Error Type">
                {connectionErrorType ? (
                    <Badge color="red">
                        {connection.options?.connection_error?.exception_type}
                    </Badge>
                ) : (
                    <>-</>
                )}
            </LabelledAttribute>

            <Flex style={{ padding: '16px', margin: '8px' }}>
                <TopLabel>Diagnostic Connection Information</TopLabel>
                <StyledTextArea disabled value={debugInfo} />
            </Flex>

            <Flex style={{ padding: '16px', margin: '8px' }}>
                <TopLabel>Full Connection Error</TopLabel>
                <StyledTextArea
                    disabled
                    value={
                        connection.options?.connection_error
                            ? JSON.stringify(connection.options?.connection_error, undefined, 2)
                            : ''
                    }
                />
            </Flex>
        </>
    )
}
