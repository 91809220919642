import SuperTokens from 'supertokens-auth-react'
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword'
import Multitenancy from 'supertokens-auth-react/recipe/multitenancy'
import Passwordless from 'supertokens-auth-react/recipe/passwordless'
import Session from 'supertokens-auth-react/recipe/session'
import ThirdParty, { Google } from 'supertokens-auth-react/recipe/thirdparty'

import { GlobalStaticState } from 'app/GlobalStaticState'
import settings from 'app/settings'

export function initializeSupertokens() {
    SuperTokens.init({
        appInfo: {
            appName: 'Stacker',
            apiDomain: settings.BACKEND_ENDPOINT,
            websiteDomain: window.location.origin,
            apiBasePath: '/api/st-auth',
            websiteBasePath: '/auth',
        },

        usesDynamicLoginMethods: true,
        recipeList: [
            ThirdParty.init({
                signInAndUpFeature: {
                    providers: [Google.init()],
                },
            }),
            Session.init({
                tokenTransferMethod: 'header',
            }),
            EmailPassword.init(),
            Multitenancy.init({
                override: {
                    functions: (oI) => {
                        return {
                            ...oI,
                            getTenantId: async () => {
                                return GlobalStaticState.getSuperTokensTenant()
                            },
                        }
                    },
                },
            }),
            Passwordless.init({ contactMethod: 'EMAIL' }),
        ],
    })
}

export function hasSession(): Promise<boolean> {
    return Session.doesSessionExist()
}

export function useSessionContext(): ReturnType<typeof Session.useSessionContext> {
    return Session.useSessionContext()
}
