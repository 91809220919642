import { fetchWithAuth } from 'data/utils/fetchWithAuth'

export const getDataConnectionDebugInfo = (
    connectionSid: string
): Promise<DataConnectionDebugInfo[]> => {
    return fetchWithAuth(`data-connections/${connectionSid}/debug_info/`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    }).then((response) => {
        return response.json()
    })
}
