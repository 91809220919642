import { useAppUserContext } from 'app/AppUserContext'

import { useIsMobile } from 'v2/ui/utils/useIsMobile'

export function useShouldDisplayAddButton(): boolean {
    const isMobile = useIsMobile()
    const { isPreviewingAsUserOrRole, isAdmin } = useAppUserContext()

    return shouldDisplayAddButton({
        isAdmin,
        isMobile,
        isPreviewingAsUserOrRole,
    })
}

export type ShouldDisplayAddButtonParams = {
    isAdmin: boolean
    isMobile: boolean
    isPreviewingAsUserOrRole: boolean
}

export function shouldDisplayAddButton(params: ShouldDisplayAddButtonParams): boolean {
    if (!params.isAdmin) return false
    if (params.isMobile) return false
    if (params.isPreviewingAsUserOrRole) return false

    return true
}
