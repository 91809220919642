import React, { useMemo } from 'react'

import type { History, Location } from 'history'
import { InboxView } from 'v2/views/Inbox/InboxView'

import { EditModeListContextProvider } from 'features/admin/edit-mode/ListView/EditModeListContextProvider'

import ListViewButtons from './ListViewButtons'
import { RowLinkFunction, ViewColumn } from './types'
import { ViewState } from './viewStateType'

type ListViewInboxModeProps = {
    setConfig: (patch: Partial<ViewDto>, shouldSave?: boolean) => void
    onCreate: (patch: Partial<RecordDto>, succesText?: string) => void
    setEndUserFilters: (newFilters: Filter) => void
    setEndUserFilters__NotForBackend: (newFilters: Filter) => void
    rowLinkFunction: RowLinkFunction
    showControls?: boolean
    showCreate?: boolean
    isRecordList?: boolean
    noLinks?: boolean
    enableBackendFiltering?: boolean
    isServerLoading?: boolean
    detailView?: ViewDto
    viewState?: ViewState
    object?: ObjectDto
    stackOptions?: StackDto['options']
    parentDetailViewIds?: string[]
    parentListViewIds?: string[]
    recordListTitle?: string
    title?: string
    columns?: ViewColumn[]
    filteredRecords?: RecordDto[]
    overrideViewOptions?: ListViewOptions
    endUserFilters?: Filter[]
    dereferencedRecords?: RecordDto[]
    totalResults?: number
    relatedFieldMayCreateNewRecords?: boolean
    hideEditControls?: boolean
    isRecordListOnCreate?: boolean
    listEditControls?: React.ComponentType
    relatedListSymmetricColumnName?: string
    relatedListType?: string
    autoFilledRelatedListRecord?: string
    history?: History
    location?: Location
    userFilterFieldIds?: string[]
    hideSearch?: boolean
    getPageSizeOptions?: (display?: string) => number[]
    getDefaultPageSize?: (display?: string) => number
    allowDownload?: boolean
    onDownloadCsv?: () => Promise<void>
    lastFilters?: any
    setLastFilters?: (filters: any) => void
    setPageIndex: (pageIdx: number) => void
    setPageSize: (pageSize: number) => void
    pageSize?: number
    pageIndex: number
}

const ListViewInboxMode: React.FC<ListViewInboxModeProps> = ({
    setConfig,
    onCreate,
    showControls,
    showCreate,
    setEndUserFilters,
    setEndUserFilters__NotForBackend,
    rowLinkFunction,
    isRecordList,
    noLinks,
    enableBackendFiltering,
    isServerLoading,
    detailView,
    viewState,
    object,
    stackOptions,
    parentDetailViewIds = [],
    parentListViewIds,
    recordListTitle,
    title,
    columns,
    filteredRecords,
    overrideViewOptions,
    endUserFilters,
    dereferencedRecords,
    totalResults,
    relatedFieldMayCreateNewRecords,
    hideEditControls,
    isRecordListOnCreate,
    listEditControls,
    relatedListSymmetricColumnName,
    relatedListType,
    autoFilledRelatedListRecord,
    history,
    location,
    userFilterFieldIds,
    hideSearch,
    getPageSizeOptions,
    getDefaultPageSize,
    allowDownload,
    onDownloadCsv,
    lastFilters,
    setLastFilters,
    setPageIndex,
    setPageSize,
    pageSize,
    pageIndex,
}) => {
    const parentViewIDs = useMemo(() => {
        if (detailView) {
            return [...parentDetailViewIds, detailView._sid]
        }

        return parentDetailViewIds
    }, [detailView, parentDetailViewIds])

    const brandColor = stackOptions?.theme?.brandColor ?? '#1f85d7'

    return (
        <EditModeListContextProvider disableEditControls={isRecordList}>
            <InboxView
                detailViewProps={{
                    view: detailView,
                    objectId: detailView?.object_id,
                    config: detailView?.options,
                    showControls: false,
                    isRecordList: isRecordList,
                    showCreate,
                    onCreate,
                    parentDetailViewIds: parentViewIDs,
                    parentListViewIds: parentListViewIds,
                    title: recordListTitle,
                    fromListView: true,
                }}
                listProps={{
                    brandColor,
                    detailView,
                    enableBackendFiltering,
                    key: viewState?.view?.options?.display,
                    object,
                    isRecordList,
                    title,
                    columns,
                    data: filteredRecords,
                    dereferencedRecords,
                    totalResults,
                    isServerLoading,
                    endUserFilters,
                    lastFilters,
                    setLastFilters,
                    setEndUserFilters,
                    setEndUserFilters__NotForBackend,
                    showFilters: viewState?.view?.options?.enableEndUserFilters,
                    userFilterFieldIds,
                    display: viewState?.view?.options?.display,
                    viewOptions: overrideViewOptions,
                    showControls,
                    buttons: (
                        <ListViewButtons
                            object={object}
                            text=""
                            viewState={viewState}
                            isRecordList={isRecordList}
                            relatedFieldMayCreateNewRecords={relatedFieldMayCreateNewRecords}
                            hideEditControls={hideEditControls}
                            isRecordListOnCreate={isRecordListOnCreate}
                            listEditControls={listEditControls}
                            relatedListSymmetricColumnName={relatedListSymmetricColumnName}
                            relatedListType={relatedListType}
                            autoFilledRelatedListRecord={autoFilledRelatedListRecord}
                        />
                    ),
                    rowLink: noLinks || !object ? undefined : rowLinkFunction,
                    setConfig,
                    history,
                    location,
                    showPageSizeOptions: viewState?.view?.options?.allowChangePageSize,
                    getPageSizeOptions,
                    getDefaultPageSize,
                    hideSearch,
                    allowDownload,
                    onDownloadCsv,
                    setServerPageIndex: setPageIndex,
                    setServerPageSize: setPageSize,
                    serverPageSize: pageSize,
                    serverPageIndex: pageIndex,
                }}
            />
        </EditModeListContextProvider>
    )
}

export default ListViewInboxMode
