// @ts-strict-ignore
import React from 'react'

import { assertNever } from 'data/utils/ts_utils'
import { ProviderPickerItem } from 'features/AppSettings/DataSources/providerPickerTypes'

import AirtableConnectionEditor from './editors/AirtableConnectionEditor/AirtableConnectionEditor'
import FivetranConnectionEditor from './editors/FivetranConnectionEditor'
import PostgresConnectionEditor from './editors/PostgresConnectionEditor/PostgresConnectionEditor'
import SalesforceConnectionEditor from './editors/SalesforceConnectionEditor'
import SimpleOAuthconnectionEditor from './editors/SimpleOAuthConnectionEditor'
import StripeConnectionEditor from './editors/StripeConnectionEditor'
import { DataConnectionEditorContextType } from './dataConnectionEditorContext'
import { ProviderTypes } from './dataConnectorTypes'
import { DATA_PROVIDERS } from './dataProviderConfig'

export function isProviderDisabled(stack, providerType: ProviderTypes) {
    if (isProviderHidden(stack, providerType)) {
        return true
    }
    const provider = DATA_PROVIDERS[providerType]
    if (!provider || !stack) {
        return false
    }
    if (typeof provider.disabled === 'boolean') {
        return provider.disabled
    }
    if (typeof provider.disabledFn === 'function') {
        return provider.disabledFn(stack)
    }
    return false
}

export function isProviderHidden(stack, providerType: ProviderTypes) {
    const provider = DATA_PROVIDERS[providerType]
    if (!provider || !stack) {
        return false
    }
    if (typeof provider.hidden === 'boolean') {
        return provider.hidden
    }
    if (typeof provider.hiddenFn === 'function') {
        return provider.hiddenFn(stack)
    }
    return false
}

export const getProvidersForOnboarding = (stack: any) => {
    return Object.keys(DATA_PROVIDERS).reduce(
        (acc: ProviderPickerItem<ProviderTypes>[], key: ProviderTypes) => {
            const provider = DATA_PROVIDERS[key]
            if (provider.isProviderShownWhenCreatingAStack && !isProviderHidden(stack, key)) {
                acc.push({
                    id: key,
                    name: provider.name,
                    icon: provider.icon,
                })
            }
            return acc
        },
        []
    )
}

export const RenderEditor: React.FC<{
    providerType: ProviderTypes
    onChangeHintImage: (url: string | undefined) => void
    context: DataConnectionEditorContextType
}> = (props) => {
    switch (props.providerType) {
        case 'airtable': {
            return (
                <AirtableConnectionEditor
                    onChangeHintImage={props.onChangeHintImage}
                    context={props.context}
                />
            )
        }
        case 'stripe': {
            return <StripeConnectionEditor context={props.context} />
        }
        case 'intercom': {
            return <SimpleOAuthconnectionEditor context={props.context} />
        }
        case 'salesforce': {
            return <SalesforceConnectionEditor context={props.context} />
        }
        case 'postgresV2': {
            return <PostgresConnectionEditor context={props.context} />
        }
        case 'fivetran': {
            return <FivetranConnectionEditor context={props.context} />
        }
        case 'stacker_users':
        case 'stacker':
        case 'native_tables': {
            throw new Error('Unexpected datasource')
        }
        default:
            //@ts-ignore
            assertNever(props.providerType)
    }
}
