import React, { useCallback, useContext, useMemo } from 'react'

import {
    deleteRecords as originalDeleteRecords,
    updateRecord as originalUpdateRecord,
} from 'data/hooks/records/recordOperations'
import {
    RecordEditManagerContextProvider,
    useRecordEditManagerContext,
} from 'features/records/RecordEditManagerContext'
import { useListViewRecords } from 'features/views/ListView/hooks/useListViewRecords'
import { useListViewContext } from 'features/views/ListView/ListViewContext'
import { ListViewSort } from 'features/views/ListView/Sort/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useCardViewFilters } from './Filters/hooks/useCardViewFilters'
import { useAttributesFromView } from './hooks/useAttributesFromView'
import { useCardViewPagination } from './Pagination/hooks/useCardViewPagination'
import { useCardViewSearch } from './Search/hooks/useCardViewSearch'
import { useCardViewSort } from './Sort/hooks/useCardViewSort'
import { CardViewAttribute } from './types'

type CardViewInternalContextValue = {
    attributes: CardViewAttribute[]
    records?: RecordDto[]
    dereferencedRecords?: RecordDto[]
    recordCount: number
    isLoading: boolean
    isFetchingSlow: boolean
    retryFetchRecords: () => Promise<void>
    hasError: boolean
    object: ObjectDto
    stack: StackDto
    view: ViewDto
    currentPageIndex: number
    setCurrentPageIndex: (pageIndex: number) => void
    pageSize: number
    allowReorder: boolean
    sortBy?: ListViewSort
    manualSortKey?: string
    clearFilters?: () => void
    errorMessage?: string
    labelStyle?: ListViewOptions['cardLabelStyle']
    embeddedByField?: FieldDto
    embeddedByFieldValue?: string
    embeddedByRecord?: RecordDto
    hasFilters: boolean
    inlineFilterType?: ListViewInlineFilters
    availableInlineFilterFields: FieldDto[]
    isEmbedded: boolean
    allFields: FieldDto[]
    requestFilters: Filter[]
    requestIncludedFields: string[]
    coverImageField?: FieldDto
    profileImageField?: FieldDto
    titleSize: Required<ListViewOptions['cardTitleSize']>
    cardSize: Required<ListViewOptions['cardCardSize']>
    cardStyle: Required<ListViewOptions['cardCardStyle']>
    eyebrowField?: FieldDto
    titleField?: FieldDto
    subtitleField?: FieldDto
    header: ListViewHeader
    onRecordClick: Required<ListViewOptions['onRecordClick']>
}

export type CardViewContextValue = CardViewInternalContextValue & {
    pendingRecords: RecordDto[]
}

export const CardViewContext = React.createContext<CardViewInternalContextValue | null>(null)

export function useCardViewContext(): CardViewContextValue {
    const context = useContext(CardViewContext)

    if (!context) {
        throw new Error('useCardViewContext must be used within a CardViewContextProvider')
    }

    const { records, pendingNewRecords } = useRecordEditManagerContext()

    let isLoading = context.isLoading
    // On the initial record load, it may take a few frames
    // until the record manager picks up the first batch of records.
    if (records.length < 1 && !!context.records?.length) {
        isLoading = true
    }

    const pendingRecords = pendingNewRecords.map((record) => record.record)

    return useDeepEqualsMemoValue({
        ...context,
        isLoading,
        pendingRecords,
    })
}

export type CardViewContextProviderProps = {}

export const CardViewContextProvider: React.FC<CardViewContextProviderProps> = ({ children }) => {
    const {
        object,
        stack,
        view,
        embeddedByField,
        embeddedByFieldValue,
        embeddedByRecord,
        isEmbedded,
        allFields,
        header,
    } = useListViewContext()

    const {
        hasFilters: hasInlineFilters,
        clearFilters: clearInlineFilters,
        inlineFilterType,
        availableInlineFilterFields,
    } = useCardViewFilters()

    const coverImageFieldApiName = view.options.coverImage?.id
    const coverImageField = allFields.find((field) => field.api_name === coverImageFieldApiName)

    const profileImageFieldApiName = view.options.profileImage?.fieldApiName
    const profileImageField = allFields.find((field) => field.api_name === profileImageFieldApiName)

    const eyebrowFieldSid = view.options.cardCardEyebrow?.fieldSid
    const eyebrowField = allFields.find((field) => field._sid === eyebrowFieldSid)
    const eyebrowFieldApiName = eyebrowField?.api_name

    const titleFieldSid = view.options.cardCardTitle?.fieldSid
    const titleField = allFields.find((field) => field._sid === titleFieldSid)
    const titleFieldApiName = titleField?.api_name

    const subtitleFieldSid = view.options.cardCardSubtitle?.fieldSid
    const subtitleField = allFields.find((field) => field._sid === subtitleFieldSid)
    const subtitleFieldApiName = subtitleField?.api_name

    const attributes = useAttributesFromView({ titleFieldSid })

    const additionalFieldApiNames = useMemo(() => {
        const apiNames = new Set<string>()

        // Include card footer fields.
        const footerLeftFieldSid = view.options.cardFooter?.leftFieldSid
        if (footerLeftFieldSid && footerLeftFieldSid !== '_record_stats') {
            const field = allFields.find((field) => field._sid === footerLeftFieldSid)
            if (field) {
                apiNames.add(field.api_name)
            }
        }

        const footerRightFieldSid = view.options.cardFooter?.rightFieldSid
        if (footerRightFieldSid && footerRightFieldSid !== '_record_stats') {
            const field = allFields.find((field) => field._sid === footerRightFieldSid)
            if (field) {
                apiNames.add(field.api_name)
            }
        }

        // Include cover image field.
        if (coverImageFieldApiName) {
            apiNames.add(coverImageFieldApiName)
        }

        // Include profile image field.
        if (profileImageFieldApiName) {
            apiNames.add(profileImageFieldApiName)
        }

        // Include eyebrow field.
        if (eyebrowFieldApiName) {
            apiNames.add(eyebrowFieldApiName)
        }

        // Include title field.
        if (titleFieldApiName) {
            apiNames.add(titleFieldApiName)
        }

        // Include subtitle field.
        if (subtitleFieldApiName) {
            apiNames.add(subtitleFieldApiName)
        }

        return Array.from(apiNames)
    }, [
        allFields,
        view.options.cardFooter?.leftFieldSid,
        view.options.cardFooter?.rightFieldSid,
        coverImageFieldApiName,
        profileImageFieldApiName,
        titleFieldApiName,
        eyebrowFieldApiName,
        subtitleFieldApiName,
    ])

    const {
        records,
        dereferencedRecords,
        recordCount = 0,
        retryFetchRecords,
        hasError,
        isLoading,
        isFetchingSlow,
        effectiveFilters,
        includedFieldsApiNames,
    } = useListViewRecords({
        additionalFieldApiNames,
    })

    const { sortBy, manualSortKey } = useCardViewSort()
    const { query, setQuery } = useCardViewSearch()

    const hasFilters = !!query || hasInlineFilters
    const allowReorder = view.options?.orderType === 'manual'

    const requestFilters = useDeepEqualsMemoValue(effectiveFilters)
    const requestIncludedFields = useDeepEqualsMemoValue(includedFieldsApiNames)

    const clearFilters = useCallback(() => {
        setQuery('')
        clearInlineFilters()
    }, [setQuery, clearInlineFilters])

    // empty addRecord callback as card views don't need this
    const addRecord = useCallback(async (record: RecordDto) => record, [])

    const updateRecord = useCallback(
        async (recordId: string, patch: Partial<RecordDto>) => {
            await originalUpdateRecord(recordId, patch, requestIncludedFields, {
                deferStoreUpdate: true,
            })
        },
        [requestIncludedFields]
    )

    const deleteRecords = useCallback(
        async (recordIds: string[]) => {
            await originalDeleteRecords(object._sid, recordIds)
            await retryFetchRecords()
        },
        [object._sid, retryFetchRecords]
    )

    const labelStyle = view.options.cardLabelStyle

    const { pageIndex, pageSize, setPageIndex } = useCardViewPagination({
        recordCount,
        isLoading,
    })

    const titleSize = view.options.cardTitleSize ?? 'medium'
    const cardSize = view.options.cardCardSize ?? 'large'

    const cardStyle = (coverImageField && view.options.cardCardStyle) || 'border'

    const onRecordClick = view.options.onRecordClick || 'preview'

    const value = useMemo(
        () => ({
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            currentPageIndex: pageIndex,
            pageSize,
            setCurrentPageIndex: setPageIndex,
            isLoading,
            isFetchingSlow,
            retryFetchRecords,
            hasError,
            allowReorder,
            sortBy,
            manualSortKey,
            attributes,
            labelStyle,
            embeddedByField,
            embeddedByFieldValue,
            embeddedByRecord,
            view,
            hasFilters,
            inlineFilterType,
            availableInlineFilterFields,
            isEmbedded,
            allFields,
            requestFilters,
            requestIncludedFields,
            clearFilters,
            coverImageField,
            profileImageField,
            titleSize,
            titleField,
            eyebrowField,
            subtitleField,
            cardSize,
            cardStyle,
            header,
            onRecordClick,
        }),
        [
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            pageIndex,
            pageSize,
            setPageIndex,
            isLoading,
            isFetchingSlow,
            retryFetchRecords,
            hasError,
            allowReorder,
            sortBy,
            manualSortKey,
            attributes,
            labelStyle,
            embeddedByField,
            embeddedByFieldValue,
            embeddedByRecord,
            view,
            hasFilters,
            inlineFilterType,
            availableInlineFilterFields,
            isEmbedded,
            allFields,
            requestFilters,
            requestIncludedFields,
            clearFilters,
            coverImageField,
            profileImageField,
            titleSize,
            titleField,
            eyebrowField,
            subtitleField,
            cardSize,
            cardStyle,
            header,
            onRecordClick,
        ]
    )

    return (
        <CardViewContext.Provider value={value}>
            <RecordEditManagerContextProvider
                records={records ?? []}
                addRecord={addRecord}
                updateRecord={updateRecord}
                deleteRecords={deleteRecords}
            >
                {children}
            </RecordEditManagerContextProvider>
        </CardViewContext.Provider>
    )
}
