import React from 'react'

import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'

import { usePercentageAttributeDisplayState } from './hooks/usePercentageAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type PercentageAttributeTagProps = React.ComponentPropsWithoutRef<typeof Tag> & {
    isLoading?: boolean
}

export const PercentageAttributeTag: AttributeDisplayComponent<
    number,
    PercentageAttributeTagProps
> = React.memo(function PercentageAttributeTag({ value, isLoading, style, ...props }) {
    const { content } = usePercentageAttributeDisplayState({ value, isLoading })

    return (
        <Skeleton isLoading={isLoading}>
            <Tag
                size="2xs"
                type="outline"
                weight="regular"
                style={{ wordBreak: 'break-word', ...style }}
                pointerEvents="none"
                noShrink
                {...props}
            >
                {content}
            </Tag>
        </Skeleton>
    )
})
