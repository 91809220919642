import { useQuery as _useQuery } from 'react-query'

import { fetchWithAuth } from 'data/utils/fetchWithAuth'

import { useCanRunWorkspaceScopedQueries, useQuery } from './_helpers'

const ENDPOINT = 'oauth-tokens/'

/**
 * Reads all oauth tokens that belong to the current user.
 */
export function useOAuthTokens() {
    const enabled = useCanRunWorkspaceScopedQueries()

    return useQuery<OAuthTokenDto[]>(
        'useOAuthTokens',
        ENDPOINT,
        { enabled },
        {
            bypassMatchingStackCheck: true,
            bypassPreviewAs: true,
        }
    )
}

/**
 * Checks if the token can still access the third party service.
 */
export function useOAuthTokenValidate(tokenSid?: string) {
    return _useQuery<boolean>(['validateToken', tokenSid], async (): Promise<boolean> => {
        if (!tokenSid) {
            return false
        }
        const response = await fetchWithAuth(ENDPOINT + tokenSid + '/validate/', {
            method: 'POST',
        })
        const { is_valid } = await response.json()
        return is_valid
    })
}
