import React, { useContext, useMemo } from 'react'

import { LayoutEditorBlock } from 'v2/blocks/types'

import AppContext from 'app/AppContext'
import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'
import { useAttributeRecordFilter } from 'features/records/components/AttributeFilter'
import { ListView } from 'features/views/ListView/ListView'

import CardsRenderer from './Cards/CardsRenderer'
import { RowsRenderer } from './Rows/RowsRenderer'
import { TableRenderer } from './Table/TableRenderer'
import { DataListBlockContext } from './DataListBlockContext'
import DataListBlockEditor from './DataListBlockEditor'
import { DataListBlockLoadingState } from './DataListBlockLoadingState'
import { DataListBlockNoObjectState } from './DataListBlockNoObjectState'
import { getDefaultPageSize, getPageSizesVariants } from './utils'

function getCustomRenderer(display: ListViewDisplay) {
    switch (display) {
        case 'rows':
            return RowsRenderer
        case 'card':
            return CardsRenderer
        case 'table':
            return TableRenderer
        default:
            return undefined
    }
}
type DataListBlockProps = {}

const CUSTOM_CONDITIONAL_VISIBILITY_ARG_NAME = 'conditionalVisibility'

const DataListBlock: LayoutEditorBlock<DataListBlockProps, DataListBlock> = (props) => {
    const { attrs, context } = props
    const widgetViewConfig = useMemo(
        () =>
            ({
                object_id: attrs.object_id,
                options: { ...attrs },
            } as unknown as ViewDto),
        [attrs]
    )

    const { isEditing } = context.editor

    const display = attrs.display

    const CustomRenderer = useMemo(() => {
        const Renderer = getCustomRenderer(display)
        if (!Renderer) return undefined
        return Renderer
    }, [display])

    const { selectedStack } = useContext(AppContext)

    // Conditional Visibility
    const passesFilters = useAttributeRecordFilter(
        attrs,
        context.record,
        CUSTOM_CONDITIONAL_VISIBILITY_ARG_NAME
    )
    if (passesFilters && !isEditing) return null

    return (
        <DataListBlockContext.Provider value={props}>
            <ListView
                view={widgetViewConfig}
                isRecordList
                context={context}
                showControls={false}
                relatedListType="all"
                relatedFieldMayCreateNewRecords={!attrs.hide_create_button}
                hideSearch={attrs.hide_search_bar}
                getPageSizeOptions={getPageSizesVariants}
                getDefaultPageSize={getDefaultPageSize}
                customListRenderer={CustomRenderer}
                customEmptyState={() => <DataListBlockNoObjectState />}
                customLoadingState={DataListBlockLoadingState}
                stack={selectedStack}
                allowDownload={attrs.allowDownload}
            />
        </DataListBlockContext.Provider>
    )
}

DataListBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: CUSTOM_CONDITIONAL_VISIBILITY_ARG_NAME,
        simple: true,
    }),
    configuratorWrapper({
        Element: DataListBlockEditor,
        simple: true,
        fullHeight: true,
        requireObject: false,
        fullWidth: true,
        hideLabel: true,
    }),
]

export default DataListBlock
