import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Redirect } from 'react-router-dom'

import QueryString from 'qs'
import { signIn } from 'supertokens-auth-react/recipe/emailpassword'

import { useAuthContext } from 'app/AuthContext/AuthContext'
import { getUrl, Urls } from 'app/UrlService'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Divider } from 'ui/components/Divider'
import { Input } from 'ui/components/Input'
import { Link } from 'ui/components/Link'

import { AuthFrame } from './AuthFrame'
import { GoogleLoginButton } from './GoogleLoginButton'

type LoginFormData = {
    email: string
    password: string
}
export function LoginPage() {
    const { user } = useAuthContext()
    const query = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormData>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: { email: query.email?.toString() ?? '' },
    })

    const [errorMessage, setErrorMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    async function onSubmit({ email, password }: LoginFormData) {
        try {
            setIsLoading(true)
            const response = await signIn({
                formFields: [
                    {
                        id: 'email',
                        value: email,
                    },
                    {
                        id: 'password',
                        value: password,
                    },
                ],
            })

            if (response.status === 'WRONG_CREDENTIALS_ERROR') {
                setErrorMessage('Invalid email or password')
            } else if (response.status !== 'OK') {
                console.log(response)
                setErrorMessage('Login failed. Please try again.')
            }
        } catch (ex) {
            console.error(ex)
            setErrorMessage('Login failed. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }

    if (user) {
        const redirect = decodeURIComponent(query.r?.toString() ?? '')
        return <Redirect to={redirect || getUrl(Urls.Root)} />
    }
    return (
        <AuthFrame title="Log in to Stacker">
            <GoogleLoginButton />
            <Box flex gap="m" center my="2xl" color="textWeakest">
                <Divider />
                <Box>or</Box>
                <Divider />
            </Box>
            <Box as="form" flex column gap="m" onSubmit={handleSubmit(onSubmit)}>
                <Input
                    placeholder="Email address"
                    {...register('email', { required: true })}
                    isError={errors?.email}
                    helperText={errors?.email && 'Email is required'}
                    autoFocus
                />
                <Input
                    placeholder="Password"
                    type="password"
                    {...register('password', { required: true, minLength: 8 })}
                    isError={errors?.password}
                    helperText={
                        errors?.password?.type === 'required'
                            ? 'Password is required'
                            : errors?.password?.type === 'minLength'
                            ? 'Must be at least 8 characters'
                            : ''
                    }
                />
                {errorMessage && (
                    <Box color="textError" fontSize="bodyM" alignSelf="center" mb="m">
                        {errorMessage}
                    </Box>
                )}
                <Link size="s" to={getUrl(Urls.ResetPassword)} alignSelf="center">
                    Forgot password?
                </Link>
                <Button variant="primary" mt="m" type="submit" isLoading={isLoading}>
                    Log in
                </Button>
                {/* <Body size="s" mt="m" alignSelf="center">
                    Don&apos;t have an account?{' '}
                    <Link size="s" to={getUrl(Urls.SignUp) + window.location.search}>
                        Sign up
                    </Link>
                </Body> */}
            </Box>
        </AuthFrame>
    )
}
