// @ts-strict-ignore
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { UseQueryResult } from 'react-query'

import isEqual from 'lodash/isEqual'

import { useAppContext } from 'app/AppContext'
import { useObject } from 'data/hooks/objects'
import { useGetRecord } from 'data/hooks/records'
import { invalidateRecord } from 'data/hooks/records/recordOperations'
import { useRealtimeObjectUpdates } from 'data/realtime/realtimeUpdates'

type WithRecordReturnProps = {
    object?: ObjectDto
    primaryField?: string
    record?: RecordDto
}

type WithRecordProps = {
    objectId?: string
    recordId: string
    options?: any[]
    children: (props: WithRecordReturnProps) => void
    loading?: JSX.Element
    disableRealTimeUpdates?: boolean
    reloadOnMount?: boolean
    onError?: (error: unknown) => void
    includeFields: string[]
}

export const WithRecord = ({
    recordId,
    children,
    loading,
    disableRealTimeUpdates = false,
    reloadOnMount = false,
    onError,
    includeFields,
}: WithRecordProps) => {
    const { data: record, isLoading: isRecordLoading } = useGetRecord({
        recordId,
        includeFields,
        useQueryOptions: { onError },
    }) as UseQueryResult<RecordDto>

    const { selectedStack } = useAppContext()

    const RecordObjectId = record?._object_id
    const { object, primaryField, schemaTimestamp } = useObject(RecordObjectId)
    const [currentRecord, setCurrentRecord] = useState(record)
    const [currentObject, setCurrentObject] = useState(object)
    const prevRecord = useRef(record)

    useEffect(() => {
        // Schema has changed
        if (record && record.schemaTimestamp !== schemaTimestamp) {
            invalidateRecord(recordId)
        }
    }, [record, recordId, schemaTimestamp])

    // Subscribe to any record updates
    useRealtimeObjectUpdates({
        stack: selectedStack,
        objectIds: [object?._sid],
        disabled: disableRealTimeUpdates,
        handler: () => {
            invalidateRecord(recordId)
        },
    })

    // This makes sure that we always fetch the most recent version of the record on mount
    useEffect(() => {
        if (reloadOnMount) invalidateRecord(recordId)
    }, [recordId, reloadOnMount])

    useEffect(() => {
        if (!isEqual(record, currentRecord)) {
            setCurrentRecord(record)
        }

        if (!isEqual(object, currentObject)) {
            setCurrentObject(object)
        }
    }, [record, object, currentObject, currentRecord])

    useEffect(() => {
        const prev = prevRecord.current
        if (record?._sid && record?._sid === recordId) {
            prevRecord.current = record
        }
        // We've changed record id
        if (prev?._sid !== recordId) {
            prevRecord.current = undefined
        }
    }, [record, prevRecord, recordId])

    const returnObject = useMemo(() => {
        return {
            object: currentObject,
            primaryField,
            record: isRecordLoading && prevRecord?.current ? prevRecord?.current : currentRecord,
        }
    }, [currentRecord, primaryField, currentObject, isRecordLoading])

    if ((isRecordLoading || !object) && loading) return loading

    if (!currentObject || !currentRecord) return null

    return <>{children(returnObject)}</>
}

export default WithRecord
